export const server = {
  API_URL: "https://backendbutiko.com/api/",
  // API_URL: "https://enviossendiback.azurewebsites.net/api/",
};
export const directionUsa = {
  nombre: "“Tu nombre”",
  direccion: "11751 SW 103RD LN CODE 169",
  ciudad: "Miami",
  estado: "Florida",
  codigoPostal: "33186",
  pais: "Estados Unidos",
  telefono: "(202) 977-3377",
};
