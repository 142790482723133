<template>
  <div
    :class="select.type === 2 ? 'ring-mainGreenLight' : 'ring-gray-100'"
    class="relative ring hover:ring-mainGreenLight shadow-sm rounded-2xl mb-8"
  >
    <div
      @click.prevent="selectDirection"
      class="border-b rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold cursor-pointer"
    >
      <div
        class="text-text-blue"
        v-if="
          data.nombre.length !== 0 &&
          data.apellido.length !== 0 &&
          data.direccion.length !== 0 &&
          data.departamento.length !== 0 &&
          data.telefono.length !== 0 &&
          data.documento.length !== 0 &&
          data.piso.length !== 0 &&
          data.referencia.length !== 0 &&
          data.provincia.length !== 0 &&
          data.distrito.length !== 0
        "
      >
        <p class="text-sm xl:text-base mb-2">
          {{ data.nombre }} {{ data.apellido }}
        </p>
        <p class="text-xs xl:text-sm mb-2">{{ data.direccion }}</p>
        <p class="text-xs xl:text-sm mb-2">{{ data.departamento }}</p>
        <p class="text-xs xl:text-sm mb-2">{{ data.telefono }}</p>
      </div>
      <p class="text-text-blue text-sm xl:text-base" v-else>Nueva Dirección</p>
      <span
        :class="
          select.type === 2
            ? 'bg-mainGreenLight text-white'
            : 'bg-gray-light text-gray-400'
        "
        class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl"
        >{{ select.type === 2 ? "Seleccionado" : "Seleccionar" }}</span
      >
    </div>
    <div class="px-2 py-6" v-show="form && select.type === 2">
      <div class="xl:grid grid-cols-2 gap-3">
        <div class="mb-5">
          <label
            for="nombre"
            class="block mb-2 font-semibold text-xs xl:text-sm"
            >Nombre</label
          >
          <input
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            :class="errorNewDirection.name ? 'ring ring-mainRed' : ''"
            type="text"
            placeholder="Ingresar el nombre"
            v-model="data.nombre"
          />
        </div>
        <div class="mb-5">
          <label
            for="apellidos"
            class="block mb-2 font-semibold text-xs xl:text-sm"
            >Apellidos</label
          >
          <input
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            :class="errorNewDirection.apellido ? 'ring ring-mainRed' : ''"
            type="text"
            placeholder="Ingresar el apellido"
            v-model="data.apellido"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-3">
        <div class="mb-5">
          <label
            for="documento"
            class="block mb-2 font-semibold text-xs xl:text-sm"
            >Documento</label
          >
          <input
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            :class="errorNewDirection.documento ? 'ring ring-mainRed' : ''"
            type="tel"
            placeholder="Ingresar el documento"
            v-model="data.documento"
            @keypress="onlyNumber"
            @keydown="onlyNumber"
          />
        </div>
        <div class="mb-5">
          <label
            for="telefono"
            class="block mb-2 font-semibold text-xs xl:text-sm"
            >Teléfono</label
          >
          <input
            class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            :class="errorNewDirection.telefono ? 'ring ring-mainRed' : ''"
            type="number"
            placeholder="Ingresar el teléfono"
            v-model="data.telefono"
            @keypress="onlyNumber"
            @keydown="onlyNumber"
          />
        </div>
      </div>

      <div class="mb-5" v-if="direction === false">
        <label
          for="direccion"
          class="block mb-2 font-semibold text-xs xl:text-sm"
          >Dirección</label
        >
        <GMapAutocomplete
          placeholder="Ingrese la dirección"
          @place_changed="setPlace"
          :class="
            error.direccion || errorNewDirection.direccion
              ? 'ring ring-mainRed'
              : ''
          "
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
        >
        </GMapAutocomplete>
        <span
          v-if="error.direccion || errorNewDirection.direccion"
          class="text-mainRed text-xs pt-1 pl-7 font-medium"
          >*Ingrese y seleccione su dirección</span
        >
      </div>
      <div class="mb-5" v-if="direction">
        <label
          for="direccion"
          class="block mb-2 font-semibold text-xs xl:text-sm"
          >Dirección</label
        >
        <input
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          :class="
            error.direccion || errorNewDirection.direccion
              ? 'ring ring-mainRed'
              : ''
          "
          type="text"
          placeholder="Ingrese la dirección"
          @keypress="updateDirection"
          v-model="data.direccion"
        />
        <span
          v-if="error.direccion || errorNewDirection.direccion"
          class="text-mainRed text-xs pt-1 pl-7 font-medium"
          >*Ingrese y seleccione su dirección</span
        >
      </div>
      <div class="mb-5">
        <label
          for="referencia"
          class="block mb-2 font-semibold text-xs xl:text-sm"
          ># Piso u Oficina</label
        >
        <input
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          :class="errorNewDirection.piso ? 'ring ring-mainRed' : ''"
          type="text"
          placeholder="Ingresar piso u oficina"
          v-model="data.piso"
        />
      </div>
      <div class="mb-5">
        <label
          for="referencia"
          class="block mb-2 font-semibold text-xs xl:text-sm"
          >Referencia</label
        >
        <input
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          :class="errorNewDirection.referencia ? 'ring ring-mainRed' : ''"
          type="text"
          placeholder="Ingresar referencia"
          v-model="data.referencia"
        />
      </div>

      <div class="mb-5">
        <label
          for="departamento"
          class="block mb-2 font-semibold text-xs xl:text-sm"
          >Departamento</label
        >
        <input
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          :class="errorNewDirection.departamento ? 'ring ring-mainRed' : ''"
          type="text"
          placeholder="Ingrese el departamento"
          v-model="data.departamento"
        />
      </div>

      <div class="mb-5">
        <label
          for="provincia"
          class="block mb-2 font-semibold text-xs xl:text-sm"
          >Provincia</label
        >
        <input
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          :class="errorNewDirection.provincia ? 'ring ring-mainRed' : ''"
          type="text"
          placeholder="Ingrese la provincia"
          v-model="data.provincia"
        />
      </div>

      <div class="mb-5">
        <label
          for="distrito"
          class="block mb-2 font-semibold text-xs xl:text-sm"
          >Distrito</label
        >
        <input
          class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
          :class="errorNewDirection.distrito ? 'ring ring-mainRed' : ''"
          type="text"
          placeholder="Ingrese el distrito"
          v-model="data.distrito"
        />
      </div>
      <button
        @click.prevent="selectDirection"
        class="bg-secondary-blue block w-10/12 mx-auto text-center p-5 font-semibold rounded-2xl text-white"
      >
        Guardar
      </button>
      <!-- <div class="absolute inset-x-0 -bottom-4">
        <figure
          @click.prevent="selectDirection"
          class="cursor-pointer mx-auto w-8 h-8 p-2 rounded-full bg-white border-2 shadow-lg items-center justify-center"
        >
          <img
            src="../../../../../assets/icons/drop-up-blue.svg"
            alt="Icono de Envios USA"
          />
        </figure>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  props: ["select", "errorNewDirection", "directionIguals"],
  data() {
    return {
      form: false,
      statusMap: false,
      center: { lat: -12.080730407219086, lng: -77.03606970955586 },
      markers: [
        {
          position: {
            lat: -12.080730407219086,
            lng: -77.03606970955586,
          },
        }, // Along list of clusters
      ],
      direction: false,
      error: {
        direccion: false,
      },
      data: {
        nombre: "",
        apellido: "",
        documento: "",
        telefono: "",
        direccion: "",
        referencia: "",
        piso: "",
        departamento: "",
        provincia: "",
        distrito: "",
        lat: 0,
        lng: 0,
        free: 0,
      },
    };
  },
  mounted() {
    this.getDirectionLocal();
  },
  methods: {
    getDirectionLocal() {
      const direction = localStorage.getItem("direction");
      if (direction !== null) {
        if (this.directionIguals === false) {
          const dataDirection = JSON.parse(direction);
          this.data.direccion = dataDirection.nombre;
          this.data.lat = dataDirection.lat;
          this.data.lng = dataDirection.lng;
          this.data.departamento = dataDirection.departamento;
          this.data.provincia = dataDirection.provincia;
          this.data.distrito = dataDirection.distrito;
          this.data.free = dataDirection.location;
          this.center.lat = dataDirection.lat;
          this.center.lng = dataDirection.lng;
          this.markers[0].position.lat = dataDirection.lat;
          this.markers[0].position.lng = dataDirection.lng;
          this.selectDirection();
          this.direction = true;
        } else {
          this.data.direccion = "";
          this.data.lat = 0;
          this.data.lng = 0;
          this.data.departamento = "";
          this.data.provincia = "";
          this.data.distrito = "";
          this.data.free = 0;
          this.center.lat = -12.080730407219086;
          this.center.lng = -77.03606970955586;
          this.markers[0].position.lat = -12.080730407219086;
          this.markers[0].position.lng = -77.03606970955586;
        }
      }
    },
    directionMap() {
      this.statusMap = !this.statusMap;
    },
    updateDirection() {
      this.direction = false;
      this.data.direccion = "";
    },
    setPlace(place) {
      this.data.direccion = place.name;
      //--------------------------------//
      this.center.lat = place.geometry.location.lat();
      this.center.lng = place.geometry.location.lng();
      this.markers[0].position.lat = place.geometry.location.lat();
      this.markers[0].position.lng = place.geometry.location.lng();
      //---------------------------------//
      this.data.lat = place.geometry.location.lat();
      this.data.lng = place.geometry.location.lng();
      //Obtener distrito-departamento-provincia
      var addressComponents = place.address_components;
      var departamento = "";
      var provincia = "";
      var distrito = "";
      var country = "";
      var location = 0;

      addressComponents.forEach((value) => {
        if (value.types[0] === "administrative_area_level_1") {
          departamento = value.long_name;
        }
        if (value.types[0] === "administrative_area_level_2") {
          provincia = value.long_name;
        }
        if (value.types[0] === "locality") {
          distrito = value.long_name;
        }
        if (value.types[0] === "country") {
          country = value.short_name;
        }

        //Determinamos si la direcciòn pertenece a Lima
        if (
          value.long_name === "Gobierno Regional de Lima" ||
          value.long_name === "Provincia de Lima"
        ) {
          location = 1;
        }
      });
      if (country !== "PE" || country === "") {
        this.data.direccion = "";
        this.data.lat = 0;
        this.data.lng = 0;
        this.error.direccion = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Solo direcciones de Perú";
        this.$store.state.alert.estado = true;
        //--------------------------//

        this.center.lat = -12.080730407219086;
        this.center.lng = -77.03606970955586;
        this.markers[0].position.lat = -12.080730407219086;
        this.markers[0].position.lng = -77.03606970955586;
      } else {
        this.error.direccion = false;
        this.selectDirection2();
      }
      this.data.departamento = departamento;
      this.data.provincia = provincia;
      this.data.distrito = distrito;
      this.data.free = location;
    },
    selectDirection() {
      this.form = !this.form;
      this.$emit("new", { type: 2, datos: this.data });
      this.$emit("changeDirection");
      window.scrollTo(0, 0);
    },
    selectDirection2() {
      this.$emit("new", { type: 2, datos: this.data });
      this.$emit("changeDirection");
    },
  },
  watch: {
    "select.type": function (val) {
      if (val !== 2) {
        this.form = false;
      }
    },
    directionIguals: function () {
      this.getDirectionLocal();
    },
  },
};
</script>
