<template>
  <div
    :class="preloadShooper ? '' : 'hidden'"
    class="mb-16 relative grid md:grid-cols-3 gap-4 xl:gap-8 lg:px-8"
  >
    <!-- CARD - 1 -->
    <div
      class="animate-pulse w-full border shadow-md rounded-3xl p-4 xl:py-6 xl:px-6"
    >
      <div class="w-full bg-gray-200 h-32 mb-6"></div>
      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-1/2 h-4 bg-gray-200 mb-8"></span>
      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-full h-4 bg-gray-200 mb-8"></span>
      <div class="flex justify-between items-end">
        <span class="block w-1/3 h-4 bg-gray-200"></span>
        <span class="block w-1/3 h-4 bg-gray-200"></span>
      </div>
    </div>
    <!-- CARD - 2 -->
    <div
      class="animate-pulse w-full border shadow-md rounded-3xl p-4 xl:py-6 xl:px-6"
    >
      <div class="w-full bg-gray-200 h-32 mb-6"></div>
      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-1/2 h-4 bg-gray-200 mb-8"></span>

      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-full h-4 bg-gray-200 mb-8"></span>

      <div class="flex justify-between items-end">
        <span class="block w-1/3 h-4 bg-gray-200"></span>
        <span class="block w-1/3 h-4 bg-gray-200"></span>
      </div>
    </div>
    <!-- CARD - 3 -->
    <div
      class="animate-pulse w-full border shadow-md rounded-3xl p-4 xl:py-6 xl:px-6"
    >
      <div class="w-full bg-gray-200 h-32 mb-6"></div>
      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-1/2 h-4 bg-gray-200 mb-8"></span>

      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-full h-4 bg-gray-200 mb-8"></span>

      <div class="flex justify-between items-end">
        <span class="block w-1/3 h-4 bg-gray-200"></span>
        <span class="block w-1/3 h-4 bg-gray-200"></span>
      </div>
    </div>
    <!-- CARD - 4 -->
    <div
      class="animate-pulse w-full border shadow-md rounded-3xl p-4 xl:py-6 xl:px-6"
    >
      <div class="w-full bg-gray-200 h-32 mb-6"></div>
      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-1/2 h-4 bg-gray-200 mb-8"></span>

      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-full h-4 bg-gray-200 mb-8"></span>

      <div class="flex justify-between items-end">
        <span class="block w-1/3 h-4 bg-gray-200"></span>
        <span class="block w-1/3 h-4 bg-gray-200"></span>
      </div>
    </div>
    <!-- CARD - 5 -->
    <div
      class="animate-pulse w-full border shadow-md rounded-3xl p-4 xl:py-6 xl:px-6"
    >
      <div class="w-full bg-gray-200 h-32 mb-6"></div>
      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-1/2 h-4 bg-gray-200 mb-8"></span>

      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-full h-4 bg-gray-200 mb-8"></span>

      <div class="flex justify-between items-end">
        <span class="block w-1/3 h-4 bg-gray-200"></span>
        <span class="block w-1/3 h-4 bg-gray-200"></span>
      </div>
    </div>
    <!-- CARD - 6 -->
    <div
      class="animate-pulse w-full border shadow-md rounded-3xl p-4 xl:py-6 xl:px-6"
    >
      <div class="w-full bg-gray-200 h-32 mb-6"></div>
      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-1/2 h-4 bg-gray-200 mb-8"></span>

      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-full h-4 bg-gray-200 mb-2"></span>
      <span class="block w-full h-4 bg-gray-200 mb-8"></span>

      <div class="flex justify-between items-end">
        <span class="block w-1/3 h-4 bg-gray-200"></span>
        <span class="block w-1/3 h-4 bg-gray-200"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["preloadShooper"],
  data() {
    return {};
  },

  created() {},
  methods: {},
};
</script>
