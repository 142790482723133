<template>
  <article class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
    <div
      class="flex gap-10 items-center mb-10 justify-between xl:justify-start"
    >
      <h3 class="text-xl xl:text-3xl font-bold text-bg-black-blue">Mi Saldo</h3>

      <div class="flex items-center gap-3">
        <p
          class="text-sm xl:text-lg font-semibold py-4 px-6 bg-gray-100 rounded-2xl no-underline"
        >
          USD <span class="text-mainGreenLight">$ {{ totalBalance }}</span>
        </p>

        <div class="tooltip hidden xl:block">
          <img src="../../assets/icons/icon-info.svg" alt="" />
          <span class="tooltip-box text-xs font-normal bottom-8 -left-8"
            >Podrás usar este saldo en tus compras</span
          >
        </div>
      </div>
    </div>
    <div>
      <header
        class="grid gap-3 xl:grid-cols-6 p-5 bg-bg-black text-white font-bold text-xs xl:text-sm rounded-tr-2xl rounded-tl-2xl"
      >
        <p class="text-center xl:hidden">Dashboard</p>
        <p class="hidden xl:block">N° Operación</p>
        <p class="hidden xl:block">Descripción</p>
        <p class="hidden xl:block">Método de pago</p>
        <p class="hidden xl:block">Estado</p>
        <p class="hidden xl:block">Fecha</p>
        <p class="hidden xl:block">Monto</p>
      </header>
      <div v-for="balance in dataBalance" :key="balance">
        <div
          class="grid items-center gap-3 xl:grid-cols-6 p-5 border-b text-sm xl:text-base"
        >
          <p
            class="font-semibold xl:font-normal flex justify-between items-center xl:block"
          >
            EUP-{{ balance.id }}
            <span
              :class="balance.signo ? 'text-main-green ' : 'text-main-red'"
              class="text-right xl:text-left font-semibold xl:hidden"
            >
              $ {{ balance.attributes.amount }}
            </span>
          </p>
          <p>
            <span class="font-semibold block xl:hidden">Descripción:</span>
            <span v-if="balance.attributes.type === 1" class="font-medium"
              >Abono</span
            >
            <span v-if="balance.attributes.type === 2" class="font-medium"
              >Cargo</span
            >
            <span v-if="balance.attributes.type === 3" class="font-medium"
              >Compensación</span
            >

            <span
              :class="
                balance.attributes.type_balance === 1
                  ? 'bg-green-100 border-green-300 text-green-700'
                  : 'bg-blue-100 border-blue-300 text-blue-700'
              "
              class="block w-max my-1 text-xs font-medium px-2 py-1 rounded-md"
            >
              {{
                balance.attributes.type_balance === 1
                  ? "Saldo disponible"
                  : "Saldo contable"
              }}
            </span>

            <span class="text-xs text-gray-400 block font-semibold">
              {{ balance.attributes.titleOperation
              }}<br v-if="balance.attributes.titleOperation" />{{
                balance.attributes.descriptionOperation
              }}<br v-if="balance.attributes.descriptionOperation" />
              {{
                balance.attributes.purchaseorder_id
                  ? " N° orden #" + balance.attributes.purchaseorder_id
                  : ""
              }}</span
            >
          </p>
          <p>
            <span class="font-semibold block xl:hidden">Método de pago:</span>
            <span class="block">{{ balance.attributes.payment_method }}</span>
          </p>
          <p>
            <span class="font-semibold block xl:hidden">Estado:</span>
            <span class="flex gap-2 mb-3 items-center tooltip no-underline">
              <img src="../../assets/icons/icon-pago-status.png" alt="" />
              <span>
                <span
                  v-if="balance.attributes.status === 1"
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-gray-400 bg-gray-400 bg-opacity-20"
                  >PENDIENTE</span
                >
                <span
                  v-else-if="balance.attributes.status === 2"
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-mainOrange bg-mainOrange bg-opacity-20"
                  >EN PROCESO</span
                >
                <span
                  v-else-if="balance.attributes.status === 3"
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-mainGreenLight bg-mainGreenLight bg-opacity-20"
                  >CONFIRMADO</span
                >
                <span
                  v-else-if="balance.attributes.status === 10"
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-main-red bg-main-red bg-opacity-20"
                  >RECHAZADO"</span
                >
                <span
                  v-else-if="balance.attributes.status === 11"
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-mainOrange bg-mainOrange bg-opacity-20"
                  >RECLAMADO</span
                >
                <span
                  v-else-if="balance.attributes.status === 12"
                  class="font-semibold text-xs px-2 py-1 rounded-xl text-gray-400 bg-gray-400 bg-opacity-20"
                  >RETORNADO</span
                >
              </span>
              <small class="tooltip-box">Estado de pago</small>
            </span>
          </p>
          <p>
            <span class="font-semibold block xl:hidden">Fecha:</span>
            {{ balance.attributes.date }} <br />
            {{ balance.hour }}
          </p>
          <p
            :class="balance.signo ? 'text-main-green ' : 'text-main-red'"
            class="text-right xl:text-left font-semibold hidden xl:block"
          >
            $ {{ balance.attributes.amount }}
          </p>
        </div>
      </div>

      <p class="font-medium text-center pb-8 pt-14" v-if="loading">
        Cargando su saldo...
      </p>
      <p
        class="font-medium text-center pb-8 pt-14"
        v-else-if="dataBalance.length == 0"
      >
        No tienes ningún saldo registrado
      </p>
      <!-- Pagination -->
      <div class="mt-12 flex justify-end items-center gap-12">
        <p>
          Pág.
          <span class="mx-3 py-1 px-3 ring ring-blue-300">{{
            dataTable.from
          }}</span>
          de
          {{ dataTable.page }}
        </p>
        <div class="flex items-center gap-4">
          <figure
            v-if="dataTable.pastStatus"
            @click.prevent="getBalanceUser('anterior', pagePast)"
            class="cursor-pointer p-3"
          >
            <img src="../../assets/icons/chevron-left-orange.png" />
          </figure>
          <figure
            v-if="dataTable.nextStatus"
            @click.prevent="getBalanceUser('posterior', pageNext)"
            class="cursor-pointer p-3"
          >
            <img src="../../assets/icons/chevron-right-orange.png" />
          </figure>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
//Importando serve
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
export default {
  props: ["id"],
  data() {
    return {
      loading: true,

      dataBalance: [],
      totalBalance: 0.0,
      pageNext: 1,
      pagePast: 1,

      dataTable: {
        pagination: 4,
        page: 0,
        from: 0,
        next: "",
        past: "",
        nextStatus: false,
        pastStatus: false,
      },
    };
  },
  mounted() {
    this.getBalanceUser();
  },
  methods: {
    getBalanceUser(type, data) {
      var dataTable = 1;
      if (type === "anterior") {
        dataTable = data;
      }
      if (type === "posterior") {
        dataTable = data;
      }
      axios
        .get(
          `${BASE_URL}` +
            "balance/getClientBalance/" +
            this.id +
            `?orderBy=created_at&length=${this.dataTable.pagination}&page=${dataTable}`
        )
        .then((response) => {
          this.loading = false;

          this.dataBalance = response.data.data.balance_list;
          this.totalBalance = response.data.balance;
          this.dataBalance.forEach((value) => {
            const typeNumbre = Math.sign(parseFloat(value.attributes.amount));

            if (typeNumbre === 1) {
              value.signo = true;
            }
            if (typeNumbre === -1) {
              value.signo = false;
            }
            //Separamos la hora d ela fecha
            // var result = value.attributes.created_at.date.split(" ");
            // value.hour = result[1].slice(0, 8);

            var result = value.attributes.created_at.split("T");
            value.hour = result[1].slice(0, 8);

            // value.attributes.date = value.attributes.date.split("").reverse().join("");
          });

          //Paginación
          this.dataTable.page = response.data.meta.last_page;
          this.dataTable.from = response.data.meta.current_page;
          this.dataTable.next = response.data.links.next;
          this.dataTable.past = response.data.links.prev;
          if (this.dataTable.next !== null) {
            let separator = this.dataTable.next.split("?page=");
            this.pageNext = separator[1];
            this.dataTable.nextStatus = true;
          } else {
            this.dataTable.nextStatus = false;
          }
          if (this.dataTable.past !== null) {
            let separator = this.dataTable.past.split("?page=");
            this.pagePast = separator[1];
            this.dataTable.pastStatus = true;
          } else {
            this.dataTable.pastStatus = false;
          }
        })
        .catch((error) => {
          //----------component--------//
          this.$store.state.alert.titulo = "Error";
          this.$store.state.alert.description =
            "No se pudo mostrar su informacion";
          this.$store.state.alert.estado = true;
          //--------------------------//
          console.log("Error: ", error);
        });
    },
  },
};
</script>
