<template>
  <span v-if="saldoTotalStatus">
    <div
      class="text-text-blue relative ring ring-gray-100 shadow-sm rounded-2xl mb-8 bg-gray-500 bg-opacity-20 cursor-not-allowed"
    >
      <div
        class="rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold"
      >
        <p class="text-xs xl:text-base flex items-center gap-2 xl:gap-4">
          <img src="../../../../../assets/icons/bank.svg" class="w-7" />
          <span>
            PagoEfectivo
            <span style="font-size: 10px" class="block text-gray-400">
              Paga sin tarjeta</span
            >
          </span>
        </p>
        <span
          class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl bg-gray-light text-gray-400"
          >Seleccionar</span
        >
      </div>
    </div>
  </span>
  <!-- //_-------------------------------------------------// -->
  <span v-else>
    <div
      @click.prevent="selectPayment"
      :class="payment === 1 ? 'ring-mainGreenLight' : 'ring-gray-100'"
      class="text-text-blue relative ring ring-gray-100 hover:ring-main-green shadow-sm rounded-2xl mb-8"
    >
      <div
        class="tooltip no-underline rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold cursor-pointer"
      >
        <p class="text-xs xl:text-base flex items-center gap-2 xl:gap-4">
          <img
            src="../../../../../assets/icons/pago-efectivo.png"
            class="w-7"
          />
          <span>
            PagoEfectivo
            <span style="font-size: 10px" class="block text-gray-400"
              >Paga sin tarjeta</span
            >
          </span>
        </p>
        <span
          :class="
            payment === 1
              ? 'bg-mainGreenLight text-white'
              : 'bg-gray-light text-gray-400'
          "
          class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl"
          >{{ payment === 1 ? "Seleccionado" : "Seleccionar" }}</span
        >
        <span class="tooltip-box font-medium bottom-20">
          Paga sin tarjeta. Recibes un codigo de pago CIP y te damos la libertad
          de elegir dónde y cómo pagar. <br />
          <ul class="list-disc pl-8 pt-3">
            <li>Billetera digital</li>
            <li>Bancapor internet y movil</li>
            <li>Agencias y agentes</li>
          </ul>
        </span>
      </div>
    </div>
  </span>
</template>
<script>
export default {
  props: ["payment"],
  methods: {
    selectPayment() {
      this.$emit("changePayment", 1);
      this.$emit("sendChangePayment");
    },
  },
  computed: {
    saldoTotalStatus() {
      return this.$store.state.saldoTotalStatu;
    },
  },
};
</script>
