<template>
  <div
    class="px-4 py-6 xl:px-9 rounded-2xl bg-white border shadow-lg xl:border-none xl:shadow-none"
  >
    <div class="xl:flex items-center justify-between mb-10">
      <h3 class="text-text-blue font-semibold xl:text-lg mb-6 xl:mb-0">
        Pagar con:
      </h3>
      <span v-if="saldoTotalStatus">
        <div
          class="lg:w-max bg-white rounded-2xl border shadow-sm grid grid-cols-2"
        >
          <button
            class="font-semibold py-4 px-6 rounded-2xl cursor-not-allowed"
          >
            Soles (S/.)
          </button>
          <button
            class="font-semibold py-4 px-6 bg-gray-300 text-white rounded-2xl cursor-not-allowed"
          >
            Dólares ($)
          </button>
        </div>
      </span>
      <span v-else>
        <div
          class="p-1 rounded-2xl bg-gray-100 bg-opacity-50 shadow-sm grid grid-cols-2"
        >
          <button
            @click.prevent="changeMoney('PEN')"
            :class="
              moneda === 'PEN'
                ? 'bg-mainOrange text-white'
                : 'bg-gray-100 text-gray-400'
            "
            class="font-semibold py-5 xl:px-12 rounded-2xl text-xs xl:text-base"
          >
            Soles (S/.)
          </button>
          <button
            @click.prevent="changeMoney('USD')"
            :class="
              moneda === 'USD'
                ? 'bg-mainOrange text-white'
                : 'bg-gray-100 text-gray-400'
            "
            class="font-semibold py-5 xl:px-12 rounded-2xl text-xs xl:text-base"
          >
            Dólares ($)
          </button>
        </div>
      </span>
    </div>

    <!-- Tarjeta de crédito -->
    <tarjeta-component
      v-if="moneda === 'PEN'"
      :payment="payment"
      :data="data"
      :calculoTarifa="calculoTarifa"
      :terminos="terminos"
      :moneda="moneda"
      :selectDirection="selectDirection"
      @changePayment="payment = $event"
      @sendChangePayment="changeTypePayment"
      @next="next = $event"
      @paymentMercadoPago="paymentMercadoPago"
      @purchaseData="dataMercadoPago = $event"
      @ResetFormTarjeta="resetTarjeta"
    />
    <!-- Agentes y agencias -->
    <atm-component
      v-show="moneda === 'PEN'"
      :payment="payment"
      @changePayment="payment = $event"
      @sendChangePayment="changeTypePayment"
    />
    <!-- Transferencia bancaria -->
    <transferencia-component
      v-show="moneda === 'USD' || moneda === 'PEN'"
      :payment="payment"
      :moneda="moneda"
      @changePayment="payment = $event"
      @sendChangePayment="changeTypePayment"
    />
    <!-- Paypal -->
    <paypal-component
      v-show="moneda === 'USD'"
      :payment="payment"
      @changePayment="payment = $event"
      @sendChangePayment="changeTypePayment"
    />
  </div>
</template>
<script>
import paypalComponent from "../payment/paypal/paypalComponent.vue";
import transferenciaComponent from "../payment/transferencia/transferenciaComponent.vue";
import tarjetaComponent from "../payment/tarjeta/tarjetaComponent.vue";
import atmComponent from "../payment/atm/atmComponent.vue";
export default {
  props: ["moneda", "data", "calculoTarifa", "terminos", "selectDirection"],
  components: {
    paypalComponent,
    transferenciaComponent,
    tarjetaComponent,
    atmComponent,
  },
  data() {
    return {
      dataMercadoPago: {},
      next: 0,
      payment: 0,
    };
  },
  methods: {
    resetTarjeta() {
      this.payment = 0;
      this.$emit("changeMoney", "USD");
      this.changeTypePayment();
    },
    paymentMercadoPago() {
      this.$emit("next", this.next);
      this.$emit("purchaseData", this.dataMercadoPago);
      this.$emit("paymentMercadoPago");
    },
    changeMoney(data) {
      this.$emit("changeMoney", data);
    },
    changeTypePayment() {
      this.$emit("typePayment", this.payment);
      this.$emit("changePayment");
    },
  },
  computed: {
    saldoTotalStatus() {
      return this.$store.state.saldoTotalStatu;
    },
  },
};
</script>
