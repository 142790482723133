import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Tracking from "../views/Tracking.vue";
import Perfil from "../views/Perfil.vue";
import Pedido from "../views/Pedido.vue";
import Verification from "../views/Verification.vue";
import ResetPassword from "../views/ResetPassword.vue";
import SearchShooper from "../views/SearchShooper.vue";
import Shooper from "../views/Shooper.vue";
import Courier from "../views/Courier.vue";
import CompraSegura from "../views/CompraSegura.vue";
import Tiendas from "../views/Tiendas.vue";
import Afiliados from "../views/Alifiados.vue";
import Complaints from "../views/Complaints.vue";
import Ayuda from "../views/Ayuda.vue";
import auth from "../middleware/auth";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/traking/:code",
    name: "Tracking",
    component: Tracking,
  },
  {
    path: "/process/courier/:precio/:peso:/:unidad/:typeCotizacion",
    name: "Courier",
    component: Courier,
  },
  {
    path: "/process/shooper/:url",
    name: "Shooper",
    component: Shooper,
  },
  {
    path: "/shooper/product/:name",
    name: "SearchShooper",
    component: SearchShooper,
  },
  {
    path: "/user/:vista",
    name: "Perfil",
    component: Perfil,
    beforeEnter: auth,
  },
  {
    path: "/detail/:idPedido",
    name: "DetallePedido",
    component: Pedido,
    beforeEnter: auth,
  },
  {
    path: "/resetPassword/:token",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/verification/account/:token",
    name: "Verification",
    component: Verification,
  },
  {
    path: "/compra/segura",
    name: "CompraSegura",
    component: CompraSegura,
  },
  {
    path: "/guia/tiendas",
    name: "Tiendas",
    component: Tiendas,
  },
  {
    path: "/afiliados/:code",
    name: "Afiliados",
    component: Afiliados,
  },
  {
    path: "/complaints",
    name: "Complaints",
    component: Complaints,
  },
  {
    path: "/ayuda",
    name: "Ayuda",
    component: Ayuda,
    beforeEnter: auth,
  },

  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
