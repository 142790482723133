<template>
  <article
    class="fixed inset-x-0 bottom-0 xl:relative bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 xl:mb-8"
    style="z-index: 1"
  >
    <h3 class="text-text-blue font-semibold xl:text-lg mb-5 hidden xl:block">
      Detalles de pago
      <span
        class="cursor-pointer font-normal text-sm text-mainOrange"
        @click.prevent="openAll"
        >{{ arrowGeneral ? "(Ver más)" : "(Ver menos)" }}</span
      >
    </h3>

    <div class="py-4 px-5 rounded-2xl border mb-4">
      <span :class="arrowGeneral ? '' : 'hidden'" class="xl:block">
        <p
          @click.prevent="openStatusSelect('cif')"
          class="cursor-pointer text-xs text-bg-black-blue xl:text-sm font-bold flex items-center justify-between"
        >
          <span>{{ data.servicio === 1 ? "Gastos en usa" : "Producto" }}</span>
          <span class="flex items-center gap-3">
            <span v-if="data.servicio === 1">
              {{ moneda === "USD" ? "$" : "S/." }}
              {{
                moneda === "USD"
                  ? data.dolares.primera.frontTotal
                  : data.soles.primera.frontTotal
              }}
            </span>
            <span v-if="data.servicio === 2">
              {{ moneda === "USD" ? "$" : "S/." }}
              {{
                moneda === "USD"
                  ? data.dolares.primera.total
                  : data.soles.primera.total
              }}
            </span>
            <img
              :class="!style.statusArrowCif ? 'transform rotate-180' : ''"
              src="../../../assets/icons/chevron-down-orange.png"
              alt=""
            />
          </span>
        </p>
        <hr class="my-4" />
        <div
          :class="style.statusArrowCif ? 'hidden' : ''"
          class="text-sm text-gray-400 grid gap-3 pr-6"
        >
          <p
            class="flex items-center justify-between"
            v-if="data.servicio === 2"
          >
            <span class="flex gap-3">
              <img
                src="../../../assets/icons/flecha-enter.svg"
                class="w-2"
                alt=""
              />
              Precio del producto
            </span>
            <span
              >{{ moneda === "USD" ? "$" : "S/." }}
              {{
                moneda === "USD"
                  ? data.dolares.primera.precio
                  : data.soles.primera.precio
              }}</span
            >
          </p>
          <p
            class="flex items-center justify-between"
            v-if="data.servicio === 2"
          >
            <span class="flex gap-3 underline tooltip">
              <img
                src="../../../assets/icons/flecha-enter.svg"
                class="w-2"
                alt=""
              />
              <span class="flex gap-3">
                Shipping
                <img
                  src="../../../assets/icons/icon-question.svg"
                  alt="Icono de Sendi"
                />
              </span>
              <span class="tooltip-box"
                >Cobrado por la tienda o proveedor por enviar su compra al
                warehouse</span
              >
            </span>
            <span
              >{{ moneda === "USD" ? "$" : "S/." }}
              {{
                moneda === "USD"
                  ? data.dolares.primera.shipping
                  : data.soles.primera.shipping
              }}</span
            >
          </p>
          <p
            class="flex items-center justify-between"
            v-if="data.servicio === 2"
          >
            <span class="flex gap-3 underline tooltip">
              <img
                src="../../../assets/icons/flecha-enter.svg"
                class="w-2"
                alt=""
              />

              <span class="flex gap-3">
                Tax
                <img
                  src="../../../assets/icons/icon-question.svg"
                  alt="Icono de Sendi"
                />
              </span>
              <span class="tooltip-box"
                >Impuestos de ley que el gobierno de USA cobra a todas las
                personas</span
              >
            </span>
            <span
              >{{ moneda === "USD" ? "$" : "S/." }}
              {{
                moneda === "USD"
                  ? data.dolares.primera.tax
                  : data.soles.primera.tax
              }}</span
            >
          </p>
          <p
            class="flex items-center justify-between"
            v-if="data.servicio === 1 || data.servicio === 2"
          >
            <span class="flex gap-3 underline tooltip">
              <img
                src="../../../assets/icons/flecha-enter.svg"
                class="w-2"
                alt=""
              />
              <span class="flex gap-3">
                Warehouse
                <img
                  src="../../../assets/icons/icon-question.svg"
                  alt="Icono de Sendi"
                />
              </span>
              <span class="tooltip-box"
                >Cobrado por la logistica de almacenaje en USA</span
              >
            </span>
            <span>
              {{ moneda === "USD" ? "$" : "S/." }}
              <span>
                {{
                  moneda === "USD"
                    ? data.dolares.primera.warehouse
                    : data.soles.primera.warehouse
                }}
              </span>
            </span>
          </p>
          <p
            class="flex items-center justify-between"
            v-if="data.servicio === 1 || data.servicio === 2"
          >
            <span class="flex gap-3 underline tooltip">
              <img
                src="../../../assets/icons/flecha-enter.svg"
                class="w-2"
                alt=""
              />
              <span class="flex gap-3">
                Flete
                <img
                  src="../../../assets/icons/icon-question.svg"
                  alt="Icono de Sendi"
                />
              </span>
              <span class="tooltip-box"
                >Cobrado por el transporte aereo del aeropuerto de USA a
                Perú</span
              >
            </span>
            <span
              >{{ moneda === "USD" ? "$" : "S/." }}
              {{
                moneda === "USD"
                  ? data.dolares.primera.flete
                  : data.soles.primera.flete
              }}</span
            >
          </p>
          <hr class="my-3" />
        </div>

        <span v-if="data.dolares.segundo.estado === 1">
          <p
            @click.prevent="openStatusSelect('impuestos')"
            class="cursor-pointer text-xs xl:text-sm font-bold text-bg-black-blue flex items-center justify-between"
          >
            <span>Impuestos</span>
            <span class="flex items-center gap-3">
              {{ moneda === "USD" ? "$" : "S/." }}

              {{
                moneda === "USD"
                  ? data.dolares.segundo.total
                  : data.soles.segundo.total
              }}
              <img
                :class="
                  !style.statusArrowImpuesto ? 'transform rotate-180' : ''
                "
                src="../../../assets/icons/chevron-down-orange.png"
                alt=""
              />
            </span>
          </p>
          <hr class="my-4" />
          <div
            :class="style.statusArrowImpuesto ? 'hidden' : ''"
            class="text-sm text-gray-400 grid gap-3 pr-6"
          >
            <p class="flex items-center justify-between">
              <span class="flex gap-3 tooltip">
                <img
                  src="../../../assets/icons/flecha-enter.svg"
                  class="w-2"
                  alt=""
                />
                <span class="flex gap-3">
                  Ad/Valorem
                  <img
                    src="../../../assets/icons/icon-question.svg"
                    alt="Icono de Sendi"
                  />
                </span>
                <span class="tooltip-box">4% del CIF</span>
              </span>
              <span
                >{{ moneda === "USD" ? "$" : "S/." }}

                {{
                  moneda === "USD"
                    ? data.dolares.segundo.valorem
                    : data.soles.segundo.valorem
                }}</span
              >
            </p>
            <p class="flex items-center justify-between">
              <span class="flex gap-3 underline tooltip">
                <img
                  src="../../../assets/icons/flecha-enter.svg"
                  class="w-2"
                  alt=""
                />
                <span class="flex gap-3">
                  IGV
                  <img
                    src="../../../assets/icons/icon-question.svg"
                    alt="Icono de Sendi"
                  />
                </span>
                <span class="tooltip-box">16% del CIF + Ad/Valorem</span>
              </span>
              <span
                >{{ moneda === "USD" ? "$" : "S/." }}
                {{
                  moneda === "USD"
                    ? data.dolares.segundo.igv
                    : data.soles.segundo.igv
                }}</span
              >
            </p>
            <p class="flex items-center justify-between">
              <span class="flex gap-3 underline tooltip">
                <img
                  src="../../../assets/icons/flecha-enter.svg"
                  class="w-2"
                  alt=""
                />
                <span class="flex gap-3">
                  IPM
                  <img
                    src="../../../assets/icons/icon-question.svg"
                    alt="Icono de Sendi"
                  />
                </span>
                <span class="tooltip-box">2% del CIF + Ad/Valorem</span>
              </span>
              <span
                >{{ moneda === "USD" ? "$" : "S/." }}
                {{
                  moneda === "USD"
                    ? data.dolares.segundo.ipm
                    : data.soles.segundo.ipm
                }}</span
              >
            </p>
            <hr class="my-4" />
          </div>
        </span>

        <span v-if="data.dolares.segundo.estado === 0">
          <p
            class="text-xs xl:text-sm font-bold text-bg-black-blue flex items-center justify-between"
          >
            <span>Impuestos</span>
            <span class="flex items-center gap-3"
              >Gratis
              <img
                class="opacity-0"
                src="../../../assets/icons/chevron-down-orange.png"
                alt=""
            /></span>
          </p>
          <hr class="my-4" />
        </span>

        <p
          @click.prevent="openStatusSelect('tarifa')"
          class="cursor-pointer text-xs xl:text-sm font-bold text-bg-black-blue flex items-center justify-between"
        >
          <span>Tarifa de servicio</span>
          <span class="flex items-center gap-3">
            {{ moneda === "USD" ? "$" : "S/." }}

            {{
              moneda === "USD"
                ? calculoTarifa.dolar.totalTarifa
                : calculoTarifa.soles.totalTarifa
            }}

            <img
              :class="!style.statusArrowTarifa ? 'transform rotate-180' : ''"
              src="../../../assets/icons/chevron-down-orange.png"
              alt=""
            />
          </span>
        </p>
        <hr class="my-4" />
        <div
          :class="style.statusArrowTarifa ? 'hidden' : ''"
          class="text-sm text-gray-400 grid gap-3 pr-6"
        >
          <p class="flex items-center justify-between">
            <span class="flex gap-3 tooltip">
              <img
                src="../../../assets/icons/flecha-enter.svg"
                class="w-2"
                alt=""
              />
              <span class="flex gap-3">
                Trámite aduanero
                <img
                  src="../../../assets/icons/icon-question.svg"
                  alt="Icono de Sendi"
                />
              </span>
              <span class="tooltip-box"
                >Cobrado por los procedimientos aduaneros de importación</span
              >
            </span>
            <span>
              <span v-if="moneda === 'USD'">
                {{ calculoTarifa.dolar.tramiteAduanero }}
              </span>
              <span v-if="moneda === 'PEN'">
                {{ calculoTarifa.soles.tramiteAduanero }}
              </span></span
            >
          </p>
          <p class="flex items-center justify-between">
            <span class="flex gap-3 underline tooltip">
              <img
                src="../../../assets/icons/flecha-enter.svg"
                class="w-2"
                alt=""
              />
              <span class="flex gap-3">
                Envío Nacional
                <img
                  src="../../../assets/icons/icon-question.svg"
                  alt="Icono de Sendi"
                />
              </span>
              <span class="tooltip-box"
                >Cobrado por la distribución nacional de la importación hasta la
                dirección final del cliente</span
              >
            </span>
            <span
              ><span
                v-if="moneda === 'USD'"
                :class="
                  calculoTarifa.dolar.envioNacional === '0.00'
                    ? 'text-mainGreenLight  font-bold'
                    : ''
                "
              >
                {{
                  calculoTarifa.dolar.envioNacional === "0.00"
                    ? "Gratis"
                    : "$ " + calculoTarifa.dolar.envioNacional
                }}
              </span>
              <span
                v-if="moneda === 'PEN'"
                :class="
                  calculoTarifa.soles.envioNacional === '0.00'
                    ? 'text-mainGreenLight  font-bold'
                    : ''
                "
              >
                {{
                  calculoTarifa.soles.envioNacional === "0.00"
                    ? "Gratis"
                    : "S/. " + calculoTarifa.soles.envioNacional
                }}
              </span></span
            >
          </p>
          <hr class="my-3" />
        </div>

        <span v-if="servicioElegido.length !== 0">
          <p
            @click.prevent="openStatusSelect('service')"
            class="text-xs xl:text-sm font-bold flex text-bg-black-blue items-center justify-between cursor-pointer"
          >
            <span>Servicios Adicionales</span>
            <span class="flex items-center gap-3">
              <span v-if="moneda === 'PEN'">
                S/ {{ servicePrecioSoles.toFixed(2) }}</span
              >
              <span v-if="moneda === 'USD'">
                $ {{ servicePrecioDolares.toFixed(2) }}</span
              >
              <img
                :class="!style.statusArrowService ? 'transform rotate-180' : ''"
                src="../../../assets/icons/chevron-down-orange.png"
                alt=""
              />
            </span>
          </p>
          <hr class="my-4" />
          <div
            :class="style.statusArrowService ? 'hidden' : ''"
            class="text-sm text-gray-400 grid gap-3 pr-6"
          >
            <p
              class="flex items-center justify-between"
              v-for="data in servicioElegido"
              :key="data"
            >
              <span class="flex gap-3">
                <img
                  src="../../../assets/icons/flecha-enter.svg"
                  class="w-2"
                  alt=""
                />
                {{ data.name }}
              </span>
              <span>
                <span v-if="moneda === 'PEN'">
                  S/
                  {{
                    data.type === 1
                      ? data.newCostSoles.toFixed(2)
                      : data.local_amount.toFixed(2)
                  }}</span
                >
                <span v-if="moneda === 'USD'">
                  $
                  {{
                    data.type === 1
                      ? data.newCost.toFixed(2)
                      : data.cost.toFixed(2)
                  }}</span
                ></span
              >
            </p>

            <hr class="my-3" />
          </div>
        </span>

        <h4
          v-if="statusCupon"
          class="cursor-pointer text-xs xl:text-sm font-bold text-bg-black-blue flex items-center justify-between"
        >
          <span>Cupón utilizado</span>
          <span class="flex items-center gap-3">
            - {{ moneda === "USD" ? "$ " : "S/ " }}
            {{
              moneda === "USD"
                ? Number(montoCupon).toFixed(2)
                : Number(montoCuponSoles).toFixed(2)
            }}
            <img
              class="opacity-0"
              src="../../../assets/icons/chevron-down-orange.png"
              alt=""
            />
          </span>
        </h4>
        <hr v-if="statusCupon" class="my-3" />
        <h4
          v-if="getStadoBalanceCheck"
          class="cursor-pointer text-xs xl:text-sm font-bold text-bg-black-blue flex items-center justify-between"
        >
          <span>Balance</span>
          <span class="flex items-center gap-3">
            - {{ moneda === "USD" ? "$" : "S/" }}
            {{
              moneda === "USD" ? saldoUtilizado.dolares : saldoUtilizado.soles
            }}
            <img
              class="opacity-0"
              src="../../../assets/icons/chevron-down-orange.png"
              alt=""
            />
          </span>
        </h4>
        <hr v-if="getStadoBalanceCheck" class="my-3" />
      </span>
      <h4 class="xl:text-lg font-bold flex items-center justify-between">
        <span class="text-secondary-blue">Pago total</span>
        <span class="block">
          <span class="text-mainOrange">{{ moneda }} </span>
          <span class="text-secondary-blue ml-1">
            {{
              moneda === "USD" ? totalPaymentDolares : totalPaymentSoles
            }}</span
          ></span
        >
      </h4>

      <div
        class="flex items-center justify-center gap-5 xl:hidden mt-2"
        v-if="flujo === 3"
      >
        <span
          @click.prevent="agree"
          :class="
            terminosAndConditions
              ? 'bg-mainBlue ring-mainBlue'
              : ' ring-gray-400 bg-gray-400'
          "
          class="block w-3 h-3 ring ring-offset-2 cursor-pointer"
        ></span>
        <p class="text-center">
          Acepto los
          <a
            href="https://sendi.freshdesk.com/support/solutions/articles/73000534539-t%C3%A9rminos-y-condiciones"
            target="Blank"
            class="font-semibold underline"
            >términos y condiciones</a
          >
        </p>
      </div>
    </div>
    <span v-if="flujo === 1 || flujo === 2">
      <a
        @click.prevent="nextStep"
        :class="butonPayment ? 'bg-mainGreenLight' : '  bg-gray-400'"
        class="cursor-pointer flex items-center justify-center gap-3 mx-8 xl:mx-12 py-4 px-6 rounded-2xl text-white text-center font-semibold"
      >
        {{ flujo !== 3 ? "Continuar" : "Pagar" }}
      </a>
    </span>

    <span v-if="flujo === 3">
      <a
        v-if="
          selectPayment === 0 ||
          selectPayment === 3 ||
          getStatusBalance ||
          selectPayment === 1 ||
          (selectPayment === 2 && terminosAndConditions === false)
        "
        @click.prevent="nextStep"
        :class="butonPayment ? 'bg-mainGreenLight' : '  bg-gray-400'"
        class="cursor-pointer flex items-center justify-center gap-3 mx-8 xl:mx-12 py-4 px-6 rounded-2xl text-white text-center font-semibold"
      >
        {{ flujo !== 3 ? "Continuar" : "Pagar" }}
      </a>
    </span>
    <span
      v-show="
        selectPayment === 2 &&
        terminosAndConditions &&
        flujo === 3 &&
        getStatusBalance === false
      "
      class="block mx-4 mt-4 xl:mx-0 xl:mt:0 overflow-auto max-h-[70vh]"
      style="max-height: 70vh"
    >
      <div class="w-full" ref="paypal"></div>
    </span>
    <div class="hidden xl:block">
      <div
        class="flex items-center justify-center gap-5 mt-6"
        v-if="flujo === 3"
      >
        <span
          @click.prevent="agree"
          :class="
            terminosAndConditions
              ? 'bg-mainBlue ring-mainBlue'
              : ' ring-gray-400 bg-gray-400'
          "
          class="block w-3 h-3 ring ring-offset-2 cursor-pointer"
        ></span>
        <p class="text-center">
          Acepto los
          <a
            href="https://sendi.freshdesk.com/support/solutions/articles/73000534539-t%C3%A9rminos-y-condiciones"
            target="Blank"
            class="font-semibold underline"
            >términos y condiciones</a
          >
        </p>
      </div>
    </div>

    <div class="absolute inset-x-0 -top-4 xl:hidden cursor-pointer">
      <figure
        @click.prevent="openAll"
        class="mx-auto w-8 h-8 rounded-full bg-white border-2 shadow-lg flex items-center justify-center"
      >
        <img
          :class="arrowGeneral ? '' : 'transform rotate-180'"
          src="../../../assets/icons/arrow-down-blue.svg"
          alt="Icono de Envios USA"
        />
      </figure>
    </div>
  </article>
  <login-component :login="login" @closeLogin="login = $event" />
  <preload-component :preload="preload" />
</template>
<script>
import preloadComponent from "../../preload/preloadComponent.vue";
import { server } from "../../../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
import loginComponent from "../../global/autentificacion/loginComponent.vue";
export default {
  props: [
    "flujo",
    "stateAuth",
    "data",
    "selectDirection",
    "selectPayment",
    "calculoTarifa",
    "moneda",
    "statusForm",
  ],
  components: { loginComponent, preloadComponent },
  data() {
    return {
      arrowGeneral: false,
      style: {
        statusArrowAll: false,
        statusArrowCif: false,
        statusArrowImpuesto: false,
        statusArrowTarifa: false,
        statusArrowService: false,
      },
      terminosAndConditions: false,
      preload: false,
      emailUser: "",
      idUser: 0,
      login: false,

      //Errores cuando ignresa una neuva dirección
      error: {
        name: false,
        apellido: false,
        piso: false,
        referencia: false,
        departamento: false,
        provincia: false,
        distrito: false,
        direccion: false,
        documento: false,
        telefono: false,
      },
      paypal: {
        order_id: 0,
      },
      saldoUtilizado: {
        soles: "",
        dolares: "",
      },
    };
  },
  mounted: function () {
    const script = document.createElement("script");
    script.src =
      "https://www.paypal.com/sdk/js?client-id=AZoYd202u5lD3JMG4FEIvuKRdkKGibXzE7ynRCfFMo0_MOVsitzShzg1Xi7m05i2wa7kP0wNf0UP7jas";

    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  created() {
    this.getIdUser();
  },
  methods: {
    //Metodo de paypal
    setLoaded: function () {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: this.data.producto.nombre.substring(0, 125),
                  amount: {
                    currency_code: "USD",
                    value: this.totalPaymentDolares,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            console.log(data, "datos de paypal");
            const order = await actions.order.capture();
            this.paypal.order_id = order.id;
            this.preparationObjectPurchase();
          },
          onError: (err) => {
            if (err) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al realizar el pago con Paypal";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          },
        })
        .render(this.$refs.paypal);
    },
    //Abrir todos los detalles
    openAll() {
      this.arrowGeneral = !this.arrowGeneral;
      if (
        this.style.statusArrowCif === true ||
        this.style.statusArrowImpuesto === true ||
        this.style.statusArrowTarifa === true ||
        this.style.statusArrowService === true
      ) {
        this.style.statusArrowCif = false;
        this.style.statusArrowImpuesto = false;
        this.style.statusArrowTarifa = false;
        this.style.statusArrowService = false;
      } else if (
        this.style.statusArrowCif === false ||
        this.style.statusArrowImpuesto === false ||
        this.style.statusArrowTarifa === false ||
        this.style.statusArrowService === false
      ) {
        this.style.statusArrowCif = true;
        this.style.statusArrowImpuesto = true;
        this.style.statusArrowTarifa = true;
        this.style.statusArrowService = true;
      }
    },
    //Estilo de las flechas del cuadro de detalle
    openStatusSelect(id_select) {
      if (id_select === "cif") {
        this.style.statusArrowCif = !this.style.statusArrowCif;
      }
      if (id_select === "impuestos") {
        this.style.statusArrowImpuesto = !this.style.statusArrowImpuesto;
      }
      if (id_select === "tarifa") {
        this.style.statusArrowTarifa = !this.style.statusArrowTarifa;
      }
      if (id_select === "service") {
        this.style.statusArrowService = !this.style.statusArrowService;
      }
    },
    agree() {
      this.terminosAndConditions = !this.terminosAndConditions;
      this.$emit("terminos", this.terminosAndConditions);
    },
    getIdUser() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (data !== null) {
        this.idUser = dataUser.globalID;
        this.emailUser = dataUser.glovalEmail;
      }
    },
    // preparación del objeto para crear la orden
    preparationObjectPurchase() {
      var objetoOrden = new Object();
      //Datos del producto
      objetoOrden.product_info = this.data.producto.nombre;
      objetoOrden.product_specimens = this.data.producto.cantidad;
      objetoOrden.price = this.data.producto.precio;
      objetoOrden.weight = this.data.producto.peso;
      objetoOrden.weight_unit_key = this.data.producto.unidad;

      //Tiempo de tienda - envios usa o sendi
      objetoOrden.store = "sendi";
      objetoOrden.description = this.data.producto.description;
      // Tipo de moneda
      objetoOrden.currency = this.moneda;
      //-------------------------//
      this.data.servicio === 1
        ? (objetoOrden.direct_type = 1)
        : (objetoOrden.direct_type = 2);
      //-------------------------//
      if (this.data.servicio === 1) {
        if (this.data.producto.foto.length !== 0) {
          objetoOrden.product_image = this.data.producto.foto;
        }
      }
      if (this.data.servicio === 2) {
        objetoOrden.product_image_url = this.data.producto.foto;
      }
      if (this.data.servicio === 2) {
        objetoOrden.reference_link = this.data.producto.link;
      }
      //parametro del cupon
      if (this.$store.state.cuponStatu === true) {
        objetoOrden.coupon_code = this.$store.state.cuponCode;
      }
      //Metodo de Pago - 5 si es transferencia
      // atm  - 1
      // transferencia-3
      // tarjeta - 4
      // paypal -2
      //primero validatos si utilizo balance y todo fue cero
      if (this.$store.state.saldoTotalStatu === false) {
        if (this.selectPayment === 1) {
          objetoOrden.payment_method_flag = 4;
          objetoOrden.mp_payer_email = this.emailUser;
          objetoOrden.mp_payment_method_id = "pagoefectivo_atm";
        }
        if (this.selectPayment === 2) {
          objetoOrden.paypal_order_id = this.paypal.order_id;
          objetoOrden.payment_method_flag = 2;
        }
        if (this.selectPayment === 3) {
          objetoOrden.payment_method_flag = 5;
        }
      } else {
        objetoOrden.payment_method_flag = 6;
      }

      //Balance
      if (this.$store.state.saldoStatu) {
        objetoOrden.balance_flag = true;
      } else {
        objetoOrden.balance_flag = false;
      }
      //Si el usuario agrego oytro tipo de servicio
      if (this.servicioElegido.length !== 0) {
        var idService = "";
        this.servicioElegido.forEach((value, index) => {
          if (index === 0) {
            idService = idService.concat(value.id);
          } else {
            idService = idService.concat("," + value.id);
          }
        });

        objetoOrden.extra_services_id = idService;
      }
      // si recoge en oficina es true por lo contrario false
      this.selectDirection.type === 3
        ? (objetoOrden.at_office_flag = true)
        : (objetoOrden.at_office_flag = false);
      //aqui se manda el vaucher
      if (this.data.servicio === 1) {
        if (this.data.producto.vaucher.length !== 0) {
          objetoOrden.voucher_usa = this.data.producto.vaucher;
        }
      }

      // si la dirección es una existente
      if (this.selectDirection.type === 1) {
        objetoOrden.shipping_address_id = this.selectDirection.datos.id;
      }
      // si es una nueva dirección
      if (this.selectDirection.type === 2) {
        objetoOrden.shipping_address_name =
          this.selectDirection.datos.direccion;
        objetoOrden.shipping_address_telephone =
          this.selectDirection.datos.telefono;
        objetoOrden.shipping_address_reference =
          this.selectDirection.datos.referencia;
        objetoOrden.shipping_address_inner = this.selectDirection.datos.piso;
        objetoOrden.shipping_address_contact_name =
          this.selectDirection.datos.nombre;
        objetoOrden.shipping_address_contact_lastname =
          this.selectDirection.datos.apellido;
        objetoOrden.shipping_address_document_number =
          this.selectDirection.datos.documento;
        objetoOrden.shipping_address_document_type = 1;
        objetoOrden.shipping_address_district =
          this.selectDirection.datos.distrito;
        objetoOrden.shipping_address_city =
          this.selectDirection.datos.provincia;
        objetoOrden.shipping_address_department =
          this.selectDirection.datos.departamento;
        objetoOrden.shipping_address_alias = "alias";
        objetoOrden.shipping_address_lat = this.selectDirection.datos.lat;
        objetoOrden.shipping_address_lng = this.selectDirection.datos.lng;
      }
      //Mandamos el id de la cotizacion

      objetoOrden.quotation_id = this.data.producto.idCotizacion;

      this.createOrder(objetoOrden);
    },
    //Servicio para crear la orden
    createOrder(objetoOrden) {
      if (
        this.selectPayment === 1 ||
        this.selectPayment === 3 ||
        this.selectPayment === 2 ||
        this.getStatusBalance
      ) {
        this.preload = true;
      }
      axios
        .post(
          `${BASE_URL}` +
            "purchaseOrder/" +
            this.idUser +
            "/createDirectOrderByClient",
          objetoOrden
        )
        .then((response) => {
          if (response.status === 200) {
            this.preload = false;
            const responseData = response.data.body;
            this.$emit("next", 4);
            this.$emit("compra", responseData);
            window.localStorage.removeItem("datalleProduct");
            window.localStorage.removeItem("shooperProducts");
            window.localStorage.removeItem("producDetail");
            //Setear el Storage
            this.$store.state.cuponStatu = null;
            this.$store.state.cuponCode = "";
            this.$store.state.cuponMonto = null;
            this.$store.state.cuponMontoSoles = null;
            this.$store.state.saldoStatu = false;
            this.$store.state.saldoMonto = 0.0;
            this.$store.state.saldoMontoSoles = 0.0;
            this.$store.state.saldoTotalStatu = false;
            this.$store.commit("resetServicios");
          }
        })
        .catch((error) => {
          if (error) {
            this.preload = false;
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Lo sentimos ocurrió un error";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    datosValidateFlujo1() {
      const nombre = this.data.producto.nombre;

      const precio = String(this.data.producto.precio);
      const peso = String(this.data.producto.peso);
      const unidad = this.data.producto.unidad;
      const cantidad = String(this.data.producto.cantidad);
      const amountCorrect = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
      ];

      if (nombre.length !== 0) {
        if (precio.length !== 0 && this.data.producto.precio !== 0) {
          if (peso.length !== 0 && this.data.producto.peso !== 0) {
            if (unidad.length !== 0) {
              if (cantidad.length !== 0) {
                const found = amountCorrect.find(
                  (element) => element === cantidad
                );
                if (found !== undefined) {
                  return true;
                } else {
                  //----------component--------//
                  this.$store.state.alert.titulo = "Validación";
                  this.$store.state.alert.description =
                    "La cantidad no puede ser inferior a 1 ni superior a 25";
                  this.$store.state.alert.estado = true;
                  //--------------------------//

                  return false;
                }
              } else {
                //----------component--------//
                this.$store.state.alert.titulo = "Validación";
                this.$store.state.alert.description = "Ingrese la cantidad";
                this.$store.state.alert.estado = true;
                //--------------------------//

                return false;
              }
            } else {
              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description =
                "Seleccione la unidad de peso del producto";
              this.$store.state.alert.estado = true;
              //--------------------------//

              return false;
            }
          } else {
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description = "Ingrese el peso";
            this.$store.state.alert.estado = true;
            //--------------------------//

            return false;
          }
        } else {
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description = "Ingrese el precio";
          this.$store.state.alert.estado = true;
          //--------------------------//

          return false;
        }
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese el nombre del producto";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    valDataFlujo2() {
      this.$emit("errorNewDirection", this.error);
      const name = this.selectDirection.datos.nombre;
      const apellido = this.selectDirection.datos.apellido;
      const piso = this.selectDirection.datos.piso;
      const referencia = this.selectDirection.datos.referencia;
      const departamento = this.selectDirection.datos.departamento;
      const provincia = this.selectDirection.datos.provincia;
      const distrito = this.selectDirection.datos.distrito;

      if (name.length !== 0) {
        this.error.name = false;
        if (apellido.length !== 0) {
          this.error.apellido = false;
          if (piso.length !== 0) {
            this.error.piso = false;
            if (referencia.length !== 0) {
              this.error.referencia = false;
              if (departamento.length !== 0) {
                this.error.departamento = false;
                if (provincia.length !== 0) {
                  this.error.provincia = false;
                  if (distrito.length !== 0) {
                    this.error.distrito = false;
                    return true;
                  } else {
                    this.error.distrito = true;
                    //----------component--------//
                    this.$store.state.alert.titulo = "Validación";
                    this.$store.state.alert.description = "Ingrese el distrito";
                    this.$store.state.alert.estado = true;
                    //--------------------------//
                    return false;
                  }
                } else {
                  this.error.provincia = true;
                  //----------component--------//
                  this.$store.state.alert.titulo = "Validación";
                  this.$store.state.alert.description = "Ingrese la provincia";
                  this.$store.state.alert.estado = true;
                  //--------------------------//
                  return false;
                }
              } else {
                this.error.departamento = true;
                //----------component--------//
                this.$store.state.alert.titulo = "Validación";
                this.$store.state.alert.description = "Ingrese el departamento";
                this.$store.state.alert.estado = true;
                //--------------------------//
                return false;
              }
            } else {
              this.error.referencia = true;
              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description = "Ingrese una referencia";
              this.$store.state.alert.estado = true;
              //--------------------------//
              return false;
            }
          } else {
            this.error.piso = true;
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description = "Ingrese el piso u oficina";
            this.$store.state.alert.estado = true;
            //--------------------------//
            return false;
          }
        } else {
          this.error.apellido = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description = "Ingrese el apellido";
          this.$store.state.alert.estado = true;
          //--------------------------//
          return false;
        }
      } else {
        this.error.name = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese el nombre";
        this.$store.state.alert.estado = true;
        //--------------------------//
        return false;
      }
    },
    // metodo para validar dirección
    valDirectionFlujo2() {
      const direction = this.selectDirection.datos.direccion;
      const lat = this.selectDirection.datos.lat;
      const lng = this.selectDirection.datos.lng;
      if (direction.length !== 0) {
        this.error.direccion = false;
        if (lat !== 0) {
          this.error.direccion = false;
          if (lng !== 0) {
            this.error.direccion = false;
            return true;
          } else {
            this.error.direccion = true;
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description =
              "Ingrese una dirección  y selecciónela";
            this.$store.state.alert.estado = true;
            //--------------------------//

            return false;
          }
        } else {
          this.error.direccion = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "Ingrese una dirección  y selecciónela";
          this.$store.state.alert.estado = true;
          //--------------------------//
          return false;
        }
      } else {
        this.error.direccion = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description =
          "Ingrese una dirección  y selecciónela";
        this.$store.state.alert.estado = true;
        //--------------------------//
        return false;
      }
    },
    //validateData "documento " - "telefono" - "correo de contacto"
    valDataEspecialFlujo2() {
      const documento = this.selectDirection.datos.documento.toString();
      const telefono = this.selectDirection.datos.telefono.toString();

      if (documento.length !== 0) {
        this.error.documento = false;
        if (documento.length >= 8) {
          this.error.documento = false;
          if (telefono.length !== 0) {
            this.error.telefono = false;
            if (telefono.length >= 9) {
              this.error.telefono = false;
              return true;
            } else {
              this.error.telefono = true;
              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description =
                "El teléfono debe ser mínimo 9 dígitos";
              this.$store.state.alert.estado = true;
              //--------------------------//

              return false;
            }
          } else {
            this.error.telefono = true;
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description = "Ingrese su teléfono";
            this.$store.state.alert.estado = true;
            //--------------------------//

            return false;
          }
        } else {
          this.error.documento = true;

          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "El documento debe ser mínimo 8 dígitos";
          this.$store.state.alert.estado = true;
          //--------------------------//
          return false;
        }
      } else {
        this.error.documento = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description =
          "Ingrese el número de su documento";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    nextStep() {
      //Aqui se alamacena en que flujo se encuentra el usuario
      const flujo = this.flujo;
      if (flujo === 1) {
        const resultFlujo1 = this.datosValidateFlujo1();
        if (resultFlujo1) {
          if (this.stateAuth) {
            this.$emit("next", 2);
          } else {
            this.login = true;
          }
        }
      }
      if (flujo === 2) {
        if (this.selectDirection.type === 1) {
          if (this.statusForm === false) {
            this.$emit("next", 3);
          } else {
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description =
              "Actualizar o cerrar el formulario de direccón";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        }

        if (this.selectDirection.type === 2) {
          const result1 = this.valDataFlujo2();
          const result2 = this.valDirectionFlujo2();
          const result3 = this.valDataEspecialFlujo2();
          if (result1 && result2 && result3) {
            this.$emit("next", 3);
          }
        }
        if (this.selectDirection.type === 3) {
          this.$emit("next", 3);
        }
        if (this.selectDirection.type === 0) {
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description = "Seleccione una dirección";
          this.$store.state.alert.estado = true;
          //--------------------------//
        }
      }
      if (flujo === 3) {
        if (this.getStatusBalance === false) {
          if (this.selectPayment === 1) {
            if (this.terminosAndConditions) {
              this.preparationObjectPurchase();
            } else {
              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description =
                "Aceptar términos y condiciones";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          }
          if (this.selectPayment === 3) {
            if (this.terminosAndConditions) {
              this.preparationObjectPurchase();
            } else {
              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description =
                "Aceptar términos y condiciones";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          }
          if (this.selectPayment === 2) {
            if (this.terminosAndConditions === false) {
              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description =
                "Aceptar términos y condiciones";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          }

          if (this.selectPayment === 0) {
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description =
              "Seleccionar un método de pago";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        } else {
          if (this.getStatusBalance) {
            if (this.terminosAndConditions) {
              this.preparationObjectPurchase();
            } else {
              //----------component--------//
              this.$store.state.alert.titulo = "Validación";
              this.$store.state.alert.description =
                "Aceptar términos y condiciones";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          }
        }
      }
    },
    //Validando data sin las alertas
    datosValidateFlujo1Button() {
      const amountCorrect = [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
      ];
      const nombre = this.data.producto.nombre;
      const precio = String(this.data.producto.precio);
      const peso = String(this.data.producto.peso);
      const unidad = this.data.producto.unidad;
      const cantidad = String(this.data.producto.cantidad);
      if (nombre.length !== 0) {
        if (precio.length !== 0 && this.data.producto.precio !== 0) {
          if (peso.length !== 0 && this.data.producto.peso !== 0) {
            if (unidad.length !== 0) {
              if (cantidad.length !== 0) {
                const found = amountCorrect.find(
                  (element) => element === cantidad
                );
                if (found !== undefined) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    valDataButton() {
      const name = this.selectDirection.datos.nombre;
      const apellido = this.selectDirection.datos.apellido;
      const piso = this.selectDirection.datos.piso;
      const referencia = this.selectDirection.datos.referencia;
      const departamento = this.selectDirection.datos.departamento;
      const provincia = this.selectDirection.datos.provincia;
      const distrito = this.selectDirection.datos.distrito;
      if (name.length !== 0) {
        if (apellido.length !== 0) {
          if (piso.length !== 0) {
            if (referencia.length !== 0) {
              if (departamento.length !== 0) {
                if (provincia.length !== 0) {
                  if (distrito.length !== 0) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    valDirectionButton() {
      const direction = this.selectDirection.datos.direccion;
      const lat = this.selectDirection.datos.lat;
      const lng = this.selectDirection.datos.lng;
      if (direction.length !== 0) {
        if (lat !== 0) {
          if (lng !== 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    valDataEspecialButton() {
      const documento = this.selectDirection.datos.documento.toString();
      const telefono = this.selectDirection.datos.telefono.toString();

      if (documento.length !== 0) {
        if (documento.length >= 8) {
          if (telefono.length !== 0) {
            if (telefono.length >= 9) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    changeStatusTotalTrue() {
      this.$store.state.saldoTotalStatu = true;
    },
    changeStatusTotalFalse() {
      this.$store.state.saldoTotalStatu = false;
    },
    obtenerValorSaldoUtilizadoDolar(data) {
      this.saldoUtilizado.dolares = data;
    },
    obtenerValorSaldoUtilizadoSoles(data) {
      this.saldoUtilizado.soles = data;
    },
  },
  computed: {
    servicePrecioSoles() {
      return this.$store.state.servicePrecioSoles;
    },
    servicePrecioDolares() {
      return this.$store.state.servicePrecioDolares;
    },
    servicioElegido() {
      return this.$store.state.servicioElegido;
    },
    montoCupon() {
      return this.$store.state.cuponMonto;
    },
    montoCuponSoles() {
      return this.$store.state.cuponMontoSoles;
    },
    statusCupon() {
      return this.$store.state.cuponStatu;
    },
    getStadoBalanceCheck() {
      return this.$store.state.saldoStatu;
    },
    getMontoDolares() {
      return this.$store.state.saldoMonto;
    },
    getMontoSoles() {
      return this.$store.state.saldoMontoSoles;
    },
    getStatusBalance() {
      return this.$store.state.saldoTotalStatu;
    },
    totalPaymentDolares() {
      var total1 = 0.0;
      var total2 = 0.0;
      var total3 = 0.0;
      var total = 0.0;
      var reserva = 0.0;

      if (this.data.servicio === 1) {
        total1 = parseFloat(this.data.dolares.primera.frontTotal);
      }
      if (this.data.servicio === 2) {
        total1 = parseFloat(this.data.dolares.primera.total);
      }

      total2 = parseFloat(this.data.dolares.segundo.total);
      total3 = parseFloat(this.calculoTarifa.dolar.totalTarifa);

      total = total1 + total2 + total3;

      if (this.servicioElegido.length !== 0) {
        total = total + this.servicePrecioDolares;
      }
      if (this.statusCupon === true) {
        total = total - this.montoCupon;
      }
      reserva = total;
      if (this.$store.state.saldoStatu) {
        total = total - this.$store.state.saldoMonto;
        var signo = Math.sign(total);
        if (signo === -1 || signo === 0) {
          total = 0.0;
          this.obtenerValorSaldoUtilizadoDolar(reserva);
          this.changeStatusTotalTrue();
        } else {
          this.obtenerValorSaldoUtilizadoDolar(this.$store.state.saldoMonto);
          this.changeStatusTotalFalse();
        }
      } else {
        this.changeStatusTotalFalse();
      }

      return total.toFixed(2);
    },
    totalPaymentSoles() {
      var total1 = 0.0;
      var total2 = 0.0;
      var total3 = 0.0;
      var total = 0.0;
      var reserva = 0.0;
      if (this.data.servicio === 1) {
        total1 = parseFloat(this.data.soles.primera.frontTotal);
      }
      if (this.data.servicio === 2) {
        total1 = parseFloat(this.data.soles.primera.total);
      }

      total2 = parseFloat(this.data.soles.segundo.total);
      total3 = parseFloat(this.calculoTarifa.soles.totalTarifa);
      total = total1 + total2 + total3;
      if (this.servicioElegido.length !== 0) {
        total = total + this.servicePrecioSoles;
      }
      if (this.statusCupon === true) {
        total = total - this.montoCuponSoles;
      }
      reserva = total;
      if (this.$store.state.saldoStatu) {
        total = total - this.$store.state.saldoMontoSoles;
        var signo = Math.sign(total);
        if (signo === -1 || signo === 0) {
          total = 0.0;
          this.obtenerValorSaldoUtilizadoSoles(reserva);
          this.changeStatusTotalTrue();
        } else {
          this.obtenerValorSaldoUtilizadoSoles(
            this.$store.state.saldoMontoSoles
          );
          this.changeStatusTotalFalse();
        }
      } else {
        this.changeStatusTotalFalse();
      }

      return total.toFixed(2);
    },
    butonPayment() {
      const data = this.flujo;
      if (data === 1) {
        const valproduct = this.datosValidateFlujo1Button();
        if (valproduct) {
          return true;
        } else {
          return false;
        }
      } else if (data === 2) {
        if (
          (this.selectDirection.type === 1 && this.statusForm === false) ||
          this.selectDirection.type === 3
        ) {
          return true;
        } else if (this.selectDirection.type === 2) {
          const resultData = this.valDataButton();
          const resulDirection = this.valDirectionButton();
          const resulDataEspecial = this.valDataEspecialButton();
          if (resultData && resulDirection && resulDataEspecial) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (data === 3) {
        if (this.selectPayment === 1 && this.terminosAndConditions) {
          return true;
        } else if (this.selectPayment === 3 && this.terminosAndConditions) {
          return true;
        } else if (this.getStatusBalance && this.terminosAndConditions) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>
