import { createStore } from "vuex";

export default createStore({
  state: {
    modal: {
      direction: false,
    },
    cuponStatu: null,
    cuponCode: "",
    cuponMonto: null,
    cuponMontoSoles: null,
    saldoStatu: false,
    saldoMonto: 0.0,
    saldoMontoSoles: 0.0,
    saldoTotalStatu: false,
    alert: {
      titulo: "",
      description: "",
      estado: false,
    },
    errorShooper: false,
    servicioElegido: [],
    servicePrecioSoles: 0.0,
    servicePrecioDolares: 0.0,
    servicePrecioProduct: 0.0,
  },
  mutations: {
    resetServicios: (state) => {
      state.servicioElegido = [];
      state.servicePrecioSoles = 0.0;
      state.servicePrecioDolares = 0.0;
      state.servicePrecioProduct = 0.0;
    },
    resetCupon: (state) => {
      state.cuponStatu = null;
      state.cuponCode = "";
      state.cuponMonto = null;
      state.cuponMontoSoles = null;
    },
    statusModalDirection: (state) => {
      state.modal.direction = !state.modal.direction;
    },
  },
  actions: {},
  modules: {},
});
