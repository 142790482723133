<template>
  <section class="relative overflow-hidden">
    <img
      src="../../assets/images/banner-bg.png"
      class="w-full hidden xl:block"
    />
    <img src="../../assets/images/banner-bg-mb.png" class="w-full xl:hidden" />
    <article
      class="u-container py-14 xl:py-20 absolute inset-0 flex items-start xl:items-center"
    >
      <div class="w-1/2 text-white text-center">
        <h3 class="text-sm xl:text-5xl xl:mb-2">TU COMPRA</h3>
        <h3 class="text-sm xl:text-5xl flex gap-3 justify-center xl:mb-2">
          <span class="text-bg-black">DESDE EE.UU</span>
          <img src="../../assets/icons/bandera_usa.svg" class="w-6 xl:w-16" />
        </h3>
        <h3 class="text-sm xl:text-5xl leading-[1.25em]">
          HASTA LA PUERTA <br />
          DE TU CASA <br />
          CON UN SOLO CLICK
        </h3>
      </div>
    </article>
    <img
      src="../../assets/images/banner-aside.png"
      class="w-[150px] xl:w-auto absolute bottom-0 -right-4 xl:right-[5%] z-10"
    />
    <article class="absolute inset-x-0 bottom-6 xl:bottom-12 bg-bg-black">
      <div class="u-container py-1 xl:py-3 text-white">
        <div
          class="w-2/3 xl:w-1/2 flex items-center justify-between xl:justify-center xl:gap-5"
        >
          <p class="text-[10px] xl:text-xl font-bold">Tarjetas Nacionales</p>
          <span class="text-[10px] xl:text-xl font-bold">-</span>
          <img
            src="../../assets/icons/pago-efectivo.svg"
            class="w-8 h-3 xl:w-[80px] xl:h-8 object-cover"
          />
          <span class="text-[10px] xl:text-xl font-bold">-</span>
          <img
            src="../../assets/icons/icon-paypal.svg"
            class="w-8 h-3 xl:w-[80px] xl:h-8 object-cover"
          />
        </div>
      </div>
    </article>
  </section>

  <section
    class="u-container xl:w-4/5 grid xl:grid-cols-2 items-center justify-between relative"
  >
    <article class="z-10">
      <h3 class="text-2xl xl:text-4xl text-bg-black mb-2 xl:mb-6">
        <span class="font-normal block xl:font-bold xl:inline">¿Cómo</span>
        funciona Sendi?
      </h3>
      <p
        class="text-bg-black mb-2 xl:mb-8 text-xs xl:text-lg leading-8 xl:leading-9"
      >
        Sendi, es una <b>plataforma de delivery</b> para tus <br />
        <b>encargos de USA.</b>
      </p>
      <p
        class="text-bg-black text-xs xl:text-lg leading-8 xl:leading-9 mb-6 xl:mb-0"
      >
        Puedes comprar desde cualquier parte del <br />
        mundo usando
        <span
          @click.prevent="changeModalDirection"
          class="cursor-pointer font-bold underline"
        >
          nuestra dirección en USA
          <img
            src="../../assets/icons/question-black.svg"
            class="cursor-pointer inline ml-3 mb-1"
            alt="Icono de Sendi"
          />
        </span>
        <br />
        y recibirlo en Perú.
      </p>
    </article>

    <div class="relative z-10">
      <img
        src="../../assets/images/degradado-blanco.svg"
        class="absolute top-0 right-0 pointer-events-none hidden xl:block min-w-[90%] max-h-full object-cover z-10"
        alt="Icono de Sendi"
      />

      <!-- Slide SPLIDE -->
      <Splide :options="optionPasos">
        <SplideSlide>
          <img
            src="../../assets/images/como-funciona-1.png"
            data-slide="1"
            class="mx-auto"
            alt="Icono de Sendi"
          />
        </SplideSlide>
        <SplideSlide>
          <img
            src="../../assets/images/como-funciona-2.png"
            data-slide="1"
            class="mx-auto"
            alt="Icono de Sendi"
          />
        </SplideSlide>
        <SplideSlide>
          <img
            src="../../assets/images/como-funciona-3.png"
            data-slide="1"
            class="mx-auto"
            alt="Icono de Sendi"
          />
        </SplideSlide>
        <SplideSlide>
          <img
            src="../../assets/images/como-funciona-4.png"
            data-slide="1"
            class="mx-auto"
            alt="Icono de Sendi"
          />
        </SplideSlide>
        <SplideSlide>
          <img
            src="../../assets/images/como-funciona-5.png"
            data-slide="1"
            class="mx-auto"
            alt="Icono de Sendi"
          />
        </SplideSlide>
        <SplideSlide>
          <img
            src="../../assets/images/como-funciona-6.png"
            data-slide="1"
            class="mx-auto"
            alt="Icono de Sendi"
          />
        </SplideSlide>
      </Splide>
    </div>
    <div class="bg-blur-blue xl:hidden"></div>
  </section>

  <section class="bg-[#F6F5FA] relative">
    <div class="u-container xl:py-80 xl:w-3/5 z-10 xl:relative">
      <h3 class="text-2xl xl:text-4xl font-normal text-bg-black mb-1 xl:mb-2">
        Importa con seguridad
      </h3>
      <h3 class="text-2xl xl:text-4xl text-bg-black mb-3">Compra Protegida</h3>
      <p class="text-xs xl:text-base leading-6 xl:leading-8 xl:w-1/2">
        El programa Compra Protegida garantiza la seguridad de las compras de
        nuestros clientes, cubriendo al 100% el valor de la compra en caso de
        problemas o inconvenientes. Además, estamos comprometidos a resolver
        cualquier problema que surja para garantizar la satisfacción del
        cliente.
      </p>
      <!-- <p class="text-xs xl:text-base leading-6 xl:leading-8 xl:w-1/2 mt-2">
        Nuestras compras estan totalmente protegidas.
      </p> -->
      <a
        href="https://sendi.freshdesk.com/a/solutions/articles/73000533273"
        target="Blank"
        class="text-[10px] xl:text-sm text-bg-black flex items-center gap-3 underline mt-2"
      >
        Aplican términos y condiciones
        <img
          src="../../assets/icons/question-black.svg"
          class="cursor-pointer"
          alt="Icono de Sendi"
        />
      </a>
    </div>
    <img
      src="../../assets/images/chica-aside-2.png"
      class="xl:absolute xl:bottom-0 xl:right-0"
      alt="Icono de Sendi"
    />
  </section>

  <section class="bg-white z-10 relative">
    <div class="u-container pb-0">
      <h3
        class="text-sm xl:text-2xl text-center font-normal text-bg-black mb-10"
      >
        Ellos ya <span class="font-semibold">confían en nosotros</span>
      </h3>
      <Splide :options="optionMarca" :extensions="extensions">
        <SplideSlide v-for="marca in marcas" :key="marca" class="items-center">
          <a :href="marca.link" target="_blank">
            <img :src="marca.img" data-slide="1" class="mx-auto" />
          </a>
        </SplideSlide>
      </Splide>
    </div>
  </section>
  <!-- Como funciona -->

  <div
    :class="styleDirection ? '' : 'oculto'"
    class="z-50 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
  >
    <div class="mx-4">
      <div
        class="rounded-tr-2xl rounded-tl-2xl bg-mainBlue py-4 px-6 xl:px-12 flex justify-between cursor-pointer"
      >
        <div class="flex gap-6 items-center mr-auto xl:mr-28">
          <img
            src="../../assets/images/mapita.png"
            class="w-10"
            alt="Icono de Sendi"
          />
          <span class="text-white text-sm">Tu dirección en USA </span>
        </div>
        <img
          @click.prevent="changeModalDirection"
          src="../../assets/icons/icon-exit-white.svg"
          class="w-5"
          alt="Icono de Sendi"
        />
      </div>
      <div
        class="rounded-br-2xl rounded-bl-2xl bg-white flex px-6 xl:px-12 py-6 leading-6 text-sm font-medium gap-6"
      >
        <div>
          <span class="font-semibold block"> Nombre: </span>
          <span class="font-semibold block"> Dirección: </span>
          <span class="font-semibold block"> Ciudad: </span>
          <span class="font-semibold block"> Estado: </span>
          <span class="font-semibold block"> Teléfono: </span>
        </div>
        <div>
          <span class="block"> {{ direction.nombre }}</span>
          <span class="block"> {{ direction.direccion }} </span>
          <span class="block"> {{ direction.ciudad }} </span>
          <span class="block"> {{ direction.estado }} </span>
          <span class="block"> {{ direction.telefono }} </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { directionUsa } from "../../config/global";
//marcas
import marca1 from "@/assets/images/tienda-1.png";
import marca2 from "@/assets/images/tienda-2.png";
import marca3 from "@/assets/images/tienda-3.png";
import marca4 from "@/assets/images/tienda-4.png";
import marca5 from "@/assets/images/tienda-5.png";
import marca6 from "@/assets/images/tienda-6.png";
import marca7 from "@/assets/images/tienda-7.png";
import marca8 from "@/assets/images/tienda-8.png";
import marca9 from "@/assets/images/tienda-9.png";
import marca10 from "@/assets/images/tienda-10.png";
export default {
  data() {
    return {
      optionMarca: {
        classes: {
          arrows: "splide__arrows hidden",
          pagination: "splide__pagination opacity-0",
        },
        type: "loop",
        drag: "free",
        focus: "center",
        perPage: 4,
        breakpoints: {
          640: {
            perPage: 1,
          },
        },
        autoScroll: {
          speed: 1,
        },
      },
      extensions: {
        AutoScroll,
      },
      marcas: [
        { link: "https://www.mercadolibre.com.pe", img: marca1 },
        { link: "https://www.linio.com.pe", img: marca2 },
        { link: "https://www.falabella.com", img: marca3 },
        { link: "https://simple.ripley.cl", img: marca4 },
        { link: "https://www.oechsle.pe", img: marca5 },
        { link: "https://www.plazavea.com.pe", img: marca6 },
        { link: "https://www.realplaza.com", img: marca7 },
        { link: "https://www.lumingo.com", img: marca8 },
        { link: "https://www.promart.pe", img: marca9 },
        { link: "https://www.inretail.pe", img: marca10 },
      ],
      direction: directionUsa,
      styleDirection: false,
      optionPasos: {
        type: "loop",
        perPage: 2,
        perMove: 1,
        breakpoints: {
          640: {
            perPage: 1,
          },
        },
      },
    };
  },

  methods: {
    changeModalDirection() {
      this.styleDirection = !this.styleDirection;
    },
  },
};
</script>
