<template>
  <div
    class="px-4 py-6 xl:px-9 rounded-2xl bg-white xl:border xl:shadow-lg xl:border-none xl:shadow-none"
  >
    <h4
      class="text-xs xl:text-base text-text-blue font-semibold mb-8 flex items-center justify-between"
    >
      Detalles del producto
      <span class="text-xs xl:text-sm text-textColor">
        <span>N° orden:</span>
        <span class="ml-2 text-mainBlue">SDX{{ compra.id }}</span>
      </span>
    </h4>
    <!-- Producto -->
    <div class="p-6 rounded-2xl border shadow-sm mb-8">
      <div class="flex items-center gap-4 mb-6">
        <!-- <img class="w-16 h-16 xl:w-28 xl:h-28" src="../../../../assets/images/jordan-1.svg"> -->
        <img
          class="w-16 h-16 xl:w-28 xl:h-28 object-contain"
          :src="compra.attributes.product_image_url"
          v-if="compra.attributes.product_image_url !== null"
        />
        <img
          class="w-16 h-16 xl:w-28 xl:h-28 object-contain"
          src="../../../../assets/images/caja.svg"
          v-else
        />
        <div class="text-xs xl:text-base font-bold">
          <!-- Estatus / Tipo de servicio -->
          <div class="flex items-center gap-4 mb-4">
            <span
              class="hidden px-3 py-1 rounded-full text-xs bg-gray-400 w-max text-white font-semibold"
              >Solicitud recibida</span
            >
            <img
              src="../../../../assets/icons/icon-tracking-statu.png"
              alt=""
            />

            <span
              :class="[
                compra.attributes.order_tracking_details[
                  compra.attributes.order_tracking_details.length - 1
                ].attributes.status === 1
                  ? 'text-gray-500 bg-gray-500'
                  : '',
                compra.attributes.order_tracking_details[
                  compra.attributes.order_tracking_details.length - 1
                ].attributes.status === 2
                  ? 'text-mainOrange bg-mainOrange'
                  : '',
                compra.attributes.order_tracking_details[
                  compra.attributes.order_tracking_details.length - 1
                ].attributes.status === 3
                  ? 'text-mainOrange bg-mainOrange'
                  : '',
                compra.attributes.order_tracking_details[
                  compra.attributes.order_tracking_details.length - 1
                ].attributes.status === 4
                  ? 'text-mainOrange bg-mainOrange'
                  : '',
                compra.attributes.order_tracking_details[
                  compra.attributes.order_tracking_details.length - 1
                ].attributes.status === 5
                  ? 'text-mainOrange bg-mainOrange'
                  : '',
                compra.attributes.order_tracking_details[
                  compra.attributes.order_tracking_details.length - 1
                ].attributes.status === 6
                  ? 'text-mainOrange bg-mainOrange'
                  : '',
                compra.attributes.order_tracking_details[
                  compra.attributes.order_tracking_details.length - 1
                ].attributes.status === 7
                  ? 'text-mainGreenLight bg-mainGreenLight'
                  : '',
                compra.attributes.order_tracking_details[
                  compra.attributes.order_tracking_details.length - 1
                ].attributes.status === 8
                  ? 'text-red-500 bg-red-500'
                  : '',
                compra.attributes.order_tracking_details[
                  compra.attributes.order_tracking_details.length - 1
                ].attributes.status === 9
                  ? 'text-red-500 bg-red-500'
                  : '',
                compra.attributes.order_tracking_details[
                  compra.attributes.order_tracking_details.length - 1
                ].attributes.status === 10
                  ? 'text-gray-500 bg-gray-500'
                  : '',
              ]"
              class="px-3 py-1 rounded-full text-xs bg-opacity-20 w-max font-semibold"
            >
              <span
                v-if="
                  compra.attributes.order_tracking_details[
                    compra.attributes.order_tracking_details.length - 1
                  ].attributes.status === 1
                "
              >
                EN REVISIÓN</span
              ><span
                v-if="
                  compra.attributes.order_tracking_details[
                    compra.attributes.order_tracking_details.length - 1
                  ].attributes.status === 2
                "
              >
                COMPRADO</span
              ><span
                v-if="
                  compra.attributes.order_tracking_details[
                    compra.attributes.order_tracking_details.length - 1
                  ].attributes.status === 3
                "
              >
                PREPARANDO PARA ENVIAR</span
              ><span
                v-if="
                  compra.attributes.order_tracking_details[
                    compra.attributes.order_tracking_details.length - 1
                  ].attributes.status === 4
                "
              >
                LISTO PARA ENVIAR</span
              ><span
                v-if="
                  compra.attributes.order_tracking_details[
                    compra.attributes.order_tracking_details.length - 1
                  ].attributes.status === 5
                "
              >
                ENVIADO</span
              ><span
                v-if="
                  compra.attributes.order_tracking_details[
                    compra.attributes.order_tracking_details.length - 1
                  ].attributes.status === 6
                "
              >
                LISTO PARA ENTREGAR</span
              ><span
                v-if="
                  compra.attributes.order_tracking_details[
                    compra.attributes.order_tracking_details.length - 1
                  ].attributes.status === 7
                "
              >
                ENTREGADO</span
              ><span
                v-if="
                  compra.attributes.order_tracking_details[
                    compra.attributes.order_tracking_details.length - 1
                  ].attributes.status === 8
                "
              >
                SINIESTRADO</span
              ><span
                v-if="
                  compra.attributes.order_tracking_details[
                    compra.attributes.order_tracking_details.length - 1
                  ].attributes.status === 9
                "
              >
                EN PROCESO DE DEVOLUCIÓN</span
              ><span
                v-if="
                  compra.attributes.order_tracking_details[
                    compra.attributes.order_tracking_details.length - 1
                  ].attributes.status === 10
                "
              >
                RETORNADO AL PROVEEDOR</span
              ></span
            >

            <span
              class="px-3 py-1 rounded-full text-xs border border-mainOrange w-max text-mainOrange font-semibold"
              >{{
                typeService === 1 ? "Servicio Courier" : "Servicio Shopper"
              }}</span
            >
          </div>
          <p>
            <span class="line-clamp">
              {{ compra.attributes.product_info }}
            </span>
            <a
              :href="compra.attributes.reference_link"
              target="_Blank"
              v-if="typeService === 2"
            >
              <img
                src="../../../../assets/icons/icon-url.svg"
                class="ml-2 w-4 inline"
              />
            </a>
          </p>
        </div>
      </div>
      <div class="flex flex-col xl:flex-row gap-5 text-xs xl:text-base mb-6">
        <p class="flex items-center gap-3">
          <span class="font-medium text-sm">Precio</span>
          <span class="font-bold"
            ><span class="text-mainOrange">USD </span
            >{{ compra.attributes.real_price }}</span
          >
        </p>
        <p class="flex items-center gap-3">
          <span class="font-medium text-sm">Cantidad</span>
          <span class="font-bold">{{
            compra.attributes.product_specimens
          }}</span>
        </p>
        <p class="flex items-center gap-3">
          <span class="font-medium text-sm">Peso</span>
          <span class="font-bold">{{ compra.attributes.weight_kg }} kg</span>
        </p>
      </div>

      <div class="flex flex-row gap-4 items-center text-xs xl:text-sm">
        <p>Canal de SUNAT:</p>

        <span
          v-if="compra.attributes.channel === 'A'"
          class="px-5 py-1 bg-mainGreenLight text-white text-xs xl:text-sm rounded-2xl"
          >Verde</span
        >
        <span
          v-if="compra.attributes.channel === 'B'"
          class="px-5 py-1 bg-mainRed text-white text-xs xl:text-sm rounded-2xl"
          >Rojo</span
        >

        <span
          v-if="compra.attributes.channel === 'C'"
          class="px-5 py-1 bg-gray-400 text-white text-xs xl:text-sm rounded-2xl"
          >Por asignar</span
        >
        <div class="relative">
          <img
            @mouseover="popUp = true"
            @mouseleave="popUp = false"
            src="../../../../assets/icons/icon-info.svg"
            class="cursor-pointer"
          />
          <div
            v-if="popUp"
            :class="[
              compra.attributes.channel === 'A' ? 'bg-mainGreenLight' : '',
              compra.attributes.channel === 'B' ? 'bg-mainRed' : '',
              compra.attributes.channel === 'C' ? 'bg-gray-400' : '',
            ]"
            class="z-10 absolute -top-48 xl:-top-44 right-[-2.5rem] xl:-right-32 text-white text-xs xl:text-sm py-4 px-6 rounded-2xl w-max transition-all delay-400"
            style="max-width: 95vw"
          >
            <span class="block mb-3 font-semibold underline"
              >CANALES DE CONTROL</span
            >
            <span class="block mb-3">
              <span class="font-semibold">Canal VERDE:</span> No se requiere la
              revisión documentaria de <br />
              la declaración ni el reconocimiento físico de la mercancia.
            </span>
            <span class="block">
              <span class="font-semibold">Canal ROJO:</span> La mercancía se
              encuentra sujeta <br />
              a reconocimiento físico
            </span>
            <span
              :class="[
                compra.attributes.channel === 'A' ? 'bg-mainGreenLight' : '',
                compra.attributes.channel === 'B' ? 'bg-mainRed' : '',
                compra.attributes.channel === 'C' ? 'bg-gray-400' : '',
              ]"
              class="absolute -bottom-7 right-6 xl:right-28 w-14 h-8 triangle"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-4" />
    <!-- Dirección de entrega -->
    <h4 class="text-xs xl:text-base text-text-blue font-semibold mb-8">
      Dirección de entrega
    </h4>

    <div
      v-if="compra.attributes.at_office_flag === 0"
      class="relative ring ring-gray-100 hover:ring-mainGreenLight shadow-sm rounded-2xl mb-8"
    >
      <div class="flex items-center gap-4 xl:gap-6 py-4 px-5 xl:p-6">
        <img src="../../../../assets/icons/oficina2.svg" alt="" />
        <div
          class="flex items-center justify-between font-semibold cursor-pointer"
        >
          <div class="text-text-blue">
            <p class="text-sm xl:text-base mb-2">
              {{ compra.attributes.shipping_address.attributes.owner_name }}
              {{ compra.attributes.shipping_address.attributes.owner_lastname }}
            </p>
            <p class="text-xs xl:text-sm mb-2">
              {{ compra.attributes.shipping_address.attributes.address }}
            </p>
            <p class="text-xs xl:text-sm mb-2">
              {{ compra.attributes.shipping_address.attributes.reference }}
            </p>
            <p class="text-xs xl:text-sm mb-2">
              {{
                compra.attributes.shipping_address.attributes.telephone_number
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Dirección de entrega CASO 2 -->

    <div
      v-if="compra.attributes.at_office_flag === 1"
      class="relative ring ring-gray-100 hover:ring-mainGreenLight shadow-sm rounded-2xl mb-8"
    >
      <div
        class="border-b rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold cursor-pointer"
      >
        <p class="text-text-blue text-sm xl:text-base">Recojo en Oficina</p>
      </div>
      <div
        class="rounded-2xl p-6 cursor-pointer flex items-center gap-4 xl:gap-8"
      >
        <img src="../../../../assets/icons/oficina-2.png" alt="" />
        <div>
          <p class="text-sm font-bold">Lima</p>
          <p class="text-xs text-gray-400">
            Jr. Emilio Althaus Nro. 121 Oficina 403
          </p>
        </div>
        <a
          href="https://goo.gl/maps/e2vcgRdQ93BdyYrC7"
          target="Blank"
          class="ml-auto"
        >
          <img src="../../../../assets/icons/ver-direccion.png" class="ml-auto"
        /></a>
      </div>
    </div>

    <hr class="my-4" />
    <!-- Métodos de pago -->
    <h4
      class="text-xs xl:text-base text-text-blue font-semibold mb-8 flex items-center gap-3"
    >
      Método de pago
      <img
        src="../../../../assets/icons/icon-pago-status.png"
        alt=""
        class="ml-4"
      />
      <span
        :class="[
          compra.attributes.payed === 1 ? 'text-gray-500 bg-gray-500' : '',
          compra.attributes.payed === 2 ? 'text-mainOrange bg-mainOrange' : '',
          compra.attributes.payed === 3
            ? 'text-mainGreenLight bg-mainGreenLight'
            : '',
          compra.attributes.payed === 10 ? 'text-red-500 bg-red-500' : '',
          compra.attributes.payed === 11 ? 'text-mainOrange bg-mainOrange' : '',
          compra.attributes.payed === 12 ? 'text-gray-500 bg-gray-500' : '',
        ]"
        class="px-3 py-1 rounded-full text-xs bg-opacity-20 w-max font-semibold"
      >
        <span v-if="compra.attributes.payed === 1">PENDIENTE</span>
        <span v-else-if="compra.attributes.payed === 2">EN PROCESO</span>
        <span v-else-if="compra.attributes.payed === 3">CONFIRMADO</span>
        <span v-else-if="compra.attributes.payed === 10">RECHAZADO"</span>
        <span v-else-if="compra.attributes.payed === 11">RECLAMADO</span>
        <span v-else-if="compra.attributes.payed === 12">RETORNADO</span></span
      >
    </h4>
    <!-- Transferencia bancaria -->
    <div
      class="text-text-blue relative ring ring-gray-100 hover:ring-mainGreenLight shadow-sm rounded-2xl mb-8"
      v-if="compra.attributes.payment_method_flag === 5"
    >
      <div
        class="relative rounded-2xl border-b flex items-center justify-between pl-16 xl:pl-20 p-6 font-semibold cursor-pointer"
      >
        <div class="absolute inset-y-0 left-2 xl:left-4 flex items-center">
          <img
            src="../../../../assets/icons/transferencia_bancaria.svg"
            alt="Icono de Envios USA"
          />
        </div>
        <span>Transferencia bancaria</span>
      </div>
      <div class="py-5 px-4 xl:px-6 xl:py-6">
        <div
          class="flex items-center justify-between xl:justify-start gap-2 xl:gap-8 mb-8"
        >
          <img
            src="../../../../assets/icons/interbank-2.svg"
            class="mt-2 w-12 xl:w-auto"
          />
          <p class="text-xs xl:text-base">
            <span id="transferencia-nombre" class="block font-bold"
              >GRUPO CASZA SAC</span
            >
            <span id="transferencia-ruc" class="block text-gray-400"
              >20609271044</span>
            <span id="transferencia-cuenta" class="block text-gray-400"
              >{{ moneda === "USD" ? "Cuenta corriente: 200-3005545622" : "Cuenta Corriente: 200-3005545615" }}
            </span>
            <span id="transferencia-cci" class="block text-gray-400"
              >{{ moneda === "USD" ? "CCI: 003-200-003005545622-39" : "CCI: 003-200-003005545615-33" }}
            </span>
          </p>
          <div
            class="xl:mr-6 xl:ml-auto"
            id="btn-copy"
            @click.prevent="copyPayment"
          >
            <img
              src="../../../../assets/icons/icon-copiar-2.png"
              class="cursor-pointer mx-auto"
            />
            <span class="text-mainOrange text-center text-xs">Copiar</span>
          </div>
        </div>

        <div class="" v-if="compra.attributes.payment_method_flag === 5">
          <div class="grid grid-cols-12 xl:gap-4 items-center">
            <label
              for="num_operacion"
              class="col-span-12 xl:col-span-3 text-xs sm:text-sm xl:text-base xl:mb-4 font-semibold cursor-pointer"
              >N° Operación:</label
            >
            <input
              :class="
                styleTrans && trans.number
                  ? 'border border-mainOrange '
                  : 'border border-gray-600 '
              "
              type="text"
              class="col-span-12 xl:col-span-9 xl:mb-4 rounded-2xl border shadow-sm py-4 placeholder-gray-400 text-center transition-colors focus:outline-none"
              placeholder="Escribe aquí ..."
              v-model="trans.number"
              :disabled="styleTrans"
            />

            <span
              class="font-semibold xl:mb-6 col-span-12 xl:col-span-3 text-xs sm:text-sm xl:text-base"
              >Voucher:</span
            >
            <label
              :class="
                styleTrans && trans.nombreImg === 'Voucher cargado'
                  ? 'border border-mainOrange '
                  : 'border border-gray-600 '
              "
              class="underline col-span-12 xl:col-span-9 xl:mb-6 rounded-2xl border shadow-sm py-4 placeholder-gray-400 text-center text-gray-400 cursor-pointer flex items-center justify-center gap-2"
            >
              <img src="../../../../assets/icons/agregar__boleta.png" alt="" />
              <input
                type="file"
                @change="factura2($event)"
                accept="image/*,.pdf"
                class="overflow-hidden h-0 w-0"
                :disabled="styleTrans"
              />
              <span v-if="trans.nombreImg === 'Voucher cargado'">
                <a :href="trans.url.url" target="_Blank">{{
                  trans.nombreImg
                }}</a>
              </span>
              <span v-else> {{ trans.nombreImg }} </span>
            </label>
          </div>

          <button
            :class="
              trans.number.length !== 0 ? 'bg-bg-black-blue' : ' bg-gray-500'
            "
            v-if="styleTrans === false"
            @click.prevent="sendVaucher2"
            class="text-white py-5 font-bold rounded-2xl block w-10/12 mx-auto mb-2"
          >
            Enviar
          </button>
          <p v-if="styleTrans" class="text-center text-xs mt-2 xl:text-sm">
            Acreditaremos tu pago en las proximas 48 horas
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="compra.attributes.payment_method_flag === 6"
      class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl"
    >
      <div
        class="relative rounded-2xl p-6 font-bold cursor-pointer text-mainGreenLight"
      >
        <span>Mi balance</span>
      </div>
    </div>

    <div
      v-if="compra.attributes.payment_method_flag === 3"
      class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl mt-1 mb-6"
    >
      <label class="relative block">
        <span class="bg-white absolute left-4 -top-2 text-xs font-medium"
          >Tarjeta de débito/crédito</span
        >
        <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
          <img
            src="../../../../assets/icons/icon-tarjetas-outline.svg"
            alt="Icono de Sendi"
          />
        </div>
        <input
          class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white rounded-2xl"
          type="text"
          value="0000-0000-0000-0000"
          readonly
        />
        <div class="absolute inset-y-0 right-3 xl:right-5 flex items-center">
          <img
            class="w-6"
            src="../../../../assets/icons/icon-visa-small.svg"
            alt="Icono de Sendi"
          />
        </div>
      </label>
    </div>

    <div
      v-if="compra.attributes.payment_method_flag === 2"
      class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl mb-6"
    >
      <div
        class="relative rounded-2xl pl-16 xl:pl-20 p-6 font-bold cursor-pointer"
      >
        <div class="absolute inset-y-0 left-4 xl:left-6 flex items-center">
          <img
            src="../../../../assets/icons/paypal-1.svg"
            alt="Icono de Sendi"
          />
        </div>
        <span>Paypal</span>
      </div>
    </div>
    <!-- Agentes y agencias -->
    <div
      class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl mb-6"
      v-if="compra.attributes.payment_method_flag === 4"
    >
      <div
        class="relative rounded-2xl border-b pl-16 xl:pl-20 p-6 font-bold cursor-pointer"
      >
        <div class="absolute inset-y-0 left-4 xl:left-6 flex items-center">
          <img
            class="w-10"
            src="../../../../assets/icons/pago-efectivo.png"
            alt="Icono de Sendi"
          />
        </div>
        <span class="text-text-blue">
          PagoEfectivo
          <span class="block text-xs text-gray-400 font-normal"
            >Agentes y agencias</span
          >
        </span>
      </div>

      <div class="py-4">
        <p
          class="text-gray-400 text-sm xl:text-base flex items-center justify-center mb-2 gap-6"
        >
          <span>Este es tu código de pago CIP:</span>
          <span
            class="text-right text-textColor font-bold xl:text-xl flex items-center gap-2"
            >{{ compra.attributes.atm_payment.payment_code }}</span
          >
          <span class="tooltip text-left">
            <img src="../../../../assets/icons/icon-clock.png" alt="" />
            <span class="tooltip-box -left-8"
              >Tienes tiempo hasta el
              {{ compra.attributes.atm_payment.expiration }}
            </span>
          </span>
        </p>
        <a
          :href="compra.attributes.atm_payment.external_resource_url"
          target="_blank"
          class="block text-center text-mainBlue font-semibold text-sm xl:text-base"
          >Ver instrucciones de pago</a
        >
      </div>
    </div>
    <hr class="my-4" />
    <!-- Detalles de pago -->
    <h4 class="text-xs xl:text-base text-text-blue font-semibold mb-8">
      Detalles de pago
    </h4>
    <div class="py-4 px-5 rounded-2xl border mb-8">
      <p
        class="text-xs text-bg-black-blue xl:text-sm font-bold flex items-center justify-between"
      >
        <span> {{ typeService === 2 ? "Producto" : "Gastos en USA" }}</span>
        <span>{{ moneda }} {{ detalle.cif }}</span>
      </p>
      <hr class="my-4" />
      <div class="text-sm text-gray-400 grid gap-3">
        <p v-if="typeService === 2" class="flex items-center justify-between">
          <span class="flex gap-3">
            <img
              src="../../../../assets/icons/flecha-enter.svg"
              class="w-2"
              alt=""
            />
            Precio del producto
          </span>
          <span>{{ moneda }} {{ detalle.precioProducto }}</span>
        </p>
        <p v-if="typeService === 2" class="flex items-center justify-between">
          <span class="flex gap-3 underline">
            <img
              src="../../../../assets/icons/flecha-enter.svg"
              class="w-2"
              alt=""
            />
            Shipping
          </span>
          <span>{{ moneda }}{{ detalle.shipping }}</span>
        </p>
        <p v-if="typeService === 2" class="flex items-center justify-between">
          <span class="flex gap-3 underline">
            <img
              src="../../../../assets/icons/flecha-enter.svg"
              class="w-2"
              alt=""
            />
            Tax
          </span>
          <span>{{ moneda }} {{ detalle.tax }}</span>
        </p>
        <p class="flex items-center justify-between">
          <span class="flex gap-3 underline">
            <img
              src="../../../../assets/icons/flecha-enter.svg"
              class="w-2"
              alt=""
            />
            Warehouse
          </span>
          <span>{{ moneda }} {{ detalle.warehouse }}</span>
        </p>
        <p class="flex items-center justify-between">
          <span class="flex gap-3 underline">
            <img
              src="../../../../assets/icons/flecha-enter.svg"
              class="w-2"
              alt=""
            />
            Flete
          </span>
          <span>{{ moneda }} {{ detalle.flete }}</span>
        </p>
        <hr class="my-3" />
      </div>
      <div v-if="detalle.impuestos !== '0.00'">
        <p
          class="text-xs text-bg-black-blue xl:text-sm font-bold flex items-center justify-between mt-1"
        >
          <span>Impuestos</span>
          <span>{{ moneda }} {{ detalle.impuestos }}</span>
        </p>

        <hr class="my-4" />
        <div class="text-sm text-gray-400 grid gap-3">
          <p class="flex items-center justify-between">
            <span class="flex gap-3">
              <img
                src="../../../../assets/icons/flecha-enter.svg"
                class="w-2"
                alt=""
              />
              Ad/Valorem
            </span>
            <span>{{ moneda }} {{ detalle.valorem }}</span>
          </p>
          <p class="flex items-center justify-between">
            <span class="flex gap-3 underline">
              <img
                src="../../../../assets/icons/flecha-enter.svg"
                class="w-2"
                alt=""
              />
              I.G.V
            </span>
            <span>{{ moneda }} {{ detalle.igv }}</span>
          </p>
          <p class="flex items-center justify-between">
            <span class="flex gap-3 underline">
              <img
                src="../../../../assets/icons/flecha-enter.svg"
                class="w-2"
                alt=""
              />
              I.P.M
            </span>
            <span>{{ moneda }} {{ detalle.ipm }}</span>
          </p>

          <hr class="my-3" />
        </div>
      </div>

      <div v-else>
        <p
          class="text-xs text-bg-black-blue xl:text-sm font-bold flex items-center justify-between mt-1"
        >
          <span>Impuestos</span>
          <span class="text-mainGreenLight">Gratis</span>
        </p>
        <hr class="my-4" />
      </div>
      <p
        class="text-xs text-bg-black-blue xl:text-sm font-bold flex items-center justify-between"
      >
        <span>Tarifa de servicio</span>
        <span>{{ moneda }} {{ detalle.tarifa }}</span>
      </p>
      <hr class="my-4" />

      <div class="text-sm text-gray-400 grid gap-3">
        <p class="flex items-center justify-between">
          <span class="flex gap-3">
            <img
              src="../../../../assets/icons/flecha-enter.svg"
              class="w-2"
              alt=""
            />
            Trámite aduanero
          </span>
          <span
            :class="
              detalle.tramiteAduanero === '0.00'
                ? 'text-mainGreenLight  font-bold'
                : ''
            "
            >{{ moneda }}
            {{
              detalle.tramiteAduanero === "0.00"
                ? "Gratis"
                : detalle.tramiteAduanero
            }}</span
          >
        </p>
        <p class="flex items-center justify-between">
          <span class="flex gap-3 underline">
            <img
              src="../../../../assets/icons/flecha-enter.svg"
              class="w-2"
              alt=""
            />
            Envío nacional
          </span>
          <span :class="free === 1 ? 'text-mainGreenLight font-bold' : ''"
            >{{ moneda }}
            {{ free === 1 ? "Gratis" : detalle.envioNacional }}</span
          >
        </p>
        <hr class="my-3" />
      </div>
      <span v-if="servicios.length !== 0">
        <p
          class="text-xs xl:text-sm font-bold flex items-center justify-between"
        >
          <span>Servicios Adicionales</span>
          <span
            >{{ moneda }}
            {{ moneda === "USD" ? servicioDolares : servicioSoles.toFixed(2) }}
          </span>
        </p>
        <hr class="my-4" />
        <div class="text-sm text-gray-400 grid gap-3">
          <p
            class="flex items-center justify-between"
            v-for="data in servicios"
            :key="data"
          >
            <span class="flex gap-3">
              <img
                src="../../../../assets/icons/flecha-enter.svg"
                class="w-2"
                alt=""
              />
              {{ data.name }}
            </span>
            <span
              >{{ moneda }}

              {{
                moneda === "USD"
                  ? data.pivot.amount
                  : (data.pivot.amount * data.exchange_rate).toFixed(2)
              }}</span
            >
          </p>

          <hr class="my-3" />
        </div>
      </span>

      <h4
        v-if="compra.attributes.coupon_discount !== null"
        class="text-xs xl:text-sm font-bold flex items-center justify-between"
      >
        <span>Cupón</span>
        <span>
          {{ moneda === "USD" ? "- USD" : "- PEN" }}
          {{
            moneda === "USD"
              ? Number(compra.attributes.coupon_discount).toFixed(2)
              : Number(
                  compra.attributes.coupon_discount * compra.attributes.exchange
                ).toFixed(2)
          }}
        </span>
      </h4>
      <hr class="my-3" v-if="compra.attributes.coupon_discount !== null" />

      <h4
        v-if="compra.attributes.balance_discount !== null"
        class="text-xs xl:text-sm font-bold flex items-center justify-between"
      >
        <span> Saldo utilizado</span>
        <span> - USD {{ compra.attributes.balance_discount }} </span>
      </h4>

      <hr class="my-3" v-if="compra.attributes.balance_discount !== null" />
      <h4
        v-if="compra.attributes.new_total_cost === null"
        class="xl:text-lg font-bold text-text-blue flex items-center justify-between"
      >
        <span class="text-secondary-blue">Pago total</span>
        <span class="block">
          <span class="text-mainOrange">{{ moneda }}</span>
          <span class="text-secondary-blue ml-1">
            {{ detalle.totalPagar }}</span
          >
        </span>
      </h4>

      <h4
        v-else
        class="xl:text-lg font-bold text-text-blue flex items-center justify-between"
      >
        <span>Pago total</span>
        <span class="block">
          <span class="text-mainOrange">{{ moneda }}</span>
          {{ compra.attributes.new_total_cost }}
        </span>
      </h4>
    </div>
    <!-- Tiempo de entrega -->
    <div class="t-xs xl:text-base flex items-center justify-center gap-4 mb-6">
      <img src="../../../../assets/icons/entrega.svg" />
      <p>
        Tiempo estimado de entrega:
        <span class="font-semibold text-mainBlue">{{
          compra.attributes.delivery_date
        }}</span>
      </p>
    </div>
    <!-- Descargar resumen -->
  </div>
</template>
<script>
import { server } from "../../../../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  props: ["compra"],
  data() {
    return {
      styleTrans: false,
      trans: {
        number: "",
        img: "",
        nombreImg: "Adjuntar archivo",
        url: null,
      },
      servicioSoles: 0.0,
      servicioDolares: 0.0,
      servicios: this.compra.attributes.services,
      popUp: false,
      moneda: this.compra.attributes.currency,
      typeService: this.compra.attributes.direct_type,
      free:
        this.compra.attributes.shipping_address === null
          ? 1
          : this.compra.attributes.shipping_address.attributes.free,
      detalle: {
        cif: (
          this.compra.attributes.cif_cost * this.compra.attributes.exchange
        ).toFixed(2),
        precioProducto: (
          this.compra.attributes.real_price *
          this.compra.attributes.product_specimens *
          this.compra.attributes.exchange
        ).toFixed(2),
        shipping: (
          this.compra.attributes.real_shipping * this.compra.attributes.exchange
        ).toFixed(2),
        tax: (
          this.compra.attributes.real_tax * this.compra.attributes.exchange
        ).toFixed(2),
        warehouse: (
          this.compra.attributes.warehouse * this.compra.attributes.exchange
        ).toFixed(2),
        flete: (
          this.compra.attributes.flete_cost * this.compra.attributes.exchange
        ).toFixed(2),
        impuestos: (
          this.compra.attributes.arancel_cost * this.compra.attributes.exchange
        ).toFixed(2),
        valorem: (
          this.compra.attributes.ad_valorem * this.compra.attributes.exchange
        ).toFixed(2),
        igv: (
          this.compra.attributes.igv * this.compra.attributes.exchange
        ).toFixed(2),
        ipm: (
          this.compra.attributes.ipm * this.compra.attributes.exchange
        ).toFixed(2),
        tarifa: (
          this.compra.attributes.distribution_cost *
          this.compra.attributes.exchange
        ).toFixed(2),
        tramiteAduanero: (
          this.compra.attributes.tramite_aduanero *
          this.compra.attributes.exchange
        ).toFixed(2),
        envioNacional: (
          this.compra.attributes.national_shipping *
          this.compra.attributes.exchange
        ).toFixed(2),
        totalPagar: (
          this.compra.attributes.total_cost * this.compra.attributes.exchange
        ).toFixed(2),
      },
    };
  },
  mounted() {
    this.$store.commit("resetServicios");
    this.getTotalServices();
    //Actualziando direccion del localstorage
    if (
      this.compra.attributes.shipping_address &&
      this.compra.attributes.original_shipping_address_id === null
    ) {
      const data = {};
      data.nombre = this.compra.attributes.shipping_address.attributes.address;
      data.lat = this.compra.attributes.shipping_address.attributes.lat;
      data.lng = this.compra.attributes.shipping_address.attributes.lng;
      data.departamento =
        this.compra.attributes.shipping_address.attributes.department;
      data.provincia = this.compra.attributes.shipping_address.attributes.city;
      data.distrito =
        this.compra.attributes.shipping_address.attributes.district;
      data.location = this.compra.attributes.shipping_address.attributes.free;
      const dataCompress = JSON.stringify(data);
      window.localStorage.setItem("direction", dataCompress);
    }
  },
  methods: {
    getTotalServices() {
      var totalServicioDolar = 0.0;
      var totalServicioSoles = 0.0;
      if (this.servicios.length !== 0) {
        this.servicios.forEach((value) => {
          totalServicioDolar = totalServicioDolar + value.pivot.amount;
          totalServicioSoles =
            totalServicioSoles + value.pivot.amount * value.exchange_rate;
        });
        this.servicioDolares = totalServicioDolar;
        this.servicioSoles = totalServicioSoles;
      }
    },
    factura2(e) {
      const file = e.target.files[0];
      this.trans.nombreImg = e.target.files[0].name;
      this.cargarImagen2(file);
    },
    cargarImagen2(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.trans.img = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    validateFormTrans() {
      const imagen = this.trans.img;
      const codigo = this.trans.number;

      if (codigo.length !== 0) {
        if (imagen.length === 0) {
          this.trans.img = null;
        }
        return true;
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Debe subir el N° de Operación";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    sendVaucher2() {
      const result = this.validateFormTrans();
      if (result) {
        axios
          .put(
            `${BASE_URL}` +
              "purchaseOrder/update-order-no-grabr/" +
              this.compra.id,
            {
              payment_code: this.trans.number,
              voucher: { voucher: this.trans.img, isFromAdmin: false },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              this.trans.number = response.data.body.attributes.payment_code;
              this.trans.url =
                response.data.body.attributes.vouchers_usa[0] ?? null;

              if (response.data.body.attributes.voucher_link !== null) {
                this.trans.nombreImg = "Voucher cargado";
              } else {
                this.trans.nombreImg = "Sin Voucher";
              }

              this.styleTrans = true;
              //----------component--------//
              this.$store.state.alert.titulo = "Éxito";
              this.$store.state.alert.description = "Orden Actualizada";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          })
          .catch((error) => {
            if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al subir el Voucher de la transferencia de pago";
              this.$store.state.alert.estado = true;
              //-------------------------
            }
          });
      }
    },
    copyPayment() {
      const nombre = document.getElementById(
        "transferencia-nombre"
      ).textContent;
      const ruc = document.getElementById("transferencia-ruc").textContent;
      const cuenta = document.getElementById(
        "transferencia-cuenta"
      ).textContent;
      const datos = `${nombre}\n${ruc}\n${cuenta} `;
      navigator.clipboard
        .writeText(datos)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description =
            "Se copio los datos de tranferencia con éxito";
          this.$store.state.alert.estado = true;
          //-------------------------
        })
        .catch((error) => console.log(error));
    },
    imprimir() {
      window.print();
    },
  },
};
</script>
