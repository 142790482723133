<template>
  <div
    :class="preload ? '' : 'oculto'"
    class="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div
      class="mx-4 px-8 xl:px-16 py-24 xl:py-32 bg-white rounded-2xl border shadow-sm"
    >
      <img
        class="animate-spin mb-8 xl:mb-12 mx-auto"
        src="../../assets/icons/loader.svg"
        style="animation-duration: 2s"
        alt="Icono de Sendi"
      />
      <p class="font-semibold text-lg text-center">
        "Obteniendo datos del producto"
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["preload"],
};
</script>
