<template>
  <!---Notificación de que se envios un correo-------->
  <div
    :class="confirm ? '' : 'hidden'"
    class="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    @click.prevent="closeModal($event)"
  >
    <div
      class="px-8 py-12 bg-white rounded-2xl border shadow-sm"
      id="modalConfirm"
    >
      >
      <img src="../../../assets/images/check-green.svg" class="mb-8 mx-auto" />
      <p class="text-center mb-3">El correo fue envíado a:</p>
      <p class="text-center font-bold mb-3">{{ data.email }}</p>
      <p class="text-center mb-4">
        Por favor, revisa tu buzón de entrada o spam.
      </p>
    </div>
  </div>
  <!---------------------------------->
  <!--Modal para recuperar contraseña---------------->
  <div
    :class="resetPassword ? '' : 'oculto'"
    class="fixed inset-0 sm:py-8 bg-[#FAFDFF] sm:bg-black sm:bg-opacity-50 z-50 flex items-center justify-center"
  >
    <section
      class="u-container py-0 overflow-hidden bg-white sm:rounded-3xl sm:border sm:shadow-md grid xl:grid-cols-2"
    >
      <aside class="py-20 bg-[#F2F8FF] hidden xl:block">
        <h2 class="font-normal text-center mb-20">
          Bienvenido a <span class="font-semibold">SendiBox</span>
        </h2>
        <figure>
          <img
            width="561"
            height="423"
            src="../../../assets/images/login-aside.svg"
            class="mx-auto"
          />
        </figure>
      </aside>
      <article
        class="min-h-screen sm:min-h-0 py-20 bg-[#FAFDFF] relative flex flex-col items-center justify-center"
      >
        <div class="sm:w-8/12 mx-auto">
          <!-- TITULO -->
          <h2 class="font-semibold text-center mb-4 text-4xl">
            Restablecer contraseña
          </h2>
          <p class="text-sm text-center mb-6">
            Enviaremos un enlace a tu correo electrónico
          </p>

          <!-- INPUTS -->
          <form action="" class="">
            <!-- USUARIO -->
            <label class="relative grid items-center mb-8">
              <input
                :class="error.email ? 'ring ring-mainRed' : ''"
                class="px-5 input w-full text-xs sm:text-sm font-semibold"
                type="email"
                v-model="data.email"
                placeholder="Correo electrónico"
              />
            </label>
            <!-- BUTTON -->
            <button
              @click.prevent="petittionReset"
              class="btn btn-blue w-full py-5 mb-4"
            >
              Enviar
            </button>
          </form>
        </div>
        <img
          @click.prevent="closeForm"
          src="../../../assets/icons/icon-exit-login.svg"
          alt="exit"
          class="absolute top-8 right-2 sm:top-8 sm:right-8 cursor-pointer"
        />
      </article>
    </section>
  </div>
  <!---------------------------------------------------->
</template>
<script>
import { server } from "../../../config/global";
const BASE_URL = server.API_URL;
import axios from "axios";
export default {
  props: ["resetPassword"],
  data() {
    return {
      confirm: false,
      error: {
        email: false,
      },
      data: {
        email: "",
      },
    };
  },
  methods: {
    petittionReset() {
      const valCorreo = this.validateEmail();
      if (valCorreo) {
        axios
          .post(`${BASE_URL}` + "client/sendResetPasswordLink", {
            email: this.data.email,
            store_id: 1,
          })
          .then((response) => {
            if (response.status === 200) {
              this.confirm = true;
              this.error.email = false;
              this.$emit("closeReset", false);
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              const mensajeError = error.response.data.errors.email[0].message;
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description = mensajeError;
              this.$store.state.alert.estado = true;
              //--------------------------//
            } else if (error.response.status === 404) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description = "Correo no encontrado";
              this.$store.state.alert.estado = true;
              //--------------------------//
            } else {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al pedir cambio de contraseña";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
    },
    validateEmail() {
      const data = this.data.email;
      if (data.length !== 0) {
        var expReg =
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        var resultado = expReg.test(data);
        if (resultado === true) {
          this.error.email = false;
          return true;
        } else {
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description = "Ingrese un correo válido";
          this.$store.state.alert.estado = true;
          //--------------------------//

          this.error.email = true;
          return false;
        }
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese su correo";
        this.$store.state.alert.estado = true;
        //--------------------------//

        this.error.email = true;
        return false;
      }
    },

    closeForm() {
      this.error.email = false;
      this.data.email = "";
      this.$emit("closeReset", false);
    },
    closeModal(e) {
      const modalclose = document
        .getElementById("modalConfirm")
        .contains(e.target);
      if (modalclose === false) {
        this.confirm = false;
        this.data.email = "";
        this.error.email = false;
      }
    },
  },
  watch: {
    "data.email": function () {
      this.error.email = false;
    },
  },
};
</script>
