<template>
  <header-component />
  <main class="mt-14 xl:mt-24">
    <section class="hidden xl:block">
      <img src="../assets/images/franja-flujo.jpg" class="w-full" />
    </section>
    <section class="section-flujo-compra">
      <article class="u-container py-14 grid xl:grid-cols-2 gap-8">
        <!-- Bloque de la izquierda -->
        <section>
          <div
            :class="$store.state.modal.direction ? '' : 'oculto'"
            class="z-50 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            v-if="flujo === 1"
          >
            <div class="mx-4">
              <div
                class="rounded-tr-2xl rounded-tl-2xl bg-mainBlue py-4 px-6 xl:px-12 flex justify-between cursor-pointer"
              >
                <div class="flex gap-6 items-center mr-auto xl:mr-28">
                  <img
                    src="../assets/images/mapita.png"
                    class="w-10"
                    alt="Icono de Sendi"
                  />
                  <span class="text-white text-sm">Tu dirección en USA </span>
                </div>
                <img
                  @click.prevent="openDirection"
                  src="../assets/icons/icon-exit-white.svg"
                  class="w-5"
                  alt="Icono de Sendi"
                />
              </div>
              <div
                class="rounded-br-2xl rounded-bl-2xl bg-white flex px-6 xl:px-12 py-6 leading-6 text-sm font-medium gap-6"
              >
                <div>
                  <span class="font-semibold block"> Nombre: </span>
                  <span class="font-semibold block"> Dirección: </span>
                  <span class="font-semibold block"> Ciudad: </span>
                  <span class="font-semibold block"> Estado: </span>
                  <span class="font-semibold block"> Código Postal: </span>
                  <span class="font-semibold block"> País: </span>
                  <span class="font-semibold block"> Teléfono: </span>
                </div>
                <div>
                  <span class="block" v-if="user !== null"
                    >{{
                      user.globalName !== null
                        ? user.globalName.split(" ", 1)[0]
                        : ""
                    }}
                    {{
                      user.globalLastName !== null
                        ? user.globalLastName.split(" ", 1)[0]
                        : ""
                    }}{{ user.identificador }}
                  </span>
                  <span class="block" v-else> {{ direction.nombre }}</span>
                  <span class="block"> {{ direction.direccion }} </span>
                  <span class="block"> {{ direction.ciudad }} </span>
                  <span class="block"> {{ direction.estado }} </span>
                  <span class="block"> {{ direction.codigoPostal }} </span>
                  <span class="block"> {{ direction.pais }} </span>
                  <span class="block"> {{ direction.telefono }} </span>
                </div>
              </div>
            </div>
          </div>
          <balance-component v-if="flujo === 3" />
          <article class="xl:bg-white xl:border xl:shadow-lg rounded-2xl">
            <!-- Timeline -->
            <time-line-component :flujo="flujo" @changeFlujo="flujo = $event" />
            <span v-show="flujo === 1">
              <courier-component
                :changeMoney="changeMoney"
                @productoCalculo="courier = $event"
              />
            </span>
            <procesoComponent
              :flujo="flujo"
              :moneda="moneda"
              :errorNewDirection="error"
              :cambioSoles="changeMoney"
              :compra="compra"
              :data="courier"
              :terminos="terminos"
              @changeMoney="moneda = $event"
              @typeDirection="selectDirection = $event"
              @typePayment="selectPayment = $event"
              @calculoTarifa="calculoTarifa = $event"
              @next="flujo = $event"
              @dataMercadoPago="compra = $event"
              @existForm="statusForm = $event"
            />
          </article>
        </section>
        <!-- Bloque de la derecha -->
        <section>
          <!-- Servicios Extra -->
          <span v-show="flujo === 3">
            <servicios-component />
          </span>
          <span v-show="flujo === 3">
            <cupon-component :idUser="user" />
          </span>
          <!-- Detalles de pago -->
          <detalle-component
            v-if="flujo === 1 || flujo === 2 || flujo === 3"
            :flujo="flujo"
            :data="courier"
            :selectDirection="selectDirection"
            :selectPayment="selectPayment"
            :stateAuth="stateAuth"
            :calculoTarifa="calculoTarifa"
            :moneda="moneda"
            :statusForm="statusForm"
            @errorNewDirection="error = $event"
            @next="flujo = $event"
            @compra="compra = $event"
            @terminos="terminos = $event"
          />
          <!-- Informacion de envio -->
          <information-component
            :data="courier"
            v-if="flujo === 1 || flujo === 2 || flujo === 3"
          />
          <thankyou-componet v-if="flujo === 4" :compra="compra" />
        </section>
      </article>
    </section>
  </main>

  <footer-component />
</template>
<script>
import headerComponent from "../components/global/headerComponent.vue";
import footerComponent from "../components/global/footerComponent.vue";
import detalleComponent from "../components/servicios/detalle/detalleComponent.vue";
import informationComponent from "../components/servicios/detalle/informationComponent.vue";
import timeLineComponent from "../components/servicios/timeline/timeLineComponent.vue";
import courierComponent from "../components/servicios/courierComponent.vue";
import procesoComponent from "../components/servicios/proceso/procesoComponent.vue";
import thankyouComponet from "../components/servicios/detalle/thankyouComponent.vue";
import cuponComponent from "../components/servicios/detalle/cuponComponent.vue";
import balanceComponent from "../components/servicios/proceso/payment/Balance/balanceComponent.vue";
import serviciosComponent from "../components/servicios/detalle/serviciosComponent.vue";
import { server, directionUsa } from "../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  components: {
    headerComponent,
    footerComponent,
    detalleComponent,
    informationComponent,
    timeLineComponent,
    courierComponent,
    procesoComponent,
    thankyouComponet,
    cuponComponent,
    balanceComponent,
    serviciosComponent,
  },
  data() {
    return {
      statusForm: false,
      terminos: false,

      direction: directionUsa,
      user: {},
      compra: {},
      calculoTarifa: {
        dolar: {
          doorTodoor: "0.00",
          manipulacion: "0.00",
          tramiteAduanero: "0.00",
          envioNacional: "0.00",
          totalTarifa: "0.00",
        },
        soles: {
          doorTodoor: "0.00",
          manipulacion: "0.00",
          tramiteAduanero: "0.00",
          envioNacional: "0.00",
          totalTarifa: "0.00",
        },
      },
      selectPayment: 0,
      selectDirection: { type: 0 },
      flujo: 1,
      moneda: "USD",
      changeMoney: 0.0,
      error: {},
      courier: {
        servicio: 1,
        producto: {
          nombre: "",
          precio: 0.0,
          peso: 0.0,
          unidad: "",
          cantidad: 0,
          foto: "",
          vaucher: "",
          description: "",
        },
        dolares: {
          primera: {
            precio: "0.00",
            shipping: "0.00",
            tax: "0.00",
            frontTax: "0.00",
            warehouse: "0.00",
            flete: "0.00",
            total: "0.00",
            frontTotal: "0.00",
          },
          segundo: {
            estado: 0,
            valorem: "0.00",
            igv: "0.00",
            ipm: "0.00",
            total: "0.00",
          },
        },
        soles: {
          primera: {
            precio: "0.00",
            shipping: "0.00",
            tax: "0.00",
            frontTax: "0.00",
            warehouse: "0.00",
            flete: "0.00",
            total: "0.00",
            frontTotal: "0.00",
          },
          segundo: {
            estado: 0,
            valorem: "0.00",
            igv: "0.00",
            ipm: "0.00",
            total: "0.00",
          },
        },
      },
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("auth"));
    this.getMoney();
  },
  mounted() {
    window.scrollTo(0, 0);
    window.localStorage.removeItem("shooperProducts");
    window.localStorage.removeItem("producDetail");
  },
  methods: {
    openDirection() {
      this.$store.commit("statusModalDirection");
    },
    //Metodo para obtener el el precio de la moneda para hacer la conversión a soles
    getMoney() {
      axios
        .get(`${BASE_URL}` + "exchange_rate/list")
        .then((response) => {
          if (response.status === 200) {
            this.changeMoney = parseFloat(response.data.sale);
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al obtener el valor de la moneda";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
  },
  computed: {
    stateAuth() {
      const data = localStorage.getItem("auth");
      if (data !== null) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    flujo: function (val) {
      window.scrollTo(0, 0);
      if (val === 1 || val === 2) {
        this.moneda = "USD";
      }
    },
  },
};
</script>
