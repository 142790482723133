<template>
  <article class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
    <h3 class="text-xl xl:text-3xl font-bold text-bg-black-blue mb-10">
      Cotizaciones
    </h3>
    <div>
      <header
        class="grid xl:grid-cols-12 p-5 bg-bg-black text-white font-bold text-xs xl:text-sm rounded-tr-2xl rounded-tl-2xl"
      >
        <p class="text-center xl:hidden">Producto</p>
        <p class="hidden xl:block col-span-1"></p>
        <p class="hidden xl:block col-span-6">Producto</p>
        <p class="hidden xl:block col-span-3">URL del producto</p>
        <p class="hidden xl:block col-span-2"></p>
      </header>
      <span v-for="cotizacion in dataCotizacion" :key="cotizacion">
        <div
          class="grid grid-cols-12 gap-5 xl:gap-3 xl:grid-cols-12 p-5 border-b text-sm xl:text-base items-center"
        >
          <p class="order-1 xl:order-1 col-span-2 xl:col-span-1">
            <img
              class="object-cover mx-auto w-8 xl:w-auto"
              :src="cotizacion.img"
              style="max-width: 50px"
              alt="Icono de Sendi"
              v-if="cotizacion.img && cotizacion.img !== 'vacío'"
            />
            <img
              v-else
              class="object-cover mx-auto w-8 xl:w-auto"
              src="../../assets/images/caja.svg"
              style="max-width: 50px"
              alt="Icono de Sendi"
            />
          </p>

          <p class="order-2 xl:order-2 col-span-10 xl:col-span-6">
            <span
              class="line-clamp"
              v-if="cotizacion.title !== null && cotizacion.title !== 'PAQUETE'"
              >{{ cotizacion.title }}</span
            >

            <span v-else class="line-clamp">
              Precio:{{ cotizacion.price }} | Peso:{{ cotizacion.weight }}
            </span>
            <br />
            {{ cotizacion.created_at }}
          </p>

          <p class="order-3 xl:order-3 col-span-10 xl:col-span-3 col-start-3">
            <a
              v-if="cotizacion.url !== null && cotizacion.url !== 'vacío'"
              :href="cotizacion.url"
              target="Black"
              class="flex items-center gap-4"
            >
              Link aquí
              <img src="../../assets/icons/ir-al-link-orange.png" alt="" />
            </a>
            <a v-else class="flex items-center gap-4"> Sin link </a>
          </p>
          <p
            class="order-4 col-span-10 xl:col-span-2 col-start-3 col-start-3 xl:col-start-auto"
          >
            <router-link
              :to="{ name: 'Shooper', params: { url: cotizacion.url } }"
              class="text-mainOrange underline"
              >Seguir cotizando</router-link
            >
          </p>
        </div>
      </span>
      <p
        class="font-medium text-center pb-8 pt-14"
        v-if="dataCotizacion.length === 0"
      >
        No tienes ninguna cotización registrada
      </p>
      <p class="font-medium text-center pb-8 pt-14" v-if="loading">
        Cargando sus cotizaciones...
      </p>
      <!-- Pagination -->
      <div
        class="mt-12 flex justify-end items-center gap-12 col-start-3 xl:col-start-auto"
      >
        <p>
          Pág.
          <span class="mx-3 py-1 px-3 ring ring-blue-300">{{
            dataTable.from
          }}</span>
          de
          {{ dataTable.page }}
        </p>
        <div class="flex items-center gap-4">
          <figure
            v-if="dataTable.pastStatus"
            @click.prevent="getPedidosPast"
            class="cursor-pointer p-3"
          >
            <img src="../../assets/icons/chevron-left-orange.png" />
          </figure>
          <figure
            v-if="dataTable.nextStatus"
            @click.prevent="getPedidosNext"
            class="cursor-pointer p-3"
          >
            <img src="../../assets/icons/chevron-right-orange.png" />
          </figure>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import { server } from "../../config/global";
import axios from "axios";
const BASE_URL = server.API_URL;
export default {
  components: {},
  props: ["id"],
  data() {
    return {
      loading: true,
      dataCotizacion: {},
      dataTable: {
        pagination: 4,
        page: 0,
        from: 0,
        next: "",
        past: "",
        nextStatus: false,
        pastStatus: false,
      },
      pageNext: 1,
      pagePast: 1,
    };
  },
  created() {
    this.getPedidosNext();
  },
  methods: {
    getPedidosNext() {
      axios
        .get(
          `${BASE_URL}` +
            "client/getQuotationList/" +
            this.id +
            `?length=${this.dataTable.pagination}&page=${this.pageNext}`
        )
        .then((response) => {
          this.loading = false;
          this.dataCotizacion = response.data.body.data;
          this.dataTable.page = response.data.body.last_page;
          this.dataTable.from = response.data.body.current_page;
          this.dataTable.next = response.data.body.next_page_url;
          this.dataTable.past = response.data.body.prev_page_url;
          if (this.dataTable.next !== null) {
            let separator = this.dataTable.next.split("?page=");
            this.pageNext = separator[1];
            this.dataTable.nextStatus = true;
          } else {
            this.dataTable.nextStatus = false;
          }
          if (this.dataTable.past !== null) {
            let separator = this.dataTable.past.split("?page=");
            this.pagePast = separator[1];
            this.dataTable.pastStatus = true;
          } else {
            this.dataTable.pastStatus = false;
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error Inténtelo nuevamente";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    getPedidosPast() {
      axios
        .get(
          `${BASE_URL}` +
            "client/getQuotationList/" +
            this.id +
            `?length=${this.dataTable.pagination}&page=${this.pagePast}`
        )
        .then((response) => {
          this.dataCotizacion = response.data.body.data;
          this.dataTable.page = response.data.body.last_page;
          this.dataTable.from = response.data.body.current_page;
          this.dataTable.next = response.data.body.next_page_url;
          this.dataTable.past = response.data.body.prev_page_url;
          if (this.dataTable.next !== null) {
            let separator = this.dataTable.next.split("?page=");
            this.pageNext = separator[1];
            this.dataTable.nextStatus = true;
          } else {
            this.dataTable.nextStatus = false;
          }
          if (this.dataTable.past !== null) {
            let separator = this.dataTable.past.split("?page=");
            this.pagePast = separator[1];
            this.dataTable.pastStatus = true;
          } else {
            this.dataTable.pastStatus = false;
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error Inténtelo nuevamente";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
  },
};
</script>
