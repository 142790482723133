<template>
  <!-- Hero -->
  <section class="bg-mainBlue">
    <div class="u-container">
      <div class="bg-white rounded-xl px-6 py-8 xl:py-10 xl:px-20">
        <article
          :class="typeService === 2 ? 'grid' : 'hidden'"
          class="xl:grid-cols-2 gap-20"
        >
          <div class="">
            <header
              class="bg-gray-200 xl:bg-white p-4 xl:p-0 rounded-full ring ring-gray-100 xl:ring-transparent flex gap-6 justify-around xl:justify-start mb-4 font-semibold text-xs xl:text-base relative underline"
            >
              <a
                @click.prevent="changeServiceForm(1)"
                class="z-10 flex gap-2 item-center text-gray-400 hover:text-mainOrange transition-colors cursor-pointer"
              >
                <img
                  src="../../assets/icons/SERVICIO-COURIER-1.png"
                  alt=""
                  class="hidden xl:block w-6 h-6"
                />Servicio Courier
              </a>
              <div
                class="absolute w-1/2 rounded-full top-0 bottom-0 right-0 border border-mainOrange xl:hidden transition-all bg-white"
              ></div>
              <a
                class="z-10 flex gap-2 item-center text-mainOrange hover:text-mainOrange transition-colors cursor-pointer"
              >
                <img
                  src="../../assets/icons/SERVICIO-SHOPPER-2.png"
                  alt=""
                  class="hidden xl:block w-6 h-6"
                />Servicio Shopper
              </a>
            </header>
            <h2 class="text-bg-black-blue mb-6 text-xl xl:text-4xl font-bold">
              Deja que Sendi Shopper haga la importación por ti <br />
            </h2>
            <p class="mb-8 text-sm xl:text-base">
              Importa con comodidad y sin preocupaciones. Sendi Shopper te
              ahorra tiempo y esfuerzo en tus compras internacionales. Cotiza y
              compra fácilmente:
            </p>
            <label
              for="buscador-shopper"
              class="font-semibold text-xs xl:text-sm block mb-1"
              >Pega el link de tu producto</label
            >
            <form>
              <div class="relative mb-8">
                <img
                  src="../../assets/icons/icon-search-orange.png"
                  alt="Buscar"
                  class="absolute left-6 bottom-4 cursor-pointer"
                />
                <input
                  type="text"
                  :class="shooper.error ? 'ring ring-mainRed' : ''"
                  v-model="shooper.name"
                  placeholder="Ingresa el enlace o nombre"
                  class="text-textColor py-4 pl-16 px-8 rounded-xl w-full border bg-gray-100 bg-opacity-50 focus:outline-none focus:ring focus:ring-mainOrange"
                />
              </div>
              <button
                @click.prevent="searchProduct"
                class="font-semibold block w-full xl:w-1/2 rounded-xl py-4 bg-mainGreenLight text-white"
              >
                Cotizar GRATIS
              </button>
            </form>
          </div>
          <img
            src="../../assets/images/2-sendi.png"
            class="ml-auto hidden xl:block"
          />
        </article>
        <article
          :class="typeService === 1 ? 'grid' : 'hidden'"
          class="xl:grid-cols-2 gap-20"
        >
          <div class="">
            <header
              class="bg-gray-200 xl:bg-white p-4 xl:p-0 rounded-full ring ring-gray-100 xl:ring-transparent flex gap-6 justify-around xl:justify-start mb-4 font-semibold text-xs xl:text-base relative underline"
            >
              <a
                class="z-10 flex gap-2 item-center text-mainOrange hover:text-mainOrange transition-colors cursor-pointer"
              >
                <img
                  src="../../assets/icons/SERVICIO-COURIER-1.png"
                  alt=""
                  class="hidden xl:block w-6 h-6"
                />Servicio Courier
              </a>

              <a
                @click.prevent="changeServiceForm(2)"
                class="z-10 flex gap-2 item-center text-gray-400 hover:text-mainOrange transition-colors cursor-pointer"
              >
                <img
                  src="../../assets/icons/SERVICIO-SHOPPER-2.png"
                  alt=""
                  class="hidden xl:block w-6 h-6"
                />Servicio Shopper
              </a>
              <div
                class="absolute w-1/2 rounded-full top-0 bottom-0 left-0 border border-mainOrange xl:hidden transition-all bg-white"
              ></div>
            </header>

            <h2 class="text-bg-black-blue mb-6 text-base xl:text-3xl font-bold">
              <span class="flex items-center gap-5 mb-2">
                Tu mejor opción para
                <img src="../../assets/icons/bandera_usa.svg" class="w-10" />
              </span>
              <span class="flex items-center gap-5">
                importar con rapidez y seguridad.
                <img src="../../assets/icons/bandera_peru.svg" class="w-10" />
              </span>
            </h2>
            <p class="mb-8 text-sm xl:text-base">
              Envía tus compras internacionales con seguridad y rapidez con
              Sendi Courier, tu mejor opción para importar con confianza. Conoce
              el costo exacto de tu importación con Sendi Courier:
            </p>
            <!-- Precio referencial y Peso -->
            <form>
              <div class="grid xl:grid-cols-2 gap-8 xl:gap-6 mb-8">
                <div>
                  <label
                    for="precio_referencial"
                    class="block mb-2 font-semibold text-xs xl:text-sm"
                    >Precio referencial</label
                  >
                  <div class="relative">
                    <div
                      class="absolute left-6 inset-y-0 flex items-center font-bold text-mainOrange"
                    >
                      <span>USD</span>
                    </div>
                    <input
                      :class="errorCourier.precio ? 'ring ring-mainRed' : ''"
                      class="pl-16 p-6 input w-full font-semibold focus:border-blue-400 bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl"
                      type="number"
                      placeholder="0.00"
                      v-model="courier.precio"
                      @keydown="typeNumber"
                    />
                    <span class="absolute bottom-2 left-6 text-xs text-gray-400"
                      >Precio Max. USD 3000.00</span
                    >
                    <div
                      class="cursor-pointer absolute right-6 inset-y-0 flex items-center text-mainRed"
                    >
                      <img
                        @mouseover="popUp = true"
                        @mouseleave="popUp = false"
                        id="popup-btn"
                        src="../../assets/icons/question-black.svg"
                      />
                      <!-- POP UP -->
                      <div
                        :class="popUp ? '' : 'oculto'"
                        class="z-10 absolute -top-16 xl:-top-20 -right-14 xl:-right-32 bg-mainRed text-white text-xs xl:text-sm text-center p-4 rounded-2xl w-max transition-all delay-400"
                      >
                        Si el precio de tu producto excede el valor máximo,
                        <span class="block">
                          comunícate con atención al cliente para poder ayudarte
                        </span>
                        <span
                          class="absolute -bottom-7 right-10 xl:right-28 w-14 h-8 triangle bg-mainRed"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <label
                    for="peso"
                    class="block mb-2 font-semibold text-xs xl:text-sm"
                    >Peso</label
                  >
                  <div class="relative">
                    <input
                      :class="errorCourier.peso ? 'ring ring-mainRed' : ''"
                      class="p-6 input w-full font-bold focus:border-blue-400 bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl"
                      v-model="courier.peso"
                      type="number"
                      placeholder="0.00"
                      @keydown="typeNumber"
                    />

                    <div
                      class="absolute right-6 inset-y-0 flex items-center font-bold"
                    >
                      <span
                        @click.prevent="changeUnit('kg')"
                        :class="
                          courier.unidad === 'kg' ? 'text-mainOrange' : ''
                        "
                        class="cursor-pointer px-3 hover:text-mainOrange transition-colors"
                        >kg</span
                      >
                      <span
                        @click.prevent="changeUnit('lb')"
                        :class="
                          courier.unidad === 'lb' ? 'text-mainOrange' : ''
                        "
                        class="cursor-pointer px-3 hover:text-mainOrange transition-colors border-l border-r"
                        >lb</span
                      >
                      <span
                        @click.prevent="changeUnit('oz')"
                        :class="
                          courier.unidad === 'oz' ? 'text-mainOrange' : ''
                        "
                        class="cursor-pointer px-3 hover:text-mainOrange transition-colors border-r"
                        >oz</span
                      >

                      <img
                        @mouseover="popUp2 = true"
                        @mouseleave="popUp2 = false"
                        src="../../assets/icons/icon-info.svg"
                        class="pl-3 cursor-pointer"
                      />
                      <div
                        :class="popUp2 ? '' : 'oculto'"
                        class="z-10 absolute -top-16 xl:-top-20 -right-14 xl:-right-32 bg-mainRed text-white text-xs xl:text-sm text-center p-4 rounded-2xl w-max transition-all delay-400"
                      >
                        Si el precio de tu producto excede el valor máximo,
                        <span class="block">
                          comunícate con atención al cliente para poder ayudarte
                        </span>
                        <span
                          class="absolute -bottom-7 right-10 xl:right-28 w-14 h-8 triangle bg-mainRed"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex items-center gap-4 pl-2 mb-8">
                <input
                  type="checkbox"
                  class="cursor-pointer w-4 h-4 block"
                  id="paquete"
                  v-model="cotzacionAvanzada"
                />
                <label
                  for="paquete"
                  class="flex items-center gap-3 cursor-pointer text-sm xl:text-base font-medium tooltip no-underline"
                >
                  Estoy cotizando un paquete
                  <img src="../../assets/icons/icon-info.svg" alt="" />
                  <span class="tooltip-box">
                    Un paquete es un conjunto de productos agrupados para su
                    transporte, comprados juntos y enviados juntos a tu
                    dirección en nuestro almacén de Miami
                  </span>
                </label>
              </div>
              <button
                @click.prevent="processCourier"
                class="font-semibold block w-full xl:w-1/2 rounded-xl py-4 bg-mainGreenLight text-white"
              >
                Cotizar GRATIS
              </button>
            </form>
          </div>
          <img
            src="../../assets/images/3-sendi.png"
            class="ml-auto hidden xl:block"
          />
        </article>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  components: {},
  props: ["typeService"],
  data() {
    return {
      cotzacionAvanzada: false,
      errorCourier: {
        peso: false,
        precio: false,
      },
      courier: {
        peso: "",
        precio: "",
        unidad: "kg",
      },
      shooper: {
        name: "",
        error: false,
      },
      popUp: false,
      popUp2: false,
      selectPeso: false,
    };
  },
  methods: {
    typeNumber($event) {
      const val = $event.target.value;
      if (val.includes(".")) {
        const dotIndex = val.indexOf(".");
        const valLength = val.length;
        const diferencia = valLength - dotIndex;
        if (
          diferencia > 2 &&
          $event.key !== "Backspace" &&
          $event.key !== "Tab"
        ) {
          $event.preventDefault();
        }
      }
    },
    validateDataCourier() {
      this.styleErrorCourier();
      const peso = this.courier.peso;
      const precio = this.courier.precio;
      const unidad = this.courier.unidad;
      if (
        peso.length !== 0 &&
        precio.length !== 0 &&
        unidad.length !== 0 &&
        precio !== 0 &&
        peso !== 0
      ) {
        return true;
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese los datos solicitados";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    styleErrorCourier() {
      const peso = this.courier.peso;
      const precio = this.courier.precio;
      const unidad = this.courier.unidad;

      if (peso.length === 0 || unidad.length === 0) {
        this.errorCourier.peso = true;
      } else {
        this.errorCourier.peso = false;
      }
      if (precio.length === 0) {
        this.errorCourier.precio = true;
      } else {
        this.errorCourier.precio = false;
      }
    },
    processCourier() {
      const result = this.validateDataCourier();
      if (result) {
        this.$router.push({
          name: "Courier",
          params: {
            precio: this.courier.precio,
            peso: this.courier.peso,
            unidad: this.courier.unidad,
            typeCotizacion: this.cotzacionAvanzada,
          },
        });
      }
    },
    changeUnit(data) {
      this.courier.unidad = data;
    },
    searchProduct() {
      const result = this.validateNameShooper();
      if (result) {
        this.$router.push({
          name: "SearchShooper",
          params: { name: this.shooper.name },
        });
      }
    },
    validateNameShooper() {
      const data = this.shooper.name;
      const result = data.length === 0 ? false : true;
      result ? (this.shooper.error = false) : (this.shooper.error = true);
      return result;
    },

    openSelectPeso() {
      this.selectPeso = !this.selectPeso;
    },
    changeServiceForm(id) {
      this.$emit("changeService", id);
      window.localStorage.setItem("servicio", id);
    },
  },
  watch: {
    "shooper.name": function () {
      this.shooper.error = false;
    },
    "courier.precio": function () {
      this.errorCourier.precio = false;
    },
    "courier.peso": function () {
      this.errorCourier.peso = false;
    },
  },
};
</script>
