<template>
  <!-- MODAL -->
  <div
    :class="confirmReset ? '' : 'hidden'"
    class="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
  >
    <div class="px-8 py-12 bg-white rounded-2xl border shadow-sm">
      <img
        src="../assets/images/check-green.svg"
        class="mb-8 mx-auto"
        alt="Icono de Sendi"
      />
      <p class="text-center mb-10">
        ¡Tú contraseña se restableció <br />
        existosamente!
      </p>
      <button
        @click.prevent="inicio"
        class="btn btn-blue w-full py-5 px-10 xl:px-16 mb-4"
      >
        Volver a la página de incio
      </button>
    </div>
  </div>
  <!-- Error-->
  <div
    :class="error.token ? '' : 'hidden'"
    class="fixed inset-0 sm:py-8 bg-[#FAFDFF] sm:bg-gray-100 z-50 min-h-screen w-full flex items-center justify-center"
  >
    <section
      class="u-container py-0 overflow-hidden bg-white sm:rounded-3xl sm:border sm:shadow-md grid"
    >
      <article
        class="py-12 xl:py-20 bg-[#FAFDFF] relative flex flex-col items-center justify-center"
      >
        <div class="sm:w-8/12 mx-auto">
          <img
            class="mx-auto mb-12"
            src="../assets/images/404.svg"
            alt="Icono de Sendi"
          />
          <!-- TITULO -->
          <h2 class="font-semibold text-center mb-12 text-4xl">
            ¡Uy! Algo salió mal
          </h2>
          <h4 class="font-semibold text-center mb-12 text-2xl">
            {{ message }}
          </h4>
          <!-- BUTTON -->
          <button
            @click.prevent="generationToken"
            class="block btn btn-blue py-5 px-20 mx-auto"
          >
            Volver a Generar Token
          </button>
        </div>
        <img
          @click.prevent="closeError"
          src="../assets/icons/icon-exit-login.svg"
          alt="Icono de Sendi"
          class="absolute top-8 right-4 sm:top-8 sm:right-8 cursor-pointer"
        />
      </article>
    </section>
  </div>

  <div
    class="sm:py-8 bg-[#FAFDFF] sm:bg-gray-100 z-50 min-h-screen w-full flex items-center justify-center"
  >
    <section
      class="u-container py-0 overflow-hidden bg-white sm:rounded-3xl sm:border sm:shadow-md grid xl:grid-cols-2"
    >
      <aside class="py-20 bg-[#F2F8FF] hidden xl:block">
        <h2 class="font-normal text-center mb-20">
          Bienvenido a <span class="font-semibold">SendiBox</span>
        </h2>
        <figure>
          <img
            width="561"
            height="423"
            src="../assets/images/login-aside.svg"
            class="mx-auto"
            alt="Icono de Sendi"
          />
        </figure>
      </aside>
      <article
        class="py-20 bg-[#FAFDFF] relative flex flex-col items-center justify-center"
      >
        <div class="sm:w-8/12 mx-auto">
          <!-- TITULO -->
          <h2 class="font-semibold text-center mb-4 text-4xl">
            Restablecer contraseña
          </h2>
          <p class="text-sm text-center mb-6">
            Enviaremos un enlace a tu correo electrónico
          </p>

          <!-- INPUTS -->
          <form action="" class="">
            <!-- CORREO -->
            <label class="relative grid items-center mb-5">
              <input
                :class="error.email ? 'ring ring-mainRed' : ''"
                class="input px-5 w-full text-xs sm:text-sm font-semibold"
                type="email"
                placeholder="Ingrese su correo"
                v-model="data.email"
              />
            </label>
            <label class="relative grid items-center mb-5">
              <img
                @click.prevent="changeInput(1)"
                width="24"
                height="15"
                src="../assets/icons/icon-watch.svg"
                class="absolute top-7 right-6 cursor-pointer"
                alt="Icono de Sendi"
              />
              <input
                :class="error.password ? 'ring ring-mainRed' : ''"
                class="input px-5 w-full text-xs sm:text-sm font-semibold"
                :type="style.new ? 'text' : 'password'"
                placeholder="Nueva contraseña"
                v-model="data.password"
              />
            </label>
            <label class="relative grid items-center mb-5">
              <img
                src="../assets/icons/icon-watch.svg"
                width="24"
                height="15"
                class="absolute top-7 right-6 cursor-pointer"
                @click.prevent="changeInput(2)"
                alt="Icono de Sendi"
              />

              <input
                :class="error.confirmPassword ? 'ring ring-mainRed' : ''"
                class="input px-5 w-full text-xs sm:text-sm font-semibold"
                :type="style.old ? 'text' : 'password'"
                placeholder="Repita nueva contraseña"
                v-model="data.confirmPassword"
              />
            </label>
            <!-- BUTTON -->
            <button
              @click.prevent="resetPassword"
              class="btn btn-blue w-full py-5 mb-4"
            >
              Guardar y continuar
            </button>
          </form>
        </div>
      </article>
    </section>
  </div>
</template>
<script>
import { server } from "../config/global";
import axios from "axios";
const BASE_URL = server.API_URL;
export default {
  components: {},
  data() {
    return {
      confirmReset: false,
      data: {
        token: this.$route.params.token,
        email: "",
        password: "",
        confirmPassword: "",
      },
      error: {
        token: false,
        email: false,
        password: false,
        confirmPassword: false,
      },
      style: {
        new: false,
        old: false,
      },
      message: "",
    };
  },
  created() {},
  mounted() {
    window.scrollTo(0, 0);
    window.localStorage.removeItem("datalleProduct");
    window.localStorage.removeItem("shooperProducts");
    window.localStorage.removeItem("direction");
    window.localStorage.removeItem("auth");
    window.localStorage.removeItem("producDetail");
  },
  methods: {
    changeInput(data) {
      if (data === 1) {
        this.style.new = !this.style.new;
      } else if (data === 2) {
        this.style.old = !this.style.old;
      }
    },
    inicio() {
      this.$router.push({ name: "Home" });
      this.error.token = false;
      this.error.email = false;
      this.error.password = false;
      this.error.confirmPassword = false;

      this.data.email = "";
      this.data.password = "";
      this.data.confirmPassword = "";
      this.message = "";
      this.confirmReset = false;
    },
    generationToken() {
      this.$router.push({ name: "Home" });
    },
    closeError() {
      this.error.token = false;
      this.error.token = false;
      this.error.email = false;
      this.error.password = false;
      this.error.confirmPassword = false;

      this.data.email = "";
      this.data.password = "";
      this.data.confirmPassword = "";
      this.message = "";
      this.confirmReset = false;
    },
    validateToken() {
      const data = this.data.token;
      if (data.length === 0) {
        this.error.token = true;
        this.message =
          "El token esta incompleto, vuelva a dar click al botón que le llegó al correo";
        return false;
      } else {
        this.message = "";
        return true;
      }
    },
    validateEmail() {
      const data = this.data.email;
      if (data.length !== 0) {
        var expReg =
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        var resultado = expReg.test(data);
        if (resultado === true) {
          this.error.email = false;
          return true;
        } else {
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description = "Ingrese un correo válido";
          this.$store.state.alert.estado = true;
          //--------------------------//

          this.error.email = true;
          return false;
        }
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese su correo";
        this.$store.state.alert.estado = true;
        //--------------------------//

        this.error.email = true;
        return false;
      }
    },
    validatePassword() {
      const dataPassword = this.data.password;
      const confirmPassword = this.data.confirmPassword;

      if (dataPassword.length !== 0) {
        if (dataPassword.length >= 8) {
          if (dataPassword === confirmPassword) {
            this.error.password = false;
            this.error.confirmPassword = false;
            return true;
          } else {
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description =
              "Las contraseñas no coinciden";
            this.$store.state.alert.estado = true;
            //--------------------------//

            this.error.password = true;
            this.error.confirmPassword = true;
            return false;
          }
        } else {
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "La contraseña es mayor o igual a 8 dígitos";
          this.$store.state.alert.estado = true;
          //--------------------------//

          this.error.password = true;
          return false;
        }
      } else {
        this.error.password = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese su nueva contraseña";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    resetPassword() {
      const valEmail = this.validateEmail();
      const valPassword = this.validatePassword();
      const valToken = this.validateToken();
      if (valEmail && valPassword && valToken) {
        axios
          .post(`${BASE_URL}` + "client/resetPassword", {
            token: this.data.token,
            email: this.data.email,
            password: this.data.password,
            password_confirmation: this.data.confirmPassword,
            store_id: 1,
          })
          .then((response) => {
            if (response.status === 200) {
              this.style.new = false;
              this.style.old = false;

              this.error.token = false;
              this.error.email = false;
              this.error.password = false;
              this.error.confirmPassword = false;

              this.data.email = "";
              this.data.password = "";
              this.data.confirmPassword = "";
              this.message = "";
              this.confirmReset = true;
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              this.error.token = true;
              this.style.new = false;
              this.style.old = false;
              this.message = "El token ya expiró,vuelva a generar otro";
            } else {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Vuelva a intentarlo más tarde";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
    },
  },
  computed: {},
};
</script>
