<template>
  <div
    :class="registro ? '' : 'oculto'"
    class="transition-all fixed sm:py-8 inset-0 bg-[#FAFDFF] sm:bg-black sm:bg-opacity-50 z-50 flex items-center justify-center"
  >
    <section
      class="max-h-screen overflow-y-auto u-container py-0 overflow-x-hidden bg-white sm:rounded-3xl sm:border sm:shadow-md grid xl:grid-cols-2"
    >
      <aside class="py-20 bg-[#F2F8FF] hidden xl:block">
        <h2 class="font-normal text-center mb-20">
          Vamos a <span class="font-semibold">registrarnos ...</span>
        </h2>
        <figure>
          <img
            width="562"
            height="423"
            src="../../../assets/images/registro-aside.svg"
            class="mx-auto"
            alt="Icono de Sendi"
          />
        </figure>
      </aside>
      <article class="py-20 bg-[#FAFDFF] relative">
        <div class="sm:w-8/12 mx-auto">
          <!-- TITULO -->
          <h2 class="font-semibold text-center mb-4 text-4xl">Registrarte</h2>
          <p class="text-sm text-center mb-6">
            con tu cuenta para continuar...
          </p>
          <!-- GOOGLE/FACEBOOK -->
          <div class="grid grid-cols-2 items-center gap-4">
            <gmail-component />
            <facebook-component />
          </div>
          <!-- SEPARADORES -->
          <div class="my-6 flex items-center gap-4">
            <hr class="w-full" />
            <span class="h-4 w-8 rounded-full border-2 border-gray-400"></span>
            <hr class="w-full" />
          </div>
          <!-- INPUTS -->
          <form autocomplete="off" action="" class="">
            <!-- NOMBRE -->
            <label class="relative grid mb-4">
              <span
                class="absolute px-2 -top-2 left-3 bg-[#FAFDFF] text-gray-400 text-xs"
                >Nombre</span
              >
              <input
                :class="error.name ? 'ring ring-mainRed' : ''"
                class="input text-sm w-full font-semibold"
                type="text"
                placeholder="Ingrese su nombre"
                v-model="dataRegister.name"
              />
            </label>
            <!-- APELLIDOS -->
            <label class="relative grid mb-4">
              <span
                class="absolute px-2 -top-2 left-3 bg-[#FAFDFF] text-gray-400 text-xs"
                >Apellidos</span
              >
              <input
                :class="error.surnames ? 'ring ring-mainRed' : ''"
                class="input text-sm w-full font-semibold"
                type="text"
                placeholder="Ingrese su apellido"
                v-model="dataRegister.surnames"
              />
            </label>
            <label class="relative grid mb-4">
              <span
                class="absolute px-2 -top-2 left-3 bg-[#FAFDFF] text-gray-400 text-xs"
                >Celular</span
              >
              <input
                :class="error.telefono ? 'ring ring-mainRed' : ''"
                class="input text-sm w-full font-semibold"
                type="tel"
                placeholder="999 999 999"
                v-model="dataRegister.telefono"
              />
            </label>
            <!-- CORREO ELECTRONICO -->
            <label class="relative grid mb-4">
              <span
                class="absolute px-2 -top-2 left-3 bg-[#FAFDFF] text-gray-400 text-xs"
                >Correo electrónico</span
              >
              <input
                :class="error.email ? 'ring ring-mainRed' : ''"
                class="input text-sm w-full font-semibold"
                type="email"
                placeholder="Ingrese su correo"
                v-model="dataRegister.email"
              />
            </label>
            <!-- CONTRASEÑA -->
            <label class="relative grid mb-12 sm:mb-6">
              <span
                class="absolute px-2 -top-2 left-3 bg-[#FAFDFF] text-gray-400 text-xs"
                >Contraseña</span
              >
              <input
                :class="error.password ? 'ring ring-mainRed' : ''"
                class="input text-sm w-full font-semibold"
                :type="style.passworHide ? 'text' : 'password'"
                placeholder="Ingrese su contraseña"
                v-model="dataRegister.password"
              />
              <div class="absolute right-4 bottom-6 text-gray-400 bg-white-300">
                <img
                  v-on:click="statusHidePassword"
                  class="inline cursor-pointer"
                  width="24"
                  height="15"
                  src="../../../assets/icons/icon-watch.svg"
                  alt="Icono de Sendi"
                />
              </div>
            </label>
            <!-- BUTTONS -->
            <button
              v-on:click.prevent="registerUser"
              class="btn btn-blue w-full py-5 mb-4"
            >
              Crear cuenta
            </button>
          </form>
        </div>
        <img
          v-on:click="closeRegister"
          src="../../../assets/icons/icon-exit-login.svg"
          alt="Icono de Sendi"
          class="absolute top-8 right-2 sm:top-8 sm:right-8 cursor-pointer"
        />
      </article>
    </section>
  </div>
  <div
    :class="verification ? '' : 'hidden'"
    class="fixed h-screen inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center transition-opacity delay-400"
  >
    <div
      class="mx-4 bg-[#FAFAFA] px-8 py-24 xl:py-52 xl:px-36 rounded-2xl relative"
    >
      <div>
        <h2 class="text-2xl xl:text-4xl text-center font-semibold mb-6">
          Verifica tu correo
        </h2>
        <p class="mb-6 text-center text-sm xl:text-base">
          Esta acción requiere una verificación de correo.
          <br class="hiddensm:block" />
          Por favor revisa tu buzón de correo y <br class="hidden sm:block" />
          sigue las instrucciones enviadas.
        </p>
        <p class="mb-6 text-center">El correo fue enviado a:</p>
        <p class="font-semibold text-base xl:text-lg mb-8 text-center">
          {{ email2 }}
        </p>
        <button
          @click.prevent="repetitionVerification"
          class="w-full block bg-mainBlue py-6 text-white font-bold rounded-2xl"
        >
          Reenviar
        </button>
      </div>
      <img
        @click.prevent="closeModalVerification"
        class="absolute right-4 top-4 xl:right-10 xl:top-10 cursor-pointer"
        src="../../../assets/icons/icon-exit-login.svg"
        alt="Icono de Sendi"
      />
    </div>
  </div>
</template>
<script>
import facebookComponent from "../../global/autentificacion/redes/facebookComponent.vue";
import gmailComponent from "../../global/autentificacion/redes/gmailComponent.vue";
import { server } from "../../../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  components: { facebookComponent, gmailComponent },
  props: ["registro"],
  data() {
    return {
      codeInfluencer: this.$route.query.invitation,
      idUser: 0,
      email2: "",
      verification: false,
      style: {
        passworHide: false,
      },
      dataRegister: {
        name: "",
        surnames: "",
        email: "",
        password: "",
        telefono: "",
      },
      error: {
        name: false,
        surnames: false,
        email: false,
        password: false,
        telefono: false,
      },
    };
  },
  methods: {
    repetitionVerification() {
      axios
        .get(`${BASE_URL}` + "client/sendVerificationEmail/" + this.idUser)
        .then((response) => {
          if (response.status === 200) {
            //----------component--------//
            this.$store.state.alert.titulo = "Éxito";
            this.$store.state.alert.description = "Correo enviado";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Inténtelo más tarde";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    closeModalVerification() {
      this.verification = false;
      this.email2 = "";
      this.idUser = 0;
    },
    validateName(data) {
      if (data.length !== 0) {
        this.error.name = false;
        return true;
      } else {
        this.error.name = true;
        return false;
      }
    },
    validateSurname(data) {
      if (data.length !== 0) {
        this.error.surnames = false;
        return true;
      } else {
        this.error.surnames = true;
        return false;
      }
    },
    validateTelefono(data) {
      if (data.length !== 0) {
        this.error.telefono = false;
        return true;
      } else {
        this.error.telefono = true;
        return false;
      }
    },
    validateEmail(data) {
      if (data.length !== 0) {
        var expReg =
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        var resultado = expReg.test(data);
        if (resultado === true) {
          this.error.email = false;
          this.email2 = data;
          return true;
        } else {
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description = "Ingrese un correo";
          this.$store.state.alert.estado = true;
          //--------------------------//

          this.error.email = true;
          return false;
        }
      } else {
        this.error.email = true;
        return false;
      }
    },
    validatePassword(data) {
      if (data.length !== 0) {
        if (data.length >= 8) {
          this.error.password = false;
          return true;
        } else {
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "La contraseña es mayor o igual a 8 dígitos";
          this.$store.state.alert.estado = true;
          //--------------------------//

          this.error.password = true;
          return false;
        }
      } else {
        this.error.password = true;
        return false;
      }
    },
    validateFormRegister() {
      let name = this.dataRegister.name;
      let surnames = this.dataRegister.surnames;
      let email = this.dataRegister.email;
      let password = this.dataRegister.password;
      let telefono = this.dataRegister.telefono;
      let resultName = this.validateName(name);
      let resultSurname = this.validateSurname(surnames);
      let resultEmail = this.validateEmail(email);
      let resultPassword = this.validatePassword(password);
      let resultTelefono = this.validateTelefono(telefono);
      return (
        resultName &&
        resultSurname &&
        resultEmail &&
        resultPassword &&
        resultTelefono
      );
    },
    registerUser() {
      if (this.validateFormRegister()) {
        axios
          .post(`${BASE_URL}` + "client/create-client", {
            name: this.dataRegister.name,
            surnames: this.dataRegister.surnames,
            email: this.dataRegister.email,
            password: this.dataRegister.password,
            store_id: 1,
            influencer_code: this.codeInfluencer,
            cellphone: "+51" + this.dataRegister.telefono,
          })
          .then((response) => {
            if (response.status === 200) {
              this.idUser = response.data.body.id;
              this.verification = true;
              window.localStorage.removeItem("open");
              this.closeRegister();
            }
          })
          .catch((error) => {
            window.localStorage.removeItem("open");
            if (error.response.status === 404) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description = "El correo ya esta en uso";
              this.$store.state.alert.estado = true;
              //--------------------------//
            } else {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al registrarse inténtalo más tarde";
              this.$store.state.alert.estado = true;
              //--------------------------//

              this.resetdata();
              this.resetDataError();
            }
          });
      }
    },
    resetDataError() {
      this.error.name = false;
      this.error.surnames = false;
      this.error.email = false;
      this.error.password = false;
      this.error.telefono = false;
    },
    resetdata() {
      this.dataRegister.name = "";
      this.dataRegister.surnames = "";
      this.dataRegister.email = "";
      this.dataRegister.password = "";
      this.dataRegister.telefono = "";
    },
    closeRegister() {
      this.resetDataError();
      this.resetdata();
      this.$emit("closeRegister", false);
    },
    statusHidePassword() {
      this.style.passworHide = !this.style.passworHide;
    },
  },
  watch: {
    "dataRegister.name": function () {
      this.error.name = false;
    },
    "dataRegister.surnames": function () {
      this.error.surnames = false;
    },
    "dataRegister.email": function () {
      this.error.email = false;
    },
    "dataRegister.password": function () {
      this.error.password = false;
    },
  },
};
</script>
