<template>
  <section class="grid">
    <article
      class="relative bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
    >
      <button
        @click.prevent="imprimir"
        class="tooltip fixed xl:absolute right-4 top-20 xl:right-16 xl:top-12 block ml-auto bg-bg-black-blue text-white rounded-2xl p-3 xl:py-4 xl:px-6 font-bold flex items-center gap-4"
      >
        <img
          src="../../../assets/icons/icon-impresora.png"
          class="w-6 xl:w-8"
        />
        <span
          class="tooltip-box bottom-16 xl:bottom-20 text-xs xl:text-sm -left-6 xl:left-0"
          >Imprimir</span
        >
      </button>
      <div class="xl:p-8 text-center">
        <h4 class="text-sm xl:text-base font-semibold mb-10 text-text-blue">
          Solicitud recibida
        </h4>
        <img
          src="../../../assets/icons/solicitud-recibida.svg"
          class="mx-auto mb-14 xl:w-48"
        />
        <p class="mb-4 text-xs xl:text-sm">
          Tu número de tracking ha sido enviado a
          <a href="#" class="text-mainBlue font-bold block">{{
            compra.attributes.client.attributes.profile.attributes.userable
              .attributes.email
          }}</a>
        </p>

        <div
          class="font-medium text-sm xl:text-base text-black text-center flex items-center justify-center gap-3 border-b border-l border-r shadow-xl rounded-xl px-6 pb-6"
        >
          <p id="tracking-code">
            {{ compra.attributes.system_tracking_number }}
          </p>
          <button
            type="button"
            @click.prevent="copyCode"
            @keydown="copyCode"
            class="cursor-pointer"
            alt="Icono de Envios USA"
          >
            <img src="../../../assets/icons/icon-copiar-3.png" alt="" />
          </button>
        </div>
      </div>
    </article>
    <!-- Invoice de compra -->
    <article
      v-if="data.typeService === 1 && data.vaucher === false"
      class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
    >
      <h3 class="text-text-blue font-semibold xl:text-lg mb-6 text-center">
        No olvides agregar tu factura comercial
      </h3>
      <label
        class="xl:mx-16 cursor-pointer font-semibold text-white rounded-2xl bg-mainOrange py-5 text-sm xl:text-base flex items-center justify-center gap-4"
      >
        <img src="../../../assets/icons/subir-archivo.png" />
        <input
          type="file"
          class="hidden"
          @change="factura"
          ref="fileupload"
          accept="image/*,.pdf"
        />
        Agregar factura comercial
      </label>
    </article>

    <article
      v-if="data.typeService === 1 && data.vaucher === true"
      class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
    >
      <div
        class="mb-6 xl:mx-16 font-semibold rounded-2xl bg-mainGreenLight text-main-green ring ring-mainGreenLight bg-opacity-20 py-5 text-sm xl:text-base flex items-center justify-center gap-4 cursor-pointer"
      >
        Factura comercial cargado
        <img src="../../../assets/icons/check-green.png" class="w-6" />
      </div>

      <div class="xl:mx-16 font-semibold rounded-2xl p-5 text-sm xl:text-base">
        <span
          v-for="(invoice, index) in invoices"
          :key="invoice"
          class="cursor-pointer"
        >
          <a
            :href="invoice.url"
            target="_Blank"
            class="flex items-center gap-4"
          >
            <img src="../../../assets/icons/file.svg" alt="" />
            Invoice-{{ index + 1 }}

            <span class="tooltip ml-auto">
              <img
                v-if="invoice.status === 1"
                src="../../../assets/icons/check-green.png"
                class=""
              />
              <img
                v-if="invoice.status === 0"
                src="../../../assets/icons/error-orange.png"
                class=""
              />
              <span class="tooltip-box">{{
                invoice.status === 1
                  ? "Factura aprobada"
                  : "Factura en revisión"
              }}</span></span
            >
          </a>
          <hr class="my-3" />
        </span>

        <label
          class="cursor-pointer flex items-center justify-center gap-4 text-blue-500"
        >
          <input
            type="file"
            class="hidden"
            @change="factura"
            ref="fileupload"
            accept="image/*,.pdf"
          />
          <img src="../../../assets/icons/plus-blue.svg" />
          Agregar otra factura comercial
        </label>
      </div>
    </article>
    <!-- Manifiesto de carga -->
    <article
      class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
    >
      <!-- CASO 1 -->
      <span v-if="statusReturn === false">
        <h3 class="text-text-blue font-semibold xl:text-lg mb-6 text-center">
          Manifiesto de carga
        </h3>
        <label
          class="xl:mx-16 cursor-pointer font-semibold text-white rounded-2xl bg-mainGreenLight py-5 text-sm xl:text-base flex items-center justify-center gap-4"
        >
          <img src="../../../assets/icons/subir-archivo.png" />
          <input
            type="file"
            class="hidden"
            @change="returnFile"
            ref="fileuploadReturn"
            accept="image/*,.pdf"
          />
          Adjuntar
        </label>
      </span>
      <!-- CASO 2 -->
      <div
        v-if="statusReturn"
        class="mb-6 xl:mx-16 font-semibold rounded-2xl bg-mainGreenLight text-mainGreenLight ring ring-mainGreenLight bg-opacity-20 py-5 text-sm xl:text-base flex items-center justify-center gap-4"
      >
        Manifiesto de carga
        <img src="../../../assets/icons/check-green.png" class="w-6" />
      </div>

      <div
        v-if="statusReturn"
        class="xl:mx-16 font-semibold rounded-2xl p-5 text-sm xl:text-base"
      >
        <span v-for="(data, index) in returnList" :key="data">
          <a :href="data.url" target="_Blank" class="flex items-center gap-4">
            <img src="../../../assets/icons/file.svg" alt="" />
            {{ data.type === 1 ? "Manifiesto" : "Evidencia " }} -
            {{ index + 1 }}

            <img
              v-if="data.status === 1"
              src="../../../assets/icons/check-green.png"
              class="ml-auto"
            />
            <img
              v-if="data.status === 0"
              src="../../../assets/icons/error-orange.png"
              class="ml-auto"
            />
          </a>
          <hr class="my-3" />
        </span>

        <label
          class="cursor-pointer flex items-center justify-center gap-4 text-blue-500"
        >
          <input
            type="file"
            class="hidden"
            @change="returnFile"
            ref="fileuploadReturn"
            accept="image/*,.pdf"
          />
          <img src="../../../assets/icons/plus-blue.svg" />
          Agregar otro manifiesto de compra
        </label>
      </div>
    </article>

    <article
      v-if="
        this.compra.attributes.direct_type === 2 &&
        this.compra.attributes.code_amazon
      "
      class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
    >
      <h3 class="text-text-blue font-semibold xl:text-lg mb-5">
        Te podría interesar
      </h3>
      <span
        v-if="productsRecommend && this.compra.attributes.direct_type === 2"
      >
        <div class="grid grid-cols-3 gap-8">
          <a
            v-for="data in productsRecommend"
            :key="data"
            @click.prevent="reloadViewProductRecomend(data.url)"
            class="cursor-pointer"
          >
            <img :src="data.image" class="w-full h-40 object-contain mb-3" />
            <img src="../../../assets/images/amazon.svg" class="w-16" />
            <p class="text-xs xl:text-sm font-medium line-clamp mb-3">
              {{ data.title }}
            </p>
            <p class="text-xs xl:text-sm font-bold">
              <span class="text-mainOrange">USD</span>
              {{ data.price }}
            </p>
          </a>
        </div>
      </span>
      <span v-else-if="this.compra.attributes.direct_type === 2">
        <div class="grid grid-cols-3 gap-8">
          <a href="#">
            <img
              src="../../../assets/icons/loader.svg"
              class="mx-auto animate-spin w-1/2 h-40 object-contain mb-3"
              style="animation-duration: 1.5s"
            />
            <p
              class="text-center text-xs xl:text-sm font-medium line-clamp mb-3"
            >
              Cargando
            </p>
          </a>
          <a href="#">
            <img
              src="../../../assets/icons/loader.svg"
              class="mx-auto animate-spin w-1/2 h-40 object-contain mb-3"
              style="animation-duration: 1.5s"
            />
            <p
              class="text-center text-xs xl:text-sm font-medium line-clamp mb-3"
            >
              Cargando
            </p>
          </a>
          <a href="#">
            <img
              src="../../../assets/icons/loader.svg"
              class="mx-auto animate-spin w-1/2 h-40 object-contain mb-3"
              style="animation-duration: 1.5s"
            />
            <p
              class="text-center text-xs xl:text-sm font-medium line-clamp mb-3"
            >
              Cargando
            </p>
          </a>
        </div>
      </span>
    </article>
  </section>
</template>
<script>
import { server } from "../../../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
export default {
  props: ["compra"],
  data() {
    return {
      statusReturn: this.compra.attributes.manifest.length === 0 ? false : true,
      imageReturn: "",
      returnList: this.compra.attributes.manifest,
      data: {
        id: this.compra.id,
        typeService: this.compra.attributes.direct_type,
        vaucher:
          this.compra.attributes.voucher_usa_link === null ? false : true,
      },
      imageVaucher: "",
      invoices:
        this.compra.attributes.vouchers_usa === null
          ? []
          : this.compra.attributes.vouchers_usa,
      productsRecommend: null,
    };
  },
  mounted() {
    if (this.compra.attributes.code_amazon !== null) {
      this.getRecommendProducts(this.compra.attributes.code_amazon);
    }
  },
  methods: {
    returnFile(e) {
      const file = e.target.files[0];
      this.cargarArchivoReturn(file);
    },
    cargarArchivoReturn(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imageReturn = e.target.result;
        this.sendImageReturn();
      };
      reader.readAsDataURL(file);
    },
    sendImageReturn() {
      axios
        .post(`${BASE_URL}` + "purchaseOrder/createmanifest/" + this.data.id, {
          from_admin: 0,
          manifest_archivo: this.imageReturn,
        })
        .then((response) => {
          this.returnList.push(response.data.body);
          this.imageReturn = "";
          this.statusReturn = true;
        })
        .catch((error) => {
          if (error) {
            this.imageReturn = "";
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al enviar archivo";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    reloadViewProductRecomend(codeProduct) {
      this.$router.push({ name: "Shooper", params: { url: codeProduct } });
    },
    //metodo para traer productos recomendados segun el codigo de la orden
    getRecommendProducts(codeAmazon) {
      axios
        .get(`${BASE_URL}` + "product/getMalltinaProductRelation/" + codeAmazon)
        .then((response) => {
          this.productsRecommend = response.data.body ?? null;
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al traer productos relacionados";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    imprimir() {
      window.print();
    },
    copyCode() {
      const trackingCode = document.getElementById("tracking-code").textContent;
      navigator.clipboard
        .writeText(trackingCode)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description =
            "El número de tracking se copio con éxito";
          this.$store.state.alert.estado = true;
          //--------------------------//
        })
        .catch((error) => console.log(error));
    },
    factura(e) {
      const file = e.target.files[0];
      this.cargarImagen(file);
    },
    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imageVaucher = e.target.result;
        this.sendVaucher();
      };
      reader.readAsDataURL(file);
    },
    sendVaucher() {
      axios
        .put(
          `${BASE_URL}` + "purchaseOrder/update-order-no-grabr/" + this.data.id,
          {
            voucher_usa: this.imageVaucher,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.$refs.fileupload.value = null;
            this.imageVaucher = "";
            this.data.vaucher = true;

            this.invoices.push(response.data.body.attributes.vouchers_usa);

            //----------component--------//
            this.$store.state.alert.titulo = "Éxito";
            this.$store.state.alert.description = "Factura comercial enviado";
            this.$store.state.alert.estado = true;
            //--------------------------//

            // location.reload();
            // this.closeModal();
            // this.exito = true;
          }
        })
        .catch((error) => {
          if (error) {
            this.imageVaucher = "";
            this.$refs.fileupload.value = null;
            // this.closeModal();
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al subir su factura comercial ";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
  },
};
</script>
