<template>
  <footer class="bg-bg-black-blue text-white">
    <div
      class="u-container grid grid-cols-2 gap-x-4 gap-y-12 xl:gap-0 xl:grid-cols-4 items-start"
    >
      <div class="col-span-2 xl:col-span-1 order-2 xl:order-1">
        <img class="hidden xl:block mb-10" src="../../assets/LOGO-white.png" />
        <h2 class="text-xl font-bold xl:hidden mb-8">SENDIBOX</h2>
        <ul class="mb-6 xl:mb-12">
          <li class="flex gap-4 items-center font-medium mb-6">
            <img src="../../assets/icons/icon-market-white.svg" />
            <span>Jr. Emilio Althaus Nro. 121 Ofic. 403</span>
          </li>
          <li class="flex gap-4 items-center font-medium mb-6">
            <img src="../../assets/icons/icon-mail-white.svg" />
            <span>hola@sendi.com.pe</span>
          </li>
          <li class="flex gap-4 items-center font-medium">
            <img src="../../assets/icons/icon-phone-white.svg" />
            <span> (+51) 968 727 182</span>
          </li>
        </ul>
        <div class="hidden xl:flex gap-6 mb-6">
          <a href="https://www.instagram.com/sendi.envios/" target="Blank">
            <img src="../../assets/icons/icon-instagram.svg"
          /></a>

          <a href="https://www.facebook.com/sendi.envios" target="Blank"
            ><img src="../../assets/icons/icon-facebook.svg"
          /></a>
        </div>
        <small class="block text-gray-400 mb-10 xl:mb-0"
          >© Sendibox Perú S.A.C.</small
        >
        <div class="flex gap-6 items-center justify-start xl:hidden">
          <a href="https://www.instagram.com/sendi.envios/" target="Blank">
            <img src="../../assets/icons/icon-instagram-white.svg"
          /></a>
          <a href="https://www.facebook.com/sendi.envios" target="Blank">
            <img src="../../assets/icons/icon-facebook-white.svg"
          /></a>
        </div>
      </div>
      <div class="grid xl:order-2 text-sm xl:text-base">
        <a href="#" class="text-base xl:text-lg font-semibold mb-8">EMPRESA</a>
        <a href="/" class="mb-8">Acerca de Nosotros</a>

        <a
          href="https://sendi.freshdesk.com/support/solutions/articles/73000534539-t%C3%A9rminos-y-condiciones"
          target="Blank"
          class="mb-8"
          >Aviso Legal</a
        >

        <a
          href="https://sendi.freshdesk.com/support/solutions/articles/73000534434-pol%C3%ADtica-de-privacidad"
          target="Blank"
          class="mb-8"
        >
          Privacidad</a
        >

        <a href="/" class="mb-8">Seguridad </a>
        <a href="/" class="mb-8"> Desarrolladores</a>

        <a @click.prevent="seeMore" class="text-mainBlue cursor-pointer">{{
          moreDetail ? "- Ver menos" : "+ Ver mas"
        }}</a>
      </div>
      <div class="grid xl:order-3 text-sm xl:text-base">
        <a href="#" class="text-base xl:text-lg font-semibold mb-8">CLIENTES</a>

        <a href="/" class="mb-8">Servicios </a>

        <a
          href="https://sendi.freshdesk.com/support/solutions/folders/73000349133"
          target="Blank"
          class="mb-8"
          >Preguntas Frecuentes</a
        >

        <a
          href="https://sendi.freshdesk.com/support/home"
          target="Blank"
          class="mb-8"
          >Base de Conocimiento</a
        >

        <a
          href="https://sendi.freshdesk.com/support/solutions/articles/73000535161-mercader%C3%ADa-prohibida"
          target="Blank"
          class="mb-8"
          >Productos Prohibidos</a
        >

        <router-link
          :to="{ name: 'Tiendas' }"
          target="_blank"
          class="mb-8"
          :class="moreDetail ? '' : 'hidden'"
        >
          Guía de Tiendas
        </router-link>

        <a href="/" class="mb-8" :class="moreDetail ? '' : 'hidden'"
          >Libro de Reclamaciones</a
        >

        <a href="/" class="mb-8" :class="moreDetail ? '' : 'hidden'"
          >Atención al Cliente</a
        >
      </div>
      <div class="hidden md:block md:order-4">
        <div class="flex gap-8 items-center mb-2">
          <img class="w-12" src="../../assets/icons/icon-visa.svg" alt="VISA" />
          <img
            class="w-12"
            src="../../assets/icons/icon-master-card.svg"
            alt="Master card"
          />
          <img
            class="w-12"
            src="../../assets/icons/icon-american-express.svg"
            alt="American express"
          />
        </div>
        <div class="flex gap-8 items-center mb-6">
          <img
            class="w-12"
            src="../../assets/icons/icon-pago-efectivo.svg"
            alt="Pago efectivo"
          />
          <img
            class="w-12"
            src="../../assets/icons/icon-dinners-club.svg"
            alt="Dinners club"
          />
          <img
            class="w-12"
            src="../../assets/icons/icon-paypal.svg"
            alt="Paypal"
          />
        </div>
        <small class="text-sm mb-10 block">
          + Más tarjetas locales y tarjetas de pago
        </small>
        <div class="grid grid-cols-2 gap-4">
          <router-link :to="{ name: 'Complaints' }">
            <img
              src="../../assets/icons/libro_reclamaciones_white.png"
              alt=""
            />
          </router-link>
          <a href="https://aws.amazon.com/what-is-cloud-computing"
            ><img
              src="https://d0.awsstatic.com/logos/powered-by-aws-white.png"
              alt="Powered by AWS Cloud Computing"
          /></a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      moreDetail: false,
    };
  },
  methods: {
    seeMore() {
      this.moreDetail = !this.moreDetail;
    },
  },
};
</script>
