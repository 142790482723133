<template>
  <alert-component />
  <cellphone-component />
  <router-view />
</template>

<script>
import alertComponent from "./components/Alert/alertComponent.vue";
import cellphoneComponent from "./components/global/autentificacion/cellphoneComponent.vue";
export default {
  components: { alertComponent, cellphoneComponent },
  data() {
    return {};
  },
  methods: {
    setStorage() {
      if (this.nameRoute !== "Courier" || this.nameRoute !== "Shooper") {
        this.$store.state.cuponStatu = null;
        this.$store.state.cuponCode = "";
        this.$store.state.cuponMonto = null;
        this.$store.state.cuponMontoSoles = null;
        this.$store.state.saldoStatu = false;
        this.$store.state.saldoMonto = 0.0;
        this.$store.state.saldoMontoSoles = 0.0;
        this.$store.state.saldoTotalStatu = false;
        this.$store.commit("resetServicios");
      }
    },
  },
  computed: {
    nameRoute() {
      return this.$route.name;
    },
  },
  watch: {
    nameRoute: function () {
      this.setStorage();
    },
  },
};
</script>
