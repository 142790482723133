<template>
  <div
    :class="dni === false ? '' : 'oculto'"
    class="fixed h-screen inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center transition-opacity delay-400"
  >
    <div
      class="mx-2 bg-white px-6 py-10 grid items-center justify-center w-full xl:w-auto xl:py-24 xl:px-32 rounded-2xl relative"
    >
      <h2 class="text-4xl text-center font-semibold mb-5 text-bg-black">
        Verifica tu cuenta
      </h2>
      <p class="mb-14 text-center">
        Para acceder a los <br class="xl:hidden" />
        <b>beneficios de cupones</b> <br class="hidden xl:block" />
        debes verificar tu identidad.
      </p>
      <img
        class="mx-auto mb-10"
        src="../../assets/images/verificar-cuenta-icon.png"
        alt="Verifica tu cuenta"
      />
      <router-link
        :to="{ name: 'Perfil', params: { vista: 'credenciales' } }"
        class="py-5 px-8 text-center text-white bg-mainGreenLight rounded-2xl font-semibold text-lg"
        >Verificar identidad</router-link
      >
      <router-link :to="{ name: 'Perfil', params: { vista: 'credenciales' } }">
        <img
          class="absolute -right-8 -top-6 cursor-pointer"
          src="../../assets/icons/cerrar-box.png"
          alt="Cerrar"
        />
      </router-link>
    </div>
  </div>

  <article class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
    <h3 class="text-xl xl:text-3xl font-bold text-bg-black mb-10">Cupones</h3>
    <p class="text-sm xl:text-base mb-16">
      Utiliza estos cupones en tus compras para <br />
      obtener descuentos y más.
    </p>
    <hr class="mb-6" />
    <div class="grid xl:grid-cols-2 gap-6">
      <span v-for="cupon in dataCupon" :key="cupon">
        <figure class="p-4 rounded-2xl border-2 border-mainRed border-dashed">
          <img
            @click.prevent="copyCupon(cupon.attributes.code)"
            :src="cupon.attributes.img"
            alt="Cupon..."
            class="w-full"
          />
        </figure>
      </span>
    </div>
    <!-- Pagination -->
    <div class="mt-12 flex justify-end items-center gap-12">
      <p>Pág. <span class="mx-3 py-1 px-3 ring ring-blue-300">1</span> de 1</p>
      <div class="flex items-center gap-4">
        <figure class="cursor-pointer p-3">
          <img src="../../assets/icons/chevron-left-orange.png" />
        </figure>
        <figure class="cursor-pointer p-3">
          <img src="../../assets/icons/chevron-right-orange.png" />
        </figure>
      </div>
    </div>
  </article>
</template>
<script>
//Importando serve
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
//Importando librerias
import axios from "axios";
export default {
  props: ["dni"],
  data() {
    return {
      dataCupon: {},
    };
  },
  mounted() {
    this.getCupon();
  },
  methods: {
    copyCupon(cupon) {
      navigator.clipboard
        .writeText(cupon)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description = "Cupón copiado";
          this.$store.state.alert.estado = true;
          //--------------------------//
        })
        .catch((error) => console.log(error));
    },
    getCupon() {
      axios
        .get(`${BASE_URL}` + "coupon/getList/1")
        .then((response) => {
          if (response) {
            this.dataCupon = response.data.body.coupon_list;
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al obtener los cupones";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
  },
};
</script>
