<template>
  <template v-if="!openDirection">
    <article class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
      <h3 class="text-xl xl:text-3xl font-bold text-main-red mb-10">
        Mis direcciones
      </h3>
      <h4 class="text-text-blue xl:text-xl font-bold mb-3">
        Direcciones en USA
      </h4>
      <p class="text-sm xl:text-base mb-10">
        Puedes usar esta dirección de nuestro almacén <br />
        para tus compras en Estados Unidos
      </p>
      <section class="">
        <div class="grid xl:grid-cols-2 gap-5 mb-14">
          <article
            class="flex items-start gap-4 xl:gap-6 py-5 px-4 rounded-lg border shadow-md"
          >
            <img
              src="../../assets/icons/bandera_usa.svg"
              class="rounded-full object-cover w-12 h-12 shadow-lg"
            />
            <div class="w-full">
              <h3
                class="flex items-center justify-between font-semibold text-sm xl:text-lg mb-5"
              >
                <span>Mi dirección en USA</span>
              </h3>
              <div class="mb-10 grid xl:grid-cols-2 gap-3">
                <p class="text-xs xl:text-sm">
                  <span class="block font-semibold mb-1">Nombres:</span>
                  <span id="usa-nombre">
                    {{ dataUser.name.toUpperCase() }}
                    {{ dataUser.lastname.toUpperCase() }}SD{{
                      dataUser.identificador.toUpperCase()
                    }}</span
                  >
                </p>
                <p class="text-xs xl:text-sm">
                  <span class="block font-semibold mb-1">Dirección:</span>
                  <span id="usa-direction">
                    {{ direction.direccion }}
                  </span>
                </p>
                <p class="text-xs xl:text-sm">
                  <span class="block font-semibold mb-1">Ciudad:</span>
                  <span id="usa-ciudad">
                    {{ direction.ciudad }}
                  </span>
                </p>
                <p class="text-xs xl:text-sm">
                  <span class="block font-semibold mb-1">Estado:</span>
                  <span id="usa-estado">
                    {{ direction.estado }}
                  </span>
                </p>
                <p class="text-xs xl:text-sm">
                  <span class="block font-semibold mb-1">Código Postal:</span>
                  <span id="usa-codigo">
                    {{ direction.codigoPostal }}
                  </span>
                </p>
                <p class="text-xs xl:text-sm">
                  <span class="block font-semibold mb-1">País:</span>
                  <span id="usa-pais">
                    {{ direction.pais }}
                  </span>
                </p>
                <p class="text-xs xl:text-sm">
                  <span class="block font-semibold mb-1">Teléfono:</span>
                  <span id="usa-telefono">
                    {{ direction.telefono }}
                  </span>
                </p>
              </div>
              <div class="flex gap-6 items-center">
                <!-- <button
                type="button"
                class="font-semibold text-xs xl:text-sm text-green-500"
              >
                Agregar alias
              </button> -->
                <button
                  type="button"
                  @click.prevent="copyDirection"
                  class="font-semibold text-xs xl:text-sm text-blue-500"
                >
                  Copiar
                </button>
              </div>
            </div>
          </article>
          <img
            src="../../assets/images/banner1.jpg"
            class="rounded-md xl:h-96 w-full object-cover"
          />
        </div>
        <h4 class="text-text-blue xl:text-xl font-bold mb-6 xl:mb-10">
          Direcciones de envio
        </h4>
        <div class="grid xl:grid-cols-2 gap-5">
          <span v-for="(data, index) in shipping" :key="data">
            <article
              class="flex items-start gap-4 xl:gap-6 py-5 px-4 rounded-lg border shadow-md"
            >
              <img
                src="../../assets/icons/bandera_peru.svg"
                class="rounded-full object-cover w-10 h-10 shadow-lg"
              />
              <div class="w-full">
                <h3
                  class="flex items-start xl:items-center justify-between gap-4 font-semibold text-sm xl:text-lg mb-5"
                >
                  <span
                    class="flex flex-col xl:flex-row gap-1 xl:gap-3 xl:items-center"
                  >
                    {{ data.attributes.alias }}
                    <span
                      v-if="data.attributes.default === 'current'"
                      class="px-3 py-1 rounded-full text-xs bg-blue-100 w-max text-blue-500 font-semibold"
                      >Activa</span
                    >
                  </span>
                  <button
                    type="button"
                    class="relative"
                    @click="showOptions('tooltip-' + index)"
                  >
                    <img
                      src="../../assets/icons/icon-tres-puntos.svg"
                      class="transform rotate-90"
                    />
                    <span
                      @mouseleave="mouserLeaveOption('tooltip-' + index)"
                      :id="'tooltip-' + index"
                      class="oculto optionsAddres absolute bg-white border shadow-lg py-2 top-4 -left-40 rounded-xl text-left transition-opacity"
                    >
                      <a
                        type="button"
                        @click.prevent="updatCurrentAddress(data.id)"
                        class="flex items-center gap-2 text-xs font-semibold py-3 px-4 transition-colors hover:bg-mainBlue hover:bg-opacity-20 w-max"
                        >Marcar por defecto</a
                      >
                    </span>
                  </button>
                </h3>
                <div class="mb-10 pr-6 grid xl:grid-cols-2 gap-3">
                  <p class="text-xs xl:text-sm">
                    <span class="block font-semibold mb-1">Nombres:</span>
                    <span :id="'usa-nombre' + data.id">
                      {{ dataUser.name.toUpperCase() }}
                      {{ dataUser.lastname.toUpperCase() }}SD{{
                        dataUser.identificador.toUpperCase()
                      }}
                    </span>
                  </p>
                  <p class="text-xs xl:text-sm">
                    <span class="block font-semibold mb-1">Dirección:</span>
                    <span :id="'usa-direction' + data.id">
                      {{ data.attributes.address }}
                    </span>
                  </p>
                  <p class="text-xs xl:text-sm">
                    <span class="block font-semibold mb-1">Ciudad:</span>
                    <span :id="'usa-ciudad' + data.id">
                      {{ data.attributes.city }}
                    </span>
                  </p>
                  <p class="text-xs xl:text-sm">
                    <span class="block font-semibold mb-1">Departamento:</span>
                    <span :id="'usa-departamento' + data.id">
                      {{ data.attributes.department }}
                    </span>
                  </p>
                  <p class="text-xs xl:text-sm">
                    <span class="block font-semibold mb-1">Distrito:</span>
                    <span :id="'usa-distrito' + data.id">
                      {{ data.attributes.district }}
                    </span>
                  </p>
                  <p class="text-xs xl:text-sm">
                    <span class="block font-semibold mb-1">Referencia:</span>
                    <span :id="'usa-referencia' + data.id">
                      {{ data.attributes.reference }}
                    </span>
                  </p>
                  <p class="text-xs xl:text-sm">
                    <span class="block font-semibold mb-1">Piso:</span>
                    <span :id="'usa-piso' + data.id">
                      {{ data.attributes.inner }}
                    </span>
                  </p>
                </div>
                <div class="flex gap-6 items-center">
                  <button
                    type="button"
                    class="font-semibold text-xs xl:text-sm text-green-500"
                    @click.prevent="updateDirection(data)"
                  >
                    Editar
                  </button>
                  <button
                    type="button"
                    @click.prevent="copyDirectionUserShipping(data.id)"
                    class="font-semibold text-xs xl:text-sm text-blue-500"
                  >
                    Copiar
                  </button>
                  <button
                    v-if="data.attributes.default !== 'current'"
                    type="button"
                    @click.prevent="deleteDirection(data.id)"
                    class="font-semibold text-xs xl:text-sm text-red-500"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </article>
          </span>
        </div>
        <!-- Sino tiene direcciones -->
        <p
          class="font-medium text-center pb-8 pt-14"
          v-if="shipping?.length === 0"
        >
          Sin direcciones de envio disponibles
        </p>
        <!-- Pagination -->
        <div class="mt-12 flex justify-end items-center gap-12">
          <p>
            Pág.
            <span class="mx-3 py-1 px-3 ring ring-blue-300">{{
              dataTable.from
            }}</span>
            de
            {{ dataTable.page }}
          </p>
          <div class="flex items-center gap-4">
            <figure
              v-if="dataTable.pastStatus"
              @click.prevent="shippingAddress(0)"
              class="cursor-pointer p-3"
            >
              <img src="../../assets/icons/chevron-left-orange.png" />
            </figure>
            <figure
              v-if="dataTable.nextStatus"
              @click.prevent="shippingAddress(1)"
              class="cursor-pointer p-3"
            >
              <img src="../../assets/icons/chevron-right-orange.png" />
            </figure>
          </div>
        </div>
      </section>
    </article>
  </template>

  <template v-else>
    <article class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
      <h4
        class="flex items-center gap-4 text-text-blue xl:text-xl font-bold mb-3"
      >
        <img
          @click.prevent="resetDataModal()"
          src="../../assets/icons/icono-volver.png"
          class="cursor-pointer"
        />
        Editar dirección
      </h4>
      <p class="text-sm xl:text-base mb-10">
        Puedes editar cualquiera de tus direcciones de envio <br />
        en el momento en que lo necesites.
      </p>
      <div>
        <div class="grid grid-cols-2 gap-3">
          <div class="mb-5">
            <label
              for="nombre"
              class="block mb-2 font-semibold text-xs xl:text-sm"
              >Nombre</label
            >
            <input
              id="nombre"
              class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
              type="text"
              placeholder="Nombre"
              v-model="update.nombre"
            />
            <span
              class="text-main-red text-xs pt-1 pl-7 font-medium"
              v-if="error.name"
              >*Ingrese su nombre</span
            >
          </div>
          <div class="mb-5">
            <label
              for="apellidos"
              class="block mb-2 font-semibold text-xs xl:text-sm"
              >Apellidos</label
            >
            <input
              id="apellidos"
              class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
              type="text"
              placeholder="Apellidos"
              v-model="update.apellido"
            />
            <span
              class="text-main-red text-xs pt-1 pl-7 font-medium"
              v-if="error.apellido"
              >*Ingrese su apellido</span
            >
          </div>
        </div>
        <div class="grid grid-cols-2 gap-3">
          <div class="mb-5">
            <label
              for="documento"
              class="block mb-2 font-semibold text-xs xl:text-sm"
              >Documento</label
            >
            <input
              id="documento"
              class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
              type="tel"
              placeholder="Documento"
              v-model="update.documento"
            />
            <span
              class="text-main-red text-xs pt-1 pl-7 font-medium"
              v-if="error.documento"
              >*Ingrese su documento</span
            >
          </div>
          <div class="mb-5">
            <label
              for="telefono"
              class="block mb-2 font-semibold text-xs xl:text-sm"
              >Teléfono</label
            >
            <input
              id="telefono"
              class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
              type="number"
              placeholder="Teléfono"
              v-model="update.telefono"
            />
            <span
              class="text-main-red text-xs pt-1 pl-7 font-medium"
              v-if="error.telefono"
              >*Ingrese el teléfono</span
            >
          </div>
        </div>
        <div class="grid xl:grid-cols-3 gap-3 items-start relative">
          <!-- Direccion -->
          <div class="mb-5 relative" v-if="update.state === false">
            <label
              for="direccion"
              class="block mb-2 font-semibold text-xs xl:text-sm"
              >Dirección</label
            >
            <input
              id="direccion"
              class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
              type="text"
              placeholder="Dirección"
              v-model="update.direccion"
              @keypress="stateGmapAUtocomplete"
            />
            <div class="absolute right-3 bottom-5">
              <img
                src="../../assets/icons/icon-global.svg"
                class="cursor-pointer"
                @click.prevent="directionMap"
                alt="Icono de Envios USA"
              />
            </div>
            <span
              class="text-main-red text-xs pt-1 pl-7 font-medium"
              v-if="error.direction || error.lat || error.lng"
            >
              *Ingrese y seleccione su dirección
            </span>
          </div>
          <div class="mb-5 relative" v-if="update.state === true">
            <label
              for="direccion"
              class="block mb-2 font-semibold text-xs xl:text-sm"
              >Dirección</label
            >
            <GMapAutocomplete
              placeholder="Ingrese la dirección"
              @place_changed="setPlace"
              class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            >
            </GMapAutocomplete>

            <div class="absolute right-3 bottom-5">
              <img
                src="../../assets/icons/icon-global.svg"
                class="cursor-pointer"
                @click.prevent="directionMap"
                alt="Icono de Envios USA"
              />
            </div>
            <span
              class="text-main-red text-xs pt-1 pl-7 font-medium"
              v-if="error.direction || error.lat || error.lng"
            >
              *Ingrese y seleccione su dirección
            </span>
          </div>
          <div class="absolute top-24 left-0 w-full xl:w-1/3" v-if="statusMap">
            <figure
              style="height: 250px"
              class="border shadow-lg xl:mr-3 mt-2 rounded-md"
            >
              <GMapMap
                :center="center"
                :zoom="15"
                map-type-id="terrain"
                class="h-full"
              >
                <GMapCluster>
                  <GMapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                  />
                </GMapCluster>
              </GMapMap>
            </figure>
          </div>

          <!-- Referencia -->
          <div class="mb-5">
            <label
              for="referencia"
              class="block mb-2 font-semibold text-xs xl:text-sm"
              >Referencia</label
            >
            <input
              id="referencia"
              class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
              type="text"
              placeholder="Referencia"
              v-model="update.referencia"
            />
            <span
              class="text-main-red text-xs pt-1 pl-7 font-medium"
              v-if="error.referencia"
              >*Ingrese la referencia</span
            >
          </div>
          <!-- Piso u oficina -->
          <div class="mb-5">
            <label
              for="referencia"
              class="block mb-2 font-semibold text-xs xl:text-sm"
              ># Piso u Oficina</label
            >
            <input
              id="referencia"
              class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
              type="text"
              placeholder="# Piso u Oficina"
              v-model="update.piso"
            />
            <span
              class="text-main-red text-xs pt-1 pl-7 font-medium"
              v-if="error.piso"
              >*Ingrese el piso u oficina</span
            >
          </div>
        </div>

        <div class="grid xl:grid-cols-3 gap-3 mb-6">
          <div class="mb-5">
            <label
              for="referencia"
              class="block mb-2 font-semibold text-xs xl:text-sm"
              >Provincia</label
            >
            <input
              id="referencia"
              class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
              type="text"
              placeholder="Provincia"
              v-model="update.provincia"
            />
            <span
              class="text-main-red text-xs pt-1 pl-7 font-medium"
              v-if="error.provincia"
              >*Ingrese la provincia</span
            >
          </div>
          <div class="mb-5">
            <label
              for="referencia"
              class="block mb-2 font-semibold text-xs xl:text-sm"
              >Departamento</label
            >
            <input
              id="referencia"
              class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
              type="text"
              placeholder="Departamento"
              v-model="update.departamento"
            />
            <span
              class="text-main-red text-xs pt-1 pl-7 font-medium"
              v-if="error.departamento"
              >*Ingrese el departamento</span
            >
          </div>
          <div class="mb-5">
            <label
              for="referencia"
              class="block mb-2 font-semibold text-xs xl:text-sm"
              >Distrito</label
            >
            <input
              id="referencia"
              class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
              type="text"
              placeholder="Distrito"
              v-model="update.distrito"
            />
            <span
              class="text-main-red text-xs pt-1 pl-7 font-medium"
              v-if="error.distrito"
              >*Ingrese el distrito</span
            >
          </div>
        </div>
        <div class="xl:w-8/12 xl:mx-auto grid xl:grid-cols-2 gap-3">
          <button
            v-if="formValidateButton"
            @click.prevent="updateApiDirection"
            class="bg-mainGreenLight block w-full text-center p-5 font-semibold rounded-2xl text-white cursor-pointer"
          >
            Guardar
          </button>
          <button
            v-else
            class="border border-mainGreenLight block w-full text-center p-5 font-semibold rounded-2xl text-mainGreenLight cursor-not-allowed"
          >
            Guardar
          </button>

          <button
            @click="resetDataModal()"
            class="border border-main-red block w-full text-center p-5 font-semibold rounded-2xl text-main-red transition-colors hover:bg-main-red hover:text-white"
            type="button"
          >
            Cancelar
          </button>
        </div>
      </div>
    </article>
  </template>
</template>
<script>
import { directionUsa } from "../../config/global";
//Importando serve
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
//Importando librerias
import axios from "axios";
export default {
  props: ["dataUser"],
  data() {
    return {
      center: { lat: -12.080730407219086, lng: -77.03606970955586 },
      markers: [
        {
          position: {
            lat: -12.080730407219086,
            lng: -77.03606970955586,
          },
        }, // Along list of clusters
      ],
      direction: directionUsa,
      shipping: null,
      error: {
        name: false,
        apellido: false,
        piso: false,
        referencia: false,
        departamento: false,
        provincia: false,
        distrito: false,
        documento: false,
        telefono: false,
        direction: false,
        lat: false,
        lng: false,
      },
      update: {
        state: false,
        id: null,
        nombre: null,
        apellido: null,
        documento: null,
        telefono: null,
        direccion: null,
        referencia: null,
        piso: null,
        provincia: null,
        departamento: null,
        distrito: null,
        lat: null,
        lng: null,
      },
      pageNext: 1,
      pagePast: 1,
      dataTable: {
        pagination: 2,
        page: 0,
        from: 0,
        next: "",
        past: "",
        nextStatus: false,
        pastStatus: false,
      },
      openDirection: false,
      statusMap: false,
    };
  },
  created() {
    this.shippingAddress(1);
  },
  methods: {
    mouserLeaveOption(idTooltip) {
      const tooltip = document.getElementById(idTooltip);
      if (!tooltip.classList.contains("oculto")) {
        tooltip.classList.add("oculto");
      }
    },
    directionMap() {
      this.statusMap = !this.statusMap;
    },
    stateGmapAUtocomplete() {
      this.update.state = true;
      this.update.direccion = "";
    },
    updateDirection(data) {
      window.scrollTo(0, 0);
      this.openDirection = true;
      this.update.id = data.id;
      this.update.nombre = data.attributes.owner_name ?? "";
      this.update.apellido = data.attributes.owner_lastname ?? "";
      this.update.documento = data.attributes.owner_document_number ?? "";
      this.update.telefono = data.attributes.telephone_number ?? "";
      this.update.direccion = data.attributes.address ?? "";
      this.update.referencia = data.attributes.reference ?? "";
      this.update.piso = data.attributes.inner ?? "";
      this.update.provincia = data.attributes.city ?? "";
      this.update.departamento = data.attributes.department ?? "";
      this.update.distrito = data.attributes.district ?? "";
      this.update.lat = data.attributes.lat;
      this.update.lng = data.attributes.lng;

      this.center.lat = data.attributes.lat;
      this.center.lng = data.attributes.lng;
      this.markers[0].position.lat = data.attributes.lat;
      this.markers[0].position.lng = data.attributes.lng;
    },

    resetDataModal() {
      this.update.state = false;
      this.openDirection = false;
      this.update.id = null;
      this.update.nombre = null;
      this.update.apellido = null;
      this.update.documento = null;
      this.update.telefono = null;
      this.update.direccion = null;
      this.update.referencia = null;
      this.update.piso = null;
      this.update.provincia = null;
      this.update.departamento = null;
      this.update.distrito = null;
      this.update.lat = null;
      this.update.lng = null;

      this.error.name = false;
      this.error.apellido = false;
      this.error.piso = false;
      this.error.referencia = false;
      this.error.departamento = false;
      this.error.provincia = false;
      this.error.distrito = false;
      this.error.documento = false;
      this.error.telefono = false;
      this.error.direction = false;
      this.error.lat = false;
      this.error.lng = false;
    },
    setPlace(place) {
      this.update.direccion = place.name;
      this.update.lat = place.geometry.location.lat();
      this.update.lng = place.geometry.location.lng();
      //---------------------------------//

      //Obtener distrito-departamento-provincia
      var addressComponents = place.address_components;
      var departamento = "";
      var provincia = "";
      var distrito = "";
      var country = "";

      addressComponents.forEach((value) => {
        if (value.types[0] === "administrative_area_level_1") {
          departamento = value.long_name;
        }
        if (value.types[0] === "administrative_area_level_2") {
          provincia = value.long_name;
        }
        if (value.types[0] === "locality") {
          distrito = value.long_name;
        }
        if (value.types[0] === "country") {
          country = value.short_name;
        } //Determinamos si la direcciòn pertenece a Lima
        if (
          value.long_name === "Gobierno Regional de Lima" ||
          value.long_name === "Provincia de Lima"
        ) {
          departamento = value.long_name;
          provincia = value.long_name;
          distrito = value.long_name;
          // location = 1;
        }
      });
      if (country !== "PE" || country === "") {
        this.update.direccion = "";
        this.update.lat = 0;
        this.update.lng = 0;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Solo direcciones de Perú";
        this.$store.state.alert.estado = true;
        //--------------------------//
        this.error.direction = true;
      } else {
        this.error.direction = false;
      }
      this.update.departamento = departamento;
      this.update.provincia = provincia;
      this.update.distrito = distrito;

      this.center.lat = place.geometry.location.lat();
      this.center.lng = place.geometry.location.lng();
      this.markers[0].position.lat = place.geometry.location.lat();
      this.markers[0].position.lng = place.geometry.location.lng();
    },
    formValidate() {
      this.error.name = this.update.nombre.length !== 0 ? false : true;
      this.error.apellido = this.update.apellido.length !== 0 ? false : true;
      this.error.piso = this.update.piso.length !== 0 ? false : true;
      this.error.referencia =
        this.update.referencia.length !== 0 ? false : true;
      this.error.departamento =
        this.update.departamento.length !== 0 ? false : true;
      this.error.provincia = this.update.provincia.length !== 0 ? false : true;
      this.error.distrito = this.update.distrito.length !== 0 ? false : true;
      this.error.direction = this.update.direccion.length !== 0 ? false : true;
      this.error.lat = this.update.lat !== 0 ? false : true;
      this.error.lng = this.update.lng !== 0 ? false : true;
      this.error.documento = this.update.documento.length !== 0 ? false : true;
      this.error.telefono = this.update.telefono.length !== 0 ? false : true;

      if (
        this.error.name ||
        this.error.apellido ||
        this.error.piso ||
        this.error.referencia ||
        this.error.departamento ||
        this.error.provincia ||
        this.error.distrito ||
        this.error.direction ||
        this.error.lat ||
        this.error.lng ||
        this.error.documento ||
        this.error.telefono
      ) {
        return false;
      } else {
        return true;
      }
    },
    updateApiDirection() {
      const result = this.formValidate();
      if (result) {
        axios
          .post(`${BASE_URL}` + "address/" + this.update.id + "/update", {
            address: this.update.direccion,
            lng: this.update.lng,
            lat: this.update.lat,
            owner_lastname: this.update.apellido,
            owner_name: this.update.nombre,
            owner_document_number: this.update.documento,
            district: this.update.distrito,
            city: this.update.provincia,
            department: this.update.departamento,
            inner: this.update.piso,
            telephone_number: this.update.telefono,
            reference: this.update.referencia,
          })
          .then(() => {
            this.$store.state.alert.titulo = "Éxito";
            this.$store.state.alert.description = "Dirección Actualizada";
            this.$store.state.alert.estado = true;
            this.updatCurrentAddress(this.update.id);
            // location.reload();
          })
          .catch((error) => {
            if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al actualizar su dirección";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
    },
    deleteDirection(id) {
      axios
        .delete(`${BASE_URL}` + "address/" + id + "/delete")
        .then((response) => {
          if (response) {
            location.reload();
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al eliminar la dirección";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    copyDirection() {
      const nombre = document.getElementById("usa-nombre").textContent;
      const direccion = document.getElementById("usa-direction").textContent;
      const ciudad = document.getElementById("usa-ciudad").textContent;
      const estado = document.getElementById("usa-estado").textContent;
      const codigo = document.getElementById("usa-codigo").textContent;
      const pais = document.getElementById("usa-pais").textContent;
      const telefono = document.getElementById("usa-telefono").textContent;
      const datos = `${nombre}\n${direccion}\n${ciudad}\n${estado}\n${codigo}\n${pais}\n${telefono} `;
      navigator.clipboard
        .writeText(datos)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description =
            "Se copio los datos de su dirección en USA";
          this.$store.state.alert.estado = true;
          //--------------------------//
        })
        .catch((error) => console.log(error));
    },
    copyDirectionUserShipping(id) {
      const nombre = document.getElementById("usa-nombre" + id).textContent;
      const direccion = document.getElementById(
        "usa-direction" + id
      ).textContent;
      const ciudad = document.getElementById("usa-ciudad" + id).textContent;
      const departamento = document.getElementById(
        "usa-departamento" + id
      ).textContent;
      const distrito = document.getElementById("usa-distrito" + id).textContent;
      const referencia = document.getElementById(
        "usa-referencia" + id
      ).textContent;
      const piso = document.getElementById("usa-piso" + id).textContent;
      const datos = `${nombre}\n${direccion}\n${ciudad}\n${departamento}\n${distrito}\n${referencia}\n${piso} `;
      navigator.clipboard
        .writeText(datos)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description =
            "Se copio los datos de su dirección en USA";
          this.$store.state.alert.estado = true;
          //--------------------------//
        })
        .catch((error) => console.log(error));
    },
    shippingAddress(value) {
      var url = "";
      if (value === 1) {
        url =
          `${BASE_URL}` +
          "client/" +
          this.dataUser.id +
          "/listShippingAddresses" +
          `?length=${this.dataTable.pagination}&page=${this.pageNext}`;
      }
      if (value === 0) {
        url =
          `${BASE_URL}` +
          "client/" +
          this.dataUser.id +
          "/listShippingAddresses" +
          `?length=${this.dataTable.pagination}&page=${this.pagePast}`;
      }

      axios
        .get(url)
        .then((response) => {
          if (response) {
            this.shipping = response.data.data ?? null;

            this.dataTable.page = response.data.meta.last_page;
            this.dataTable.from = response.data.meta.current_page;
            this.dataTable.next = response.data.links.next;
            this.dataTable.past = response.data.links.prev;
            if (this.dataTable.next !== null) {
              let separator = this.dataTable.next.split("?page=");
              this.pageNext = separator[1];
              this.dataTable.nextStatus = true;
            } else {
              this.dataTable.nextStatus = false;
            }
            if (this.dataTable.past !== null) {
              let separator = this.dataTable.past.split("?page=");
              this.pagePast = separator[1];
              this.dataTable.pastStatus = true;
            } else {
              this.dataTable.pastStatus = false;
            }
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al obtener Shipping Address";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    updatCurrentAddress(id) {
      axios
        .post(
          `${BASE_URL}` + "client/" + this.dataUser.id + "/setAddressAsCurrent",
          {
            address_id: id,
          }
        )
        .then((response) => {
          const data = {};
          data.nombre = response.data.body.attributes.address;
          data.lat = response.data.body.attributes.lat;
          data.lng = response.data.body.attributes.lng;
          data.departamento = response.data.body.attributes.department;
          data.provincia = response.data.body.attributes.city;
          data.distrito = response.data.body.attributes.district;
          data.location = response.data.body.attributes.free;
          const dataCompress = JSON.stringify(data);
          window.localStorage.setItem("direction", dataCompress);
          location.reload();
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al actualizar dirección";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },

    showOptions(idTooltip) {
      const tooltips = [...document.querySelectorAll(".optionsAddres")];
      const tooltipAbierto = tooltips.find(
        (tooltip) => !tooltip.matches(".oculto")
      );
      if (tooltipAbierto && !tooltipAbierto.matches(`#${idTooltip}`)) {
        tooltipAbierto.classList.add("oculto");
      }
      const tooltip = document.getElementById(idTooltip);
      tooltip.classList.toggle("oculto");
    },
  },
  computed: {
    getStatusAlert() {
      return this.$store.state.alert.estado;
    },
    formValidateButton() {
      if (
        (this.update.nombre.length !== 0 ? false : true) ||
        (this.update.apellido.length !== 0 ? false : true) ||
        (this.update.piso.length !== 0 ? false : true) ||
        (this.update.referencia.length !== 0 ? false : true) ||
        (this.update.departamento.length !== 0 ? false : true) ||
        (this.update.provincia.length !== 0 ? false : true) ||
        (this.update.distrito.length !== 0 ? false : true) ||
        (this.update.direccion.length !== 0 ? false : true) ||
        (this.update.lat !== 0 ? false : true) ||
        (this.update.lng !== 0 ? false : true) ||
        (this.update.documento.length !== 0 ? false : true) ||
        (this.update.telefono.length !== 0 ? false : true)
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
