<template>
  <header-component :name="name" @forceSearch="getProducts" />

  <main class="mt-8 xl:mt-24 bg-[#F5F7F9]">
    <preload-shooper-component-1 :preloadShooper="preloadShooper" />
    <preload-shooper-component-2 :preloadShooper="preloadShooper" />

    <section class="u-container">
      <span v-if="estadoUrl">
        <h2 class="text-bg-black-blue mb-8">Producto encontrado</h2>
        <article class="grid xl:grid-cols-2 gap-6">
          <router-link
            v-for="(product, index) in result"
            :key="product"
            :class="index === 0 ? '' : 'hidden'"
            :to="{ name: 'Shooper', params: { url: product.item_url } }"
            @click.prevent="saveProduct(product)"
            class="flex items-start gap-3 xl:gap-8 ring ring-gray-100 hover:ring hover:ring-gray-100 rounded-xl p-4 xl:py-8 xl:px-6 bg-white shadow-2xl hover:bg-white hover:shadow-2xl transition-all"
            v
          >
            <img
              :src="product.product_image_url"
              class="w-1/3 xl:w-1/4 object-contain"
              style="max-height: 180px"
            />
            <div class="grid gap-3 text-bg-black-blue w-2/3 xl:w-3/4">
              <p class="font-semibold text-sm xl:text-base">
                {{ product.product_name }}
              </p>

              <p
                class="flex items-center gap-4 text-xs xl:text-sm"
                v-if="product.product_stock !== null"
              >
                <span>Stock:</span>
                <span class="font-semibold"
                  >Solo {{ product.product_stock }} en Stock.</span
                >
              </p>

              <p
                class="flex items-center gap-3 text-xs xl:text-sm"
                v-if="product.product_brand !== null"
              >
                <span>Marca:</span>
                <span class="font-semibold">{{ product.product_brand }}</span>
              </p>

              <p
                class="flex items-center gap-2 text-xs xl:text-sm"
                v-if="product.store !== null"
              >
                <span>Tienda:</span>
                <span class="font-semibold">
                  <img
                    v-if="product.store === 'ebay'"
                    src="../assets/images/ebay-card.svg"
                    alt="Icono de Sendi"
                  />
                  <img
                    v-else-if="product.store === 'amazon'"
                    src="../assets/images/amazon-small.svg"
                    alt="Icono de Sendi"
                  />
                  <span v-else class="font-semibold">
                    {{ product.store }}</span
                  ></span
                >
              </p>

              <p
                class="flex items-center gap-4 text-xs xl:text-sm"
                v-if="product.weight !== null || product.weight_unit !== null"
              >
                <span>Peso de empaque:</span>
                <span class="font-semibold"
                  >{{ product.weight }}{{ product.weight_unit }}</span
                >
              </p>

              <div
                class="flex flex-col xl:flex-row xl:items-center gap-4 text-xs xl:text-sm justify-between"
              >
                <p
                  class="flex items-center gap-3"
                  v-if="product.original_price !== null"
                >
                  <span>Precio:</span>
                  <span class="font-semibold"
                    ><span class="text-mainOrange">{{
                      product.original_currency
                    }}</span>
                    {{ product.original_price }}</span
                  >
                </p>
                <button
                  class="py-3 px-8 rounded-xl bg-mainGreenLight text-white font-semibold"
                >
                  Comprar
                </button>
              </div>
            </div>
          </router-link>

          <img
            src="../assets/images/sendi-banner.jpg"
            class="w-full xl:h-80 object-cover ring ring-gray-100 rounded-xl"
          />
        </article>
        <hr class="my-12" />
      </span>

      <span v-if="Object.entries(result).length !== 0">
        <h2 class="text-bg-black-blue mb-8">Productos relacionados</h2>
        <article class="grid xl:grid-cols-2 gap-6">
          <span v-for="(product, index2) in result" :key="product">
            <router-link
              :to="{ name: 'Shooper', params: { url: product.item_url } }"
              @click.prevent="saveProduct(product)"
              :class="
                index2 === 0
                  ? '  ring ring-gray-100 bg-white shadow-2xl  '
                  : ' '
              "
              class="flex items-start gap-3 xl:gap-8 hover:shadow-2xl transition-all hover:ring-gray-100 rounded-xl p-4 xl:py-8 xl:px-6 hover:bg-white hover:ring"
            >
              <img
                :src="product.product_image_url"
                class="w-1/3 xl:w-1/4 object-contain"
                style="max-height: 180px"
              />
              <div class="grid gap-3 text-bg-black-blue w-2/3 xl:w-3/4">
                <p class="font-semibold text-sm xl:text-base">
                  {{ product.product_name }}
                </p>
                <p
                  class="flex items-center gap-4 text-xs xl:text-sm"
                  v-if="product.product_stock !== null"
                >
                  <span>Stock:</span>
                  <span class="font-semibold"
                    >Solo {{ product.product_stock }} en Stock.</span
                  >
                </p>
                <p
                  class="flex items-center gap-3 text-xs xl:text-sm"
                  v-if="product.product_brand !== null"
                >
                  <span>Marca:</span>
                  <span class="font-semibold">{{ product.product_brand }}</span>
                </p>
                <p
                  class="flex items-center gap-2 text-xs xl:text-sm"
                  v-if="product.store !== null"
                >
                  <span>Tienda:</span>
                  <span class="font-semibold">
                    <img
                      v-if="product.store === 'ebay'"
                      src="../assets/images/ebay-card.svg"
                      alt="Icono de Sendi"
                    />
                    <img
                      v-else-if="product.store === 'amazon'"
                      src="../assets/images/amazon-small.svg"
                      alt="Icono de Sendi"
                    />
                    <span v-else class="font-semibold">
                      {{ product.store }}</span
                    ></span
                  >
                </p>
                <p
                  class="flex items-center gap-4 text-xs xl:text-sm"
                  v-if="product.weight !== null || product.weight_unit !== null"
                >
                  <span>Peso de empaque:</span>
                  <span class="font-semibold"
                    >{{ product.weight }}{{ product.weight_unit }}</span
                  >
                </p>

                <div
                  class="flex flex-col xl:flex-row xl:items-center gap-4 text-xs xl:text-sm justify-between"
                >
                  <p
                    class="flex items-center gap-3"
                    v-if="product.original_price !== null"
                  >
                    <span>Precio:</span>
                    <span class="font-semibold"
                      ><span class="text-mainOrange">{{
                        product.original_currency
                      }}</span>
                      {{ product.original_price }}</span
                    >
                  </p>
                  <button
                    class="py-3 px-8 rounded-xl bg-mainGreenLight text-white font-semibold"
                  >
                    Comprar
                  </button>
                </div>
              </div>
            </router-link>
          </span>
        </article>
      </span>
    </section>
  </main>
  <footer-component />
</template>
<script>
import headerComponent from "../components/global/headerComponent.vue";
import footerComponent from "../components/global/footerComponent.vue";
import preloadShooperComponent1 from "../components/preload/preloadShooperComponent1.vue";
import preloadShooperComponent2 from "../components/preload/preloadShooperComponent2.vue";
import { server } from "../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";

export default {
  components: {
    headerComponent,
    footerComponent,
    preloadShooperComponent1,
    preloadShooperComponent2,
  },
  data() {
    return {
      preloadShooper: true,
      name: this.$route.params.name,
      result: {},
      estadoUrl: false,
    };
  },
  created() {
    const data = localStorage.getItem("shooperProducts");
    if (data !== null) {
      const shooper = JSON.parse(data);

      this.result = shooper.product;
      this.estadoUrl = shooper.url;
      this.preloadShooper = false;
    } else {
      this.getProducts();
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    window.localStorage.removeItem("datalleProduct");
    window.localStorage.removeItem("producDetail");
  },
  methods: {
    saveProduct(data) {
      const objProduct = {};
      objProduct.error = false;
      objProduct.name = data.product_name;
      objProduct.imagen = data.product_image_url;
      objProduct.stock = data.product_stock;
      objProduct.precio = data.original_price;
      objProduct.peso = data.weight;
      objProduct.unidad = data.weight_unit;
      objProduct.linkReference = data.item_url;
      objProduct.store = data.store;
      const dataCompress = JSON.stringify(objProduct);
      window.localStorage.setItem("producDetail", dataCompress);
    },
    borrarBuscador() {
      this.name = "";
    },
    buscarProduct() {
      if (this.name.length !== 0) {
        this.result = {};
        this.preloadShooper = true;
        this.getProducts();
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description =
          "Ingrese el nombre o enlace del producto";
        this.$store.state.alert.estado = true;
        //--------------------------//
      }
    },
    getProducts() {
      // const OLD_API_URL = "https://backendbutiko.com/api/"
      if (this.name.length !== 0) {
        axios
          .get(
            `${BASE_URL}` + "product/product-detail",
            {
              params: { product_url: this.name },
            },
            { timeout: 30000 }
          )
          .then((response) => {
            this.preloadShooper = false;
            this.result = response.data.body.data;
            this.estadoUrl = response.data.body.url;

            const data = {};
            data.product = response.data.body.data;
            data.url = response.data.body.url;

            const shooperData = JSON.stringify(data);
            window.localStorage.setItem("shooperProducts", shooperData);
            this.$store.state.errorShooper = false;
          })
          .catch((error) => {
            this.preloadShooper = false;

            if (error) {
              //----------component--------//
              // this.$store.state.alert.titulo = "Error";
              // this.$store.state.alert.description = "Lo sentimos ocurrió un error";
              // this.$store.state.alert.estado = true;
              //--------------------------//
              this.$store.state.errorShooper = true;
              this.$router.push({
                name: "Shooper",
                params: { url: this.name },
              });

              // this.$router.push({ name: "Home" });
            }
          });
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description =
          "Ingrese el nombre o enlace del producto";
        this.$store.state.alert.estado = true;
        //--------------------------//
      }
    },
  },
  watch: {
    "$route.params.name": function () {
      if (this.$route.name === "SearchShooper") {
        this.name = this.$route.params.name;
        this.buscarProduct();
      }
    },
  },
};
</script>
