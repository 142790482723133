<template>
  <header-component />
  <main>
    <!-- hero -->
    <section class="relative">
      <picture>
        <source
          srcset="../assets/images/hero-image-compra-mobile.png"
          media="(max-width: 1024px)"
        />
        <img
          srcset="../assets/images/hero-image-compra.png"
          class="w-full"
          alt="Compra Segura con Sendi"
        />
      </picture>
      <article
        class="flex flex-col justify-center pt-52 pb-28 xl:py-28 px-4 xl:px-0 xl:w-1/2 absolute inset-y-0 right-0 left-0 xl:left-auto"
      >
        <img
          src="../assets/images/segura-icon-verde.svg"
          class="w-24 mb-10 xl:hidden"
          alt="Compra Segura con Sendi"
        />
        <h2 class="text-white text-3xl xl:text-6xl mb-6 xl:ml-16">
          <span class="block mb-2 xl:mb-4">COMPRA 100%</span>
          PROTEGIDA
        </h2>
        <div
          class="bg-white py-5 xl:py-12 px-5 xl:px-16 rounded-xl xl:rounded-tr-none xl:rounded-br-none text-black xl:text-mainBlue"
        >
          <h3 class="font-semibold text-xl xl:text-3xl mb-2 xl:mb-4">
            Nosotros te cuidamos
          </h3>
          <p class="text-xs xl:text-xl">
            ¿No recibiste lo que esperabas? ¿Te arrepentiste? <br />
            <span class="font-semibold xl:font-normal"
              >Te devolvemos el dinero.</span
            >
          </p>
        </div>
      </article>
    </section>
    <!-- ---- -->
    <section class="u-container pb-0 xl:pb-20">
      <h2 class="font-normal mb-20 xl:mb-12 text-center text-black">
        Con la <span class="font-semibold">Compra Protegida</span> <br />
        tu dinero esta seguro en cada momento
      </h2>
      <article class="xl:py-20 relative">
        <div
          class="relative z-10 border-2 border-mainBlue xl:border-none flex flex-col xl:flex-row items-center gap-5 xl:gap-16 py-8 px-16 xl:pr-20 xl:pl-10 bg-white rounded-xl shadow-sm xl:w-8/12 mx-auto mb-20 xl:mb-10"
        >
          <div class="-mt-16 xl:mt-0">
            <div class="pt-0 p-3 xl:p-5 bg-white xl:bg-[#E2F4FF]">
              <img
                src="../assets/icons/reembolso.png"
                alt="Compra Segura con Sendi"
              />
            </div>
            <!-- <span class="w-16 h-16 block rounded-full bg-mainBlue xl:hidden"></span> -->
          </div>
          <div>
            <p class="font-semibold xl:text-2xl mb-1">
              Reembolso del 100% de la compra,
            </p>
            <p class="text-sm xl:text-xl">si no llega tu producto.</p>
          </div>
        </div>
        <div
          class="relative z-10 border-2 border-mainBlue xl:border-none flex flex-col xl:flex-row items-center gap-5 xl:gap-16 py-8 px-16 xl:pr-20 xl:pl-10 bg-white rounded-xl shadow-sm xl:w-8/12 mx-auto mb-20 xl:mb-10"
        >
          <div class="-mt-16 xl:mt-0">
            <div class="pt-0 p-3 xl:p-5 bg-white xl:bg-[#E2F4FF]">
              <img
                src="../assets/icons/garantia-2.png"
                alt="Compra Segura con Sendi"
              />
            </div>
            <!-- <span class="w-16 h-16 block rounded-full bg-mainBlue xl:hidden"></span> -->
          </div>
          <div>
            <p class="font-semibold xl:text-2xl mb-1">
              Garantía de 90 días para cambios o reparaciones,
            </p>
            <p class="text-sm xl:text-xl">
              Retorno gratis a USA por 90 días para cambio o <br />
              reparación de productos
            </p>
          </div>
        </div>
        <div
          class="relative z-10 border-2 border-mainBlue xl:border-none flex flex-col xl:flex-row items-center gap-5 xl:gap-16 py-8 px-16 xl:pr-20 xl:pl-10 bg-white rounded-xl shadow-sm xl:w-8/12 mx-auto xl:mb-24"
        >
          <div class="-mt-16 xl:mt-0">
            <div class="pt-0 p-3 xl:p-5 bg-white xl:bg-[#E2F4FF]">
              <img
                src="../assets/icons/devoluciones.png"
                alt="Compra Segura con Sendi"
              />
            </div>
            <!-- <span class="w-16 h-16 block rounded-full bg-mainBlue xl:hidden"></span> -->
          </div>
          <div>
            <p class="font-semibold xl:text-2xl mb-1">
              Devoluciones gratis por 10 días
            </p>
            <p class="text-sm xl:text-xl">
              Si no te gusto o no es el producto esperado retorno <br />
              gratis a USA por 10 días para devolución de producto.
            </p>
          </div>
        </div>
        <div class="relative z-10 hidden xl:flex w-max mx-auto gap-10 -mb-36">
          <img
            src="../assets/icons/caja-segura.svg"
            alt="Compra Segura con Sendi"
          />
          <div class="bg-white py-8 px-9 rounded-xl shadow-md text-2xl">
            <p class="text-mainGreenLight font-bold">
              Contamos con Certificación SSL
            </p>
            <p class="text-gray-400">Protegemos tus datos personales</p>
          </div>
        </div>
        <div class="bg-blur-blue"></div>
      </article>
    </section>
    <!-- ---- -->
    <section class="u-container">
      <h2 class="font-medium mb-24 text-center text-black">
        <span class="block text-mainBlue">No te dejaremos solo en la</span>
        devolución de tu dinero
      </h2>
      <article class="relative xl:flex xl:justify-around gap-4">
        <div class="flex gap-5 xl:block xl:text-center z-10 mb-12 xl:mb-0">
          <span
            class="mt-1 w-5 h-5 rounded-full bg-mainBlue flex items-center justify-center xl:hidden text-white text-xs font-semibold z-10"
            >1</span
          >
          <span
            class="mb-8 mx-auto hidden xl:flex w-20 h-20 rounded-full bg-[#E2F4FF] text-3xl font-semibold items-center justify-center"
            >1</span
          >
          <div>
            <h4 class="font-normal xl:text-2xl mb-3 xl:mb-6">
              Inicia <br class="hidden xl:block" />
              un reclamo
            </h4>
            <p class="text-xs xl:text-base text-gray-400">
              Cuéntanos lo que pasó y le <br class="hidden xl:block" />
              avisaremos al vendedor
            </p>
          </div>
        </div>
        <div class="flex gap-5 xl:block xl:text-center z-10 mb-12 xl:mb-0">
          <span
            class="mt-1 w-5 h-5 rounded-full bg-mainBlue flex items-center justify-center xl:hidden text-white text-xs font-semibold z-10"
            >2</span
          >
          <span
            class="mb-8 mx-auto hidden xl:flex w-20 h-20 rounded-full bg-[#E2F4FF] text-3xl font-semibold items-center justify-center"
            >2</span
          >
          <div>
            <h4 class="font-normal xl:text-2xl mb-3 xl:mb-6">
              Le pedimos al vendedor <br />
              que lo resuelva
            </h4>
            <p class="text-xs xl:text-base text-gray-400">
              Facilitamos las herramientas <br />
              para que soluciones el reclamo <br />
              según lo que necesites
            </p>
          </div>
        </div>
        <div class="flex gap-5 xl:block xl:text-center z-10 mb-12 xl:mb-0">
          <span
            class="mt-1 w-5 h-5 rounded-full bg-mainBlue flex items-center justify-center xl:hidden text-white text-xs font-semibold z-10"
            >3</span
          >
          <span
            class="mb-8 mx-auto hidden xl:flex w-20 h-20 rounded-full bg-[#E2F4FF] text-3xl font-semibold items-center justify-center"
            >3</span
          >
          <div>
            <h4 class="font-normal xl:text-2xl mb-3 xl:mb-6">
              Te cubrimos si no se <br class="hidden xl:block" />
              soluciona
            </h4>
            <p class="text-xs xl:text-base text-gray-400">
              Nuestro equipo de COMPRA PROTEGIDA <br />
              intervendra para ayudarte <br />
              con la devolución de tu dinero
            </p>
          </div>
        </div>
        <div
          class="absolute inset-y-0 inset-x-0 xl:top-10 xl:bottom-auto flex flex-col xl:justify-center"
        >
          <span
            class="h-4/5 w-1 xl:h-2 bg-mainBlue xl:bg-[#E2F4FF] xl:w-8/12 xl:mx-auto xl:my-0 mt-1 ml-2"
          ></span>
        </div>
      </article>
    </section>
    <!-- ---- -->
    <section
      class="relative u-container bg-[#E2F4FF] h-screen w-full xl:w-11/12 xl:h-[60vh] mb-20 flex justify-center items-center"
    >
      <article class="service service--active flex-col" data-id="0">
        <h2
          class="text-xl xl:text-2xl font-normal text-mainBlue xl:text-black text-center mb-14"
        >
          Con la <span class="font-semibold">Compra Protegida</span> <br />
          tu dinero esta seguro en cada momento
        </h2>
        <div class="flex flex-col xl:flex-row gap-8 xl:gap-16 items-center">
          <img
            src="../assets/icons/segura-icon.png"
            class="hidden xl:block"
            alt="Compra Segura con Sendi"
          />
          <img
            src="../assets/images/segura-icon-verde.svg"
            class="w-40 xl:hidden"
            alt="Compra Segura con Sendi"
          />
          <p
            class="text-mainBlue xl:text-2xl py-8 xl:py-14 px-20 xl:px-28 bg-white rounded-2xl shadow-sm font-semibold xl:font-normal"
          >
            Las compras en
            <span class="text-black xl:text-mainBlue">SENDI</span> <br />
            están protegidas.
          </p>
        </div>
      </article>
      <article class="service flex-col" data-id="1">
        <h2 class="font-semibold text-black text-center mb-14">
          No cubrimos <br class="xl:hidden" />
          en estos casos:
        </h2>
        <div class="flex flex-col xl:flex-row gap-8 xl:gap-16 items-center">
          <img
            src="../assets/icons/icon-alert-red.svg"
            alt="Compra Segura con Sendi"
          />
          <div
            class="text-mainBlue xl:text-2xl px-3 py-8 xl:p-10 bg-white rounded-2xl shadow-sm"
          >
            <ul>
              <li class="flex items-center gap-4 mb-3">
                <span class="w-2 h-2 rounded-full bg-mainBlue block"></span>
                <p>Productos usados</p>
              </li>
              <li class="flex items-center gap-4 mb-3">
                <span class="w-2 h-2 rounded-full bg-mainBlue block"></span>
                <p>Envíos de dinero</p>
              </li>
              <li class="flex items-center gap-4">
                <span class="w-2 h-2 rounded-full bg-mainBlue block"></span>
                <p>Productos y categorías excluidas</p>
              </li>
            </ul>
          </div>
        </div>
      </article>
      <!-- dots -->
      <div class="flex gap-2 items-center justify-center absolute bottom-12">
        <span class="dot dot--active"></span>
        <span class="dot"></span>
      </div>
    </section>
  </main>
  <footer-component />
</template>
<script>
import headerComponent from "../components/global/headerComponent.vue";
import footerComponent from "../components/global/footerComponent.vue";
export default {
  components: { headerComponent, footerComponent },
  mounted() {
    //Eliminando localStorage//
    window.localStorage.removeItem("servicio");
    window.localStorage.removeItem("open");
    window.localStorage.removeItem("datalleProduct");
    window.localStorage.removeItem("shooperProducts");
    window.localStorage.removeItem("producDetail");
    //---------------------//
    const dots = Array.from(document.querySelectorAll(".dot"));
    const services = Array.from(document.querySelectorAll(".service"));

    const changuePicture = (next) => {
      document
        .querySelector(".service--active")
        .classList.remove("service--active");
      document.querySelector(".dot--active").classList.remove("dot--active");

      services[next].classList.add("service--active");
      dots[next].classList.add("dot--active");
    };

    dots.map((dot, i) => {
      dot.addEventListener("click", () => {
        changuePicture(i);
      });
    });
  },
};
</script>
