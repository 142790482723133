<template>
  <header-component :name="url" />

  <main class="mt-14 xl:mt-24">
    <section class="hidden xl:block">
      <img src="../assets/images/franja-flujo.jpg" class="w-full" />
    </section>
  </main>
  <section class="section-flujo-compra">
    <article class="u-container py-14 grid xl:grid-cols-2 gap-8">
      <section>
        <balance-component v-if="flujo === 3" />
        <article class="xl:bg-white xl:border xl:shadow-lg rounded-2xl">
          <time-line-component :flujo="flujo" @changeFlujo="flujo = $event" />

          <span v-show="flujo === 1">
            <shooper-component
              :changeMoney="changeMoney"
              @productoCalculo="courier = $event"
              @url="url = $event"
            />
          </span>
          <procesoComponent
            :flujo="flujo"
            :moneda="moneda"
            :errorNewDirection="error"
            :cambioSoles="changeMoney"
            :compra="compra"
            :data="courier"
            :terminos="terminos"
            @changeMoney="moneda = $event"
            @typeDirection="selectDirection = $event"
            @typePayment="selectPayment = $event"
            @calculoTarifa="calculoTarifa = $event"
            @next="flujo = $event"
            @dataMercadoPago="compra = $event"
            @existForm="statusForm = $event"
          />
        </article>
      </section>

      <section>
        <span v-show="flujo === 3">
          <servicios-component />
        </span>
        <span v-show="flujo === 3">
          <cupon-component :idUser="user" />
        </span>
        <detalle-component
          v-if="flujo === 1 || flujo === 2 || flujo === 3"
          :flujo="flujo"
          :data="courier"
          :selectDirection="selectDirection"
          :selectPayment="selectPayment"
          :stateAuth="stateAuth"
          :calculoTarifa="calculoTarifa"
          :moneda="moneda"
          :statusForm="statusForm"
          @next="flujo = $event"
          @errorNewDirection="error = $event"
          @compra="compra = $event"
          @terminos="terminos = $event"
        />

        <information-component
          :data="courier"
          v-if="flujo === 1 || flujo === 2 || flujo === 3"
        />
        <thankyou-componet v-if="flujo === 4" :compra="compra" />
      </section>
    </article>
  </section>
  <footer-component />
</template>
<script>
import headerComponent from "../components/global/headerComponent.vue";
import footerComponent from "../components/global/footerComponent.vue";
import detalleComponent from "../components/servicios/detalle/detalleComponent.vue";
import informationComponent from "../components/servicios/detalle/informationComponent.vue";
import timeLineComponent from "../components/servicios/timeline/timeLineComponent.vue";
import shooperComponent from "../components/servicios/shooperComponent.vue";
import procesoComponent from "../components/servicios/proceso/procesoComponent.vue";
import thankyouComponet from "../components/servicios/detalle/thankyouComponent.vue";
import cuponComponent from "../components/servicios/detalle/cuponComponent.vue";
import balanceComponent from "../components/servicios/proceso/payment/Balance/balanceComponent.vue";
import serviciosComponent from "../components/servicios/detalle/serviciosComponent.vue";
import { server } from "../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  components: {
    headerComponent,
    footerComponent,
    detalleComponent,
    informationComponent,
    timeLineComponent,
    shooperComponent,
    procesoComponent,
    thankyouComponet,
    cuponComponent,
    balanceComponent,
    serviciosComponent,
  },
  data() {
    return {
      url: "",
      user: {},
      statusForm: false,
      terminos: false,
      compra: {},
      calculoTarifa: {
        dolar: {
          doorTodoor: "0.00",
          manipulacion: "0.00",
          tramiteAduanero: "0.00",
          envioNacional: "0.00",
          totalTarifa: "0.00",
        },
        soles: {
          doorTodoor: "0.00",
          manipulacion: "0.00",
          tramiteAduanero: "0.00",
          envioNacional: "0.00",
          totalTarifa: "0.00",
        },
      },
      selectPayment: 0,
      selectDirection: { type: 0 },
      flujo: 1,
      moneda: "USD",
      changeMoney: 0.0,
      error: {},
      courier: {
        servicio: 1,
        producto: {
          nombre: "",
          precio: 0.0,
          peso: 0.0,
          unidad: "",
          cantidad: 0,
          foto: "",
          vaucher: "",
          description: "",
        },
        dolares: {
          primera: {
            precio: "0.00",
            shipping: "0.00",
            tax: "0.00",
            frontTax: "0.00",
            warehouse: "0.00",
            flete: "0.00",
            total: "0.00",
            frontTotal: "0.00",
          },
          segundo: {
            estado: 0,
            valorem: "0.00",
            igv: "0.00",
            ipm: "0.00",
            total: "0.00",
          },
        },
        soles: {
          primera: {
            precio: "0.00",
            shipping: "0.00",
            tax: "0.00",
            frontTax: "0.00",
            warehouse: "0.00",
            flete: "0.00",
            total: "0.00",
            frontTotal: "0.00",
          },
          segundo: {
            estado: 0,
            valorem: "0.00",
            igv: "0.00",
            ipm: "0.00",
            total: "0.00",
          },
        },
      },
    };
  },
  created() {
    this.getMoney();
  },
  mounted() {
    window.scrollTo(0, 0);
    window.localStorage.removeItem("datalleProduct");
    this.url = this.$route.params.url;
  },
  methods: {
    //Metodo para obtener el el precio de la moneda para hacer la conversión a soles
    getMoney() {
      axios
        .get(`${BASE_URL}` + "exchange_rate/list")
        .then((response) => {
          if (response.status === 200) {
            this.changeMoney = parseFloat(response.data.sale);
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al obtener el valor de la moneda";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    getIdUser() {
      this.user = JSON.parse(localStorage.getItem("auth"));
    },
  },
  computed: {
    stateAuth() {
      const data = localStorage.getItem("auth");
      if (data !== null) {
        this.getIdUser();
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    flujo: function (val) {
      window.scrollTo(0, 0);
      if (val === 1 || val === 2) {
        this.moneda = "USD";
      }
    },
  },
};
</script>
