<template>
  <div
    class="px-4 py-6 xl:px-9 rounded-2xl bg-white border shadow-lg xl:border-none xl:shadow-none"
  >
    <!-- Dirección -->

    <exist-direction-component
      :select="selectDirection"
      @exist="selectDirection = $event"
      @changeDirection="sendTypeDirection"
      @DirectionIguals="directionIguals = $event"
      @existForm="statusForm = $event"
      @existDirection="existDirection = $event"
    />

    <!-- Nueva direccion -->
    <new-direction-component
      :select="selectDirection"
      :errorNewDirection="errorNewDirection"
      :directionIguals="directionIguals"
      @new="selectDirection = $event"
      @changeDirection="sendTypeDirection"
    />

    <!-- Recojo en oficina -->
    <default-direction-component
      :select="selectDirection"
      :existDirection="existDirection"
      @default="selectDirection = $event"
      @changeDirection="sendTypeDirection"
    />
  </div>
</template>
<script>
import defaultDirectionComponent from "../direction/default/defaultDirectionComponent.vue";
import newDirectionComponent from "../direction/new/newDirectionComponent.vue";
import existDirectionComponent from "../direction/exist/existDirectionComponent.vue";
export default {
  props: ["errorNewDirection"],
  components: {
    defaultDirectionComponent,
    newDirectionComponent,
    existDirectionComponent,
  },
  data() {
    return {
      existDirection: false,
      directionIguals: false,
      selectDirection: { type: 0 },
      statusForm: false,
    };
  },
  methods: {
    sendTypeDirection() {
      this.$emit("typedirection", this.selectDirection);
      this.$emit("existForm", this.statusForm);
      this.$emit("changeDirection");
    },
  },
};
</script>
