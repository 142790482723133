<template>
  <div
    :class="vista ? '' : 'hidden'"
    class="min-h-screen bg-sky flex items-center justify-center"
  >
    <div class="u-container">
      <img
        src="../assets/images/cuenta_verificada.svg"
        class="mx-auto mb-8"
        v-if="status"
        alt="Compra Segura con Sendi"
      />
      <img
        src="../assets/images/404.svg"
        class="mx-auto mb-8"
        alt="Compra Segura con Sendi"
        v-else
      />
      <h3 class="mb-10 text-2xl xl:text-4xl font-semibold text-center">
        {{ status ? "Cuenta verificada" : "  ¡Uy! Algo salió mal!" }}
      </h3>

      <router-link
        :to="{ name: 'Home' }"
        class="block mx-auto w-max px-28 xl:px-36 py-5 bg-mainBlue rounded-2xl text-center text-white font-semibold"
      >
        {{ status ? "Continuar" : "Ir al home" }}</router-link
      >
    </div>
  </div>
</template>
<script>
import { server } from "../config/global";

const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  data() {
    return {
      vista: false,
      status: false,
      token: this.$route.params.token,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window.localStorage.removeItem("datalleProduct");
    window.localStorage.removeItem("shooperProducts");
    window.localStorage.removeItem("direction");
    window.localStorage.removeItem("auth");
    window.localStorage.removeItem("producDetail");

    this.verification();
  },
  methods: {
    verification() {
      axios
        .get(`${BASE_URL}` + "client/verify/" + this.token)
        .then((response) => {
          if (response.status === 200) {
            this.status = true;
            this.vista = true;
          }
        })
        .catch((error) => {
          if (error) {
            this.status = false;
            this.vista = true;
          }
        });
    },
  },
};
</script>
