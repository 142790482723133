<template>
  <span v-show="flujo === 2">
    <direction-component
      :errorNewDirection="errorNewDirection"
      @typedirection="selectDirection = $event"
      @changeDirection="changeDirection"
      @existForm="statusForm = $event"
    />
  </span>
  <span v-show="flujo === 3">
    <payment-component
      :moneda="moneda"
      :data="data"
      :calculoTarifa="tarifa"
      :terminos="terminos"
      :selectDirection="selectDirection"
      @changeMoney="changeMoney($event)"
      @typePayment="typePayment = $event"
      @changePayment="changePayment"
      @next="next = $event"
      @paymentMercadoPago="paymentMercadoPago"
      @purchaseData="dataMercadoPago = $event"
    />
  </span>
  <resumen-component v-if="flujo === 4" :compra="datosDeCompra" />
</template>
<script>
import directionComponent from "../proceso/direction/directionComponent.vue";
import paymentComponent from "../proceso/payment/paymentComponent.vue";
import resumenComponent from "../proceso/resumen/resumenComponent.vue";
import { server } from "../../../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  props: [
    "flujo",
    "moneda",
    "errorNewDirection",
    "cambioSoles",
    "compra",
    "data",
    "terminos",
  ],
  components: { directionComponent, paymentComponent, resumenComponent },
  data() {
    return {
      statusForm: false,
      datosDeCompra: {},
      dataMercadoPago: {},
      next: 0,
      selectDirection: { type: 0 },
      typePayment: 0,
      tarifa: {
        dolar: {
          doorTodoor: "0.00",
          manipulacion: "0.00",
          tramiteAduanero: "0.00",
          envioNacional: "0.00",
          totalTarifa: "0.00",
        },
        soles: {
          doorTodoor: "0.00",
          manipulacion: "0.00",
          tramiteAduanero: "0.00",
          envioNacional: "0.00",
          totalTarifa: "0.00",
        },
      },
    };
  },
  created() {
    this.$emit("calculoTarifa", this.tarifa);
  },

  methods: {
    paymentMercadoPago() {
      this.$emit("next", this.next);
      this.$emit("paymentMercadoPago");
    },
    changeMoney(data) {
      this.$emit("changeMoney", data);
    },
    changeDirection() {
      this.$emit("typeDirection", this.selectDirection);
      this.$emit("existForm", this.statusForm);
      this.getDoortoDoor();
    },
    changePayment() {
      this.$emit("typePayment", this.typePayment);
    },
    getDoortoDoor() {
      var doorTodoor = 0.0;
      var manipulacion = 0.0;
      var tramiteAduanero = 0.0;
      var envioNacional = 0;
      var totalTarifa = 0.0;

      if (this.selectDirection.type === 1 || this.selectDirection.type === 2) {
        if (
          this.selectDirection.datos.lat !== 0 &&
          this.selectDirection.datos.lng !== 0
        ) {
          axios
            .get(`${BASE_URL}` + "purchaseOrder/getDoorToDoor", {
              params: {
                latitud: this.selectDirection.datos.lat,
                longitud: this.selectDirection.datos.lng,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                doorTodoor = response.data;
                doorTodoor = Math.ceil(10 * doorTodoor) / 10;
                //------------------------//
                manipulacion = 4;
                //------------------------//
                tramiteAduanero = 10;
                //Aplicando Cupon valido
                if (this.$store.state.cuponStatu === true) {
                  if (this.data.dolares.segundo.estado === 1) {
                    var resultDescuento1 =
                      tramiteAduanero - this.$store.state.cuponMonto;
                    if (resultDescuento1 <= 0) {
                      this.$store.state.cuponMonto = tramiteAduanero;
                      this.$store.state.cuponMontoSoles = (
                        tramiteAduanero * this.cambioSoles
                      ).toFixed(2);
                    }
                  } else {
                    var valorDescontar1 = Math.ceil(
                      0.01 * parseFloat(this.data.dolares.primera.precio)
                    );
                    if (valorDescontar1 < 1) {
                      valorDescontar1 = 1;
                    }
                    this.$store.state.cuponMonto = valorDescontar1;
                    this.$store.state.cuponMontoSoles =
                      valorDescontar1 * this.cambioSoles;
                  }
                }
                //---------------------------//
                //----------------------//
                if (this.selectDirection.datos.free === 1) {
                  envioNacional = 0.0;
                } else {
                  envioNacional =
                    manipulacion +
                    doorTodoor +
                    (18 / 100) * (10 + manipulacion + doorTodoor);
                  envioNacional = Math.ceil(100 * envioNacional) / 100;
                }

                //----------------------//
                totalTarifa = tramiteAduanero + envioNacional;
                totalTarifa = Math.ceil(100 * totalTarifa) / 100;
                //----------------------//
                //Cambiando datos dólar
                this.tarifa.dolar.doorTodoor = doorTodoor.toFixed(2);
                this.tarifa.dolar.manipulacion = manipulacion.toFixed(2);
                this.tarifa.dolar.tramiteAduanero = tramiteAduanero.toFixed(2);
                this.tarifa.dolar.envioNacional = envioNacional.toFixed(2);
                this.tarifa.dolar.totalTarifa = totalTarifa.toFixed(2);
                //Cambiando datos soles
                this.tarifa.soles.doorTodoor = (
                  doorTodoor * this.cambioSoles
                ).toFixed(2);
                this.tarifa.soles.manipulacion = (
                  manipulacion * this.cambioSoles
                ).toFixed(2);
                this.tarifa.soles.tramiteAduanero = (
                  tramiteAduanero * this.cambioSoles
                ).toFixed(2);
                this.tarifa.soles.envioNacional = (
                  envioNacional * this.cambioSoles
                ).toFixed(2);
                this.tarifa.soles.totalTarifa = (
                  totalTarifa * this.cambioSoles
                ).toFixed(2);

                this.$emit("calculoTarifa", this.tarifa);
              }
            })
            .catch((error) => {
              if (error) {
                //----------component--------//
                this.$store.state.alert.titulo = "Error";
                this.$store.state.alert.description = "Refresque el navegador ";
                this.$store.state.alert.estado = true;
                //--------------------------//
              }
            });
        } else {
          //Cambiando datos dólar
          this.tarifa.dolar.doorTodoor = (0.0).toFixed(2);
          this.tarifa.dolar.manipulacion = (0.0).toFixed(2);
          this.tarifa.dolar.tramiteAduanero = (0.0).toFixed(2);
          this.tarifa.dolar.envioNacional = (0.0).toFixed(2);
          this.tarifa.dolar.totalTarifa = (0.0).toFixed(2);
          //Cambiando datos soles
          this.tarifa.soles.doorTodoor = (0.0).toFixed(2);
          this.tarifa.soles.manipulacion = (0.0).toFixed(2);
          this.tarifa.soles.tramiteAduanero = (0.0).toFixed(2);
          this.tarifa.soles.envioNacional = (0.0).toFixed(2);
          this.tarifa.soles.totalTarifa = (0.0).toFixed(2);

          this.$emit("calculoTarifa", this.tarifa);
        }
      }
      if (this.selectDirection.type === 3) {
        tramiteAduanero = 10;
        //Aplicando Cupon valido
        if (this.$store.state.cuponStatu === true) {
          if (this.data.dolares.segundo.estado === 1) {
            var resultDescuento2 =
              tramiteAduanero - this.$store.state.cuponMonto;
            if (resultDescuento2 <= 0) {
              this.$store.state.cuponMonto = tramiteAduanero;
              this.$store.state.cuponMontoSoles = (
                tramiteAduanero * this.cambioSoles
              ).toFixed(2);
            }
          } else {
            var valorDescontar2 = Math.ceil(
              0.01 * parseFloat(this.data.dolares.primera.precio)
            );
            if (valorDescontar2 < 1) {
              valorDescontar2 = 1;
            }

            this.$store.state.cuponMonto = valorDescontar2;
            this.$store.state.cuponMontoSoles =
              valorDescontar2 * this.cambioSoles;
          }
        }
        //---------------------------//
        //----------------------//
        envioNacional = 0.0;
        // envioNacional =
        //   manipulacion + doorTodoor + (18 / 100) * (10 + manipulacion + doorTodoor);
        // envioNacional = Math.ceil(100 * envioNacional) / 100;
        //----------------------//
        totalTarifa = tramiteAduanero + envioNacional;
        totalTarifa = Math.ceil(100 * totalTarifa) / 100;
        //----------------------//
        //Cambiando datos dólar
        this.tarifa.dolar.doorTodoor = doorTodoor.toFixed(2);
        this.tarifa.dolar.manipulacion = manipulacion.toFixed(2);
        this.tarifa.dolar.tramiteAduanero = tramiteAduanero.toFixed(2);
        this.tarifa.dolar.envioNacional = envioNacional.toFixed(2);
        this.tarifa.dolar.totalTarifa = totalTarifa.toFixed(2);
        //Cambiando datos soles
        this.tarifa.soles.doorTodoor = (doorTodoor * this.cambioSoles).toFixed(
          2
        );
        this.tarifa.soles.manipulacion = (
          manipulacion * this.cambioSoles
        ).toFixed(2);
        this.tarifa.soles.tramiteAduanero = (
          tramiteAduanero * this.cambioSoles
        ).toFixed(2);
        this.tarifa.soles.envioNacional = (
          envioNacional * this.cambioSoles
        ).toFixed(2);
        this.tarifa.soles.totalTarifa = (
          totalTarifa * this.cambioSoles
        ).toFixed(2);

        this.$emit("calculoTarifa", this.tarifa);
      }
      if (this.selectDirection.type === 0) {
        //Cambiando datos dólar
        this.tarifa.dolar.doorTodoor = (0.0).toFixed(2);
        this.tarifa.dolar.manipulacion = (0.0).toFixed(2);
        this.tarifa.dolar.tramiteAduanero = (0.0).toFixed(2);
        this.tarifa.dolar.envioNacional = (0.0).toFixed(2);
        this.tarifa.dolar.totalTarifa = (0.0).toFixed(2);
        //Cambiando datos soles
        this.tarifa.soles.doorTodoor = (0.0).toFixed(2);
        this.tarifa.soles.manipulacion = (0.0).toFixed(2);
        this.tarifa.soles.tramiteAduanero = (0.0).toFixed(2);
        this.tarifa.soles.envioNacional = (0.0).toFixed(2);
        this.tarifa.soles.totalTarifa = (0.0).toFixed(2);

        this.$emit("calculoTarifa", this.tarifa);
      }
    },
  },
  watch: {
    "$store.state.cuponStatu": function (val) {
      if (val === true) {
        this.getDoortoDoor();
      }
    },
    dataMercadoPago: function (val) {
      this.datosDeCompra = val;
      this.$emit("dataMercadoPago", this.datosDeCompra);
    },
    compra: function (val) {
      this.datosDeCompra = val;
    },
  },
};
</script>
