<template>
  <preload-component :preload="preload" />
  <span v-if="saldoTotalStatus">
    <div
      class="text-text-blue relative ring ring-gray-100 shadow-sm rounded-2xl mb-8 bg-gray-500 bg-opacity-20 cursor-not-allowed"
    >
      <div
        class="rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold"
      >
        <p class="text-xs xl:text-base flex items-center gap-2 xl:gap-4">
          <img src="../../../../../assets/icons/tarjeta.svg" />
          <span>
            Tarjeta de crédito/débito
            <span style="font-size: 10px" class="block text-gray-400"
              >Paga con tarjetas nacionales</span
            >
          </span>
        </p>
        <span
          class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl bg-gray-light text-gray-400"
          >Seleccionar</span
        >
      </div>
    </div>
  </span>
  <!-- //------------------------------------------------// -->
  <span v-else>
    <div
      :class="payment === 4 ? 'ring-mainGreenLight' : 'ring-gray-100'"
      class="text-text-blue relative ring hover:ring-mainGreenLight shadow-sm rounded-2xl mb-8"
    >
      <div
        @click.prevent="selectPayment"
        class="border-b rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold cursor-pointer"
      >
        <p class="text-xs xl:text-base flex items-center gap-2 xl:gap-4">
          <img src="../../../../../assets/icons/tarjeta.svg" />

          <span>
            Tarjeta de crédito/débito
            <span style="font-size: 10px" class="block text-gray-400"
              >Paga con tarjetas nacionales</span
            >
          </span>
        </p>
        <span
          :class="
            payment === 4
              ? 'bg-mainGreenLight text-white'
              : 'bg-gray-light text-gray-400'
          "
          class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl"
          >{{ payment === 4 ? "Seleccionado" : "Seleccionar" }}</span
        >
      </div>

      <form class="px-2 py-6" v-show="form && payment === 4" id="form-checkout">
        <label class="relative block mb-3 xl:mb-5">
          <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
            <img
              src="../../../../../assets/icons/icon-tarjetas-outline.svg"
              alt="Icono de Sendi"
            />
          </div>
          <input
            class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
            type="text"
            id="form-checkout__cardNumber"
            name="cardNumber"
            v-model="tarjeta.number"
          />
          <div class="absolute inset-y-0 right-3 xl:right-5 flex items-center">
            <img
              class="w-6"
              :src="tipoTarjeta"
              alt="Icono de Sendi"
              v-if="tipoTarjeta.length !== 0"
            />
          </div>
        </label>

        <div class="grid grid-cols-3 gap-3">
          <label class="relative block mb-3 xl:mb-5">
            <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
              <img
                src="../../../../../assets/icons/icon-calendario.outline.svg"
                alt="Icono de Sendi"
              />
            </div>
            <input
              class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
              type="text"
              id="form-checkout__cardExpirationMonth"
              name="cardExpirationMonth"
              maxlength="2"
              v-model="tarjeta.mes"
            />
          </label>

          <label class="relative block mb-3 xl:mb-5">
            <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
              <img
                src="../../../../../assets/icons/icon-calendario.outline.svg"
                alt="Icono de Sendi"
              />
            </div>
            <input
              class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
              type="text"
              id="form-checkout__cardExpirationYear"
              name="cardExpirationYear"
              maxlength="2"
              v-model="tarjeta.year"
            />
          </label>

          <label class="relative block mb-3 xl:mb-5">
            <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
              <img
                src="../../../../../assets/icons/icon-tarjeta-outline.svg"
                alt="Icono de Sendi"
              />
            </div>
            <input
              class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
              type="text"
              id="form-checkout__securityCode"
              name="securityCode"
              maxlength="3"
              v-model="tarjeta.cvv"
            />
            <div
              class="absolute inset-y-0 right-3 xl:right-5 flex items-center"
            >
              <img
                @mouseover="popUp = true"
                @mouseleave="popUp = false"
                class="cursor-pointer"
                width="20"
                height="20"
                src="../../../../../assets/icons/icon-question.svg"
                alt="Icono de Sendi"
              />

              <!-- POPUP -->
              <span
                v-if="popUp"
                class="z-10 absolute bottom-16 -right-12 bg-mainBlue p-4 rounded-2xl w-max transition-all delay-400"
              >
                <img
                  class="w-32 mx-auto"
                  src="../../../../../assets/icons/ccv.svg"
                  alt="Icono de Sendi"
                />
                <span class="text-xs text-white text-center block">
                  El código de seguridad está en la <br />
                  parte posterior de tu tarjeta
                </span>
                <span
                  class="absolute -bottom-7 right-8 w-14 h-8 triangle bg-mainBlue"
                ></span>
              </span>
            </div>
          </label>
        </div>
        <label class="relative block mb-3 xl:mb-5">
          <div class="absolute inset-y-0 left-4 xl:left-5 flex items-center">
            <img
              src="../../../../../assets/icons/icon-user-outline.svg"
              alt="Icono de Sendi"
            />
          </div>
          <input
            class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
            type="text"
            id="form-checkout__cardholderName"
            name="cardholderName"
            v-model="tarjeta.titular"
          />
        </label>

        <label class="relative block mb-3 xl:mb-5">
          <div class="absolute inset-y-0 left-4 xl:left-5 flex items-center">
            <img
              src="../../../../../assets/icons/icon-mail-gray.svg"
              alt="Icono de Sendi"
            />
          </div>
          <input
            class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
            type="text"
            id="form-checkout__cardholderEmail"
            name="cardholderEmail"
            v-model="tarjeta.email"
          />
        </label>
        <label class="relative mb-3 xl:mb-5 hidden">
          <div class="absolute inset-y-0 left-4 xl:left-5 flex items-center">
            <img
              src="../../../../../assets/icons/mail.svg"
              alt="Icono de Sendi"
            />
          </div>
          <select
            name="issuer"
            id="form-checkout__issuer"
            class="cursor-pointer pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
          ></select>
          <div
            class="cursor-pointer absolute right-6 inset-y-0 flex items-center font-bold text-main-red"
          >
            <img
              src="../../../../../assets/icons/drop-down-black.svg"
              alt="Icono de Sendi"
            />
          </div>
        </label>

        <div class="grid grid-cols-2 gap-3">
          <label class="relative block mb-3 xl:mb-5">
            <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
              <img
                src="../../../../../assets/icons/dni.svg"
                alt="Icono de Sendi"
              />
            </div>

            <select
              name="identificationType"
              id="form-checkout__identificationType"
              class="cursor-pointer pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
            ></select>

            <div
              class="absolute inset-y-0 right-3 xl:right-5 flex items-center"
            >
              <img
                width="11"
                height="7"
                src="../../../../../assets/icons/arrow-down.svg"
                alt="Icono de Sendi"
              />
            </div>
          </label>

          <label class="relative block mb-3 xl:mb-5">
            <div class="absolute inset-y-0 left-3 xl:left-5 flex items-center">
              <img
                src="../../../../../assets/icons/icon-tarjeta-outline.svg"
                alt="Icono de Sendi"
              />
            </div>
            <input
              class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl"
              type="text"
              id="form-checkout__identificationNumber"
              name="identificationNumber"
              v-model="tarjeta.documento"
            />
          </label>
        </div>
        <label class="relative block mb-8">
          <select
            name="installments"
            id="form-checkout__installments"
            class="cursor-pointer p-5 input w-full font-semibold bg-white border shadow-sm rounded-2xl text-main-green"
          ></select>
          <div
            class="cursor-pointer absolute right-6 inset-y-0 flex items-center font-bold text-main-red"
          >
            <img
              src="../../../../../assets/icons/drop-down-black.svg"
              alt="Icono de Sendi"
            />
          </div>
        </label>
        <button
          v-if="
            tarjeta.number.length === 0 ||
            tarjeta.mes.length === 0 ||
            tarjeta.year.length === 0 ||
            tarjeta.cvv.length === 0 ||
            tarjeta.titular.length === 0 ||
            tarjeta.email.length === 0 ||
            tarjeta.documento.length === 0
          "
          @click.prevent="validatePaymentForm(1)"
          class="py-5 text-center font-semibold text-white block w-full rounded-2xl bg-gray-400 cursor-pointer"
        >
          Pagar
        </button>
        <button
          v-else-if="terminos === false"
          @click.prevent="validatePaymentForm(2)"
          class="py-5 text-center font-semibold text-white block w-full rounded-2xl bg-gray-400 cursor-pointer"
        >
          Pagar
        </button>

        <button
          v-else
          type="submit"
          id="form-checkout__submit"
          class="py-5 text-center font-semibold text-white block w-full rounded-2xl bg-mainGreenLight cursor-pointer"
        >
          Pagar
        </button>
      </form>
      <div class="absolute inset-x-0 -bottom-4">
        <figure
          @click.prevent="selectPayment"
          class="cursor-pointer mx-auto w-8 h-8 rounded-full bg-white border-2 shadow-lg flex items-center justify-center"
        >
          <img
            src="../../../../../assets/icons/arrow-down-blue.svg"
            :class="form ? 'rotate rotate-180' : ''"
            alt="Icono de Sendi"
          />
        </figure>
      </div>
    </div>
  </span>
</template>
<script>
import preloadComponent from "../../../../preload/preloadComponent.vue";
import { server } from "../../../../../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
export default {
  props: [
    "payment",
    "data",
    "calculoTarifa",
    "terminos",
    "moneda",
    "selectDirection",
  ],
  components: { preloadComponent },
  data() {
    return {
      popUp: false,
      form: false,
      preload: false,
      idUser: 0,
      typeCard: "",
      tipoTarjeta: "",
      formulario: false,
      tarjeta: {
        number: "",
        mes: "",
        year: "",
        cvv: "",
        titular: "",
        email: "",
        documento: "",
      },
    };
  },
  created() {
    this.getIdUser();
  },
  mounted() {
    const formularioMercado = this.mercadoPago();
    formularioMercado.mount();
  },
  unmounted() {
    const formularioMercado = this.mercadoPago();
    formularioMercado.unmount();
  },
  methods: {
    getIdUser() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (data !== null) {
        this.idUser = dataUser.globalID;
      }
    },
    validatePaymentForm(data) {
      if (data === 1) {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Rellenar todo el formulario";
        this.$store.state.alert.estado = true;
        //--------------------------//
      }
      if (data === 2) {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Aceptar términos y condiciones";
        this.$store.state.alert.estado = true;
        //--------------------------//
      }
    },
    mercadoPago() {
      const mpMercado = window.mp;
      const cardForm = mpMercado.cardForm({
        amount: this.totalPaymentSoles,
        autoMount: false,
        form: {
          id: "form-checkout",
          cardholderName: {
            id: "form-checkout__cardholderName",
            placeholder: "Titular de la tarjeta",
          },
          cardholderEmail: {
            id: "form-checkout__cardholderEmail",
            placeholder: "E-mail",
          },
          cardNumber: {
            id: "form-checkout__cardNumber",
            placeholder: "Número de la tarjeta",
          },
          cardExpirationMonth: {
            id: "form-checkout__cardExpirationMonth",
            placeholder: "MM",
          },
          cardExpirationYear: {
            id: "form-checkout__cardExpirationYear",
            placeholder: "AA",
          },
          securityCode: {
            id: "form-checkout__securityCode",
            placeholder: "CVV",
          },
          installments: {
            id: "form-checkout__installments",
            placeholder: "Cuotas",
          },
          identificationType: {
            id: "form-checkout__identificationType",
            placeholder: "Tipo de documento",
          },
          identificationNumber: {
            id: "form-checkout__identificationNumber",
            placeholder: "Número de documento",
          },
          issuer: {
            id: "form-checkout__issuer",
            placeholder: "Banco emisor",
          },
        },
        callbacks: {
          onFormMounted: (error) => {
            if (error)
              return console.warn("Form Mounted handling error: ", error);
          },
          onFormUnmounted: (error) => {
            if (error)
              return console.warn("Form Unmounted handling error: ", error);
          },
          onPaymentMethodsReceived: (error, paymentMethods) => {
            if (error)
              return console.warn("paymentMethods handling error: ", error);
            this.typeCard = paymentMethods[0].payment_type_id;
            this.tipoTarjeta = paymentMethods[0].thumbnail;
          },
          onSubmit: (event) => {
            event.preventDefault();
            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
            } = cardForm.getCardFormData();
            const mercadoPago = JSON.stringify({
              token,
              issuer_id,
              payment_method_id,
              transaction_amount: Number(amount),
              installments: Number(installments),
              description: this.data.producto.nombre,
              payer: {
                email,
                identification: {
                  type: identificationType,
                  number: identificationNumber,
                },
              },
            });
            const data = JSON.parse(mercadoPago);
            this.preparationObjectPurchase(data);
          },
        },
      });
      return cardForm;
    },
    preparationObjectPurchase(data) {
      var objetoOrden = new Object();
      //Datos del producto
      objetoOrden.product_info = this.data.producto.nombre;
      objetoOrden.product_specimens = this.data.producto.cantidad;
      objetoOrden.price = this.data.producto.precio;
      objetoOrden.weight = this.data.producto.peso;
      objetoOrden.weight_unit_key = this.data.producto.unidad;

      //Tiempo de tienda - envios usa o sendi
      objetoOrden.store = "sendi";
      // Tipo de moneda
      objetoOrden.currency = this.moneda;
      //-------------------------//
      this.data.servicio === 1
        ? (objetoOrden.direct_type = 1)
        : (objetoOrden.direct_type = 2);

      //-------------------------//
      if (this.data.servicio === 1) {
        if (this.data.producto.foto.length !== 0) {
          objetoOrden.product_image = this.data.producto.foto;
        }
      }

      if (this.data.servicio === 2) {
        objetoOrden.product_image_url = this.data.producto.foto;
      }
      if (this.data.servicio === 2) {
        objetoOrden.reference_link = this.data.producto.link;
      }
      if (this.$store.state.cuponStatu === true) {
        objetoOrden.coupon_code = this.$store.state.cuponCode;
      }
      if (this.$store.state.saldoStatu) {
        objetoOrden.balance_flag = true;
      } else {
        objetoOrden.balance_flag = false;
      }
      //Metodo de Pago - 5 si es transferencia
      // atm  - 1
      // transferencia-3
      // tarjeta - 4
      // paypal -2
      if (this.payment === 1) {
        objetoOrden.payment_method_flag = 4;
        objetoOrden.mp_payer_email = this.emailUser;
        objetoOrden.mp_payment_method_id = "pagoefectivo_atm";
      }
      if (this.payment === 2) {
        objetoOrden.paypal_order_id = this.paypal.order_id;
        objetoOrden.payment_method_flag = 2;
      }
      if (this.payment === 3) {
        objetoOrden.payment_method_flag = 5;
      }
      if (this.payment === 4) {
        objetoOrden.payment_method_flag = 3;
      }

      // si recoge en oficina es true por lo contrario false
      this.selectDirection.type === 3
        ? (objetoOrden.at_office_flag = true)
        : (objetoOrden.at_office_flag = false);
      //aqui se manda el vaucher
      if (this.data.servicio === 1) {
        if (this.data.producto.vaucher.length !== 0) {
          objetoOrden.voucher = this.data.producto.vaucher;
        }
      }
      //Si el usuario agrego oytro tipo de servicio
      if (this.servicioElegido.length !== 0) {
        var idService = "";
        this.servicioElegido.forEach((value, index) => {
          if (index === 0) {
            idService = idService.concat(value.id);
          } else {
            idService = idService.concat("," + value.id);
          }
        });

        objetoOrden.extra_services_id = idService;
      }
      // si la dirección es una existente
      if (this.selectDirection.type === 1) {
        objetoOrden.shipping_address_id = this.selectDirection.datos.id;
      }

      // si es una nueva dirección
      if (this.selectDirection.type === 2) {
        objetoOrden.shipping_address_name =
          this.selectDirection.datos.direccion;
        objetoOrden.shipping_address_telephone =
          this.selectDirection.datos.telefono;
        objetoOrden.shipping_address_reference =
          this.selectDirection.datos.referencia;
        objetoOrden.shipping_address_inner = this.selectDirection.datos.piso;
        objetoOrden.shipping_address_contact_name =
          this.selectDirection.datos.nombre;
        objetoOrden.shipping_address_contact_lastname =
          this.selectDirection.datos.apellido;
        objetoOrden.shipping_address_document_number =
          this.selectDirection.datos.documento;
        objetoOrden.shipping_address_document_type = 1;
        objetoOrden.shipping_address_district =
          this.selectDirection.datos.distrito;
        objetoOrden.shipping_address_city =
          this.selectDirection.datos.provincia;
        objetoOrden.shipping_address_department =
          this.selectDirection.datos.departamento;
        objetoOrden.shipping_address_alias = "alias";
        objetoOrden.shipping_address_lat = this.selectDirection.datos.lat;
        objetoOrden.shipping_address_lng = this.selectDirection.datos.lng;
      }
      //Datos por pagar con mercado pago
      objetoOrden.mp_payment_from = 1;
      objetoOrden.mp_token_card = data.token;
      objetoOrden.mp_payment_method_id = data.payment_method_id;
      objetoOrden.mp_payer_email = data.payer.email;
      // objetoOrden.mp_customer_id = test.issuer_id;
      objetoOrden.mp_payment_type = this.typeCard;
      objetoOrden.mp_installments = data.installments;
      objetoOrden.mp_issuer_id = data.issuer_id;

      // console.log(objetoOrden);
      this.createOrder(objetoOrden);
    },
    createOrder(objetoOrden) {
      this.preload = true;

      axios
        .post(
          `${BASE_URL}` +
            "purchaseOrder/" +
            this.idUser +
            "/createDirectOrderByClient",
          objetoOrden
        )
        .then((response) => {
          if (response.status === 200) {
            this.preload = false;
            const responseData = response.data.body;
            this.$emit("next", 4);
            this.$emit("purchaseData", responseData);
            this.$emit("paymentMercadoPago");
            window.localStorage.removeItem("datalleProduct");
            window.localStorage.removeItem("shooperProducts");
            window.localStorage.removeItem("producDetail");
            //Setear el Storage
            this.$store.state.cuponStatu = null;
            this.$store.state.cuponCode = "";
            this.$store.state.cuponMonto = null;
            this.$store.state.cuponMontoSoles = null;
            this.$store.state.saldoStatu = false;
            this.$store.state.saldoMonto = 0.0;
            this.$store.state.saldoMontoSoles = 0.0;
            this.$store.state.saldoTotalStatu = false;
            this.$store.commit("resetServicios");
          }
        })
        .catch((error) => {
          if (error) {
            this.preload = false;
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "El pago no se realizó correctamente";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    selectPayment() {
      this.form = !this.form;
      this.$emit("changePayment", 4);
      this.$emit("sendChangePayment");
    },
    changeStatusTotalTrue() {
      this.$store.state.saldoTotalStatu = true;
    },
    changeStatusTotalFalse() {
      this.$store.state.saldoTotalStatu = false;
    },
  },
  computed: {
    servicePrecioSoles() {
      return this.$store.state.servicePrecioSoles;
    },
    servicioElegido() {
      return this.$store.state.servicioElegido;
    },
    montoCuponSoles() {
      return this.$store.state.cuponMontoSoles;
    },
    statusCupon() {
      return this.$store.state.cuponStatu;
    },
    saldoTotalStatus() {
      return this.$store.state.saldoTotalStatu;
    },
    totalPaymentSoles() {
      var total1 = 0.0;
      var total2 = 0.0;
      var total3 = 0.0;
      var total = 0.0;
      if (this.data.servicio === 1) {
        total1 = parseFloat(this.data.soles.primera.frontTotal);
      }
      if (this.data.servicio === 2) {
        total1 = parseFloat(this.data.soles.primera.total);
      }
      total2 = parseFloat(this.data.soles.segundo.total);
      total3 = parseFloat(this.calculoTarifa.soles.totalTarifa);
      total = total1 + total2 + total3;
      if (this.servicioElegido.length !== 0) {
        total = total + this.servicePrecioSoles;
      }
      if (this.$store.state.saldoStatu) {
        total = total - this.$store.state.saldoMontoSoles;
        var signo = Math.sign(total);
        if (signo === -1 || signo === 0) {
          total = 0.0;
          this.changeStatusTotalTrue();
        } else {
          this.changeStatusTotalFalse();
        }
      } else {
        this.changeStatusTotalFalse();
      }
      if (this.statusCupon === true) {
        total = total - this.montoCuponSoles;
      }

      return total.toFixed(2);
    },
  },
  watch: {
    payment: function (val) {
      if (val !== 4) {
        this.form = false;
      }
    },
    totalPaymentSoles: function (val) {
      if (val) {
        this.$emit("ResetFormTarjeta");
      }
    },
  },
};
</script>
