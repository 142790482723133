<template>
  <span v-if="saldoTotalStatus">
    <div
      class="text-text-blue relative ring ring-gray-100 shadow-sm rounded-2xl mb-8 bg-gray-500 bg-opacity-20 cursor-not-allowed"
    >
      <div
        class="rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold"
      >
        <p class="text-xs xl:text-base flex items-center gap-2 xl:gap-4">
          <img
            src="../../../../../assets/icons/transferencia_bancaria.svg"
            class="w-7"
          />
          <span>
            Transferencia bancaria
            <span style="font-size: 10px" class="block text-gray-400"
              >Sin importar cual sea tu banco</span
            >
          </span>
        </p>
        <span
          class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl bg-gray-light text-gray-400"
          >Seleccionar</span
        >
      </div>
    </div>
  </span>
  <!-- //------------------------------------------------// -->
  <span v-else>
    <div
      @click.prevent="selectPayment"
      :class="payment === 3 ? 'ring-mainGreenLight' : 'ring-gray-100'"
      class="text-text-blue relative ring hover:ring-mainGreenLight shadow-sm rounded-2xl mb-8"
    >
      <div
        class="tooltip no-underline border-b rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold cursor-pointer"
      >
        <p class="text-xs xl:text-base flex items-center gap-2 xl:gap-4">
          <img
            src="../../../../../assets/icons/transferencia_bancaria.svg"
            class="w-7"
          />
          <span>
            Transferencia bancaria
            <span style="font-size: 10px" class="block text-gray-400"
              >Sin importar cual sea tu banco</span
            >
          </span>
        </p>
        <span
          :class="
            payment === 3
              ? 'bg-mainGreenLight text-white'
              : 'bg-gray-light text-gray-400'
          "
          class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl"
          >{{ payment === 3 ? "Seleccionado" : "Seleccionar" }}</span
        >
        <span class="tooltip-box font-medium bottom-20"
          >Paga vía transferencia bancaria desde tu banca móvil o banca por
          internet sin importar cual sea tu banco</span
        >
      </div>
      <div class="px-6 py-6" v-if="payment === 3">
        <div class="flex items-start gap-4 xl:gap-8">
          <img
            src="../../../../../assets/icons/interbank-2.svg"
            class="mt-2 w-12 xl:w-auto"
          />
          <p>
            <span id="transferencia-nombre" class="block font-bold">{{ transferenciaNombre }}</span>
            <span id="transferencia-ruc" class="block text-gray-400">{{ transferenciaRUC }}</span>
            <span id="transferencia-cuenta" class="block text-gray-400">{{ transferenciaCuenta }}</span>
            <span id="transferencia-cci" class="block text-gray-400">{{ transferenciaCCI }}</span>
          </p>
          <div class="ml-auto xl:mr-6" @click.prevent="copyPayment">
            <img
              src="../../../../../assets/icons/icon-copiar-2.png"
              class="cursor-pointer mx-auto"
            />
            <span class="text-mainOrange text-xs">Copiar</span>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  props: ["payment", "moneda"],
  methods: {
    copyPayment() {
      const nombre = document.getElementById("transferencia-nombre").textContent;
      const ruc = document.getElementById("transferencia-ruc").textContent;
      const cuenta = document.getElementById("transferencia-cuenta").textContent;
      const cci = document.getElementById("transferencia-cci").textContent;
      const datos = `${nombre}\n${ruc}\n${cuenta}\n${cci}`;
      navigator.clipboard
        .writeText(datos)
        .then(() => {
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description = "Se copió los datos de transferencia con éxito";
          this.$store.state.alert.estado = true;
        })
        .catch((error) => console.log(error));
    },
    selectPayment() {
      this.$emit("changePayment", 3);
      this.$emit("sendChangePayment");
    },
  },
  computed: {
    saldoTotalStatus() {
      return this.$store.state.saldoTotalStatu;
    },
    transferenciaNombre() {
      return "GRUPO CASZA SAC";
    },
    transferenciaRUC() {
      return "20609271044";
    },
    transferenciaCuenta() {
      return this.moneda === "USD"
        ? "Cuenta Corriente: 200-3005545622"
        : "Cuenta Corriente: 200-3005545615";
    },
    transferenciaCCI() {
      return this.moneda === "USD"
        ? "CCI: 003-200-003005545622-39"
        : "CCI: 003-200-003005545615-33";
    },
  },
};
</script>
