<template>
  <header-component />
  <main class="mt-14 xl:mt-24 bg-[#F5F7F9]">
    <section class="section-flujo-compra">
      <div class="u-container">
        <section class="bg-white p-6 xl:p-14 rounded-2xl shadow-xl border">
          <article class="flex gap-10 mb-16">
            <img
              src="../assets/icons/libro_reclamaciones.png"
              class="hidden xl:block"
            />
            <div>
              <h2 class="text-bg-black-blue mb-6">Libro de reclamaciones</h2>
              <p class="font-semibold mb-3">
                SENDIBOX PERU S.A.C. - R.U.C 20606153130
              </p>
              <p class="text-sm">
                Completa la información y revisaremos tu caso. Te contestaremos
                dentro de los próximos 15 días <br />
                hábiles. Para conseguir una respuesta más rápida,
                <a
                  href="https://api.whatsapp.com/send?phone=51968727182&text=Hola%2C%20requiero%20ayuda%20%F0%9F%93%A6%3A%20%20"
                  target="_blank"
                  class="text-mainBlue"
                  >contáctanos a nuestro canal de ayuda</a
                >.
              </p>
            </div>
          </article>
          <form>
            <h3 class="text-bg-black-blue mb-8">Tus datos personales</h3>
            <div class="grid xl:grid-cols-2 gap-6 gap-x-10 mb-14">
              <!-- Nombre y Apellidos: -->
              <div>
                <label
                  for="nombres"
                  class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                  >Nombre y Apellidos:</label
                >
                <input
                  id="nombres"
                  class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                  type="text"
                  placeholder="Nombre y Apellidos"
                  v-model="form.name"
                  :class="error.name ? 'ring ring-mainRed' : ''"
                />
              </div>
              <!-- Documento de identidad: -->
              <div>
                <label
                  for="documento"
                  class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                  >Documento de identidad:</label
                >
                <input
                  id="documento"
                  class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                  type="number"
                  placeholder="DNI ó Pasaporte"
                  v-model="form.document"
                  :class="error.document ? 'ring ring-mainRed' : ''"
                />
              </div>
              <!-- Direccion -->
              <div>
                <label
                  for="direccion"
                  class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                  >Dirección:</label
                >
                <input
                  id="direccion"
                  class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                  type="text"
                  placeholder="Dirección"
                  v-model="form.direction"
                  :class="error.direction ? 'ring ring-mainRed' : ''"
                />
              </div>
              <!-- Telefono -->
              <div>
                <label
                  for="telefono"
                  class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                  >Teléfono:</label
                >
                <input
                  id="telefono"
                  class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                  type="tel"
                  placeholder="+51 000 000 000"
                  v-model="form.phone"
                  :class="error.phone ? 'ring ring-mainRed' : ''"
                />
              </div>
              <!-- Correo electrónico -->
              <div>
                <label
                  for="email"
                  class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                  >Correo electrónico:</label
                >
                <input
                  id="email"
                  class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                  type="email"
                  placeholder="ejemplo@correo.com"
                  v-model="form.email"
                  :class="error.email ? 'ring ring-mainRed' : ''"
                />
              </div>
            </div>
            <h3 class="text-bg-black-blue mb-8">Cuéntanos que pasó...</h3>
            <div class="grid xl:grid-cols-2 gap-6 gap-x-10 mb-14">
              <!-- Elige una opción -->
              <div>
                <label
                  for="opcion"
                  class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                  >Elige una opción:</label
                >
                <div class="relative">
                  <select
                    id="opcion"
                    v-model="form.type"
                    :class="error.type ? 'ring ring-mainRed' : ''"
                    class="cursor-pointer py-6 px-5 input w-full text-sm xl:text-base font-semibold bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl focus:outline-none focus:ring focus:ring-blue-400"
                  >
                    <option selected hidden>¿Qué quieres registrar?</option>
                    <option value="reclamo">Reclamo</option>
                    <option value="queja">Queja</option>
                  </select>
                  <div
                    class="absolute inset-y-0 right-3 xl:right-5 flex items-center"
                  >
                    <img src="../assets/icons/arrow-down-black.svg" />
                  </div>
                </div>
              </div>
              <!-- ¿Por qué producto o servicio reclamas? -->
              <div v-if="form.type === 'reclamo'">
                <label
                  for="producto_servicio"
                  class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                  >¿Por qué producto o servicio reclamas?</label
                >
                <input
                  id="producto_servicio"
                  class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                  type="text"
                  placeholder="Escribe aquí ..."
                  v-model="form.product"
                  :class="error.product ? 'ring ring-mainRed' : ''"
                  v-if="form.type === 'queja'"
                />

                <div class="relative" v-if="form.type === 'reclamo'">
                  <select
                    id="opcion"
                    v-model="form.product"
                    :class="error.product ? 'ring ring-mainRed' : ''"
                    class="cursor-pointer py-6 px-5 input w-full text-sm xl:text-base font-semibold bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl focus:outline-none focus:ring focus:ring-blue-400"
                  >
                    <optgroup
                      class="cursor-not-allowed font-bold"
                      label="Servicios..."
                    >
                      <option value="shopper" class="pl-8">Shopper</option>
                      <option value="courier" class="pl-8">Courier</option>
                    </optgroup>
                    <optgroup
                      class="cursor-not-allowed font-bold"
                      label="Órdenes..."
                    >
                      <option
                        v-for="data in ordenes"
                        :key="data"
                        :value="data.id"
                        class="m-8"
                      >
                        {{ data.id }} - {{ data.attributes.product_info }}
                      </option>
                    </optgroup>
                  </select>
                  <div
                    class="absolute inset-y-0 right-3 xl:right-5 flex items-center"
                  >
                    <img src="../assets/icons/arrow-down-black.svg" />
                  </div>
                </div>
              </div>
              <!-- ¿Cuál fué el problema? -->
              <div>
                <label
                  for="descripcion_problema"
                  class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                  >¿Cuál fué el problema?</label
                >
                <textarea
                  name="descripcion_problema"
                  id="descripcion_problema"
                  placeholder="Escribe aquí ..."
                  rows="7"
                  class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                  v-model="form.problem"
                  :class="error.problem ? 'ring ring-mainRed' : ''"
                ></textarea>
              </div>
              <!-- ¿Qué solución esperas? -->
              <div>
                <label
                  for="descripcion_solucion"
                  class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                  >¿Qué solución esperas?</label
                >
                <textarea
                  name="descripcion_solucion"
                  id="descripcion_solucion"
                  placeholder="Escribe aquí ..."
                  rows="7"
                  class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                  v-model="form.solution"
                  :class="error.solution ? 'ring ring-mainRed' : ''"
                ></textarea>
              </div>
              <!-- Observaciones y acciones adoptadas por Sendi -->
              <div>
                <label
                  for="observaciones"
                  class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                  >Observaciones y acciones adoptadas por
                  <span class="text-mainBlue">Sendi</span></label
                >
                <input
                  id="observaciones"
                  class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl cursor-not-allowed"
                  type="text"
                  placeholder="Escribe aquí ..."
                  v-model="form.obsAccion"
                  :class="error.obsAccion ? 'ring ring-mainRed' : ''"
                  disabled
                />
              </div>
              <!-- Fecha y Hora -->
              <!-- <div class="grid xl:grid-cols-2 gap-6 gap-x-10">
                <div>
                  <label
                    for="fecha"
                    class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                    >Fecha del reclamo</label
                  >
                  <div class="relative">
                    <input
                      id="fecha"
                      class="p-6 input w-full focus:border-blue-400 bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl"
                      type="date"
                      placeholder="dd/mm/aaaa"
                      v-model="form.dateClain"
                      :class="error.dateClain ? 'ring ring-mainRed' : ''"
                    />
                  </div>
                </div>
                <div>
                  <label
                    for="horario"
                    class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                    >Hora del reclamo</label
                  >
                  <div class="relative">
                    <input
                      id="horario"
                      class="p-6 input w-full focus:border-blue-400 bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl"
                      type="text"
                      placeholder="hh:mm"
                      v-model="hour"
                      :class="error.hourClain ? 'ring ring-mainRed' : ''"
                    />
                    <div
                      class="absolute right-6 inset-y-0 flex items-center font-semibold"
                    >
                      <span
                        :class="time === 'am' ? 'text-mainOrange  border-r' : ''"
                        @click.prevent="changeTime('am')"
                        class="cursor-pointer px-3 hover:text-mainOrange transition-colors"
                        >am</span
                      >
                      <span
                        @click.prevent="changeTime('pm')"
                        :class="time === 'pm' ? 'text-mainOrange  border-r' : ''"
                        class="cursor-pointer px-3 hover:text-mainOrange transition-colors"
                        >pm</span
                      >
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- Parrafos y botones -->
              <div>
                <p class="text-xs xl:text-sm mb-4">
                  La formulación del reclamo no impide acudir a otras vías de
                  solución de controversias ni es requisito previo para
                  interponer una denuncia ante el INDECOPI
                </p>
                <p class="text-xs xl:text-sm mb-10">
                  El proveedor debe dar respuesta al reclamo o queja en un plazo
                  no mayor a quince (15) días hábiles, el cual es improrrogable.
                </p>
                <button
                  type="submit"
                  @click.prevent="saveComplaint"
                  class="block xl:w-1/2 w-full p-4 rounded-xl text-white font-semibold text-center bg-mainOrange"
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </section>
  </main>
  <footer-component />
</template>
<script>
import HeaderComponent from "../components/global/headerComponent.vue";
import FooterComponent from "../components/global/footerComponent.vue";
import { server } from "../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  components: { HeaderComponent, FooterComponent },
  data() {
    return {
      ordenes: [],
      time: "am",
      hour: "",
      form: {
        store_id: 1,
        name: "",
        document: "",
        direction: "",
        phone: "",
        email: "",
        type: "reclamo",
        product: "shopper",
        problem: "",
        solution: "",
        obsAccion: "",
        dateClain: "",
        hourClain: "",
      },
      error: {
        name: false,
        document: false,
        direction: false,
        phone: false,
        email: false,
        type: false,
        product: false,
        problem: false,
        solution: false,
        obsAccion: false,
        dateClain: false,
        hourClain: false,
      },
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getUser();
    this.getDateHour();
    this.getOrders();
  },
  methods: {
    getDateHour() {
      var today = new Date();
      var hourData = today.toLocaleTimeString("es-ES");
      var dateData = today.toLocaleDateString("es-ES");
      this.form.hourClain = hourData;
      this.form.dateClain = dateData;
    },
    getOrders() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (dataUser !== null) {
        axios
          .get(
            `${BASE_URL}` +
              "purchaseOrder/" +
              dataUser.globalID +
              `/list-orders-by-client?orderBy=created_at`
          )
          .then((response) => {
            this.ordenes = response.data.data ?? [];
          })
          .catch(() => {
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al mostrar sus órdenes";
            this.$store.state.alert.estado = true;
          });
      }
    },
    getUser() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (dataUser !== null) {
        axios
          .get(`${BASE_URL}` + "client/getById/" + dataUser.globalID)
          .then((response) => {
            if (response.status === 200) {
              var dataUser = response.data.body.data.attributes;

              this.form.name =
                dataUser.profile.attributes.name +
                " " +
                dataUser.profile.attributes.surnames;
              this.form.email =
                dataUser.profile.attributes.userable.attributes.email;
              this.form.document = dataUser.profile.attributes.document_number;
              this.form.phone = dataUser.profile.attributes.cellphone;

              if (dataUser.addresses.length !== 0) {
                this.form.direction =
                  dataUser.addresses[0].attributes.address === null
                    ? ""
                    : dataUser.addresses[0].attributes.address;
              }
            }
          })
          .catch((error) => {
            if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al obtener sus datos";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
    },
    changeTime(value) {
      this.time = value;
    },
    saveComplaint() {
      var resultValidate = this.validateForm();

      if (resultValidate) {
        axios
          .post(`${BASE_URL}` + "create/libroDeReclamaciones", this.form)
          .then((response) => {
            if (response.status === 200) {
              this.$store.state.alert.titulo = "Éxito";
              this.$store.state.alert.description =
                "Su " + this.form.type + " fue registrado";
              this.$store.state.alert.estado = true;
              this.resetForm();
            }
          })
          .catch(() => {
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al registrar su " + this.form.type;
            this.$store.state.alert.estado = true;
          });
      }
    },
    resetForm() {
      this.form.name = "";
      this.form.document = "";
      this.form.direction = "";
      this.form.phone = "";
      this.form.email = "";
      this.form.type = "reclamo";
      this.form.product = "";
      this.form.problem = "";
      this.form.solution = "";
      this.form.obsAccion = "";
      this.form.dateClain = "";
      this.form.hourClain = "";
      this.hour = "";
    },
    validateForm() {
      var val1 = this.valName();

      var val2 = this.valDocument();

      var val3 = this.valDirection();

      var val4 = this.valPhone();

      var val5 = this.valEmail();

      var val6 = this.valType();

      var val7 = this.valProduct();

      var val8 = this.valProblem();

      var val9 = this.valSolution();

      // var val10 = this.valObsAccion();
      var val11 = this.valDateClain();

      var val12 = this.valHourClain();

      if (
        val1 &&
        val2 &&
        val3 &&
        val4 &&
        val5 &&
        val6 &&
        val7 &&
        val8 &&
        val9 &&
        // val10 &&
        val11 &&
        val12
      ) {
        return true;
      } else {
        return false;
      }
    },
    valName() {
      if (this.form.name.length !== 0) {
        this.error.name = false;
        return true;
      } else {
        this.error.name = true;
        return false;
      }
    },
    valDocument() {
      if (this.form.document.length !== 0) {
        this.error.document = false;
        return true;
      } else {
        this.error.document = true;
        return false;
      }
    },
    valDirection() {
      if (this.form.direction.length !== 0) {
        this.error.direction = false;
        return true;
      } else {
        this.error.direction = true;
        return false;
      }
    },
    valPhone() {
      if (this.form.phone.length !== 0) {
        this.error.phone = false;
        return true;
      } else {
        this.error.phone = true;
        return false;
      }
    },
    valEmail() {
      if (this.form.email.length !== 0) {
        var expReg =
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        var resultado = expReg.test(this.form.email);
        if (resultado) {
          this.error.email = false;
          return true;
        } else {
          this.error.email = true;
          return false;
        }
      } else {
        this.error.email = true;
        return false;
      }
    },
    valType() {
      if (this.form.type.length !== 0) {
        this.error.type = false;
        return true;
      } else {
        this.error.type = true;
        return false;
      }
    },
    valProduct() {
      if (this.form.product.length !== 0) {
        this.error.product = false;
        return true;
      } else {
        this.error.product = true;
        return false;
      }
    },
    valProblem() {
      if (this.form.problem.length !== 0) {
        this.error.problem = false;
        return true;
      } else {
        this.error.problem = true;
        return false;
      }
    },
    valSolution() {
      if (this.form.solution.length !== 0) {
        this.error.solution = false;
        return true;
      } else {
        this.error.solution = true;
        return false;
      }
    },
    valObsAccion() {
      if (this.form.obsAccion.length !== 0) {
        this.error.obsAccion = false;
        return true;
      } else {
        this.error.obsAccion = true;
        return false;
      }
    },
    valDateClain() {
      if (this.form.dateClain.length !== 0) {
        this.error.dateClain = false;
        return true;
      } else {
        this.error.dateClain = true;
        return false;
      }
    },
    valHourClain() {
      if (this.form.hourClain.length !== 0) {
        this.error.hourClain = false;
        return true;
      } else {
        this.error.hourClain = true;
        return false;
      }
    },
  },
  watch: {
    "form.name": function () {
      this.error.name = false;
    },
    "form.document": function () {
      this.error.document = false;
    },
    "form.direction": function () {
      this.error.direction = false;
    },
    "form.phone": function () {
      this.error.phone = false;
    },
    "form.email": function () {
      this.error.email = false;
    },
    "form.type": function () {
      this.error.type = false;
    },
    "form.product": function () {
      this.error.product = false;
    },
    "form.problem": function () {
      this.error.problem = false;
    },
    "form.solution": function () {
      this.error.solution = false;
    },
    "form.obsAccion": function () {
      this.error.obsAccion = false;
    },
    "form.dateClain": function () {
      this.error.dateClain = false;
    },
    hour: function () {
      this.error.hourClain = false;
    },
  },
};
</script>
