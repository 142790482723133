<template>
  <article class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
    <h3 class="text-xl xl:text-3xl font-bold text-bg-black-blue mb-10">
      Pedidos
    </h3>
    <div>
      <header
        class="grid xl:grid-cols-8 gap-3 p-5 bg-bg-black text-white font-bold text-xs xl:text-sm rounded-tr-2xl rounded-tl-2xl"
      >
        <p class="text-center xl:hidden">Dashboard</p>
        <p class="hidden xl:block col-span-1"></p>
        <p class="hidden xl:block col-span-3">Descripción</p>
        <p class="hidden xl:block col-span-1">Fecha</p>
        <p class="hidden xl:block col-span-1">Estado</p>
        <p class="hidden xl:block col-span-1">Monto</p>
        <p class="hidden xl:block col-span-1"></p>
      </header>
      <span v-for="(pedido, index) in dataPedidos" :key="index">
        <div
          class="relative grid grid-cols-12 gap-5 xl:gap-3 xl:grid-cols-8 p-5 border-b text-sm xl:text-base items-center"
        >
          <p class="col-span-2 xl:col-span-1">
            <img
              :src="pedido.attributes.product_image_url"
              v-if="pedido.attributes.product_image_url !== null"
              alt="Producto"
              class="h-16 w-auto object-cover"
            />
            <img
              class="object-cover"
              src="../../assets/images/caja.svg"
              alt="Icono de Sendi"
              v-else
            />
          </p>

          <p class="col-span-10 xl:col-span-3 col-start-3 xl:col-start-auto">
            <span
              :class="
                pedido.attributes.direct_type === 1
                  ? 'text-mainOrange'
                  : 'text-mainBlue'
              "
              class="text-xs font-semibold flex items-center gap-3 mb-2 uppercase"
            >
              {{
                pedido.attributes.direct_type === 1
                  ? "Servicio Courier"
                  : "Servicio Shopper"
              }}
              <a
                :href="pedido.attributes.reference_link"
                class="cursor-pointer"
                target="_Blank"
                v-if="pedido.attributes.direct_type === 2"
              >
                <img src="../../assets/icons/ir-al-link.png" alt=""
              /></a>
            </span>
            <span class="mb-2 line-clamp">{{
              pedido.attributes.product_info
            }}</span>
            <span class="flex items-center gap-2">
              <span class="text-xs font-semibold">Canal de sunat:</span>
              <span class="tooltip">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  :style="[
                    pedido.attributes.channel === 'A' ? 'fill: #76e060' : '',
                    pedido.attributes.channel === 'B' ? 'fill: #de3e3e' : '',
                    pedido.attributes.channel === 'C' ? 'fill: #4f5665' : '',
                  ]"
                >
                  <path
                    d="M9 20h6v2H9zm7.906-6.288C17.936 12.506 19 11.259 19 9c0-3.859-3.141-7-7-7S5 5.141 5 9c0 2.285 1.067 3.528 2.101 4.73.358.418.729.851 1.084 1.349.144.206.38.996.591 1.921h-.792v2h8.032v-2h-.79c.213-.927.45-1.719.593-1.925.352-.503.726-.94 1.087-1.363z"
                  ></path>
                </svg>
                <span class="tooltip-box -left-4 bottom-10">
                  <span v-if="pedido.attributes.channel === 'A'"
                    >Canal verde </span
                  ><span v-else-if="pedido.attributes.channel === 'B'"
                    >Canal rojo </span
                  ><span v-else>Por asignar</span>
                </span>
              </span>
            </span>
            <span
              :class="
                pedido.attributes.status == 10
                  ? 'text-main-red bg-main-red'
                  : 'text-mainGreenLight bg-mainGreenLight'
              "
              class="font-semibold text-xs px-2 py-1 rounded-xl bg-opacity-20"
            >
              {{ pedido.attributes.status == 10 ? "Cancelada" : "Activa" }}
            </span>
          </p>
          <p
            class="col-span-10 xl:col-span-1 col-start-3 xl:col-start-auto flex xl:flex-col gap-4 xl:gap-2"
          >
            <span>
              <span class="text-xs font-semibold block">F. compra:</span>
              <span class="text-xs block">{{
                pedido.attributes.fechaCreada
              }}</span>
            </span>
            <span>
              <span class="text-xs font-semibold block">F. entrega:</span>
              <span class="text-xs block">{{
                pedido.attributes.fechaEntrega
              }}</span>
            </span>
          </p>
          <p class="col-span-10 xl:col-span-1 col-start-3 xl:col-start-auto">
            <span class="flex gap-2 mb-3 items-center tooltip no-underline">
              <img src="../../assets/icons/icon-pago-status.png" alt="" />
              <!-- <span
                class="font-semibold text-xs px-2 py-1 rounded-xl text-mainGreenLight bg-mainGreenLight bg-opacity-20"
                >En proceso</span
              > -->
              <span
                :class="[
                  pedido.attributes.payed === 1
                    ? 'text-gray-500 bg-gray-500'
                    : '',
                  pedido.attributes.payed === 2
                    ? 'text-mainOrange bg-mainOrange'
                    : '',
                  pedido.attributes.payed === 3
                    ? 'text-mainGreenLight bg-mainGreenLight'
                    : '',
                  pedido.attributes.payed === 10
                    ? 'text-red-500 bg-red-500'
                    : '',
                  pedido.attributes.payed === 11
                    ? 'text-mainOrange bg-mainOrange'
                    : '',
                  pedido.attributes.payed === 12
                    ? 'text-gray-500 bg-gray-500'
                    : '',
                ]"
                class="font-semibold text-xs px-2 py-1 rounded-xl bg-opacity-20"
              >
                <span v-if="pedido.attributes.payed === 1">PENDIENTE</span>
                <span v-else-if="pedido.attributes.payed === 2"
                  >EN PROCESO</span
                >
                <span v-else-if="pedido.attributes.payed === 3"
                  >CONFIRMADO</span
                >
                <span v-else-if="pedido.attributes.payed === 10"
                  >RECHAZADO"</span
                >
                <span v-else-if="pedido.attributes.payed === 11"
                  >RECLAMADO</span
                >
                <span v-else-if="pedido.attributes.payed === 12"
                  >RETORNADO</span
                >
              </span>
              <span class="tooltip-box">Estado de pago</span>
            </span>
            <span class="flex gap-3 items-center tooltip no-underline">
              <img src="../../assets/icons/icon-tracking-statu.png" alt="" />

              <span
                :class="[
                  pedido.attributes.order_tracking_details[
                    pedido.attributes.order_tracking_details.length - 1
                  ].attributes.status === 1
                    ? 'text-gray-500 bg-gray-500'
                    : '',
                  pedido.attributes.order_tracking_details[
                    pedido.attributes.order_tracking_details.length - 1
                  ].attributes.status === 2
                    ? 'text-mainOrange bg-mainOrange'
                    : '',
                  pedido.attributes.order_tracking_details[
                    pedido.attributes.order_tracking_details.length - 1
                  ].attributes.status === 3
                    ? 'text-mainOrange bg-mainOrange'
                    : '',
                  pedido.attributes.order_tracking_details[
                    pedido.attributes.order_tracking_details.length - 1
                  ].attributes.status === 4
                    ? 'text-mainOrange bg-mainOrange'
                    : '',
                  pedido.attributes.order_tracking_details[
                    pedido.attributes.order_tracking_details.length - 1
                  ].attributes.status === 5
                    ? 'text-mainOrange bg-mainOrange'
                    : '',
                  pedido.attributes.order_tracking_details[
                    pedido.attributes.order_tracking_details.length - 1
                  ].attributes.status === 6
                    ? 'text-mainOrange bg-mainOrange'
                    : '',
                  pedido.attributes.order_tracking_details[
                    pedido.attributes.order_tracking_details.length - 1
                  ].attributes.status === 7
                    ? 'text-mainGreenLight bg-mainGreenLight'
                    : '',
                  pedido.attributes.order_tracking_details[
                    pedido.attributes.order_tracking_details.length - 1
                  ].attributes.status === 8
                    ? 'text-red-500 bg-red-500'
                    : '',
                  pedido.attributes.order_tracking_details[
                    pedido.attributes.order_tracking_details.length - 1
                  ].attributes.status === 9
                    ? 'text-red-500 bg-red-500'
                    : '',
                  pedido.attributes.order_tracking_details[
                    pedido.attributes.order_tracking_details.length - 1
                  ].attributes.status === 10
                    ? 'text-gray-500 bg-gray-500'
                    : '',
                ]"
                class="font-semibold text-xs px-2 py-1 rounded-xl bg-opacity-20"
              >
                <span
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 1
                  "
                >
                  EN REVISIÓN</span
                ><span
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 2
                  "
                >
                  COMPRADO</span
                ><span
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 3
                  "
                >
                  PREPARANDO PARA ENVIAR</span
                ><span
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 4
                  "
                >
                  LISTO PARA ENVIAR</span
                ><span
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 5
                  "
                >
                  ENVIADO</span
                ><span
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 6
                  "
                >
                  LISTO PARA ENTREGAR</span
                ><span
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 7
                  "
                >
                  ENTREGADO</span
                ><span
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 8
                  "
                >
                  SINIESTRADO</span
                ><span
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 9
                  "
                >
                  EN PROCESO DE DEVOLUCIÓN</span
                ><span
                  v-if="
                    pedido.attributes.order_tracking_details[
                      pedido.attributes.order_tracking_details.length - 1
                    ].attributes.status === 10
                  "
                >
                  RETORNADO AL PROVEEDOR</span
                >
              </span>
              <span class="tooltip-box">Estado del tracking</span>
            </span>
          </p>
          <p class="col-span-10 xl:col-span-1 col-start-3 xl:col-start-auto">
            <span class="font-semibold"
              ><span class="text-mainOrange">
                {{ pedido.attributes.currency }}</span
              >
              {{
                (
                  pedido.attributes.total_cost * pedido.attributes.exchange
                ).toFixed(2)
              }}</span
            >
          </p>
          <p
            class="absolute top-5 xl:top-auto right-5 xl:right-auto xl:relative col-span-10 xl:col-span-1 cursor-pointer"
            @click.prevent="acction('tooltip-' + index)"
          >
            <button type="button" class="px-4">
              <img
                src="../../assets/icons/icon-tres-puntos.svg"
                class="cursor-pointer"
              />

              <span
                :id="'tooltip-' + index"
                class="oculto tooltipPedidos absolute bg-white border shadow-lg py-2 -top-2 -left-36 rounded-xl text-left transition-opacity z-10"
              >
                <router-link
                  :to="{
                    name: 'DetallePedido',
                    params: {
                      idPedido: pedido.id,
                    },
                  }"
                  class="flex items-center gap-2 text-xs font-semibold py-3 px-4 w-full transition-colors hover:bg-mainBlue hover:bg-opacity-20"
                >
                  <img src="../../assets/icons/ver-detalles.png" class="w-5" />
                  Ver Detalles
                </router-link>
                <router-link
                  :to="{
                    name: 'Tracking',
                    params: {
                      code: pedido.attributes.system_tracking_number,
                    },
                  }"
                  class="flex items-center gap-2 text-xs font-semibold py-3 px-4 w-full transition-colors hover:bg-mainBlue hover:bg-opacity-20"
                >
                  <img src="../../assets/icons/ver-tracking.png" class="w-5" />
                  Ver Tracking
                </router-link>
                <router-link
                  :to="{
                    name: 'Ayuda',
                  }"
                  class="flex items-center gap-2 text-xs font-semibold py-3 px-4 w-full transition-colors hover:bg-mainBlue hover:bg-opacity-20"
                >
                  <img src="../../assets/icons/ayuda.png" class="w-5" />
                  Ayuda
                </router-link>
              </span>
            </button>
          </p>
        </div>
      </span>
      <p
        class="font-medium text-center pb-8 pt-14"
        v-if="dataPedidos.length === 0"
      >
        Tu historial de pedidos está vacío
      </p>
      <p class="font-medium text-center pb-8 pt-14" v-if="loading">
        Cargando sus pedidos...
      </p>
      <!-- Pagination -->
      <div class="mt-12 flex justify-end items-center gap-12">
        <p>
          Pág.
          <span class="mx-3 py-1 px-3 ring ring-blue-300">{{
            dataTable.from
          }}</span>
          de
          {{ dataTable.page }}
        </p>
        <div class="flex items-center gap-4">
          <figure
            v-if="dataTable.pastStatus"
            @click.prevent="getPedidosPast"
            class="cursor-pointer p-3"
          >
            <img src="../../assets/icons/chevron-left-orange.png" />
          </figure>
          <figure
            v-if="dataTable.nextStatus"
            @click.prevent="getPedidosNext"
            class="cursor-pointer p-3"
          >
            <img src="../../assets/icons/chevron-right-orange.png" />
          </figure>
        </div>
      </div>
    </div>
  </article>
</template>
<script>
import { server } from "../../config/global";
import axios from "axios";
const BASE_URL = server.API_URL;
export default {
  components: {},
  props: ["dataUser"],
  data() {
    return {
      loading: true,
      testId: 0,
      maxIndex: 0,
      minIndex: 0,
      dataPedidos: {},
      dataTable: {
        pagination: 4,
        page: 0,
        from: 0,
        next: "",
        past: "",
        nextStatus: false,
        pastStatus: false,
      },
      pageNext: 1,
      pagePast: 1,
    };
  },
  created() {
    this.getPedidosNext();
  },
  mounted() {},
  methods: {
    acction(idTooltip) {
      const tooltips = [...document.querySelectorAll(".tooltipPedidos")];
      const tooltipAbierto = tooltips.find(
        (tooltip) => !tooltip.matches(".oculto")
      );
      if (tooltipAbierto && !tooltipAbierto.matches(`#${idTooltip}`)) {
        tooltipAbierto.classList.add("oculto");
      }

      const tooltip = document.getElementById(idTooltip);
      tooltip.classList.toggle("oculto");
    },
    getPedidosNext() {
      axios
        .get(
          `${BASE_URL}` +
            "purchaseOrder/" +
            this.dataUser.id +
            `/list-orders-by-client?orderBy=created_at&length=${this.dataTable.pagination}&page=${this.pageNext}`
        )
        .then((response) => {
          this.loading = false;
          this.dataPedidos = response.data.data;
          this.dataTable.page = response.data.meta.last_page;
          this.dataTable.from = response.data.meta.current_page;
          this.dataTable.next = response.data.links.next;
          this.dataTable.past = response.data.links.prev;
          if (this.dataTable.next !== null) {
            let separator = this.dataTable.next.split("&page=");
            this.pageNext = separator[1];
            this.dataTable.nextStatus = true;
          } else {
            this.dataTable.nextStatus = false;
          }
          if (this.dataTable.past !== null) {
            let separator = this.dataTable.past.split("&page=");
            this.pagePast = separator[1];
            this.dataTable.pastStatus = true;
          } else {
            this.dataTable.pastStatus = false;
          }
          var max = this.maxIndex;
          var length = this.dataTable.pagination;

          this.dataPedidos.forEach((value, index) => {
            if (index === 0) {
              this.minIndex = max; // 0 4 8 12
            }

            max += 1;

            value.Idproducto = max;

            if (length === index + 1) {
              this.maxIndex = max; // 4 8 12 16
            }

            let fechaCreada = new Date(value.attributes.order_create_date);
            let diaCreada = fechaCreada.getDate();
            let mesCreada = fechaCreada.getMonth() + 1;
            let yearCreada = fechaCreada.getFullYear();
            value.attributes.fechaCreada =
              diaCreada + " / " + mesCreada + " / " + yearCreada;

            let fechaEntrega = new Date(
              value.attributes.promised_delivery_date
            );
            let diaEntrega = fechaEntrega.getDate() + 1;
            let mesEntrega = fechaEntrega.getMonth() + 1;
            let yearEntrega = fechaEntrega.getFullYear();
            value.attributes.fechaEntrega =
              diaEntrega + " / " + mesEntrega + " / " + yearEntrega;
          });
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "No se pudo mostrar su informacion";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    getPedidosPast() {
      axios
        .get(
          `${BASE_URL}` +
            "purchaseOrder/" +
            this.dataUser.id +
            `/list-orders-by-client?orderBy=created_at&length=${this.dataTable.pagination}&page=${this.pagePast}`
        )
        .then((response) => {
          this.dataPedidos = response.data.data;
          this.dataTable.page = response.data.meta.last_page;
          this.dataTable.from = response.data.meta.current_page;
          this.dataTable.next = response.data.links.next;
          this.dataTable.past = response.data.links.prev;
          if (this.dataTable.next !== null) {
            let separator = this.dataTable.next.split("&page=");
            this.pageNext = separator[1];
            this.dataTable.nextStatus = true;
          } else {
            this.dataTable.nextStatus = false;
          }
          if (this.dataTable.past !== null) {
            let separator = this.dataTable.past.split("&page=");
            this.pagePast = separator[1];
            this.dataTable.pastStatus = true;
          } else {
            this.dataTable.pastStatus = false;
          }
          var min = this.minIndex - this.dataTable.pagination; //0 - 4 - 8 - 12
          this.minIndex = min;
          var length = this.dataTable.pagination;
          this.dataPedidos.forEach((value, index) => {
            min += 1;
            value.Idproducto = min;

            if (length === index + 1) {
              this.maxIndex = min; // 4
            }
            let fechaCreada = new Date(value.attributes.order_create_date);
            let diaCreada = fechaCreada.getDate();
            let mesCreada = fechaCreada.getMonth() + 1;
            let yearCreada = fechaCreada.getFullYear();
            value.attributes.fechaCreada =
              diaCreada + " / " + mesCreada + " / " + yearCreada;

            let fechaEntrega = new Date(
              value.attributes.promised_delivery_date
            );
            let diaEntrega = fechaEntrega.getDate() + 1;
            let mesEntrega = fechaEntrega.getMonth() + 1;
            let yearEntrega = fechaEntrega.getFullYear();
            value.attributes.fechaEntrega =
              diaEntrega + " / " + mesEntrega + " / " + yearEntrega;
          });
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "No se pudo mostrar su informacion";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    detailTracking(system_tracking_number) {
      this.$router.push({
        name: "Tracking",
        params: { trackingCode: system_tracking_number },
      });
    },
  },
  computed: {},
};
</script>
