<template>
  <div
    :class="status ? '' : 'oculto'"
    class="fixed h-screen inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center transition-opacity delay-400"
  >
    <div
      class="mx-4 bg-white px-8 py-24 xl:py-52 xl:px-36 rounded-2xl relative"
    >
      <div>
        <h2 class="text-2xl xl:text-4xl text-center font-semibold mb-6">
          Agrega tu celular
        </h2>
        <p class="mb-12 text-center text-sm xl:text-base">
          Por favor agrega un número de celular.
        </p>
        <label for="cel" class="block mb-2 text-xs xl:text-sm font-semibold"
          >Número de celular</label
        >
        <div class="flex gap-4">
          <input
            type="text"
            id="cel"
            class="block w-16 p-4 rounded-md border shadow-md mb-8"
            placeholder="+51"
            disabled
          />
          <input
            type="tel"
            v-model="cellphone"
            id="cel"
            class="block w-full p-4 rounded-md border shadow-md mb-8"
            placeholder="999 999 999"
          />
        </div>

        <button
          @click.prevent="updateCellphone"
          class="w-full block bg-mainBlue py-6 text-white font-bold rounded-2xl cursor-pointer"
        >
          Continuar
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { server } from "../../../config/global";
import axios from "axios";
const BASE_URL = server.API_URL;
export default {
  data() {
    return {
      status: false,
      idUser: null,
      cellphone: "",
    };
  },
  mounted() {
    const statusModalCellphone = localStorage.getItem("completecellphone");
    const dataUser = localStorage.getItem("auth");
    const data = JSON.parse(dataUser);
    if (data && statusModalCellphone === "true") {
      this.status = true;
      this.idUser = data.globalID;
    } else {
      this.status = false;
    }
  },
  methods: {
    updateCellphone() {
      if (this.cellphone.length > 0 && this.idUser !== null) {
        axios
          .post(`${BASE_URL}` + "client/update-cellphone-client", {
            client_id: this.idUser,
            cellphone: "+51" + this.cellphone,
          })
          .then(() => {
            this.status = false;
            localStorage.removeItem("completecellphone");
          })
          .catch(() => {
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al actualizar su celular";
            this.$store.state.alert.estado = true;
            this.status = false;
          });
      }
    },
  },
};
</script>
