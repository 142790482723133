<template>
  <div
    v-if="estado"
    id="menu"
    class="oculto fixed inset-0 z-50 bg-black bg-opacity-50 transition-all"
  >
    <div
      class="oculto-izquierda flex flex-col justify-between overflow-y-auto xl:overflow-hidden px-6 py-2 w-[300px] xl:w-[320px] h-screen bg-[#FAFDFF]"
    >
      <div class="">
        <div class="rounded-2xl overflow-hidden border shadow-sm mb-4">
          <div
            class="rounded-2xl px-3 py-2 pr-4 bg-bg-black-blue flex items-center justify-between cursor-pointer"
            @click.prevent="changeSelectEstado(1)"
          >
            <img
              v-if="dataUser.globalImage === null"
              src="../../../assets/images/user-example.svg"
              class="mr-3 rounded-full h-[60px] w-[60px] object-cover"
              alt="Perfil por default de comprardor en Sendi"
            />

            <img
              v-else
              :src="dataUser.globalImage"
              class="mr-3 rounded-full h-[60px] w-[60px] object-cover"
              alt="Perfil de comprardor en Sendi"
            />

            <div class="w-full flex items-center justify-between">
              <span class="font-bold text-white">{{
                dataUser.globalName.split(" ")[0]
              }}</span>
              <img
                :class="
                  select.user ? 'transform rotate-180 transition-transform' : ''
                "
                class="cursor-pointer"
                width="11"
                height="7"
                src="../../../assets/icons/arrow-down-white.svg"
                alt="Icono de Sendi"
              />
            </div>
          </div>
          <div
            :class="select.user ? '' : 'hidden'"
            class="bg-white text-gray-400 text-sm py-4 px-8 font-semibold"
          >
            <router-link
              :to="{ name: 'Perfil', params: { vista: 'perfil' } }"
              class="block"
              @click.prevent="closeNav"
              >Mi Perfil</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'Perfil', params: { vista: 'credenciales' } }"
              class="flex items-center justify-between"
              @click.prevent="closeNav"
            >
              <span>Credenciales</span>
              <span
                v-if="dataUser.globalValidate === false"
                class="h-6 w-6 rounded-full flex items-center justify-center bg-red-500 text-white font-bold"
              >
                1
              </span>
            </router-link>
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'Perfil', params: { vista: 'direccion' } }"
              class="block"
              @click.prevent="closeNav"
              >Mis direcciones</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'Perfil', params: { vista: 'balance' } }"
              class="block"
              @click.prevent="closeNav"
              >Mi saldo</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'Perfil', params: { vista: 'cupones' } }"
              class="block"
              @click.prevent="closeNav"
              >Cupones</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'Perfil', params: { vista: 'cotizaciones' } }"
              class="block"
              @click.prevent="closeNav"
              >Cotizaciones</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'Perfil', params: { vista: 'pedidos' } }"
              class="block"
              @click.prevent="closeNav"
              >Mis pedidos</router-link
            >

            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              :to="{ name: 'Perfil', params: { vista: 'recomendados' } }"
              class="block"
              @click.prevent="closeNav"
              >Afiliados</router-link
            >
          </div>
        </div>

        <div class="block rounded-2xl overflow-hidden border shadow-sm mb-4">
          <div
            @click.prevent="changeSelectEstado(2)"
            class="border-b-2 shadow-sm rounded-2xl py-6 px-8 text-sm bg-white flex items-center justify-between cursor-pointer"
          >
            <span class="font-semibold mr-4">Nuestros Servicios</span>
            <img
              :class="
                select.servicio
                  ? 'transform rotate-180 transition-transform'
                  : ''
              "
              class="cursor-pointer"
              width="11"
              height="7"
              src="../../../assets/icons/arrow-down.svg"
              alt="Icono de Sendi"
            />
          </div>
          <div
            :class="select.servicio ? '' : 'hidden'"
            class="bg-white text-gray-400 text-sm py-4 px-8 font-semibold"
          >
            <router-link
              @click.prevent="changeService(1)"
              @contextmenu="menuService(1)"
              :to="{ name: 'Home' }"
              class="block"
              >Servicio Courier</router-link
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <router-link
              @click.prevent="changeService(2)"
              @contextmenu="menuService(2)"
              :to="{ name: 'Home' }"
              class="block"
              >Servicio Shopper</router-link
            >
          </div>
        </div>

        <div class="block rounded-2xl overflow-hidden border shadow-sm mb-4">
          <div
            @click.prevent="changeSelectEstado(3)"
            class="border-b-2 shadow-sm rounded-2xl py-6 px-8 text-sm bg-white flex items-center justify-between cursor-pointer"
          >
            <span class="font-semibold mr-4">Soporte</span>
            <img
              :class="
                select.soporte
                  ? 'transform rotate-180 transition-transform'
                  : ''
              "
              class="cursor-pointer"
              width="11"
              height="7"
              src="../../../assets/icons/arrow-down.svg"
              alt="Icono de Sendi"
            />
          </div>
          <div
            :class="select.soporte ? '' : 'hidden'"
            class="bg-white text-gray-400 text-sm py-4 px-8 font-semibold"
          >
            <a
              href="https://sendi.freshdesk.com/support/solutions/folders/73000349133"
              target="_blank"
              class="block"
              >Preguntas frecuentes</a
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <a
              href="https://sendi.freshdesk.com/support/home"
              target="_blank"
              class="block"
              >Base de conocimiento</a
            >
            <hr class="bg-gray-200 mt-3 mb-5" />
            <a href="https://wa.link/kd6xi9" target="_blank" class="block"
              >Contáctanos</a
            >
          </div>
        </div>

        <div
          @click.prevent="openTracking"
          class="block rounded-2xl overflow-hidden border shadow-sm mb-4 cursor-pointer"
        >
          <div
            class="py-6 px-8 text-sm bg-white flex items-center justify-between"
          >
            <span class="font-semibold mr-4">Tracking</span>
            <img
              class="cursor-pointer"
              width="7"
              height="11"
              src="../../../assets/icons/arrow-right-gray.svg"
              alt="Icono de Sendi"
            />
          </div>
        </div>
        <div class="flex gap-5 mb-4">
          <a href="https://www.instagram.com/sendi.envios/" target="Blank">
            <img
              width="50"
              height="50"
              src="../../../assets/icons/icon-instagram.svg"
              class="cursor-pointer w-8 xl:w-10"
              alt="Icono de Sendi"
            />
          </a>
          <a href="https://www.facebook.com/sendi.envios" target="Blank">
            <img
              width="50"
              height="50"
              src="../../../assets/icons/icon-facebook.svg"
              class="cursor-pointer w-8 xl:w-10"
              alt="Icono de Sendi"
            />
          </a>
        </div>
      </div>

      <div
        @click.prevent="closeSesion"
        class="flex items-center gap-5 p-4 xl:mb-6 cursor-pointer bg-main-red text-white rounded-2xl"
      >
        <img
          class="cursor-pointer"
          src="../../../assets/icons/cerrar-sesion-white.svg"
          alt="Icono de Sendi"
        />
        <span class="font-semibold">Cerrar Sesión</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["dataUser", "estado"],
  data() {
    return {
      select: {
        user: true,
        servicio: false,
        soporte: false,
      },
    };
  },
  methods: {
    openTracking() {
      this.$emit("openTracking", true);
    },
    closeNav() {
      const menu = document.getElementById("menu");
      const ocultar = (element) => {
        element.classList.remove("visible");
        element.classList.add("oculto");
      };
      ocultar(menu);
      setTimeout(() => {
        menu.firstElementChild.classList.add("oculto-izquierda");
      }, 1);
    },
    menuService(data) {
      window.localStorage.setItem("servicio", data);
    },
    changeService(data) {
      if (this.currentRouteName === "Home") {
        this.$emit("changeService", data);
      } else {
        window.localStorage.setItem("servicio", data);
      }
    },
    closeSesion() {
      if (
        this.currentRouteName === "DetallePedido" ||
        this.currentRouteName === "Perfil"
      ) {
        window.localStorage.removeItem("datalleProduct");
        window.localStorage.removeItem("shooperProducts");
        window.localStorage.removeItem("direction");
        window.localStorage.removeItem("auth");
        this.$router.push({ name: "Home" });
      } else {
        window.localStorage.removeItem("datalleProduct");
        window.localStorage.removeItem("shooperProducts");
        window.localStorage.removeItem("direction");
        window.localStorage.removeItem("auth");
        location.reload();
      }
      window.localStorage.removeItem("completecellphone");
    },
    changeSelectEstado(data) {
      if (data === 1) {
        this.select.user = !this.select.user;
        this.select.servicio = false;
        this.select.soporte = false;
      }
      if (data === 2) {
        this.select.servicio = !this.select.servicio;
        this.select.user = false;
        this.select.soporte = false;
      }
      if (data === 3) {
        this.select.soporte = !this.select.soporte;
        this.select.user = false;
        this.select.servicio = false;
      }
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
