<template>
  <header-component @changeService="service = $event" :typeService="service" />

  <main class="mt-14 xl:mt-24">
    <slide-service-component
      @changeService="service = $event"
      :typeService="service"
    />

    <compras-component />
    <pasos-component />
    <buscar-component />
  </main>

  <footer-component />
</template>

<script>
import headerComponent from "../components/global/headerComponent.vue";
import footerComponent from "../components/global/footerComponent.vue";
import slideServiceComponent from "../components/home/slideServiceComponent.vue";
import pasosComponent from "../components/home/pasosComponent.vue";
import comprasComponent from "../components/home/comprasComponent.vue";

import buscarComponent from "../components/home/buscarComponent.vue";
export default {
  components: {
    headerComponent,
    footerComponent,
    slideServiceComponent,
    pasosComponent,
    comprasComponent,
    buscarComponent,
  },
  data() {
    return {
      service: 1,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    //Borrado del localStorage
    window.localStorage.removeItem("datalleProduct");
    window.localStorage.removeItem("shooperProducts");
    window.localStorage.removeItem("producDetail");
    //-------------------------//
    const data = localStorage.getItem("servicio");
    if (data !== null) {
      this.service = parseInt(data);
    }
  },
  methods: {},
};
</script>
