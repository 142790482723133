<template>
  <header-component />

  <main class="min-h-screen flex bg-gray-light gap-10 mt-14 xl:mt-24">
    <opcionesComponent
      :formSelect="formSelect"
      :img="data.img"
      :id="data.id"
      :dni="data.validateDni"
    />
    <section class="u-container py-6 xl:py-10 xl:w-3/4 xl:mr-8 xl:ml-0">
      <perfil-component
        :id="data.id"
        :identificador="data.identificador"
        @cambio="formSelect = $event"
        v-if="formSelect === 'perfil'"
      />
      <delete-count-component
        v-if="formSelect === 'deleteCount'"
        :id="data.id"
        @cambio="formSelect = $event"
      />
      <span v-show="formSelect === 'credenciales'">
        <credenciales-component
          :id="data.id"
          :dni="data.validateDni"
          :verifytelefono="verify.telefono"
          :name="data.name"
          :correo="data.email"
          :type="data.type"
          :numberDni="data.dni"
          :identificador="data.identificador"
          :telefono="data.telefono"
          :verify="verify"
          @cambio="formSelect = $event"
        />
      </span>

      <dni-component
        :id="data.id"
        v-if="formSelect === 'verification'"
        @changeSection="formSelect = $event"
      />
      <recomendado-component
        v-if="formSelect === 'recomendados'"
        :id="data.id"
      />
      <cupones-component
        v-if="formSelect === 'cupones'"
        :dni="data.validateDni"
      />
      <pedidos-component v-if="formSelect === 'pedidos'" :dataUser="data" />
      <cotizaciones-component
        v-if="formSelect === 'cotizaciones'"
        :id="data.id"
      />
      <balance-component v-if="formSelect === 'balance'" :id="data.id" />
      <direccion-component
        v-if="formSelect === 'direccion'"
        :dataUser="data"
        :id="data.id"
      />
    </section>
  </main>

  <!--   
  <section class="bg-white xl:bg-bgGray">
    <div class="u-container xl:grid grid-cols-12 gap-4 items-start">
      <opcionesComponent
        :formSelect="formSelect"
        :img="data.img"
        :id="data.id"
        :dni="data.validateDni"
      />
      <perfil-component
        :id="data.id"
        :identificador="data.identificador"
        v-if="formSelect === 'perfil'"
      />
      <credenciales-component
        :id="data.id"
        :dni="data.validateDni"
        :name="data.name"
        :correo="data.email"
        :type="data.type"
        v-if="formSelect === 'credenciales'"
        @cambio="formSelect = $event"
      />
      <dni-component :id="data.id" v-if="formSelect === 'verification'" />
      <direccion-component v-if="formSelect === 'direccion'" :dataUser="data" />
      <pedidos-component v-if="formSelect === 'pedidos'" :dataUser="data" />
      <cupones-component v-if="formSelect === 'cupones'" :dni="data.validateDni" />
      <cotizaciones-component v-if="formSelect === 'cotizaciones'" :id="data.id" />
      <balance-component v-if="formSelect === 'balance'" :id="data.id" />
      <recomendado-component v-if="formSelect === 'recomendados'" :id="data.id" />
    </div>
  </section> -->

  <footer-component />
</template>
<script>
import headerComponent from "../components/global/headerComponent.vue";
import footerComponent from "../components/global/footerComponent.vue";
import opcionesComponent from "../components/perfil/opcionesComponent.vue";
import perfilComponent from "../components/perfil/perfilComponent.vue";
import credencialesComponent from "../components/perfil/credencialesComponent.vue";
import dniComponent from "../components/perfil/dniComponent.vue";
import direccionComponent from "../components/perfil/direccionComponent.vue";
import pedidosComponent from "../components/perfil/pedidosComponent.vue";
import cuponesComponent from "../components/perfil/cuponesComponent.vue";
import cotizacionesComponent from "../components/perfil/cotizacionesComponent.vue";
import balanceComponent from "../components/perfil/balanceComponent.vue";
import recomendadoComponent from "../components/perfil/recomendadoComponent.vue";
import deleteCountComponent from "../components/perfil/deleteCountComponent.vue";

import { server } from "../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  components: {
    headerComponent,
    footerComponent,
    opcionesComponent,
    perfilComponent,
    credencialesComponent,
    dniComponent,
    direccionComponent,
    pedidosComponent,
    cuponesComponent,
    cotizacionesComponent,
    balanceComponent,
    recomendadoComponent,
    deleteCountComponent,
  },
  data() {
    return {
      formSelect: this.$route.params.vista,
      data: {
        name: "",
        lastname: "",
        img: "",
        id: 0,
        email: "",
        telefono: "",
        validateDni: false,
        identificador: "",
        type: 0,
        dni: "",
      },
      verify: {
        dni: null,
        email: null,
        telefono: null,
      },
    };
  },
  created() {
    this.getDataLocalStorage();
  },
  mounted() {
    window.scrollTo(0, 0);
    window.localStorage.removeItem("datalleProduct");
    window.localStorage.removeItem("shooperProducts");
    window.localStorage.removeItem("producDetail");
  },
  methods: {
    //obteniendo los datos del localstorage para la imagen y datos del usuario
    getDataLocalStorage() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (dataUser !== null) {
        this.data.id = dataUser.globalID;
        this.getUser();
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description =
          "Vuelva a iniciar sesión por favor";
        this.$store.state.alert.estado = true;
        //--------------------------//
        this.$router.push({ name: "Home" });
        window.localStorage.removeItem("auth");
      }
    },

    getUser() {
      axios
        .get(`${BASE_URL}` + "client/getById/" + this.data.id)
        .then((response) => {
          if (response.status === 200) {
            var dataUser = response.data.body.data.attributes;
            this.data.dni = dataUser.profile.attributes.document_number;
            this.data.img =
              dataUser.profile.attributes.userable.attributes.profile_image_url;
            this.data.name =
              dataUser.profile.attributes.name === null
                ? ""
                : dataUser.profile.attributes.name;

            this.data.validateDni = dataUser.validation.verified;
            this.data.email =
              dataUser.profile.attributes.userable.attributes.email;

            if (dataUser.profile.attributes.cellphone) {
              const separed =
                dataUser.profile.attributes.cellphone.split("+51");
              const options = {
                style: "decimal",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                minimumIntegerDigits: 9,
              };
              const formattedNumber = parseInt(separed[1]).toLocaleString(
                "es-ES",
                options
              ); // "123 456 789"
              this.data.telefono = formattedNumber.replace(/\./g, " ");
            } else {
              this.data.telefono = dataUser.profile.attributes.cellphone;
            }

            this.data.identificador = response.data.body.data.client_code;
            this.data.lastname =
              dataUser.profile.attributes.surnames === null
                ? ""
                : dataUser.profile.attributes.surnames;

            if (dataUser.profile.attributes.userable.attributes.facebook_id) {
              this.data.type = 3;
            } else if (
              dataUser.profile.attributes.userable.attributes.gmail_id
            ) {
              this.data.type = 2;
            } else {
              this.data.type = 1;
            }

            this.verify.dni = dataUser.validation.verified;
            this.verify.email = dataUser.email_verified;
            this.verify.telefono =
              dataUser.profile.attributes.cellphone_verified;
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al obtener sus datos";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
  },
  watch: {
    "$route.params.vista": function (val) {
      this.formSelect = val;
    },
  },
};
</script>
