<template>
  <header-component />
  <main class="mt-14 xl:mt-24">
    <section class="section-flujo-compra">
      <article class="u-container py-14 grid xl:grid-cols-2 xl:gap-8">
        <!-- Bloque de la izquierda -->
        <div>
          <div
            class="xl:hidden mb-10 bg-white border grid grid-cols-2 items-center rounded-2xl shadow-sm overflow-hidden"
          >
            <p
              id="btn-notificaciones"
              class="cursor-pointer text-center py-4 text-sm font-bold rounded-2xl bg-mainBlue text-white"
            >
              Notificaciones
            </p>
            <p
              id="btn-mapa"
              class="cursor-pointer text-center py-4 text-sm font-bold rounded-2xl"
            >
              Mapa
            </p>
          </div>

          <!-- Bandeja de notificaciones -->
          <data-component
            :tracking="tracking"
            :fechaEntrega="fechaEntrega"
            @seeMarkerLat="center.lat = $event"
            @seeMarkerLng="center.lng = $event"
          />
        </div>
        <!-- Bloque de la derecha -->
        <mapa-component :center="center" :markers="markers" />
      </article>
    </section>
  </main>
  <footer-component />
</template>
<script>
import headerComponent from "../components/global/headerComponent.vue";
import footerComponent from "../components/global/footerComponent.vue";
import dataComponent from "../components/tracking/dataComponent.vue";
import mapaComponent from "../components/tracking/mapaComponent.vue";

import { server } from "../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  components: {
    headerComponent,
    footerComponent,
    dataComponent,
    mapaComponent,
  },
  data() {
    return {
      fechaEntrega: "",
      center: { lat: 0, lng: 0 },
      markers: [],
      codeTracking: this.$route.params.code,
      tracking: [],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    window.localStorage.removeItem("datalleProduct");
    window.localStorage.removeItem("shooperProducts");
    window.localStorage.removeItem("producDetail");

    this.getDataTracking();
    const mapaBtn = document.getElementById("btn-mapa"),
      notificacionesBtn = document.getElementById("btn-notificaciones"),
      notificaciones = document.getElementById("notificaciones"),
      mapa = document.getElementById("mapa");

    notificacionesBtn.addEventListener("click", () => {
      notificacionesBtn.classList.add("bg-mainBlue", "text-white");
      mapaBtn.classList.remove("bg-mainBlue", "text-white");
      mapa.classList.add("hidden");
      notificaciones.classList.remove("hidden");
    });

    mapaBtn.addEventListener("click", () => {
      mapaBtn.classList.add("bg-mainBlue", "text-white");
      notificacionesBtn.classList.remove("bg-mainBlue", "text-white");
      notificaciones.classList.add("hidden");
      mapa.classList.remove("hidden");
    });
  },
  methods: {
    newCode() {
      this.tracking = [];
      this.markers = [];
      this.center.lat = 0;
      this.center.lng = 0;
    },
    //Obtener los datos del código de tracking que ingreos el usuario
    getDataTracking() {
      this.newCode();
      axios
        .get(
          `${BASE_URL}` +
            "package/" +
            this.codeTracking.trim() +
            "/getSystemTracks"
        )
        .then((response) => {
          if (response.status === 200) {
            const data =
              response.data.body.order_data.order_system_tracks.reverse();
            // const codetracking = response.data.body.tracking_numer;
            this.fechaEntrega = response.data.body.delivery_date;

            this.changeDataTracking(data);
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Código de tracking no encontrado";
            this.$store.state.alert.estado = true;
            //--------------------------//

            this.$router.push({
              name: "Home",
            });
          }
        });
    },
    //Se reciba la data de la respuesta del servicio y se transforma para mostrarlo
    changeDataTracking(data) {
      const MESES = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];
      const dataTracking = data;
      var lengthdataTracking = dataTracking.length;
      dataTracking.forEach((value, index) => {
        //Declaración del objeto a crear para almacenar los datos que de van enviar
        var objTracking = new Object();
        //se obtiene el status y se le suma uno y ala vez se obtiene obtiene la ultima notificación colocandole true al resto false
        objTracking.number = index + 1;
        objTracking.statusNotification = value.status;
        // objTracking.code = codetracking;

        if (lengthdataTracking === index + 1) {
          objTracking.status = true;
        } else {
          objTracking.status = false;
        }

        //Obtenemos el atrituo o detalle del paquete
        // if (value.attributes.system_location !== null) {
        //   objTracking.atributos = value.attributes.system_location.attributes.address;
        // } else {
        //   objTracking.atributos = "";
        // }

        //Obtenemos latitud y longitus y si no tiene se les coloca null
        // if (value.attributes.system_location !== null) {
        //   objTracking.latitud = value.attributes.system_location.attributes.latitude;
        //   objTracking.longitud = value.attributes.system_location.attributes.longitude;
        // } else {
        //   objTracking.latitud = null;
        //   objTracking.longitud = null;
        // }
        //Convertimos la fecha y les damos formato
        let fecha = new Date(value.date_time);
        let monthText = MESES[fecha.getMonth()];
        let hora = fecha.getHours();
        let minutos = fecha.getMinutes();

        var ampm = hora >= 12 ? "pm" : "am";
        hora = hora % 12;
        hora = hora ? hora : 12; // the hour '0' should be '12'
        minutos = minutos < 10 ? "0" + minutos : minutos;
        objTracking.fecha =
          fecha.getDate() +
          " de " +
          monthText +
          " a las " +
          hora +
          ":" +
          minutos +
          " " +
          ampm;
        this.tracking.push(objTracking);
      });

      //----------Rellenamos el arreglo de marker----------------------------//
      //Filtramos los que tengan system_location null
      // var dataMarker;
      // dataMarker = dataTracking.filter(function (item) {
      //   if (item.attributes.system_location !== null) {
      //     return item;
      //   }
      // });
      // dataMarker.reverse();
      // var ultimaNotification = dataMarker.length;

      //   if (ultimaNotification !== 0) {
      //     dataMarker.forEach((value, index) => {
      //       var objMarker = new Object();
      //       objMarker.id = value.status;
      //       if (ultimaNotification === index + 1) {
      //         objMarker.estado = true;
      //         this.center.lat = value.attributes.system_location.attributes.latitude;
      //         this.center.lng = value.attributes.system_location.attributes.longitude;
      //       } else {
      //         objMarker.estado = false;
      //       }
      //       var puntosMarker = new Object();
      //       puntosMarker.lat = value.attributes.system_location.attributes.latitude;
      //       puntosMarker.lng = value.attributes.system_location.attributes.longitude;
      //       objMarker.position = puntosMarker;
      //       this.markers.push(objMarker);
      //     });
      //   } else {
      //     this.center.lat = -12.080709424755002;
      //     this.center.lng = -77.03609653154945;
      //     var objMarker = new Object();
      //     objMarker.id = "oficina";
      //     objMarker.estado = true;
      //     var puntosMarker = new Object();
      //     puntosMarker.lat = -12.080709424755002;
      //     puntosMarker.lng = -77.03609653154945;
      //     objMarker.position = puntosMarker;
      //     this.markers.push(objMarker);

      //   }
    },
  },
};
</script>
