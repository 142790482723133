<template>
  <header-component />
  <!-- Autos y auto partes -->
  <div
    data-id="1"
    class="oculto modal fixed inset-0 bg-bg-black bg-opacity-80 z-50 flex items-center justify-center transition-opacity"
  >
    <section
      class="u-container xl:w-2/3 py-0 rounded-2xl max-h-screen overflow-auto"
    >
      <header
        class="py-5 xl:py-6 px-3 xl:px-10 text-white bg-bg-black relative"
      >
        <p class="text-xs xl:text-base">
          Con ENVIOS USA PERÚ, obtienes los beneficios y descuentos en
          <span class="font-bold xl:text-2xl uppercase">Autos y Partes</span>
        </p>
        <img
          src="../assets/icons/salir.png"
          class="cursor-pointer absolute top-3 right-3 modal-exit"
        />
      </header>
      <article
        class="py-10 px-8 xl:px-14 bg-white grid grid-cols-2 gap-y-14 gap-x-8 items-start"
      >
        <ul class="grid gap-3">
          <h3 class="xl:text-xl mb-1 font-bold">Auto</h3>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.4wd.com/" rel="nofollow" target="_blank">4wd</a>
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.4x4superstore.com"
              rel="nofollow"
              target="_blank"
              >4x4 SuperStore</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.autogeek.net" rel="nofollow" target="_blank"
              >Auto Geek</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.autopartswarehouse.com"
              rel="nofollow"
              target="_blank"
              >Auto Parts Warehouse</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.classicautoparts.com/"
              rel="nofollow"
              target="_blank"
              >Classic Auto Parts</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.oreillyauto.com" rel="nofollow" target="_blank"
              >Oreilly Auto</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.jcwhitney.com/" rel="nofollow" target="_blank"
              >JC Whitney</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.kanter.com" rel="nofollow" target="_blank"
              >Kanter</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.jpcycles.com/" rel="nofollow" target="_blank"
              >JP Cycles</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.cmsnl.com/" rel="nofollow" target="_blank"
              >CMSNL</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.motorcycle-superstore.com"
              rel="nofollow"
              target="_blank"
              >Motorcycle Superstore</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.partsgeek.com/" rel="nofollow" target="_blank"
              >Parts Geek</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.napaonline.com/" rel="nofollow" target="_blank"
              >Napa Online</a
            >
          </li>
        </ul>
        <div class="grid gap-y-14">
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Moto</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.bikebandit.com" rel="nofollow" target="_blank"
                >Bike Bandit</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.harley-davidson.com/es"
                rel="nofollow"
                target="_blank"
                >Harley Davidson</a
              >
            </li>
          </ul>
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Lanchas</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.boatersworld.com/"
                rel="nofollow"
                target="_blank"
                >Boaters World</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.westmarine.com" rel="nofollow" target="_blank"
                >Westmarine</a
              >
            </li>
          </ul>
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Neumáticos</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.tires-easy.com" rel="nofollow" target="_blank"
                >Tires Easy</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.tirerack.com" rel="nofollow" target="_blank"
                >Tire Rack</a
              >
            </li>
          </ul>
        </div>
      </article>
    </section>
  </div>
  <!-- Electronica -->
  <div
    data-id="2"
    class="oculto modal fixed inset-0 bg-bg-black bg-opacity-80 z-50 flex items-center justify-center transition-opacity"
  >
    <section
      class="u-container xl:w-2/3 py-0 rounded-2xl max-h-screen overflow-auto"
    >
      <header
        class="py-5 xl:py-6 px-3 xl:px-10 text-white bg-bg-black relative"
      >
        <p class="text-xs xl:text-base">
          Con ENVIOS USA PERÚ, obtienes los beneficios y descuentos en
          <span class="font-bold xl:text-2xl uppercase">Electrónica</span>
        </p>
        <img
          src="../assets/icons/salir.png"
          class="cursor-pointer absolute top-3 right-3 modal-exit"
        />
      </header>
      <article
        class="py-10 px-8 xl:px-14 bg-white grid grid-cols-2 gap-y-14 gap-x-8 items-start"
      >
        <ul class="grid gap-3">
          <h3 class="xl:text-xl mb-1 font-bold">Electrónica</h3>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="https://www.amazon.com/" target="_blank">Amazon</a>
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://global.bose.com" rel="nofollow" target="_blank"
              >Bose</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.rakuten.com/" rel="nofollow" target="_blank"
              >Rakuten</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="'http://www.tigerdirect.com/"
              rel="nofollow"
              target="_blank"
              >Tiger Direct</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.crutchfield.com/S-bEP9Vxjp8P5/"
              rel="nofollow"
              target="_blank"
              >Crutchfield</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.ebay.com/electronics"
              rel="nofollow"
              target="_blank"
              >EBAY</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.macmall.com" rel="nofollow" target="_blank"
              >MacMall</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.newegg.com/" rel="nofollow" target="_blank"
              >New Egg</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.overstock.com/Electronics/2/store.html?TID=TN:Ent:Elect"
              rel="nofollow"
              target="_blank"
              >Overstock</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://es.radioshack.com/" rel="nofollow" target="_blank"
              >RadioShack</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.shop.com/electronics-a.xhtml"
              rel="nofollow"
              target="_blank"
              >Shop.com</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.sony.com/" rel="nofollow" target="_blank"
              >Sony</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.target.com/c/electronics/-/N-5xtg6#?lnk=gnav_electronics_8_0"
              rel="nofollow"
              target="_blank"
              >Target</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.thenerds.net/" rel="nofollow" target="_blank"
              >The Nerds</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.sharperimage.com" rel="nofollow" target="_blank"
              >Sharper Image</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.voltaicsystems.com/"
              rel="nofollow"
              target="_blank"
              >Voltaic Systems</a
            >
          </li>
        </ul>
        <ul class="grid gap-3">
          <h3 class="xl:text-xl mb-1 font-bold">Computación</h3>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.4allmemory.com/" rel="nofollow" target="_blank"
              >4 All Memory</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="https://www.amazon.com" target="_blank">Amazon</a>
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="'http://www.ebay.com/electronics/computers-networking"
              rel="nofollow"
              target="_blank"
              >EBAY</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.shopfujitsu.com/store/"
              rel="nofollow"
              target="_blank"
              >Fujitsu</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www8.hp.com/es" rel="nofollow" target="_blank"
              >HP</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.logitech.com" rel="nofollow" target="_blank"
              >Logitech</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.pcm.com/" rel="nofollow" target="_blank"
              >PC Mall</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="jhttp://www.pcconnection.com/"
              rel="nofollow"
              target="_blank"
              >PC Connection</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.toshiba.com/us/" rel="nofollow" target="_blank"
              >Toshiba</a
            >
          </li>
        </ul>
        <ul class="grid gap-3">
          <h3 class="xl:text-xl mb-1 font-bold">Fotografía y Video</h3>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.bhphotovideo.com/" target="_blank"
              >BH Photo Video</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="https://www.amazon.com" target="_blank">Amazon</a>
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="https://www.ebay.com" target="_blank">Ebay</a>
          </li>
        </ul>
      </article>
    </section>
  </div>
  <!-- Casa y Jardin -->
  <div
    data-id="3"
    class="oculto modal fixed inset-0 bg-bg-black bg-opacity-80 z-50 flex items-center justify-center transition-opacity"
  >
    <section
      class="u-container xl:w-2/3 py-0 rounded-2xl max-h-screen overflow-auto"
    >
      <header
        class="py-5 xl:py-6 px-3 xl:px-10 text-white bg-bg-black relative"
      >
        <p class="text-xs xl:text-base">
          Con ENVIOS USA PERÚ, obtienes los beneficios y descuentos en
          <span class="font-bold xl:text-2xl uppercase">Casa y Jardin</span>
        </p>
        <img
          src="../assets/icons/salir.png"
          class="cursor-pointer absolute top-3 right-3 modal-exit"
        />
      </header>
      <article
        class="py-10 px-8 xl:px-14 bg-white grid grid-cols-2 gap-y-14 gap-x-8 items-start"
      >
        <div class="grid gap-y-14">
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Casa</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.acehardware.com/home/index.jsp"
                rel="nofollow"
                target="_blank"
                >Ace Hardware</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.bedbathandbeyond.com/"
                rel="nofollow"
                target="_blank"
                >Bed Bath and Beyond</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.bedbathstore.com/"
                rel="nofollow"
                target="_blank"
                >Bed Bath Store</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.linenplace.com/"
                rel="nofollow"
                target="_blank"
                >Linen Place</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.lnt.com/" rel="nofollow" target="_blank"
                >Linens-n-Things</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.macys.com" rel="nofollow" target="_blank"
                >Macy's</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.mikasa.com/" rel="nofollow" target="_blank"
                >Mikasa</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.wards.com" rel="nofollow" target="_blank"
                >Montgomery Ward</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="https://www.pacificcoast.com"
                rel="nofollow"
                target="_blank"
                >Pacific Coast</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.pacificpillows.com/"
                rel="nofollow"
                target="_blank"
                >Pacific Pillows</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.potterybarn.com/"
                rel="nofollow"
                target="_blank"
                >Pottery Barn</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.potterybarnkids.com/"
                rel="nofollow"
                target="_blank"
                >Pottery Barn Kids</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.shop.com/Home+Store-a.xhtml"
                rel="nofollow"
                target="_blank"
                >Shop.com</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.target.com/" rel="nofollow" target="_blank"
                >Target</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.toolking.com/" rel="nofollow" target="_blank"
                >Tool King</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.williams-sonoma.com/"
                rel="nofollow"
                target="_blank"
                >Williams Sonoma</a
              >
            </li>
          </ul>
        </div>
        <div class="grid gap-y-14">
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Jardín</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.gardeners.com/" rel="nofollow" target="_blank"
                >Gardeners</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.target.com/c/patio-garden/-/N-5xtq9#?lnk=gnav_patio_6_0)"
                rel="nofollow"
                target="_blank"
                >Target</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.potterybarn.com/shop/outdoor/all-outdoor/?cm_type=gnav"
                rel="nofollow"
                target="_blank"
                >Pottery Barn</a
              >
            </li>
          </ul>
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Oficina</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.officedepotrewards.com"
                rel="nofollow"
                target="_blank"
                >Office Depot</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.zumaoffice.com" rel="nofollow" target="_blank"
                >Zuma Office</a
              >
            </li>
          </ul>
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Más</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.kegworks.com" rel="nofollow" target="_blank"
                >Keg Works</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.luggageonline.com/"
                rel="nofollow"
                target="_blank"
                >luggageonlinen</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.samsonite.com" rel="nofollow" target="_blank"
                >Samsonite</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.successories.com/"
                rel="nofollow"
                target="_blank"
                >Successories</a
              >
            </li>
          </ul>
        </div>
      </article>
    </section>
  </div>
  <!-- Moda Hombres -->
  <div
    data-id="4"
    class="oculto modal fixed inset-0 bg-bg-black bg-opacity-80 z-50 flex items-center justify-center transition-opacity"
  >
    <section
      class="u-container xl:w-2/3 py-0 rounded-2xl max-h-screen overflow-auto"
    >
      <header
        class="py-5 xl:py-6 px-3 xl:px-10 text-white bg-bg-black relative"
      >
        <p class="text-xs xl:text-base">
          Con ENVIOS USA PERÚ, obtienes los beneficios y descuentos en
          <span class="font-bold xl:text-2xl uppercase">Moda Hombres</span>
        </p>
        <img
          src="../assets/icons/salir.png"
          class="cursor-pointer absolute top-3 right-3 modal-exit"
        />
      </header>
      <article
        class="py-10 px-8 xl:px-14 bg-white grid grid-cols-2 gap-y-14 gap-x-8 items-start"
      >
        <ul class="grid gap-3">
          <h3 class="xl:text-xl mb-1 font-bold">Moda</h3>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.aeropostale.com" rel="nofollow" target="_blank"
              >Aeropostale</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.akademiks.com/" rel="nofollow" target="_blank"
              >Akademiks</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.armaniexchange.com/"
              rel="nofollow"
              target="_blank"
              >Armani Exchange</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.backcountry.com/" rel="nofollow" target="_blank"
              >Back Country</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://bananarepublic.gap.com"
              rel="nofollow"
              target="_blank"
              >Banana Republic</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.barenecessities.com/"
              rel="nofollow"
              target="_blank"
              >Bare Necessities</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.blair.com/" rel="nofollow" target="_blank"
              >Blair</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.tanthrough.com/" rel="nofollow" target="_blank"
              >Cool Tan</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.eddiebauer.com/home.jsp"
              rel="nofollow"
              target="_blank"
              >Eddie Bauer</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.gap.com/" rel="nofollow" target="_blank">Gap</a>
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.geneticdenim.com/"
              rel="nofollow"
              target="_blank"
              >Genetic Denim</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.gucci.com/us/home"
              rel="nofollow"
              target="_blank"
              >Gucci</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.hanes.com" rel="nofollow" target="_blank"
              >Hanes</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.hugoboss.com" rel="nofollow" target="_blank"
              >Hugo Boss</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.jcrew.com/index.jsp"
              rel="nofollow"
              target="_blank"
              >JCrew</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.lifeisgood.com" rel="nofollow" target="_blank"
              >Life is Good</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.macys.com" rel="nofollow" target="_blank"
              >Macy's</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.menswearhouse.com/"
              rel="nofollow"
              target="_blank"
              >Men's Wearhouse</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a
              href="http://www.militaryclothing.com"
              rel="nofollow"
              target="_blank"
              >Military Clothing</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.oneill.com/" rel="nofollow" target="_blank"
              >O'Neill</a
            >
          </li>
          <li class="text-xs xl:text-base hover:text-mainBlue">
            <a href="http://www.pacsun.com/" rel="nofollow" target="_blank"
              >Pacsun</a
            >
          </li>
        </ul>
        <div class="grid gap-y-14">
          <ul class="grid gap-3">
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.patagonia.com/us/home"
                rel="nofollow"
                target="_blank"
                >Patagonia</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.paulfredrick.com/"
                rel="nofollow"
                target="_blank"
                >Paul Fredrick</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.quiksilver.com/"
                rel="nofollow"
                target="_blank"
                >QuickSilver</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.ralphlauren.com"
                rel="nofollow"
                target="_blank"
                >Ralph Lauren</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.tillys.com" rel="nofollow" target="_blank"
                >Tilly's</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.tshirtoutlet.com/"
                rel="nofollow"
                target="_blank"
                >T-Shirt Outlet</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.underarmour.com/shop/CL/en"
                rel="nofollow"
                target="_blank"
                >Under Armour</a
              >
            </li>
          </ul>
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Calzado</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.6pm.com/" rel="nofollow" target="_blank"
                >6pm</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.converse.com/" rel="nofollow" target="_blank"
                >Converse</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.drjays.com/" rel="nofollow" target="_blank"
                >Dr Jays</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.fila.cl/site/" rel="nofollow" target="_blank"
                >Fila</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.jimmyjazz.com/" rel="nofollow" target="_blank"
                >Jimmy Jazz</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.zappos.com/" rel="nofollow" target="_blank"
                >Zappos</a
              >
            </li>
          </ul>
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Accesorios</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="javascript:envia('http://www.ebags.com')">eBags</a>
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="javascript:envia('http://www.wristwatch.com/')"
                >Wrist Watch</a
              >
            </li>
          </ul>
        </div>
      </article>
    </section>
  </div>
  <!-- Deporte y Salud -->
  <div
    data-id="5"
    class="oculto modal fixed inset-0 bg-bg-black bg-opacity-80 z-50 flex items-center justify-center transition-opacity"
  >
    <section
      class="u-container xl:w-2/3 py-0 rounded-2xl max-h-screen overflow-auto"
    >
      <header
        class="py-5 xl:py-6 px-3 xl:px-10 text-white bg-bg-black relative"
      >
        <p class="text-xs xl:text-base">
          Con ENVIOS USA PERÚ, obtienes los beneficios y descuentos en
          <span class="font-bold xl:text-2xl uppercase">Deporte y Salud </span>
        </p>
        <img
          src="../assets/icons/salir.png"
          class="cursor-pointer absolute top-3 right-3 modal-exit"
        />
      </header>
      <article
        class="py-10 px-8 xl:px-14 bg-white grid grid-cols-2 gap-y-14 gap-x-8 items-start"
      >
        <div class="grid gap-y-14">
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Golf</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.3balls.com" rel="nofollow" target="_blank"
                >3 Balls</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.edwinwattsgolf.com/"
                rel="nofollow"
                target="_blank"
                >Edwin Watts Golf</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.gigagolf.com" rel="nofollow" target="_blank"
                >Giga Golf</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.golfballs.com" rel="nofollow" target="_blank"
                >Golf Balls</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.golfgalaxy.com/"
                rel="nofollow"
                target="_blank"
                >Golf Galaxy</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.golfoutletsusa.com"
                rel="nofollow"
                target="_blank"
                >Golf Outlets USA</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.golfsmith.com" rel="nofollow" target="_blank"
                >Golfsmith</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.golfshoesonly.com/"
                rel="nofollow"
                target="_blank"
                >Golf Shoes Only</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.intheholegolf.com/"
                rel="nofollow"
                target="_blank"
                >In The Hole Golf</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.miamigolf.com/" rel="nofollow" target="_blank"
                >Miami Golf</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.medicus.com/" rel="nofollow" target="_blank"
                >Medicus</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.pinemeadowgolf.com"
                rel="nofollow"
                target="_blank"
                >Pine Meadow Golf</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://taylormadegolf.com/"
                rel="nofollow"
                target="_blank"
                >Taylor Made Golf</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.tgw.com" rel="nofollow" target="_blank"
                >TGW</a
              >
            </li>
          </ul>
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Para Correr</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.converse.com/" rel="nofollow" target="_blank"
                >Converse</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.babolat.com/" rel="nofollow" target="_blank"
                >Babolat</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.esportsonline.com"
                rel="nofollow"
                target="_blank"
                >eSports Online</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.roadrunnersports.com/"
                rel="nofollow"
                target="_blank"
                >Road Runner Sports</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.zappos.com/" rel="nofollow" target="_blank"
                >Zappos</a
              >
            </li>
          </ul>
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Tennis</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.tennisexpress.com/"
                rel="nofollow"
                target="_blank"
                >Tennis Express</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.tenniscompany.com/"
                rel="nofollow"
                target="_blank"
                >Tennis Company</a
              >
            </li>
          </ul>
        </div>
        <div class="grid gap-y-14">
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Calzado</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.eastbay.com" rel="nofollow" target="_blank"
                >Eastbay</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.footlocker.com" rel="nofollow" target="_blank"
                >Footlocker</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.onlineshoes.com/"
                rel="nofollow"
                target="_blank"
                >Online Shoes</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="https://shop.reebok.com/us/#/shop"
                rel="nofollow"
                target="_blank"
                >Reebok</a
              >
            </li>
          </ul>
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Accesorios Deportivos</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.championusa.com/"
                rel="nofollow"
                target="_blank"
                >Champion USA</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.dickssportinggoods.com')"
                rel="nofollow"
                target="_blank"
                >Dick's Sporting Goods</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.fogdog.com" rel="nofollow" target="_blank"
                >Fog Dog</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.fanatics.com" rel="nofollow" target="_blank"
                >Fanatics</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.motosport.com" rel="nofollow" target="_blank"
                >MotoSport</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://store.nba.com/" rel="nofollow" target="_blank"
                >NBA</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.patagonia.com/us/home"
                rel="nofollow"
                target="_blank"
                >Patagonia</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.sportsauthority.com"
                rel="nofollow"
                target="_blank"
                >Sports Authority</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="'http://www.sunandski.com/"
                rel="nofollow"
                target="_blank"
                >Sun and Ski</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.swissoutpost.com"
                rel="nofollow"
                target="_blank"
                >Swiss Outpost</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.rei.com/outlet/"
                rel="nofollow"
                target="_blank"
                >REI</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.westmarine.com" rel="nofollow" target="_blank"
                >West Marine</a
              >
            </li>
          </ul>
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Salud</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.acemagnetics.com/"
                rel="nofollow"
                target="_blank"
                >Ace Magnetics</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.babyquasar.com/"
                rel="nofollow"
                target="_blank"
                >Baby Quasar</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.drugstore.com" rel="nofollow" target="_blank"
                >Drug Store</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.thebodyshop.com/index.aspx"
                rel="nofollow"
                target="_blank"
                >The Body Shop</a
              >
            </li>
          </ul>
        </div>
      </article>
    </section>
  </div>
  <!-- Juegos y Juguetes -->
  <div
    data-id="6"
    class="oculto modal fixed inset-0 bg-bg-black bg-opacity-80 z-50 flex items-center justify-center transition-opacity"
  >
    <section
      class="u-container xl:w-2/3 py-0 rounded-2xl max-h-screen overflow-auto"
    >
      <header
        class="py-5 xl:py-6 px-3 xl:px-10 text-white bg-bg-black relative"
      >
        <p class="text-xs xl:text-base">
          Con ENVIOS USA PERÚ, obtienes los beneficios y descuentos en
          <span class="font-bold xl:text-2xl uppercase">Juegos y Juguetes</span>
        </p>
        <img
          src="../assets/icons/salir.png"
          class="cursor-pointer absolute top-3 right-3 modal-exit"
        />
      </header>
      <article
        class="py-10 px-8 xl:px-14 bg-white grid grid-cols-2 gap-y-14 gap-x-8 items-start"
      >
        <div class="grid gap-y-14">
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Bebé</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.babiesrus.com/" rel="nofollow" target="_blank"
                >Babies R Us</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://babyoutlet.com/" rel="nofollow" target="_blank"
                >Baby Outlet</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.babycenter.com/"
                rel="nofollow"
                target="_blank"
                >Baby Center</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.rightstart.com/"
                rel="nofollow"
                target="_blank"
                >Right Start</a
              >
            </li>
          </ul>
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Niños y Niñas</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.barbie.com/" rel="nofollow" target="_blank"
                >Barbie</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://store.discovery.com/"
                rel="nofollow"
                target="_blank"
                >Discovery</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.disneystore.com/"
                rel="nofollow"
                target="_blank"
                >Disney</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.etoys.com/" rel="nofollow" target="_blank"
                >eToys</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.fao.com/home/index.jsp"
                rel="nofollow"
                target="_blank"
                >FAO Schwarts</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.kbtoys.com/KB" rel="nofollow" target="_blank"
                >KB Toys</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.kazootoys.com/" rel="nofollow" target="_blank"
                >Kazoo Toys</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.kindertrains.com/"
                rel="nofollow"
                target="_blank"
                >Kinder Trains</a
              >
            </li>
          </ul>
        </div>
        <div class="grid gap-y-14">
          <ul class="grid gap-3">
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://shop.lego.com/" rel="nofollow" target="_blank"
                >Lego</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.playmobil.com/index.html"
                rel="nofollow"
                target="_blank"
                >Playmobil</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.petsmart.com/" rel="nofollow" target="_blank"
                >Petsmart</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.shop.com/" rel="nofollow" target="_blank"
                >Shop.com</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.target.com/" rel="nofollow" target="_blank"
                >Target</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.toysrus.com" rel="nofollow" target="_blank"
                >Toys R Us</a
              >
            </li>
          </ul>
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Video Juegos</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.gamestop.com/" rel="nofollow" target="_blank"
                >Game Stop</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.gogamer.com/" rel="nofollow" target="_blank"
                >Go Gamer</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://store.madcatz.com/" rel="nofollow" target="_blank"
                >Mad Catz</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.nintendo.com/" rel="nofollow" target="_blank"
                >Nintendo</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.videogamecentral.com/"
                rel="nofollow"
                target="_blank"
                >Video Game Central</a
              >
            </li>
          </ul>
        </div>
      </article>
    </section>
  </div>
  <!-- Musica y Peliculas -->
  <div
    data-id="7"
    class="oculto modal fixed inset-0 bg-bg-black bg-opacity-80 z-50 flex items-center justify-center transition-opacity"
  >
    <section
      class="u-container xl:w-2/3 py-0 rounded-2xl max-h-screen overflow-auto"
    >
      <header
        class="py-5 xl:py-6 px-3 xl:px-10 text-white bg-bg-black relative"
      >
        <p class="text-xs xl:text-base">
          Con ENVIOS USA PERÚ, obtienes los beneficios y descuentos en
          <span class="font-bold xl:text-2xl uppercase"
            >Musica y Peliculas</span
          >
        </p>
        <img
          src="../assets/icons/salir.png"
          class="cursor-pointer absolute top-3 right-3 modal-exit"
        />
      </header>
      <article
        class="py-10 px-8 xl:px-14 bg-white grid grid-cols-2 gap-y-14 gap-x-8 items-start"
      >
        <div class="grid gap-y-14">
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Música</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.123dj.com/" rel="nofollow" target="_blank"
                >123 DJ</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="https://www.amazon.com/b?_encoding=UTF8&amp;tag=eshopex04-20&amp;linkCode=ur2&amp;linkId=728763fa6ed697e004e42dd8e420ce6d&amp;camp=1789&amp;creative=9325&amp;node=301668"
                rel="nofollow"
                target="_blank"
                >Amazon</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.apple.com/" rel="nofollow" target="_blank"
                >Apple</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.bmg.com/" rel="nofollow" target="_blank"
                >BMG</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.rakuten.com/loc/buy-music-cds-at-low-prices/109.html"
                rel="nofollow"
                target="_blank"
                >Rakuten</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://shop.mtv.com/" rel="nofollow" target="_blank"
                >MTV</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.musicspace.com/"
                rel="nofollow"
                target="_blank"
                >Music Space</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.music123.com/" rel="nofollow" target="_blank"
                >Music 123</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.musiciansfriend.com/"
                rel="nofollow"
                target="_blank"
                >Musicians Friend</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.tower.com/" rel="nofollow" target="_blank"
                >Tower Records</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.virginrecords.com/"
                rel="nofollow"
                target="_blank"
                >Virgin Records</a
              >
            </li>
          </ul>
        </div>
        <div class="grid gap-y-14">
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Películas</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://store.discovery.com/dvds-books/index.php?v=discovery_dvds-books&amp;nvbar=DVDs+%26+Books"
                rel="nofollow"
                target="_blank"
                >Discovery</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.half.ebay.com/" rel="nofollow" target="_blank"
                >EBAY</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.hollywoodmegastore.com/"
                rel="nofollow"
                target="_blank"
                >Hollywood Megastore</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://shop.nick.com/" rel="nofollow" target="_blank"
                >Nickelodeon</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.history.com/" rel="nofollow" target="_blank"
                >The History Channel</a
              >
            </li>
          </ul>
        </div>
      </article>
    </section>
  </div>
  <!-- Libros -->
  <div
    data-id="8"
    class="oculto modal fixed inset-0 bg-bg-black bg-opacity-80 z-50 flex items-center justify-center transition-opacity"
  >
    <section
      class="u-container xl:w-2/3 py-0 rounded-2xl max-h-screen overflow-auto"
    >
      <header
        class="py-5 xl:py-6 px-3 xl:px-10 text-white bg-bg-black relative"
      >
        <p class="text-xs xl:text-base">
          Con ENVIOS USA PERÚ, obtienes los beneficios y descuentos en
          <span class="font-bold xl:text-2xl uppercase">Libros</span>
        </p>
        <img
          src="../assets/icons/salir.png"
          class="cursor-pointer absolute top-3 right-3 modal-exit"
        />
      </header>
      <article
        class="py-10 px-8 xl:px-14 bg-white grid grid-cols-2 gap-y-14 gap-x-8 items-start"
      >
        <div class="grid gap-y-14">
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Libros</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.alibris.com/" rel="nofollow" target="_blank"
                >Alibris</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="https://www.amazon.com/b?_encoding=UTF8&amp;tag=eshopex04-20&amp;linkCode=ur2&amp;linkId=b1b2d970cfb6c26eb232200e5fb60467&amp;camp=1789&amp;creative=9325&amp;node=283155"
                rel="nofollow"
                target="_blank"
                >Amazon</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.audible.com/" rel="nofollow" target="_blank"
                >Audible</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.barnesandnoble.com/"
                rel="nofollow"
                target="_blank"
                >Barnes &amp; Noble</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.half.ebay.com/" rel="nofollow" target="_blank"
                >Ebay</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.magazineline.com"
                rel="nofollow"
                target="_blank"
                >Magazine Line</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.magmall.com/" rel="nofollow" target="_blank"
                >Mag Mall</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.playboystore.com/"
                rel="nofollow"
                target="_blank"
                >Playboy Store</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.textbookx.com/" rel="nofollow" target="_blank"
                >Text Book X</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.tower.com/" rel="nofollow" target="_blank"
                >Tower Records</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="https://shop.scholastic.com"
                rel="nofollow"
                target="_blank"
                >The Scholastic Store</a
              >
            </li>
          </ul>
        </div>
      </article>
    </section>
  </div>
  <!-- Moda Mujeres -->
  <div
    data-id="9"
    class="oculto modal fixed inset-0 bg-bg-black bg-opacity-80 z-50 flex items-center justify-center transition-opacity"
  >
    <section
      class="u-container xl:w-2/3 py-0 rounded-2xl max-h-screen overflow-auto"
    >
      <header
        class="py-5 xl:py-6 px-3 xl:px-10 text-white bg-bg-black relative"
      >
        <p class="text-xs xl:text-base">
          Con ENVIOS USA PERÚ, obtienes los beneficios y descuentos en
          <span class="font-bold xl:text-2xl uppercase">Moda Mujeres</span>
        </p>
        <img
          src="../assets/icons/salir.png"
          class="cursor-pointer absolute top-3 right-3 modal-exit"
        />
      </header>
      <article
        class="py-10 px-8 xl:px-14 bg-white grid grid-cols-2 gap-y-14 gap-x-8 items-start"
      >
        <div class="grid gap-y-14">
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Moda</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.apeainthepod.com/"
                rel="nofollow"
                target="_blank"
                >A Pea in the Pod</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.aeropostale.com"
                rel="nofollow"
                target="_blank"
                >Aeropostale</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.additionelle.com"
                rel="nofollow"
                target="_blank"
                >Additionelle</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.akademiks.com/" rel="nofollow" target="_blank"
                >Akademiks</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.alwaysforme.com"
                rel="nofollow"
                target="_blank"
                >Always For Me</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.alloyapparel.com"
                rel="nofollow"
                target="_blank"
                >Alloy Apparel</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="'http://www.amiclubwear.com"
                rel="nofollow"
                target="_blank"
                >Ami Club Wear</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.anntaylor.com/" rel="nofollow" target="_blank"
                >Ann Taylor</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.anthropologie.com"
                rel="nofollow"
                target="_blank"
                >Anthropologie</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.armaniexchange.com/"
                rel="nofollow"
                target="_blank"
                >Armani Exchange</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.gap.com/browse/subDivision.do?cid=6487"
                rel="nofollow"
                target="_blank"
                >Baby Gap</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.backcountry.com/"
                rel="nofollow"
                target="_blank"
                >Back Country</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.beallsflorida.com"
                rel="nofollow"
                target="_blank"
                >Bealls Florida</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://bananarepublic.gap.com"
                rel="nofollow"
                target="_blank"
                >Banana Republic</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.bebe.com" rel="nofollow" target="_blank"
                >BEBE</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.blair.com/" rel="nofollow" target="_blank"
                >Blair</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.bluefly.com/" rel="nofollow" target="_blank"
                >Bluefly</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.bluegala.com/" rel="nofollow" target="_blank"
                >Blue Gala</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.boutiquetoyou.com/"
                rel="nofollow"
                target="_blank"
                >Boutique To You</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.burberry.com/" rel="nofollow" target="_blank"
                >Burberry</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.cashmereboutique.com/"
                rel="nofollow"
                target="_blank"
                >Cashmere Boutique</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.carolinaherrera.com/es/"
                rel="nofollow"
                target="_blank"
                >Carolina Herrera</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.carters.com/" rel="nofollow" target="_blank"
                >Carter's</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.chadwicks.com" rel="nofollow" target="_blank"
                >Chadwick's</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.starchic.it/" rel="nofollow" target="_blank"
                >Star Chic</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.chicos.com" rel="nofollow" target="_blank"
                >Chico's</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.tanthrough.com/"
                rel="nofollow"
                target="_blank"
                >Cool Tan</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.couturecandy.com"
                rel="nofollow"
                target="_blank"
                >Couture Candy</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.danskin.com" rel="nofollow" target="_blank"
                >Danskin</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.editorscloset.com"
                rel="nofollow"
                target="_blank"
                >Editor's Closet</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.esprit.com" rel="nofollow" target="_blank"
                >Esprit</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.flirtcatalog.com/"
                rel="nofollow"
                target="_blank"
                >Flirt Catalog</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.forever21.com/Product/Main.aspx?br=f21"
                rel="nofollow"
                target="_blank"
                >Forever 21</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.shopgarageonline.com/home/index.jsp"
                rel="nofollow"
                target="_blank"
                >Garage</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.gap.com/" rel="nofollow" target="_blank"
                >Gap</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.geneticdenim.com/"
                rel="nofollow"
                target="_blank"
                >Genetic Denim</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.gojane.com" rel="nofollow" target="_blank"
                >Go Jane</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.hanes.com" rel="nofollow" target="_blank"
                >Hanes</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.lifeisgood.com" rel="nofollow" target="_blank"
                >Life Is Good</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.lineapelle.com" rel="nofollow" target="_blank"
                >Linea Pelle</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.jcpenney.com" rel="nofollow" target="_blank"
                >JC Penny</a
              >
            </li>
          </ul>
        </div>
        <div class="grid gap-y-14">
          <ul class="grid gap-3">
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www1.macys.com" rel="nofollow" target="_blank"
                >Macy's</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.motherhood.com/"
                rel="nofollow"
                target="_blank"
                >Motherhood</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.metrostyle.com" rel="nofollow" target="_blank"
                >Metro Style</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.michaelstars.com"
                rel="nofollow"
                target="_blank"
                >Michael Stars</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="https://www.milanoo.com/" rel="nofollow" target="_blank"
                >Milanoo</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.modcloth.com" rel="nofollow" target="_blank"
                >Mod Cloth</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.neimanmarcus.com/"
                rel="nofollow"
                target="_blank"
                >Neiman Marcus</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://shop.nordstrom.com/"
                rel="nofollow"
                target="_blank"
                >Nordstrom</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.pacsun.com" rel="nofollow" target="_blank"
                >Pacsun</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.penningtons.com"
                rel="nofollow"
                target="_blank"
                >penningtons</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.pinkmascara.com/"
                rel="nofollow"
                target="_blank"
                >Pink Mascara</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.us.purecollection.com"
                rel="nofollow"
                target="_blank"
                >Pure Collection</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.quiksilver.com/"
                rel="nofollow"
                target="_blank"
                >Quiksilver</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.ralphlauren.com"
                rel="nofollow"
                target="_blank"
                >Ralph Lauren</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.shopgoldyn.com/"
                rel="nofollow"
                target="_blank"
                >Shop Goldyn</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.silkies.com" rel="nofollow" target="_blank"
                >Silkies</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.swimspot.com" rel="nofollow" target="_blank"
                >Swimspot</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.talbots.com" rel="nofollow" target="_blank"
                >Talbots</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.tillys.com" rel="nofollow" target="_blank"
                >Tilly's</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.tshirtoutlet.com/"
                rel="nofollow"
                target="_blank"
                >T-shirt Outlet</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.torrid.com" rel="nofollow" target="_blank"
                >Torrid</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.womensuits.com/"
                rel="nofollow"
                target="_blank"
                >Women Suits</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.yoga-clothing.com"
                rel="nofollow"
                target="_blank"
                >Yoga Clothing</a
              >
            </li>
          </ul>
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Calzado</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.6pm.com/" rel="nofollow" target="_blank"
                >6pm</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.converse.com/" rel="nofollow" target="_blank"
                >Converse</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.drjays.com/" rel="nofollow" target="_blank"
                >Dr Jays</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.fila.cl/site/" rel="nofollow" target="_blank"
                >Fila</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.jimmyjazz.com/" rel="nofollow" target="_blank"
                >Jimmy Jazz</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.zappos.com/" rel="nofollow" target="_blank"
                >Zappos</a
              >
            </li>

            <h4><strong>Accesorios</strong></h4>
            <a href="http://www.ebags.com" rel="nofollow" target="_blank"
              >eBags</a
            >
            <a href="http://www.wristwatch.com/" rel="nofollow" target="_blank"
              >Wrist Watch</a
            >
          </ul>
          <ul class="grid gap-3">
            <h3 class="xl:text-xl mb-1 font-bold">Accesorios</h3>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a href="http://www.ebags.com" rel="nofollow" target="_blank"
                >eBags</a
              >
            </li>
            <li class="text-xs xl:text-base hover:text-mainBlue">
              <a
                href="http://www.wristwatch.com/"
                rel="nofollow"
                target="_blank"
                >Wrist Watch</a
              >
            </li>
          </ul>
        </div>
      </article>
    </section>
  </div>
  <main>
    <section class="u-container">
      <h2 class="text-lg xl:text-2xl text-center mb-4 uppercase">
        Tiendas recomendadas para tus compras en
        <span class="text-mainBlue">Estados Unidos</span>
      </h2>
      <p class="text-xs xl:text-base text-center mb-10 xl:mb-16">
        Te recomendamos los siguientes tiendas para tus compras de Estados
        Unidos
      </p>
      <!-- CONTENEDOR DE CARDS -->
      <div class="mb-16 grid grid-cols-2 md:grid-cols-4 gap-4 xl:gap-14">
        <button
          type="button"
          data-id="1"
          class="categorie-card block overflow-hidden relative border shadow-sm rounded-2xl"
        >
          <img
            src="../assets/images/categoria-8.png"
            alt="Gamers"
            class="w-full"
          />
          <p
            class="absolute inset-x-0 bottom-0 bg-black text-sm xl:text-base text-center text-white py-2 xl:py-4 font-semibold"
          >
            Autos y autopartes
          </p>
        </button>
        <button
          type="button"
          data-id="2"
          class="categorie-card block overflow-hidden relative border shadow-sm rounded-2xl"
        >
          <img
            src="../assets/images/categoria-4.png"
            alt="Gamers"
            class="w-full"
          />
          <p
            class="absolute inset-x-0 bottom-0 bg-black text-sm xl:text-base text-center text-white py-2 xl:py-4 font-semibold"
          >
            Electrónicos
          </p>
        </button>
        <button
          type="button"
          data-id="3"
          class="categorie-card block overflow-hidden relative border shadow-sm rounded-2xl"
        >
          <img
            src="../assets/images/categoria-jardin.png"
            alt="Gamers"
            class="w-full"
          />
          <p
            class="absolute inset-x-0 bottom-0 bg-black text-sm xl:text-base text-center text-white py-2 xl:py-4 font-semibold"
          >
            Casa y Jardin
          </p>
        </button>
        <button
          type="button"
          data-id="4"
          class="categorie-card block overflow-hidden relative border shadow-sm rounded-2xl"
        >
          <img
            src="../assets/images/categoria-2.png"
            alt="Gamers"
            class="w-full"
          />
          <p
            class="absolute inset-x-0 bottom-0 bg-black text-sm xl:text-base text-center text-white py-2 xl:py-4 font-semibold"
          >
            Moda hombres
          </p>
        </button>
        <button
          type="button"
          data-id="5"
          class="categorie-card block overflow-hidden relative border shadow-sm rounded-2xl"
        >
          <img
            src="../assets/images/categoria-5.png"
            alt="Gamers"
            class="w-full"
          />
          <p
            class="absolute inset-x-0 bottom-0 bg-black text-sm xl:text-base text-center text-white py-2 xl:py-4 font-semibold"
          >
            Deportes y Salud
          </p>
        </button>
        <button
          type="button"
          data-id="6"
          class="categorie-card block overflow-hidden relative border shadow-sm rounded-2xl"
        >
          <img
            src="../assets/images/categoria-6.png"
            alt="Gamers"
            class="w-full"
          />
          <p
            class="absolute inset-x-0 bottom-0 bg-black text-sm xl:text-base text-center text-white py-2 xl:py-4 font-semibold"
          >
            Juegos y juguetes
          </p>
        </button>
        <button
          type="button"
          data-id="7"
          class="categorie-card block overflow-hidden relative border shadow-sm rounded-2xl"
        >
          <img
            src="../assets/images/categoria-7.png"
            alt="Gamers"
            class="w-full"
          />
          <p
            class="absolute inset-x-0 bottom-0 bg-black text-sm xl:text-base text-center text-white py-2 xl:py-4 font-semibold"
          >
            Música y Peliculas
          </p>
        </button>
        <button
          type="button"
          data-id="8"
          class="categorie-card block overflow-hidden relative border shadow-sm rounded-2xl"
        >
          <img
            src="../assets/images/categoria-libros.png"
            alt="Gamers"
            class="w-full"
          />
          <p
            class="absolute inset-x-0 bottom-0 bg-black text-sm xl:text-base text-center text-white py-2 xl:py-4 font-semibold"
          >
            Libros
          </p>
        </button>
        <button
          type="button"
          data-id="9"
          class="categorie-card block overflow-hidden relative border shadow-sm rounded-2xl"
        >
          <img
            src="../assets/images/categoria-3.png"
            alt="Gamers"
            class="w-full"
          />
          <p
            class="absolute inset-x-0 bottom-0 bg-black text-sm xl:text-base text-center text-white py-2 xl:py-4 font-semibold"
          >
            Moda Mujeres
          </p>
        </button>
      </div>
      <a
        href="https://sendi.freshdesk.com/support/solutions/folders/73000415239"
        target="Blank"
        class="block w-max mx-auto mb-16 py-5 px-16 rounded-2xl text-white font-bold bg-black transition-colors hover:bg-mainBlue"
        >Más información</a
      >
      <p class="mb-3 text-xs xl:text-base">
        Con Sendi, puedes comprar en distintas tiendas internacionales, de
        rápida importación hacia tus manos, solo debes copiar el link del
        producto y copiarlo en la plataforma
        <a href="/" class="text-mainBlue underline">wwww.sendi.com.pe</a> y
        realizar los pasos que te indica. Así de fácil y sencillo.
      </p>
      <p class="mb-3 text-xs xl:text-base">
        Las tiendas que recomendamos, son independientes a la nuestra por ello
        tienen su propio sistema de delivery el cual nosotros te ahorramos el
        largo proceso de tus encargos internacionales y de toda la gestión con
        nuestro <b>Servicio Shopper.</b>
      </p>
      <p class="mb-3 text-xs xl:text-base">
        Tus encargos estan totalmente seguros, con nuestro protocolo de
        <a href="#" class="text-mainBlue underline">COMPRA SEGURA</a>.
      </p>
      <p class="mb-3 text-xs xl:text-base">
        Puedes usar tus tarjetas nacionales para tus encargos internacionales de
        Estados Unidos y del mundo, hasta con 12 cuotas sin interestes.
      </p>
    </section>
  </main>
</template>
<script>
import headerComponent from "../components/global/headerComponent.vue";
export default {
  components: { headerComponent },
  data() {
    return {};
  },
  mounted() {
    const $buttons = Array.from(document.querySelectorAll(".categorie-card"));
    const $modals = Array.from(document.querySelectorAll(".modal"));

    $buttons.map((button) => {
      const id = button.dataset.id;
      button.addEventListener("click", () => {
        if ($modals[id - 1].dataset.id == id) {
          $modals[id - 1].classList.remove("oculto");
        } else {
          $modals[id - 1].classList.add("oculto");
        }
      });
    });

    addEventListener("click", (e) => {
      if (e.target.matches(".modal")) e.target.classList.add("oculto");
      if (e.target.matches(".modal-exit")) {
        e.target.parentElement.parentElement.parentElement.classList.add(
          "oculto"
        );
      }
    });
  },
};
</script>
