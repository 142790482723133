<template>
  <span>
    <button
      @click.prevent="openFormDirection"
      class="text-sm text-mainOrange flex items-center justify-star text-left gap-2"
    >
      <img src="../../../assets/icons/icon-market-orange.png" alt="" />
      <div>
        <span class="block">Enviar a {{ user.name }}</span>
        <span class="font-bold"> {{ data.direction }}</span>
      </div>
    </button>
    <div
      @click.prevent="closeModal($event)"
      :class="style.direction ? '' : 'oculto'"
      class="z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div
        class="px-8 py-12 bg-white rounded-2xl border shadow-sm"
        id="modalConfirm2"
      >
        <h3 class="font-bold px-12 mb-8 text-[black]">Elige tu ubicación</h3>
        <!-- Departamento -->
        <label class="relative bloc grid mb-4" v-if="!inputDirection">
          <GMapAutocomplete
            placeholder="Mi ubicación"
            @place_changed="setPlace"
            :class="error.direccion ? 'ring ring-mainRed' : ''"
            class="input text-sm w-full font-semibold pr-12 text-[black]"
          >
          </GMapAutocomplete>

          <div class="absolute right-6 bottom-6 text-gray-400 bg-white">
            <img
              width="24"
              height="24"
              class="inline cursor-pointer"
              src="../../../assets/icons/icon-location.svg"
            />
          </div>
        </label>
        <label class="relative bloc grid mb-4" v-else>
          <input
            placeholder="Mi ubicación"
            :value="data.direction"
            class="input text-sm w-full font-semibold pr-12 text-[black]"
            @keypress="updateDirection"
          />

          <div class="absolute right-6 bottom-6 text-gray-400 bg-white">
            <img
              width="24"
              height="24"
              class="inline cursor-pointer"
              src="../../../assets/icons/icon-location.svg"
            />
          </div>
        </label>
        <button
          @click.prevent="openFormDirection"
          class="btn btn-blue block w-full"
          v-if="selectStatus"
        >
          Actualizar
        </button>

        <button class="btn btn-blue block w-full" v-else>Actualizar</button>
      </div>
    </div>
  </span>
</template>
<script>
export default {
  data() {
    return {
      //esta variable sirve para detectar si el usuario solo dio enter o si selecciono una opcion de direcciòn
      inputDirection: false,
      selectStatus: false,
      style: {
        direction: false,
      },
      error: {
        direccion: false,
      },
      data: {
        direction: "Ubicación",
      },
      user: {
        name: "",
      },
    };
  },
  mounted() {
    const direction = localStorage.getItem("direction");
    if (direction !== null) {
      const dataDirection = JSON.parse(direction);
      this.data.direction = dataDirection.nombre;
      this.inputDirection = true;
    }
    const user = localStorage.getItem("auth");
    if (user !== null) {
      const dataUser = JSON.parse(user);
      this.user.name = dataUser.globalName.split(" ")[0];
    } else {
      this.user.name = "";
    }
  },

  onMounted() {
    this.selectStatus = false;
  },
  methods: {
    updateDirection() {
      this.inputDirection = false;
    },
    setPlace(place) {
      var nombreDirection = place.name;

      var lat = place.geometry.location.lat();
      var lng = place.geometry.location.lng();
      //Obtener distrito-departamento-provincia
      var addressComponents = place.address_components;
      var departamento = "";
      var provincia = "";
      var distrito = "";
      var country = "";
      var location = 0;
      addressComponents.forEach((value) => {
        if (value.types[0] === "administrative_area_level_1") {
          departamento = value.long_name;
        }
        if (value.types[0] === "administrative_area_level_2") {
          provincia = value.long_name;
        }
        if (value.types[0] === "locality") {
          distrito = value.long_name;
        }
        if (value.types[0] === "country") {
          country = value.short_name;
        }
        //Determinamos si la direcciòn pertenece a Lima
        //  value.long_name === "Callao Region"
        //   value.long_name === "Callao"
        if (
          value.long_name === "Gobierno Regional de Lima" ||
          value.long_name === "Provincia de Lima"
        ) {
          location = 1;
        }
      });
      if (country !== "PE" || country === "") {
        nombreDirection = "";
        lat = 0;
        lng = 0;
        this.error.direccion = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Solo direcciones de Perú";
        this.$store.state.alert.estado = true;
        //--------------------------//
      } else {
        this.selectStatus = true;
        this.data.direction = nombreDirection;
        this.error.direccion = false;
        const data = {};
        data.nombre = nombreDirection;
        data.lat = lat;
        data.lng = lng;
        data.departamento = departamento;
        data.provincia = provincia;
        data.distrito = distrito;
        data.location = location;
        const dataCompress = JSON.stringify(data);
        window.localStorage.setItem("direction", dataCompress);
      }
    },
    openFormDirection() {
      this.style.direction = !this.style.direction;
      if (this.style.direction === true) {
        document.body.classList.add("overflow-y-hidden");
        this.selectStatus = false;
      }
      if (this.style.direction === false) {
        document.body.classList.remove("overflow-y-hidden");
        this.selectStatus = false;
      }
    },
    closeModal(e) {
      const modalclose = document
        .getElementById("modalConfirm2")
        .contains(e.target);

      if (modalclose === false) {
        this.style.direction = false;
        this.selectStatus = false;
        document.body.classList.remove("overflow-y-hidden");
      }
    },
  },
};
</script>
