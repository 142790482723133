<template>
  <!-- Popup -->
  <popUp />
  <!-- Barra lateral cuando no inicia sesión -->
  <invitado-component
    :estado="estado"
    @openLogin="login = $event"
    @openRegister="registro = $event"
    @changeService="selectService($event)"
    @openTracking="statusModalTracking = $event"
  />
  <!-- Barra lateral cuano inicia sesión -->
  <user-component
    :dataUser="dataUser"
    :estado="estado"
    @changeService="selectService($event)"
    @openTracking="statusModalTracking = $event"
  />

  <!--Login -->
  <login-component
    :login="login"
    @closeLogin="login = $event"
    @openRegister="registro = $event"
  />
  <!--Registro--->
  <registro-component :registro="registro" @closeRegister="registro = $event" />

  <!--Registro -->

  <header
    class="fixed top-0 inset-x-0 z-30 h-14 xl:h-24 bg-bg-black-blue text-white"
    style="z-index: 21"
  >
    <div
      class="u-container py-3 xl:py-5 flex items-center justify-center xl:justify-between"
    >
      <router-link :to="{ name: 'Home' }">
        <figure>
          <img src="../../assets/LOGO.png" class="w-24 xl:w-auto" />
        </figure>
      </router-link>

      <div class="w-1/3 hidden xl:block relative">
        <form>
          <button @click.prevent="searchProduct">
            <img
              src="../../assets/icons/icon-search.svg"
              alt="Buscar"
              class="absolute left-6 bottom-4 cursor-pointer"
            />
          </button>

          <input
            type="text"
            :class="shooper.error ? 'ring ring-mainRed' : ''"
            v-model="shooper.name"
            placeholder="Ingresa el enlace o nombre"
            class="text-textColor py-4 pl-16 px-8 rounded-2xl w-full focus:outline-none focus:ring focus:ring-mainBlue"
          />
        </form>
      </div>
      <nav class="hidden xl:flex items-center gap-8">
        <direction-component />

        <button
          id="btn-servicios"
          class="relative font-medium text-sm flex items-center gap-2"
        >
          Servicios

          <img src="../../assets/icons/chevron-down-orange.png" alt="" />
          <span
            class="oculto absolute bg-white border shadow-lg py-2 -bottom-28 left-0 rounded-xl text-left text-textColor"
          >
            <router-link
              :to="{ name: 'Home' }"
              @click.prevent="selectService(1)"
              @contextmenu="menuService(1)"
              :class="
                typeService === 1 || currentRouteName === 'Courier'
                  ? 'text-mainOrange underline'
                  : ''
              "
              class="flex items-center gap-2 text-xs py-3 px-4 w-full transition-colors hover:bg-mainBlue hover:bg-opacity-20 w-full"
            >
              Servicio Courier
            </router-link>
            <router-link
              :to="{ name: 'Home' }"
              @click.prevent="selectService(2)"
              @contextmenu="menuService(2)"
              :class="
                typeService === 2 ||
                currentRouteName === 'Shooper' ||
                currentRouteName === 'SearchShooper'
                  ? 'text-mainOrange underline'
                  : ''
              "
              class="flex items-center gap-2 text-xs py-3 px-4 w-full transition-colors hover:bg-mainBlue hover:bg-opacity-20 w-max"
            >
              Servicio Shopper
            </router-link>
          </span>
        </button>
        <button
          @click.prevent="changeStatusModalTracking"
          class="font-medium text-sm"
        >
          Tracking
        </button>
        <!-- Sin sesión iniciada -->

        <button
          @click.prevent="openLogin"
          class="font-medium text-sm"
          v-if="!estado"
        >
          Iniciar sesíon
        </button>
        <button
          @click.prevent="openRegister"
          class="font-medium text-sm py-4 px-8 bg-mainOrange rounded-2xl"
          v-if="!estado"
        >
          Registrarse
        </button>

        <!-- Con sesión iniciada -->

        <div class="h-12 border-r shadow-sm" v-if="estado"></div>
        <div
          class="flex items-center gap-5 cursor-pointer"
          @click.prevent="styleNav(1)"
          v-if="estado"
        >
          <button class="font-bold">
            {{ dataUser.globalName.split(" ")[0] }}
          </button>
          <img src="../../assets/icons/drop-down-gray.svg" class="mr-2" />
          <img
            v-if="dataUser.globalImage === null"
            class="w-14 h-14 rounded-full"
            src="../../assets/icons/usuario.png"
          />

          <img
            class="w-14 h-14 rounded-full"
            v-else
            :src="dataUser.globalImage"
          />
        </div>
      </nav>
      <img
        id="menu-btn"
        @click.prevent="estado ? styleNav(1) : styleNav(2)"
        class="absolute top-3 left-3 cursor-pointer xl:hidden"
        src="../../assets/icons/menu-white.svg"
      />
      <button
        type="button"
        v-on:click="openModal"
        class="bg-mainOrange px-5 absolute top-0 bottom-0 right-0 xl:hidden"
      >
        <img
          class=""
          src="../../assets/icons/icon-search-white.svg"
          alt="Buscar..."
        />
      </button>
    </div>
  </header>

  <div
    id="tracking"
    @click.prevent="detectClick"
    :class="statusModalTracking ? '' : 'oculto'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div class="px-8 py-12 bg-white rounded-2xl border shadow-sm">
      <h3 class="font-bold px-12 mb-8 text-center xl:text-xl">
        ¡Nunca pierdas de vista <br />
        tu producto!
      </h3>
      <!-- Departamento -->
      <label class="relative block mb-4 border shadow-sm rounded-2xl">
        <input
          class="text-center rounded-2xl py-5 px-6 text-sm w-full font-semibold"
          :class="error.code ? 'ring ring-mainRed' : ''"
          v-model="data.code"
          type="text"
          placeholder="Ingresa el número de tracking"
        />
        <div
          class="absolute right-0 pr-6 inset-y-0 flex items-center bg-white rounded-2xl"
        >
          <img
            class="inline cursor-pointer"
            src="../../assets/icons/icon-search.svg"
          />
        </div>
      </label>
      <button
        @click.prevent="getDataTracking"
        class="rounded-2xl bg-mainGreenLight py-5 text-white font-semibold block w-full"
      >
        Buscar
      </button>
    </div>
  </div>

  <div
    id="compra"
    @click.prevent="detectClick2"
    :class="estadoModal ? '' : 'hidden'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div class="px-8 py-12 bg-white rounded-2xl border shadow-sm mx-4">
      <h3 class="font-bold xl:px-12 mb-8 text-center xl:text-xl">
        ¡Busca el producto en la tienda de USA <br class="hidden xl:block" />
        y pega el link!
      </h3>
      <label class="relative block mb-4 border shadow-sm rounded-2xl">
        <input
          type="text"
          class="rounded-2xl py-5 px-6 text-sm w-full font-semibold"
          placeholder="www.tienda.com/producto"
          v-model="shooper.name"
        />
        <div
          class="absolute right-0 pr-6 inset-y-0 flex items-center bg-white rounded-2xl"
        >
          <img
            v-if="shooper.name.length === 0"
            class="inline cursor-pointer"
            src="../../assets/icons/buscar.svg"
            alt="Icono de Envios USA"
          />
          <img
            v-else
            @click.prevent="shooper.name = ''"
            class="inline cursor-pointer"
            src="../../assets/icons/exit.svg"
            alt="Icono de Envios USA"
          />
        </div>
      </label>
      <button
        @click.prevent="searchProduct"
        @keydown="searchProduct"
        @touchstart="searchProduct"
        class="rounded-2xl bg-mainGreenLight py-5 text-white font-bold block w-full"
      >
        Buscar
      </button>
    </div>
  </div>
</template>
<script>
import invitadoComponent from "../global/lateral/invitadoComponent.vue";
import userComponent from "../global/lateral/userComponent.vue";
import loginComponent from "../global/autentificacion/loginComponent.vue";
import registroComponent from "../global/autentificacion/registroComponent.vue";
import directionComponent from "../../components/home/direction/directionComponent.vue";
import popUp from "./popUp.vue";
import { server } from "../../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  components: {
    invitadoComponent,
    userComponent,
    loginComponent,
    registroComponent,
    directionComponent,
    popUp,
  },
  props: ["typeService", "openLoginProcces", "name"],
  data() {
    return {
      estadoModal: false,
      statusModalTracking: false,
      estado: false,
      login: this.openLoginProcces,
      registro: false,
      dataUser: {},

      error: {
        code: false,
      },
      data: {
        code: "",
      },
      shooper: {
        name: this.name ? this.name : "",
        error: false,
      },
    };
  },
  created() {
    const estado = localStorage.getItem("auth");
    if (estado !== null) {
      this.estado = true;
      this.dataUser = JSON.parse(estado);
    } else {
      this.estado = false;
    }
  },
  mounted() {
    if (localStorage.getItem("open")) {
      this.login = true;
    }
    const mostrar = (element) => {
      element.classList.remove("oculto");
      element.classList.add("visible");
    };
    const ocultar = (element) => {
      element.classList.remove("visible");
      element.classList.add("oculto");
    };
    const menuBtn = document.getElementById("menu-btn");

    if (this.estado === false) {
      const menu2 = document.getElementById("menu2");

      menuBtn.addEventListener("click", () => {
        mostrar(menu2);
        setTimeout(() => {
          menu2.firstElementChild.classList.remove("oculto-izquierda");
        }, 300);
      });

      menu2.addEventListener("click", (e) => {
        if (e.target.matches("#menu2")) {
          ocultar(menu2);
          setTimeout(() => {
            menu2.firstElementChild.classList.add("oculto-izquierda");
          }, 1);
        }
      });
    }

    if (this.estado) {
      const menu = document.getElementById("menu");

      menuBtn.addEventListener("click", () => {
        mostrar(menu);
        setTimeout(() => {
          menu.firstElementChild.classList.remove("oculto-izquierda");
        }, 300);
      });

      menu.addEventListener("click", (e) => {
        if (e.target.matches("#menu")) {
          ocultar(menu);
          setTimeout(() => {
            menu.firstElementChild.classList.add("oculto-izquierda");
          }, 1);
        }
      });
    }
    const $servicios = document.querySelector("#btn-servicios span");
    addEventListener("click", (e) => {
      if (e.target.matches("#btn-servicios")) {
        $servicios.classList.toggle("oculto");
      } else {
        $servicios.classList.add("oculto");
      }
    });
  },
  methods: {
    openModal() {
      this.estadoModal = !this.estadoModal;
    },
    detectClick(e) {
      const data = e.target.matches("#tracking");
      if (data) {
        this.statusModalTracking = false;
        this.error.code = false;
        this.data.code = "";
      }
    },
    detectClick2(e) {
      const data = e.target.matches("#compra");
      if (data) {
        this.estadoModal = false;
        this.shooper.name = "";
        this.shooper.error = false;
      }
    },

    validateNameShooper() {
      const data = this.shooper.name;
      const result = data.length === 0 ? false : true;
      result ? (this.shooper.error = false) : (this.shooper.error = true);
      return result;
    },
    searchProduct() {
      const result = this.validateNameShooper();
      if (result) {
        window.localStorage.removeItem("shooperProducts");
        this.$router.push({
          name: "SearchShooper",
          params: { name: this.shooper.name },
        });
      }
    },
    validateCode() {
      const data = this.data.code;
      if (data.length !== 0) {
        this.error.code = false;
        return true;
      } else {
        this.error.code = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese el código de tracking";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    getDataTracking() {
      const validateTracking = this.validateCode();
      if (validateTracking) {
        axios
          .get(
            `${BASE_URL}` +
              "package/" +
              this.data.code.trim() +
              "/getSystemTracks"
          )
          .then((response) => {
            if (response.status === 200) {
              this.error.code = false;
              this.statusModalTracking = false;
              this.$router.push({
                name: "Tracking",
                params: { code: this.data.code },
              });
            }
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.error.code = true;
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "El código ingresado no existe";
              this.$store.state.alert.estado = true;
              //--------------------------//
            } else {
              this.error.code = true;
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description = "Código inválido";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
    },
    changeStatusModalTracking() {
      this.statusModalTracking = !this.statusModalTracking;
    },

    menuService(data) {
      window.localStorage.setItem("servicio", data);
    },
    openLogin() {
      this.login = !this.login;
    },
    openRegister() {
      this.registro = !this.registro;
      this.login = false;
    },
    styleNav(data) {
      const mostrar = (element) => {
        element.classList.remove("oculto");
        element.classList.add("visible");
      };

      if (data === 1) {
        let menu = document.getElementById("menu");
        mostrar(menu);
        setTimeout(() => {
          menu.firstElementChild.classList.remove("oculto-izquierda");
        }, 300);
      }
      if (data === 2) {
        let menu = document.getElementById("menu2");
        mostrar(menu);
        setTimeout(() => {
          menu.firstElementChild.classList.remove("oculto-izquierda");
        }, 300);
      }
    },
    selectService(data) {
      if (this.currentRouteName === "Home") {
        this.$emit("changeService", data);
      } else {
        window.localStorage.setItem("servicio", data);
      }
      this.service = false;
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  watch: {
    openLoginProcces: function () {
      this.openLogin();
    },
    name: function (val) {
      this.shooper.name = val;
    },
  },
};
</script>
