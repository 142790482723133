<template>
  <!-- Modal de cual es el peso -->
  <div
    :class="modalPeso ? '' : 'oculto'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div
      class="relative mx-4 px-8 xl:px-20 py-24 bg-white rounded-2xl border shadow-sm"
    >
      <p class="font-semibold text-2xl xl:text-4xl text-center mb-8 xl:mb-16">
        ¿Cuál es el peso?
      </p>
      <!-- Imagenes -->
      <div class="flex items-center xl:gap-28 mb-6 w-full justify-between">
        <img
          src="../../assets/images/producto-camara.png"
          class="w-12 xl:w-28 h-auto object-contain"
          alt="Icono de Sendi"
        />
        <img
          src="../../assets/images/la-caja.png"
          class="w-12 xl:w-28 h-auto object-contain"
          alt="Icono de Sendi"
        />
        <img
          src="../../assets/images/peso-completo.png"
          class="w-12 xl:w-28 h-auto object-contain"
          alt="Icono de Sendi"
        />
      </div>
      <!-- Textos -->
      <div class="flex items-center justify-between mb-8">
        <h3 class="font-bold text-center text-xs xl:text-base">Tu producto</h3>
        <h3 class="font-bold text-center text-xs xl:text-4xl">+</h3>
        <h3 class="font-bold text-center text-xs xl:text-base">La caja</h3>
        <h3 class="font-bold text-center text-xs xl:text-4xl">=</h3>
        <h3 class="font-bold text-center text-xs xl:text-base text-mainBlue">
          El peso <br />
          completo
        </h3>
      </div>
      <button
        @click.prevent="changeModalPeso"
        class="mb-6 text-white bg-mainBlue font-bold text-sm xl:text-base py-6 w-full xl:w-3/5 block mx-auto rounded-2xl"
      >
        Aceptar
      </button>
      <p class="text-center text-sm xl:text-base">
        Considerar peso completo.
        <a
          href="https://sendi.freshdesk.com/a/solutions/articles/73000554397"
          target="_blank"
          class="underline font-semibold"
          >Más información</a
        >
      </p>
      <img
        @click.prevent="changeModalPeso"
        src="../../assets/icons/icon-exit-login.svg"
        class="cursor-pointer absolute top-8 right-4 xl:right-8"
        alt="Icono de Sendi"
      />
    </div>
  </div>
  <!-- fin Modal de cual es el peso -->
  <preload-shooper-flujo-1-component :preload="preload" />

  <div
    class="px-4 xl:px-9 py-4 rounded-2xl bg-white border shadow-lg xl:border-none xl:shadow-none xl:py-0"
  >
    <div class="mb-8">
      <label
        for="nombre_producto"
        class="block mb-2 font-semibold text-xs xl:text-sm"
        >Nombre del producto</label
      >
      <input
        id="nombre_producto"
        class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
        type="text"
        placeholder="Ingresa el nombre del producto"
        v-model="product.name"
      />
    </div>

    <div class="mb-8" v-if="errorShooper === false">
      <label class="block mb-2 font-semibold text-xs xl:text-sm"
        >Foto referencial</label
      >
      <div class="relative block border shadow-sm rounded-2xl py-5 px-16 mb-24">
        <img
          id="image-screen"
          class="w-40 xl:w-56 mx-auto cursor-pointer mb-10 block transition-colors"
          :src="product.foto"
        />

        <div class="absolute inset-x-0 -bottom-10 flex justify-center">
          <div
            class="py-4 px-6 xl:py-5 xl:px-10 flex gap-6 border-2 shadow-sm rounded-2xl bg-white"
          >
            <button class="px-2 cursor-pointer">
              <img
                @click.prevent="cantidadStock(0)"
                class="cursor-pointer transform hover:scale-90 transition-all"
                src="../../assets/icons/icon-resta.svg"
              />
            </button>
            <input
              class="w-16 text-center font-semibold text-2xl xl:text-3xl"
              type="number"
              v-model="product.cantidad"
              disabled
            />
            <button class="px-2 cursor-pointer">
              <img
                @click.prevent="cantidadStock(1)"
                class="cursor-pointer transform hover:scale-90 transition-all"
                src="../../assets/icons/icon-suma.svg"
              />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-8" v-if="errorShooper === true">
      <label class="block mb-2 font-semibold text-xs xl:text-sm"
        >Foto referencial</label
      >
      <div class="relative block border shadow-sm rounded-2xl py-5 px-16 mb-24">
        <label class="cursor-pointer mb-8">
          <img
            v-if="product.foto.length === 0"
            class="w-40 xl:w-56 h-40 mx-auto cursor-pointer mb-4 transition-colors"
            id="image"
            src="../../assets/images/camara_courier.svg"
          />

          <img
            v-else
            class="w-40 xl:w-56 h-40 mx-auto cursor-pointer mb-4 transition-colors"
            id="image"
            :src="product.foto"
          />

          <input
            type="file"
            name="image"
            class="hidden"
            accept="image/png,image/jpeg,image/jpg"
            @change="previewImage"
          />
        </label>

        <img
          @click.prevent="product.foto = ''"
          class="absolute top-5 right-5 cursor-pointer"
          src="../../assets/icons/icon-exit-login.svg"
        />

        <div class="absolute inset-x-0 -bottom-10 flex justify-center">
          <div
            class="py-4 px-6 xl:py-5 xl:px-10 flex gap-6 border-2 shadow-sm rounded-2xl bg-white"
          >
            <button class="px-2 cursor-pointer">
              <img
                @click.prevent="cantidadStock(0)"
                class="cursor-pointer transform hover:scale-90 transition-all"
                src="../../assets/icons/icon-resta.svg"
              />
            </button>
            <input
              class="w-16 text-center font-semibold text-2xl xl:text-3xl"
              type="number"
              v-model="product.cantidad"
              disabled
            />
            <button class="px-2 cursor-pointer">
              <img
                @click.prevent="cantidadStock(1)"
                class="cursor-pointer transform hover:scale-90 transition-all"
                src="../../assets/icons/icon-suma.svg"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="grid xl:grid-cols-2 gap-8 xl:gap-6 mb-8 xl:mb-8">
      <div>
        <label
          for="precio_referencial"
          class="block mb-2 font-semibold text-xs xl:text-sm"
          >Precio referencial</label
        >
        <div class="relative">
          <div
            class="absolute left-6 inset-y-0 flex items-center font-bold text-mainOrange"
          >
            <span>USD</span>
          </div>
          <input
            class="pl-16 p-6 input w-full font-semibold focus:border-blue-400 bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl"
            type="number"
            v-model="product.precio"
            @keydown="typeNumber"
          />
          <span class="absolute bottom-2 left-6 text-xs text-gray-400"
            >Precio Max. USD 3000.00</span
          >
          <div
            class="cursor-pointer absolute right-6 inset-y-0 flex items-center text-mainRed"
          >
            <img
              @mouseover="popUp = true"
              @mouseleave="popUp = false"
              id="popup-btn"
              src="../../assets/icons/question-black.svg"
            />
            <!-- POP UP -->
            <div
              v-if="popUp"
              class="z-10 absolute -top-16 xl:-top-20 -right-14 xl:-right-32 bg-mainRed text-white text-xs xl:text-sm text-center p-4 rounded-2xl w-max transition-all delay-400"
            >
              Si el precio de tu producto excede el valor máximo,
              <span class="block">
                comunícate con atención al cliente para poder ayudarte
              </span>
              <span
                class="absolute -bottom-7 right-10 xl:right-28 w-14 h-8 triangle bg-mainRed"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <label for="peso" class="block mb-2 font-semibold text-xs xl:text-sm"
          >Peso</label
        >
        <div class="relative">
          <input
            class="p-6 input w-full font-bold focus:border-blue-400 bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl"
            type="number"
            v-model="product.peso"
            @keydown="typeNumber"
          />
          <div class="absolute right-6 inset-y-0 flex items-center font-bold">
            <span
              @click.prevent="changePeso('kg')"
              :class="product.unidad === 'kg' ? 'text-mainOrange' : ''"
              class="cursor-pointer px-3 hover:text-mainOrange transition-colors"
              >kg</span
            >
            <span
              @click.prevent="changePeso('lb')"
              :class="product.unidad === 'lb' ? 'text-mainOrange' : ''"
              class="cursor-pointer px-3 hover:text-mainOrange transition-colors border-l border-r"
              >lb</span
            >
            <span
              @click.prevent="changePeso('oz')"
              :class="product.unidad === 'oz' ? 'text-mainOrange' : ''"
              class="cursor-pointer px-3 hover:text-mainOrange transition-colors border-r"
              >oz</span
            >
            <img
              @click.prevent="changeModalPeso"
              src="../../assets/icons/icon-info.svg"
              class="pl-3 cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mb-8">
      <label
        for="descripcion_producto"
        class="block mb-2 font-semibold text-xs xl:text-sm"
        >Describe tu producto
        <span class="text-mainOrange">(Opcional)</span></label
      >
      <div class="relative">
        <textarea
          v-model="product.description"
          id="descripcion_producto"
          class="pb-14 py-5 px-6 border text-sm xl:text-base w-full rounded-2xl focus:outline-none p-6 text-gray-400 resize-none focus:ring focus:ring-blue-400"
          placeholder="Talla, tamaño, capacidad, color, etc"
          rows="3"
        ></textarea>
        <p
          class="absolute bottom-2 inset-x-0 bg-white font-bold text-xs xl:text-sm mx-6 pt-2 pb-4 border-t pt-3"
        >
          Ayúdanos a elegir correctamente tu producto
        </p>
      </div>
    </div>
    <!-- Descripcion del producto -->
  </div>
  <div
    :class="$store.state.errorShooper ? '' : 'oculto'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div
      class="relative mx-4 px-8 xl:px-32 pb-12 p-24 xl:py-32 xl:pb-20 bg-white rounded-2xl border shadow-sm"
    >
      <img
        class="mb-8 xl:mb-12 mx-auto"
        src="../../assets/images/alert-error.svg"
        style="animation-duration: 2s"
      />
      <p class="font-semibold text-lg text-center mb-8">
        No se logró obtener los datos del producto
      </p>
      <button
        @click.prevent="$store.state.errorShooper = false"
        class="py-5 px-16 block mx-auto mb-4 rounded-2xl bg-mainGreenLight text-white font-bold text-sm"
      >
        Cotizar manualmente
      </button>

      <a
        @click.prevent="linkWhatsapp"
        class="text-mainGreenLight text-center text-sm block font-medium flex item-center justify-center gap-3 cursor-pointer"
      >
        <img src="../../assets/icons/whatsapp.png" alt="" /> Cotizar por
        WhatsApp</a
      >
      <button
        type="button"
        @click.prevent="$store.state.errorShooper = false"
        class="absolute top-6 right-6"
      >
        <img src="../../assets/icons/icon-exit.svg" />
      </button>
    </div>
  </div>
</template>
<script>
import preloadShooperFlujo1Component from "../preload/preloadShooperFlujo1.vue";
import { server } from "../../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  props: ["changeMoney"],
  components: { preloadShooperFlujo1Component },
  data() {
    return {
      errorShooper: false,
      idUsuario: null,
      modalPeso: false,
      preload: false,
      popUp: false,
      url: this.$route.params.url,
      stock: 0,
      product: {
        store: "Sin URL",
        name: "",
        precio: 0.0,
        peso: 0.0,
        unidad: "kg",
        cantidad: 1,
        foto: "",
        vaucher: "",
        description: "",
        link: "",
      },
      idCotizacion: null,
    };
  },
  mounted() {
    this.getDataLocalStorage();
    const data = localStorage.getItem("producDetail");
    if (this.$route.query.sharedProduct !== "true") {
      if (data !== null) {
        const dataProduct = JSON.parse(data);
        this.errorShooper = dataProduct.error;
        this.product.name = dataProduct.name;
        this.product.foto = dataProduct.imagen;
        this.product.precio = dataProduct.precio;
        this.product.peso = dataProduct.peso;
        this.product.unidad = dataProduct.unidad;
        this.product.link = dataProduct.linkReference;
        this.product.store = dataProduct.store;
        this.stock = dataProduct.stock;
        if (dataProduct.cantidad !== undefined) {
          this.product.cantidad = dataProduct.cantidad;
        }
        this.calculo();
      } else {
        if (this.$store.state.errorShooper === false) {
          this.getProduct();
          this.calculo();
        } else {
          this.errorShooper = true;
          this.product.link = this.url;
        }
      }
    } else {
      this.getProduct();
      this.calculo();
      this.$router.replace({ query: undefined });
    }
    if (this.errorShooper === false) {
      this.createCotizacion();
    }
  },
  methods: {
    createCotizacion() {
      axios
        .post(`${BASE_URL}` + "quotation/save", {
          item_url: this.url,
          product_image_url: this.product.foto,
          product_name: this.product.name,
          price: this.product.precio,
          idUsuario: this.idUsuario,
          idStore: 1,
        })
        .then((response) => {
          this.idCotizacion = response.data.body.id;
        })
        .catch(() => {});
    },
    previewImage(e) {
      const file = e.target.files[0];
      this.cargarImagen(file);
    },
    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.product.foto = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    linkWhatsapp() {
      window.open(
        "https://api.whatsapp.com/send?phone=51968727182&text=Hola%2C%20requiero%20ayuda%20con%20mi%20pedido%20%F0%9F%93%A6%3A%20%20" +
          this.url,
        "_blank"
      );
    },
    changeModalPeso() {
      this.modalPeso = !this.modalPeso;
    },
    saveLocalStorage() {
      const objProduct = {};
      objProduct.error = this.errorShooper;
      objProduct.name = this.product.name;
      objProduct.imagen = this.product.foto;
      objProduct.stock = this.stock;
      objProduct.precio = this.product.precio;
      objProduct.peso = this.product.peso;
      objProduct.unidad = this.product.unidad;
      objProduct.linkReference = this.product.link;
      objProduct.store = this.product.store;
      objProduct.cantidad = this.product.cantidad;
      const dataCompress = JSON.stringify(objProduct);
      window.localStorage.setItem("producDetail", dataCompress);
    },
    typeNumber($event) {
      const val = $event.target.value;
      if (val.includes(".")) {
        const dotIndex = val.indexOf(".");
        const valLength = val.length;
        const diferencia = valLength - dotIndex;
        if (
          diferencia > 2 &&
          $event.key !== "Backspace" &&
          $event.key !== "Tab"
        ) {
          $event.preventDefault();
        }
      }
    },
    cantidadStock(data) {
      const datamin = 1;
      const datmax = this.stock;
      if (data === 1) {
        if (this.product.cantidad < datmax) {
          this.product.cantidad++;
          this.calculate();
        }
      }
      if (data === 0) {
        if (this.product.cantidad > datamin) {
          this.product.cantidad--;
          this.calculate();
        }
      }
    },
    calculo() {
      //Operación par amandar al detalle del pedido
      //variables declaradas

      var price = 0.0;
      if (isNaN(this.product.precio)) {
        price = 0.0;
      } else {
        price = this.product.precio;
      }

      var cantidad = 0;
      if (isNaN(this.product.cantidad)) {
        cantidad = 0;
      } else {
        cantidad = this.product.cantidad;
      }

      var pesoProduct = 0.0;
      if (isNaN(this.product.peso)) {
        pesoProduct = 0.0;
      } else {
        pesoProduct = this.product.peso;
      }

      var peso = pesoProduct * cantidad;

      var unidad = this.product.unidad;
      //Conversión para onvertir a kg si el peso es diferente
      if (unidad === "lb") {
        peso = peso * (1 / 2.2046);
      } else if (unidad === "oz") {
        peso = peso * (1 / 35.274);
      }
      if (peso < 1) {
        peso = 1;
      }

      //-------Precio-----------//
      var precio = price * cantidad;
      precio = Math.ceil(100 * precio) / 100;
      this.$store.state.servicePrecioProduct = precio;

      var precioSoles = 0.0;
      precioSoles = precio * this.changeMoney;
      precioSoles = Math.ceil(100 * precioSoles) / 100;
      //-------shipping-----------//
      var shipping = 0.0;

      //-------tax-----------//
      var tax = (7 / 100) * precio;

      var taxSoles = 0.0;
      taxSoles = tax * this.changeMoney;
      taxSoles = Math.ceil(100 * taxSoles) / 100;

      //----------Flete------------//
      var flete = 10 * peso;
      flete = Math.ceil(flete);

      var fleteSoles = 0.0;
      fleteSoles = flete * this.changeMoney;
      fleteSoles = Math.ceil(100 * fleteSoles) / 100;

      //----------warehouse------------//
      var warehouse =
        (7 / 100) * precio +
        (0.75 / 100) * (precio + shipping + (7 / 100) * precio) -
        precio * 0.07;
      warehouse = Math.ceil(100 * warehouse) / 100;

      var warehouseSoles = 0.0;
      warehouseSoles = warehouse * this.changeMoney;
      warehouseSoles = Math.ceil(100 * warehouseSoles) / 100;

      //-------total-Cif-----------//
      var totalCif = precio + shipping + tax + flete + warehouse;
      totalCif = Math.ceil(100 * totalCif) / 100;
      var totalCifSoles = 0.0;

      totalCifSoles =
        precioSoles + shipping + taxSoles + fleteSoles + warehouseSoles;
      totalCifSoles = Math.ceil(100 * totalCifSoles) / 100;

      //------------------------------------------//
      //-----------------IMPUESTOS - "mayor a 200 se cobra impuestos"----------------------//
      //------------------------------------------//
      var valorem = 0.0;
      var igv = 0.0;
      var ipm = 0.0;

      var valoremSoles = 0.0;
      var igvSoles = 0.0;
      var ipmSoles = 0.0;
      // 1 -> si se cobrra impuesto y 0  no se cobra impueto
      var estadoImpuesto = 0;
      if (precio > 200) {
        //estado del impuesto
        estadoImpuesto = 1;
        //Ad/Valorem
        valorem = (4 / 100) * totalCif;
        valorem = Math.ceil(valorem);
        valoremSoles = valorem * this.changeMoney;
        valoremSoles = Math.ceil(valoremSoles);
        //Igv
        igv = (16 / 100) * (valorem + totalCif);
        igv = Math.ceil(igv);
        igvSoles = igv * this.changeMoney;
        igvSoles = Math.ceil(igvSoles);
        //IPM
        ipm = (2 / 100) * (valorem + totalCif);
        ipm = Math.ceil(ipm);
        ipmSoles = ipm * this.changeMoney;
        ipmSoles = Math.ceil(ipmSoles);
      }
      // Total de impuestos -> valorem + igv + ipm
      var totalImpuesto = valorem + igv + ipm;
      totalImpuesto = Math.ceil(100 * totalImpuesto) / 100;

      var totalImpuestoSoles = valoremSoles + igvSoles + ipmSoles;
      totalImpuestoSoles = Math.ceil(100 * totalImpuestoSoles) / 100;
      //------------------------------------------//
      var storeNameLink = this.product.link.split(".", 2);

      this.$emit("productoCalculo", {
        servicio: 2,
        // store: this.product.store.length === 0 ? storeNameLink[1] : this.product.store,
        store: storeNameLink[1] ?? "Tienda no encontrada",
        producto: {
          nombre: this.product.name,
          precio: this.product.precio,
          peso: this.product.peso * cantidad,
          unidad: this.product.unidad,
          cantidad: this.product.cantidad,
          foto: this.product.foto,
          vaucher: this.product.vaucher,
          description: this.product.description,
          link: this.product.link,
          idCotizacion: this.idCotizacion,
        },
        dolares: {
          primera: {
            precio: precio.toFixed(2),
            shipping: shipping.toFixed(2),
            tax: tax.toFixed(2),
            frontTax: (0.0).toFixed(2),
            warehouse: warehouse.toFixed(2),
            flete: flete.toFixed(2),
            total: totalCif.toFixed(2),
            frontTotal: (0.0).toFixed(2),
          },
          segundo: {
            estado: estadoImpuesto,
            valorem: valorem.toFixed(2),
            igv: igv.toFixed(2),
            ipm: ipm.toFixed(2),
            total: totalImpuesto.toFixed(2),
          },
        },
        soles: {
          primera: {
            precio: precioSoles.toFixed(2),
            shipping: shipping.toFixed(2),
            tax: taxSoles.toFixed(2),
            frontTax: (0.0).toFixed(2),
            warehouse: warehouse.toFixed(2),
            flete: fleteSoles.toFixed(2),
            total: totalCifSoles.toFixed(2),
            frontTotal: (0.0).toFixed(2),
          },
          segundo: {
            estado: estadoImpuesto,
            valorem: valoremSoles.toFixed(2),
            igv: igvSoles.toFixed(2),
            ipm: ipmSoles.toFixed(2),
            total: totalImpuestoSoles.toFixed(2),
          },
        },
      });
    },
    changePeso(data) {
      this.product.unidad = data;
    },
    getProduct() {
      this.preload = true;
      axios
        .get(`${BASE_URL}` + "product/getDetailByZyte", {
          params: {
            url: this.url,
            idUsuario: this.idUsuario,
          },
        })
        .then((response) => {
          this.preload = false;
          const data = response.data.body;
          this.product.name = data.title;
          this.product.foto = data.images[0];
          this.product.precio = data.original_price;
          this.product.peso = data.weight;
          this.product.unidad = data.weight_unit;
          this.product.link = data.reference_link;
          this.product.store = data.store;
          this.stock = data.stock;
          this.saveLocalStorage();
        })
        .catch((error) => {
          this.preload = false;
          if (error) {
            this.$store.state.errorShooper = true;
            this.errorShooper = true;
            this.product.link = this.url;
            this.saveLocalStorage();

            //----------component--------//
            // this.$store.state.alert.titulo = "Error";
            // this.$store.state.alert.description = "Vuelva a intentarlo";
            // this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    getDataLocalStorage() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (dataUser !== null) {
        this.idUsuario = dataUser.globalID;
      } else {
        this.idUsuario = null;
      }
    },
  },
  watch: {
    idCotizacion: function () {
      this.calculo();
    },
    "$route.params.url": function (val) {
      this.url = val;
      location.reload();
    },
    changeMoney: function () {
      this.calculo();
      this.saveLocalStorage();
    },
    "product.precio": function () {
      this.calculo();
      this.saveLocalStorage();
    },
    "product.peso": function () {
      this.calculo();
      this.saveLocalStorage();
    },
    "product.unidad": function () {
      this.calculo();
      this.saveLocalStorage();
    },
    "product.cantidad": function () {
      this.calculo();
      this.saveLocalStorage();
    },
    "product.name": function () {
      this.calculo();
      this.saveLocalStorage();
    },
    "product.link": function () {
      this.saveLocalStorage();
    },
    "product.foto": function () {
      this.saveLocalStorage();
    },
    errorShooper: function () {
      this.saveLocalStorage();
    },
  },
};
</script>
