<template>
  <section class="u-container">
    <div
      class="xl:border xl:shadow-md xl:px-8 xl:py-28 xl:rounded-2xl xl:flex items-center justify-around xl:w-11/12 mx-auto"
    >
      <article>
        <h3 class="text-2xl xl:text-5xl font-normal text-bg-black mb-1 xl:mb-2">
          Importaciones
        </h3>
        <h3 class="text-2xl xl:text-5xl text-bg-black mb-6 xl:mb-3">
          Recientes
        </h3>
      </article>

      <aside>
        <div
          v-for="(producto, indice) in products2"
          :key="producto"
          :class="indice === 1 ? 'mt-4' : ''"
          class="border shadow-xl rounded-xl xl:rounded-2xl pt-4 px-3 pb-5 xl:pt-4 xl:pb-6 xl:px-12 xl:flex gap-8 xl:mb-6"
        >
          <figure class="rounded-2xl border shadow-sm py-6 px-6 mb-4 xl:mb-0">
            <img
              :src="producto.product_image_url"
              class="h-24 w-32 mx-auto object-contain"
              alt="Producto de Sendi"
            />
          </figure>
          <div class="text-bg-black">
            <header class="flex xl:justify-between items-center mb-2">
              <img
                :src="producto.profile_image_url"
                v-if="producto.profile_image_url !== null"
                class="xl:hidden w-8 h-8 rounded-full object-cover"
                alt="Compra en Estados Unidos desde Sendi"
              />
              <img
                src="../../assets/images/user-example.svg"
                v-else
                class="xl:hidden w-8 h-8 rounded-full object-cover"
                alt="Usuario de Sendi"
              />
              <p class="text-xs ml-3 xl:ml-0">{{ producto.name }}</p>
              <p class="text-xs ml-auto">{{ producto.time }}</p>
            </header>
            <p
              class="font-semibold text-xs xl:text-lg mb-2 max-w-xs xl:mb-0 xl:w-4/5 line-clamp"
            >
              {{ producto.product_info }}
            </p>
            <p class="text-xs xl:text-sm mb-4 xl:mb-2">
              {{ producto.us_store }}
            </p>
            <div class="xl:flex gap-4 justify-between">
              <div class="grid grid-cols-2 gap-4">
                <router-link
                  :to="{
                    name: 'Shooper',
                    params: { url: producto.reference_link },
                  }"
                  class="rounded-xl bg-bg-black text-white font-semibold text-xs xl:text-sm py-4 xl:px-8 text-center order-2 xl:order-1"
                >
                  Comprar ahora
                </router-link>
                <a
                  @click.prevent="copyOrder(producto.reference_link)"
                  class="cursor-pointer rounded-xl bg-mainBlue xl:bg-mainBlue text-white font-semibold text-xs xl:text-sm py-4 xl:px-8 text-center order-1 xl:order-2"
                >
                  Compartir
                </a>
              </div>
              <img
                :src="producto.profile_image_url"
                v-if="producto.profile_image_url !== null"
                class="hidden xl:block ml-12 order-3 w-12 h-12 rounded-full object-cover"
                alt="Usuario de Sendi"
              />
              <img
                src="../../assets/images/user-example.svg"
                v-else
                class="hidden xl:block ml-12 order-3 w-12 h-12 rounded-full object-cover"
                alt="Usuario de Sendi"
              />
            </div>
          </div>
        </div>
      </aside>
    </div>
  </section>
  <section>
    <article class="u-container">
      <h2 class="font-medium text-xl xl:text-4xl mb-6 text-center">
        Con la confianza de
        <span class="xl:mt-4 xl:block">miles de clientes felices</span>
      </h2>
      <p
        class="xl:w-1/2 mx-auto xl:leading-8 text-center mb-16 text-sm xl:text-base"
      >
        Estas son las historias de nuestro clientes y las razones por que
        deciden confiar en nosotros.
      </p>
      <Splide :options="optionTestimony" :extensions="extensions">
        <SplideSlide
          class="border-2 hover:bg-white hover:border-mainBlue hover:shadow-xl rounded-xl p-5 xl:p-7 pb-12 w-full xl:w-1/3 transition-colors delay-400 cursor-pointer"
        >
          <div class="flex gap-5 items-center mb-8">
            <img src="../../assets/images/user-1.svg" />
            <div>
              <span class="block text-lg font-medium">Hugo Vaquez</span>
              <span class="block text-sm text-gray-400 -mt-1"
                >Lambayeque, Perú</span
              >
            </div>
            <div class="ml-auto flex gap-3">
              <span>4.5</span>
              <img src="../../assets/icons/estrella.svg" />
            </div>
          </div>
          <p class="text-sm xl:text-base">
            Súper recomendado, envié un paquete desde NYC a Lima.
          </p>
        </SplideSlide>
        <SplideSlide
          class="border-2 hover:bg-white hover:border-mainBlue hover:shadow-xl rounded-xl p-5 xl:p-7 pb-12 w-full xl:w-1/3 transition-colors delay-400 cursor-pointer"
        >
          <div class="flex gap-5 items-center mb-8">
            <img src="../../assets/images/user-2.svg" />
            <div>
              <span class="block text-lg font-medium">Yessica Castillo</span>
              <span class="block text-sm text-gray-400 -mt-1"
                >Huancavelica, Perú</span
              >
            </div>
            <div class="ml-auto flex gap-3">
              <span>4.5</span>
              <img src="../../assets/icons/estrella.svg" />
            </div>
          </div>
          <p class="text-sm xl:text-base">
            Excelente servicio 100% garantizado. muchas gracias.
          </p>
        </SplideSlide>
        <SplideSlide
          class="border-2 hover:bg-white hover:border-mainBlue hover:shadow-xl rounded-xl p-5 xl:p-7 pb-12 w-full xl:w-1/3 transition-colors delay-400 cursor-pointer"
        >
          <div class="flex gap-5 items-center mb-8">
            <img src="../../assets/images/user-3.svg" />
            <div>
              <span class="block text-lg font-medium">Alex Wong</span>
              <span class="block text-sm text-gray-400 -mt-1">Lima, Perú</span>
            </div>
            <div class="ml-auto flex gap-3">
              <span>4.5</span>
              <img src="../../assets/icons/estrella.svg" />
            </div>
          </div>
          <p class="text-sm xl:text-base">
            Feliz por la atención, llego a mi casa antes del tiempo y estoy
            feliz por eso.
          </p>
        </SplideSlide>
      </Splide>
    </article>
  </section>
</template>
<script>
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
//Importando librerias
import axios from "axios";
export default {
  data() {
    return {
      products2: [],
      optionTestimony: {
        classes: {
          arrows: "splide__arrows hidden",
          pagination: "splide__pagination opacity-0",
        },
        type: "loop",
        drag: "free",
        focus: "center",
        perPage: 3,
        gap: 12,
        breakpoints: {
          640: {
            perPage: 1,
          },
        },
        autoScroll: {
          speed: 1,
        },
      },
      extensions: {
        AutoScroll,
      },
    };
  },
  mounted() {
    this.getPurchaseOrder();
  },
  methods: {
    copyOrder(link) {
      var encodeLink = encodeURIComponent(link);

      var linkEncode =
        "https://sendi.com.pe/process/shooper/" +
        encodeLink +
        "?sharedProduct=true";

      navigator.clipboard
        .writeText(linkEncode)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description = "Enlace de compra copiado";
          this.$store.state.alert.estado = true;
          //--------------------------//
        })
        .catch((error) => console.log(error));
    },
    getPurchaseOrder() {
      axios
        .get(`${BASE_URL}` + "purchaseOrder/list-last-orders?store=sendi")
        .then((response) => {
          if (response.status === 200) {
            this.products2 = response.data;
          }
        })
        .catch((error) => {
          if (error) {
            this.products2 = [];
          }
        });
    },
  },
};
</script>
