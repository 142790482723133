<template>
  <div id="mapa" class="hidden xl:block">
    <!-- Mapa en tiempo real -->
    <div
      class="text-sm xl:text-base border shadow-lg rounded-2xl overflow-hidden mb-10 bg-white"
    >
      <p
        class="hidden xl:block px-4 xl:px-6 py-3 text-white font-semibold mb-6 bg-mainBlue"
      >
        Mapa en tiempo real
      </p>

      <figure style="height: 700px" class="mb-6 mt-6 px-8 xl:px-16">
        <GMapMap
          class="h-full"
          :center="center"
          :zoom="3"
          map-type-id="terrain"
        >
          <GMapCluster>
            <GMapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :icon="
                m.estado
                  ? 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                  : 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
              "
            />
          </GMapCluster>
        </GMapMap>
      </figure>
    </div>
  </div>
</template>
<script>
export default {
  props: ["center", "markers"],
  data() {
    return {};
  },
};
</script>
