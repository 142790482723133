<template>
  <!-- Loader -->
  <div
    :class="preloadShooper ? '' : 'hidden'"
    class="z-50 fixed inset-0 flex items-center justify-center bg-white bg-opacity-90"
  >
    <!-- <div class="mx-4 px-8 xl:px-16 py-24 bg-white rounded-2xl border shadow-sm"> -->
    <div class="mx-4 px-8 xl:px-16 py-24">
      <div class="relative mb-8 animate-bounce">
        <div id="cajas-container">
          <img
            src="../../assets/icons/icon-caja.svg"
            class="hidden w-16 absolute top-8 left-0 right-0 mx-auto"
            alt="Icono de Sendi"
          />
          <img
            src="../../assets/icons/icon-caja-roja.svg"
            class="hidden w-16 absolute top-8 left-0 right-0 mx-auto"
            alt="Icono de Sendi"
          />
          <img
            src="../../assets/icons/icon-caja.svg"
            class="hidden w-16 absolute top-8 left-0 right-0 mx-auto"
            alt="Icono de Sendi"
          />
          <img
            src="../../assets/icons/icon-caja-roja.svg"
            class="hidden w-16 absolute top-8 left-0 right-0 mx-auto"
            alt="Icono de Sendi"
          />
        </div>
        <img
          class="mx-auto w-32"
          src="../../assets/icons/icon-aro.svg"
          alt="Icono de Sendi"
        />
      </div>
      <p class="font-semibold text-lg text-center">
        Estamos buscando tu producto, por favor espere
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: ["preloadShooper"],
  mounted() {
    const cajasContainer = document.getElementById("cajas-container");
    const cajas = [...cajasContainer.querySelectorAll("img")];
    const cantidadMax = cajas.length - 1;
    let actual = 0;

    function moverCajas() {
      cajas[actual].classList.remove("hidden");
      cajas[actual].classList.add("mover-caja");

      setTimeout(() => {
        cajas[actual].classList.add("hidden");
        cajas[actual].classList.remove("mover-caja");

        actual === cantidadMax ? (actual = 0) : (actual = actual + 1);
      }, 2000);
    }

    moverCajas();

    setInterval(() => {
      moverCajas();
    }, 2050);
  },
};
</script>
