<template>
  <!-- Modal que salta si el usuario no verifico su Correo -->
  <div
    :class="verification ? '' : 'hidden'"
    class="fixed h-screen inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center transition-opacity delay-400"
  >
    <div
      class="mx-4 bg-[#FAFAFA] px-8 py-24 xl:py-52 xl:px-36 rounded-2xl relative"
    >
      <div>
        <h2 class="text-2xl xl:text-4xl text-center font-semibold mb-6">
          Verifica tu correo
        </h2>
        <p class="mb-6 text-center text-sm xl:text-base">
          Esta acción requiere una verificación de correo.
          <br class="hiddensm:block" />
          Por favor revisa tu buzón de correo y <br class="hidden sm:block" />
          sigue las instrucciones enviadas.
        </p>
        <p class="mb-6 text-center">El correo fue enviado a:</p>
        <p class="font-semibold text-base xl:text-lg mb-8 text-center">
          {{ email2 }}
        </p>
        <button
          @click.prevent="repetitionVerification"
          class="w-full block bg-mainBlue py-6 text-white font-bold rounded-2xl"
        >
          Reenviar
        </button>
      </div>
      <img
        @click.prevent="closeModalVerification"
        class="absolute right-4 top-4 xl:right-10 xl:top-10 cursor-pointer"
        src="../../../assets/icons/icon-exit-login.svg"
        alt="Icono de Sendi"
      />
    </div>
  </div>
  <!--Modal para recuperar contraseña---------------->
  <petition-password
    :resetPassword="resetPassword"
    @closeReset="resetPassword = $event"
  />
  <!--------------------------------------------------------------------------------------------->

  <div
    :class="login ? '' : 'oculto'"
    class="transition-all fixed inset-0 sm:py-8 bg-[#FAFDFF] sm:bg-black sm:bg-opacity-50 z-50 flex items-center justify-center"
  >
    <section
      class="max-h-screen overflow-y-auto relative u-container py-0 overflow-x-hidden bg-white sm:rounded-3xl sm:border sm:shadow-md grid xl:grid-cols-2"
    >
      <aside class="py-20 bg-[#F2F8FF] hidden xl:block">
        <h2 class="font-normal text-center mb-20">
          Bienvenido a <span class="font-semibold">SendiBox</span>
        </h2>
        <figure>
          <img
            width="561"
            height="423"
            src="../../../assets/images/login-aside.svg"
            class="mx-auto"
            alt="Icono de Sendi"
          />
        </figure>
      </aside>
      <article class="py-20 bg-[#FAFDFF]">
        <div class="sm:w-8/12 mx-auto">
          <!-- TITULO -->
          <h2 class="font-semibold text-center mb-4 text-4xl">
            Iniciar sesión
          </h2>
          <p class="text-sm text-center mb-6">
            con tu cuenta para continuar...
          </p>
          <!-- GOOGLE/FACEBOOK -->
          <div class="grid grid-cols-2 items-center gap-4">
            <gmail-component />
            <facebook-component />
          </div>
          <!-- SEPARADORES -->
          <div class="my-6 flex items-center gap-4">
            <hr class="w-full" />
            <span class="h-4 w-8 rounded-full border-2 border-gray-400"></span>
            <hr class="w-full" />
          </div>
          <!-- INPUTS -->
          <form action="" class="">
            <!-- USUARIO -->
            <label class="relative grid items-center mb-3">
              <div class="absolute left-5 bottom-5 text-gray-400 bg-white-300">
                <img
                  class="inline cursor-pointer"
                  width="29"
                  height="29"
                  src="../../../assets/icons/user-login.svg"
                  alt="Icono de Sendi"
                />
              </div>
              <input
                v-model="dataLogin.email"
                :class="errors.email ? 'ring ring-mainRed' : ''"
                class="input pl-20 w-full text-xs sm:text-sm font-semibold"
                type="email"
                placeholder="Ingrese su usuario"
              />
            </label>
            <!-- CONTRASEÑA -->
            <label class="relative grid items-center mb-6">
              <div class="absolute left-4 bottom-5 text-gray-400 bg-white">
                <img
                  class="inline cursor-pointer"
                  width="35"
                  height="35"
                  src="../../../assets/icons/password-login.svg"
                  alt="Icono de Sendi"
                />
              </div>
              <input
                v-model="dataLogin.password"
                :class="errors.password ? 'ring ring-mainRed' : ''"
                class="input pl-20 w-full text-xs sm:text-sm font-semibold"
                :type="style.passworHide ? 'text' : 'password'"
                placeholder="Ingrese su contraseña"
              />
              <div class="absolute right-4 bottom-6 text-gray-400 bg-white">
                <img
                  v-on:click="statusHidePassword"
                  class="inline cursor-pointer"
                  width="24"
                  height="15"
                  src="../../../assets/icons/icon-watch.svg"
                  alt="Icono de Sendi"
                />
              </div>
            </label>
            <!-- CHECKBOX -->
            <div
              class="mb-12 sm:mb-6 w-10/12 mx-auto flex items-center justify-between"
            >
              <div class="flex items-center gap-6">
                <div
                  @click.prevent="recordarme"
                  :class="
                    styleRecordarme
                      ? 'bg-mainBlue ring ring-mainBlue'
                      : 'bg-gray-400 ring ring-gray-400'
                  "
                  class="h-3 w-3 rounded-sm ring-offset-2 cursor-pointer"
                ></div>
                <span class="text-xs sm:text-sm">Recordarme</span>
              </div>
              <a
                @click="resetPetitionPassword"
                class="text-mainBlue text-xs sm:text-sm cursor-pointer underline"
                >Olvidé mi contraseña</a
              >
            </div>
            <!-- BUTTONS -->
            <button
              v-on:click.prevent="peticionLogin"
              class="btn btn-blue w-full py-5 mb-4"
            >
              Iniciar Sesión
            </button>
            <button
              v-on:click.prevent="changeViewRegister"
              class="btn btn-outline-blue w-full py-5"
            >
              Registrarme
            </button>
          </form>
        </div>
        <img
          @click.prevent="closeLogin"
          src="../../../assets/icons/icon-exit-login.svg"
          alt="Icono de Sendi"
          class="absolute top-8 right-4 sm:top-8 sm:right-8 cursor-pointer"
        />
      </article>
    </section>
  </div>
</template>
<script>
import facebookComponent from "../../global/autentificacion/redes/facebookComponent.vue";
import gmailComponent from "../../global/autentificacion/redes/gmailComponent.vue";
import petitionPassword from "../../global/password/petitionComponent.vue";
import { server } from "../../../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  props: ["login"],
  components: { petitionPassword, facebookComponent, gmailComponent },
  data() {
    return {
      idUser: 0,
      email2: "",
      verification: false,
      styleRecordarme: false,
      resetPassword: false,
      style: {
        passworHide: false,
      },
      dataLogin: {
        email: "",
        password: "",
      },
      errors: {
        email: false,
        password: false,
      },
    };
  },
  mounted() {
    const dataRemind = localStorage.getItem("remind");
    if (dataRemind !== null) {
      const data = JSON.parse(dataRemind);
      if (data.status) {
        this.dataLogin.email = data.email;
        this.styleRecordarme = data.status;
      }
    }
  },
  methods: {
    closeLogin() {
      this.resetdata();
      this.resetError();
      this.$emit("closeLogin", false);
      window.localStorage.removeItem("open");
    },
    resetdata() {
      this.dataLogin.email = "";
      this.dataLogin.password = "";
    },
    statusHidePassword() {
      this.style.passworHide = !this.style.passworHide;
    },
    resetPetitionPassword() {
      this.resetPassword = true;
      this.closeLogin();
    },
    recordarme() {
      this.styleRecordarme = !this.styleRecordarme;
    },
    resetError() {
      this.errors.email = false;
      this.errors.password = false;
    },
    peticionLogin() {
      const valCorreo = this.validateEmail();
      const valPassword = this.validatePassword();
      if (valCorreo && valPassword) {
        axios
          .post(`${BASE_URL}` + "client/login-client", {
            email: this.dataLogin.email,
            password: this.dataLogin.password,
            store_id: 1,
          })
          .then((response) => {
            if (response.status === 200) {
              this.idUser = response.data.body.id;
              if (response.data.body.attributes.email_verified !== 0) {
                //funcion del recordar correo
                const remind = {
                  status: this.styleRecordarme,
                  email: this.dataLogin.email,
                };
                const dataCompressRemind = JSON.stringify(remind);
                window.localStorage.setItem("remind", dataCompressRemind);
                //--------------------------------------//

                const dataId = response.data.body.id;
                const dataName =
                  response.data.body.attributes.profile.attributes.name;
                const dataLastName =
                  response.data.body.attributes.profile.attributes.surnames;
                const dataImage =
                  response.data.body.attributes.profile.attributes.userable
                    .attributes.profile_image_url;
                const dataValidateDNI =
                  response.data.body.attributes.validation.verified;
                const dataEmail =
                  response.data.body.attributes.profile.attributes.userable
                    .attributes.email;
                const dataIdentificador = response.data.body.client_code;
                const type = 1;

                //ALmaccenamos en el localstorage si con tiene una direcciòn
                if (response.data.body.attributes.addresses.length !== 0) {
                  const data = {};
                  data.nombre =
                    response.data.body.attributes.addresses[0].attributes.address;
                  data.lat =
                    response.data.body.attributes.addresses[0].attributes.lat;
                  data.lng =
                    response.data.body.attributes.addresses[0].attributes.lng;
                  data.departamento =
                    response.data.body.attributes.addresses[0].attributes.department;
                  data.provincia =
                    response.data.body.attributes.addresses[0].attributes.city;
                  data.distrito =
                    response.data.body.attributes.addresses[0].attributes.district;
                  data.location =
                    response.data.body.attributes.addresses[0].attributes.free;
                  const dataCompress = JSON.stringify(data);
                  window.localStorage.setItem("direction", dataCompress);
                }

                const dataUser = {
                  globalID: dataId,
                  globalName: dataName,
                  globalLastName: dataLastName,
                  globalImage: dataImage,
                  globalValidate: dataValidateDNI,
                  glovalEmail: dataEmail,
                  identificador: dataIdentificador,
                  type: type,
                };
                const dataCompress = JSON.stringify(dataUser);
                window.localStorage.setItem("auth", dataCompress);
                this.closeLogin();
                if (this.$route.query.redirect) {
                  this.$router.push(String(this.$route.query.redirect));
                  window.localStorage.removeItem("open");
                } else {
                  location.reload();
                  window.localStorage.removeItem("open");
                }
              } else {
                this.verification = true;
                this.closeLogin();
              }
            }
          })
          .catch((error) => {
            if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Las credenciales son incorrectas";
              this.$store.state.alert.estado = true;
              //--------------------------//

              this.errors.email = true;
              this.errors.password = true;
            }
          });
      }
    },
    validateEmail() {
      const data = this.dataLogin.email;
      if (data.length !== 0) {
        var expReg =
          /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
        var resultado = expReg.test(data);
        if (resultado === true) {
          this.errors.email = false;
          this.email2 = data;
          return true;
        } else {
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description = "Ingrese un correo";
          this.$store.state.alert.estado = true;
          //--------------------------//

          this.errors.email = true;
          return false;
        }
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese su correo";
        this.$store.state.alert.estado = true;
        //--------------------------//

        this.errors.email = true;
        return false;
      }
    },
    validatePassword() {
      const data = this.dataLogin.password;
      if (data.length !== 0) {
        if (data.length >= 8) {
          this.errors.password = false;
          return true;
        } else {
          this.errors.password = true;

          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "La contraseña es mayor o igual a 8 dígitos";
          this.$store.state.alert.estado = true;
          //--------------------------//

          return false;
        }
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese su contraseña";
        this.$store.state.alert.estado = true;
        //--------------------------//
        this.errors.password = true;

        return false;
      }
    },
    closeModalVerification() {
      this.verification = false;
      this.email2 = "";
      this.idUser = 0;
    },

    repetitionVerification() {
      axios
        .get(`${BASE_URL}` + "client/sendVerificationEmail/" + this.idUser)
        .then((response) => {
          if (response.status === 200) {
            //----------component--------//
            this.$store.state.alert.titulo = "Éxito";
            this.$store.state.alert.description = "Correo enviado";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Inténtelo más tarde";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    changeViewRegister() {
      this.$emit("openRegister", true);
      this.closeLogin();
    },
  },
  watch: {
    "dataLogin.email": function () {
      this.errors.email = false;
    },
    "dataLogin.password": function () {
      this.errors.password = false;
    },
  },
};
</script>
