<template>
  <a
    v-on:click.prevent="authFb"
    class="hidden cursor-pointer text-[10px] sm:text-base flex items-center justify-center gap-4 py-4 border-2 border-[#1976D2] bg-[#1976D2] text-white rounded-2xl"
  >
    <img
      width="8"
      height="13"
      src="../../../../assets/icons/facebook.svg"
      alt="Facebook Sendi"
    />
    <span class="text[10px] font-semibold">FACEBOOK</span>
  </a>
</template>
<script>
import { server } from "../../../../config/global";
import axios from "axios";
const BASE_URL = server.API_URL;

export default {
  components: {},
  data() {
    return {
      codeInfluencer: this.$route.query.invitation,
    };
  },

  methods: {
    authFb() {
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            // connected
            window.FB.api(
              "/me",
              { fields: "name, email,picture" },
              function (response) {
                if (response) {
                  axios
                    .post(`${BASE_URL}` + "client/login-facebook", {
                      email: response.email,
                      facebook_id: response.id,
                      name: response.name,
                      profile_image_url: response.picture.data.url,
                      store_id: 1,
                      influencer_code: this.codeInfluencer,
                    })
                    .then((response) => {
                      if (response.status === 200) {
                        const dataId = response.data.body.id;
                        const dataName =
                          response.data.body.attributes.profile.attributes.name;
                        const dataLastName =
                          response.data.body.attributes.profile.attributes
                            .surnames;
                        const dataImage =
                          response.data.body.attributes.profile.attributes
                            .userable.attributes.profile_image_url;
                        const dataValidateDNI =
                          response.data.body.attributes.validation.verified;
                        const dataEmail =
                          response.data.body.attributes.profile.attributes
                            .userable.attributes.email;
                        const dataIdentificador =
                          response.data.body.client_code;
                        const type = 3;
                        if (
                          response.data.body.attributes.addresses.length !== 0
                        ) {
                          const data = {};
                          data.nombre =
                            response.data.body.attributes.addresses[0].attributes.address;
                          data.lat =
                            response.data.body.attributes.addresses[0].attributes.lat;
                          data.lng =
                            response.data.body.attributes.addresses[0].attributes.lng;
                          data.departamento =
                            response.data.body.attributes.addresses[0].attributes.department;
                          data.provincia =
                            response.data.body.attributes.addresses[0].attributes.city;
                          data.distrito =
                            response.data.body.attributes.addresses[0].attributes.district;
                          data.location =
                            response.data.body.attributes.addresses[0].attributes.free;
                          const dataCompress = JSON.stringify(data);
                          window.localStorage.setItem(
                            "direction",
                            dataCompress
                          );
                        }
                        const dataUser = {
                          globalID: dataId,
                          globalName: dataName,
                          globalLastName: dataLastName,
                          globalImage: dataImage,
                          globalValidate: dataValidateDNI,
                          glovalEmail: dataEmail,
                          identificador: dataIdentificador,
                          type: type,
                        };
                        const dataCompress = JSON.stringify(dataUser);
                        window.localStorage.setItem("auth", dataCompress);

                        location.reload();
                        window.localStorage.removeItem("open");
                        if (
                          !response.data.body.attributes.profile.attributes
                            .cellphone
                        ) {
                          window.localStorage.setItem(
                            "completecellphone",
                            "true"
                          );
                        }
                      }
                    })
                    .catch((error) => {
                      if (error.response.status === 401) {
                        //----------component--------//
                        this.$store.state.alert.titulo = "Error";
                        this.$store.state.alert.description =
                          error.response.data.message;
                        this.$store.state.alert.estado = true;
                        //--------------------------//
                      } else if (error) {
                        //----------component--------//
                        this.$store.state.alert.titulo = "Error";
                        this.$store.state.alert.description =
                          "Error al iniciar sesión con Google";
                        this.$store.state.alert.estado = true;
                        //--------------------------//
                      }
                    });
                } else {
                  //----------component--------//
                  this.$store.state.alert.titulo = "Error";
                  this.$store.state.alert.description = "Intentalo más tarde";
                  this.$store.state.alert.estado = true;
                  //--------------------------//
                }
              }
            );
          } else {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al iniciar sesión";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        },
        { scope: "public_profile,email" }
      );
    },
  },
  computed: {},
};
</script>
