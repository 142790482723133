<template>
  <nav class="hidden xl:block py-10 bg-white min-h-screen">
    <router-link
      @mouseover="hover.perfil = true"
      @mouseleave="hover.perfil = false"
      :to="{ name: 'Perfil', params: { vista: 'perfil' } }"
      type="router-link"
      :class="
        formSelect === 'perfil'
          ? 'w-72 p-5 flex items-center gap-4 font-semibold bg-mainOrange hover:bg-mainOrange text-white transition-colors'
          : 'w-72 p-5 flex items-center gap-4 border-b hover:bg-mainOrange hover:text-white transition-colors'
      "
    >
      <img
        v-if="formSelect === 'perfil' && hover.perfil"
        :src="icon.perfilWhite"
        alt=""
      />
      <img
        v-else-if="formSelect === 'perfil' && !hover.perfil"
        :src="icon.perfilWhite"
        alt=""
      />
      <img
        v-else-if="formSelect !== 'perfil' && hover.perfil"
        :src="icon.perfilWhite"
        alt=""
      />
      <img
        v-else-if="formSelect !== 'perfil' && !hover.perfil"
        :src="icon.perfilOrange"
        alt=""
      />

      Mi Perfil
    </router-link>
    <router-link
      @mouseover="hover.credencial = true"
      @mouseleave="hover.credencial = false"
      :to="{ name: 'Perfil', params: { vista: 'credenciales' } }"
      type="router-link"
      :class="
        formSelect === 'credenciales' || formSelect === 'verification'
          ? 'w-72 p-5 flex items-center gap-4 font-semibold bg-mainOrange hover:bg-mainOrange text-white transition-colors'
          : 'w-72 p-5 flex items-center gap-4 border-b hover:bg-mainOrange hover:text-white transition-colors'
      "
    >
      <img
        v-if="
          (formSelect === 'credenciales' || formSelect === 'verification') &&
          hover.credencial
        "
        :src="icon.credencialWhite"
        alt=""
      />
      <img
        v-else-if="
          (formSelect === 'credenciales' || formSelect === 'verification') &&
          !hover.credencial
        "
        :src="icon.credencialWhite"
        alt=""
      />
      <img
        v-else-if="
          (formSelect !== 'credenciales' || formSelect === 'verification') &&
          hover.credencial
        "
        :src="icon.credencialWhite"
        alt=""
      />
      <img
        v-else-if="
          (formSelect !== 'credenciales' || formSelect === 'verification') &&
          !hover.credencial
        "
        :src="icon.credencialOrange"
        alt=""
      />
      Credenciales
    </router-link>
    <router-link
      @mouseover="hover.direction = true"
      @mouseleave="hover.direction = false"
      :to="{ name: 'Perfil', params: { vista: 'direccion' } }"
      type="router-link"
      :class="
        formSelect === 'direccion'
          ? 'w-72 p-5 flex items-center gap-4 font-semibold bg-mainOrange hover:bg-mainOrange text-white transition-colors'
          : 'w-72 p-5 flex items-center gap-4 border-b hover:bg-mainOrange hover:text-white transition-colors'
      "
    >
      <img
        v-if="formSelect === 'direccion' && hover.direction"
        :src="icon.directionWhite"
        alt=""
      />
      <img
        v-else-if="formSelect === 'direccion' && !hover.direction"
        :src="icon.directionWhite"
        alt=""
      />
      <img
        v-else-if="formSelect !== 'direccion' && hover.direction"
        :src="icon.directionWhite"
        alt=""
      />
      <img
        v-else-if="formSelect !== 'direccion' && !hover.direction"
        :src="icon.directionOrange"
        alt=""
      />
      Mis direcciones
    </router-link>
    <router-link
      @mouseover="hover.saldo = true"
      @mouseleave="hover.saldo = false"
      :to="{ name: 'Perfil', params: { vista: 'balance' } }"
      type="router-link"
      :class="
        formSelect === 'balance'
          ? 'w-72 p-5 flex items-center gap-4 font-semibold bg-mainOrange hover:bg-mainOrange text-white transition-colors'
          : 'w-72 p-5 flex items-center gap-4 border-b hover:bg-mainOrange hover:text-white transition-colors'
      "
    >
      <img
        v-if="formSelect === 'balance' && hover.saldo"
        :src="icon.saldoWhite"
        alt=""
      />
      <img
        v-else-if="formSelect === 'balance' && !hover.saldo"
        :src="icon.saldoWhite"
        alt=""
      />
      <img
        v-else-if="formSelect !== 'balance' && hover.saldo"
        :src="icon.saldoWhite"
        alt=""
      />
      <img
        v-else-if="formSelect !== 'balance' && !hover.saldo"
        :src="icon.saldoOrange"
        alt=""
      />
      Mi saldo
    </router-link>
    <router-link
      @mouseover="hover.cupon = true"
      @mouseleave="hover.cupon = false"
      :to="{ name: 'Perfil', params: { vista: 'cupones' } }"
      type="router-link"
      :class="
        formSelect === 'cupones'
          ? 'w-72 p-5 flex items-center gap-4 font-semibold bg-mainOrange hover:bg-mainOrange text-white transition-colors'
          : 'w-72 p-5 flex items-center gap-4 border-b hover:bg-mainOrange hover:text-white transition-colors'
      "
    >
      <img
        v-if="formSelect === 'cupones' && hover.cupon"
        :src="icon.cuponWhite"
        alt=""
      />
      <img
        v-else-if="formSelect === 'cupones' && !hover.cupon"
        :src="icon.cuponWhite"
        alt=""
      />
      <img
        v-else-if="formSelect !== 'cupones' && hover.cupon"
        :src="icon.cuponWhite"
        alt=""
      />
      <img
        v-else-if="formSelect !== 'cupones' && !hover.cupon"
        :src="icon.cuponOrange"
        alt=""
      />

      Cupones
    </router-link>
    <router-link
      @mouseover="hover.cotizacion = true"
      @mouseleave="hover.cotizacion = false"
      :to="{ name: 'Perfil', params: { vista: 'cotizaciones' } }"
      type="router-link"
      :class="
        formSelect === 'cotizaciones'
          ? 'w-72 p-5 flex items-center gap-4 font-semibold bg-mainOrange hover:bg-mainOrange text-white transition-colors'
          : 'w-72 p-5 flex items-center gap-4 border-b hover:bg-mainOrange hover:text-white transition-colors'
      "
    >
      <img
        v-if="formSelect === 'cotizaciones' && hover.cotizacion"
        :src="icon.cotizacionWhite"
        alt=""
      />
      <img
        v-else-if="formSelect === 'cotizaciones' && !hover.cotizacion"
        :src="icon.cotizacionWhite"
        alt=""
      />
      <img
        v-else-if="formSelect !== 'cotizaciones' && hover.cotizacion"
        :src="icon.cotizacionWhite"
        alt=""
      />
      <img
        v-else-if="formSelect !== 'cotizaciones' && !hover.cotizacion"
        :src="icon.cotizacionOrange"
        alt=""
      />

      Cotizaciones
    </router-link>
    <router-link
      @mouseover="hover.pedido = true"
      @mouseleave="hover.pedido = false"
      :to="{ name: 'Perfil', params: { vista: 'pedidos' } }"
      type="router-link"
      :class="
        formSelect === 'pedidos'
          ? 'w-72 p-5 flex items-center gap-4 font-semibold bg-mainOrange hover:bg-mainOrange text-white transition-colors'
          : 'w-72 p-5 flex items-center gap-4 border-b hover:bg-mainOrange hover:text-white transition-colors'
      "
    >
      <img
        v-if="formSelect === 'pedidos' && hover.pedido"
        :src="icon.pedidoWhite"
        alt=""
      />
      <img
        v-else-if="formSelect === 'pedidos' && !hover.pedido"
        :src="icon.pedidoWhite"
        alt=""
      />
      <img
        v-else-if="formSelect !== 'pedidos' && hover.pedido"
        :src="icon.pedidoWhite"
        alt=""
      />
      <img
        v-else-if="formSelect !== 'pedidos' && !hover.pedido"
        :src="icon.pedidoOrange"
        alt=""
      />

      Mis pedidos
    </router-link>
    <router-link
      @mouseover="hover.recomendado = true"
      @mouseleave="hover.recomendado = false"
      :to="{ name: 'Perfil', params: { vista: 'recomendados' } }"
      type="router-link"
      :class="
        formSelect === 'recomendados'
          ? 'w-72 p-5 flex items-center gap-4 font-semibold bg-mainOrange hover:bg-mainOrange text-white transition-colors'
          : 'w-72 p-5 flex items-center gap-4 border-b hover:bg-mainOrange hover:text-white transition-colors'
      "
    >
      <img
        v-if="formSelect === 'recomendados' && hover.recomendado"
        :src="icon.recomendadoWhite"
        alt=""
      />
      <img
        v-else-if="formSelect === 'recomendados' && !hover.recomendado"
        :src="icon.recomendadoWhite"
        alt=""
      />
      <img
        v-else-if="formSelect !== 'recomendados' && hover.recomendado"
        :src="icon.recomendadoWhite"
        alt=""
      />
      <img
        v-else-if="formSelect !== 'recomendados' && !hover.recomendado"
        :src="icon.recomendadoOrange"
        alt=""
      />

      Afiliados
    </router-link>
  </nav>
</template>
<script>
// import icons
// 1
import perfilOrange from "../../assets/icons/icon-mi-perfil-orange.png";
import perfilWhite from "../../assets/icons/icon-mi-perfil-white.png";
//2
import credencialOrange from "../../assets/icons/icon-credenciales-orange.png";
import credencialWhite from "../../assets/icons/icon-credenciales-white.png";
//3
import directionOrange from "../../assets/icons/icon-dirección-orange.png";
import directionWhite from "../../assets/icons/icon-direccion-white.png";
//4
import saldoOrange from "../../assets/icons/icon-saldo-orange.png";
import saldoWhite from "../../assets/icons/icon-saldo-white.png";
//5
import cuponOrange from "../../assets/icons/icon-cupon-orange.png";
import cuponWhite from "../../assets/icons/icon-cupon-white.png";
//6
import cotizacionOrange from "../../assets/icons/icon-cotizaciones-orange.png";
import cotizacionWhite from "../../assets/icons/icon-cotizaciones-white.png";
//7
import pedidoOrange from "../../assets/icons/icon-pedidos-orange.png";
import pedidoWhite from "../../assets/icons/icon-pedidos-white.png";
//8
import recomendadoOrange from "../../assets/icons/icon-recomendados-orange.png";
import recomendadoWhite from "../../assets/icons/icon-recomendados-white.png";

//Importando imagen por default
import imgDefault from "../../assets/images/user-default.svg";
//Importando serve
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
//librerias
import axios from "axios";
export default {
  props: ["formSelect", "img", "id", "dni"],
  data() {
    return {
      icon: {
        perfilOrange: perfilOrange,
        perfilWhite: perfilWhite,
        credencialOrange: credencialOrange,
        credencialWhite: credencialWhite,
        directionOrange: directionOrange,
        directionWhite: directionWhite,
        saldoOrange: saldoOrange,
        saldoWhite: saldoWhite,
        cuponOrange: cuponOrange,
        cuponWhite: cuponWhite,
        cotizacionOrange: cotizacionOrange,
        cotizacionWhite: cotizacionWhite,
        pedidoOrange: pedidoOrange,
        pedidoWhite: pedidoWhite,
        recomendadoOrange: recomendadoOrange,
        recomendadoWhite: recomendadoWhite,
      },
      data: {
        img: "",
        imagePreview: "",
      },
      hover: {
        perfil: false,
        credencial: false,
        direction: false,
        saldo: false,
        cupon: false,
        cotizacion: false,
        pedido: false,
        recomendado: false,
      },
    };
  },
  created() {
    this.asingData();
  },
  methods: {
    //Obteniendo el archivo del input file
    previewImage(e) {
      const file = e.target.files[0];
      this.cargarImagen(file);
    },
    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
        this.updateImage();
      };
      reader.readAsDataURL(file);
    },
    //Servicio para actualizar la imagen
    updateImage() {
      axios
        .put(`${BASE_URL}` + "client/update-client-image/" + this.id, {
          profile_image: this.imagePreview,
        })
        .then((response) => {
          if (response.status === 200) {
            const dataId = response.data.body.id;
            const dataName =
              response.data.body.attributes.profile.attributes.name;
            const dataLastName =
              response.data.body.attributes.profile.attributes.surnames;
            const dataImage =
              response.data.body.attributes.profile.attributes.userable
                .attributes.profile_image_url;
            const dni = response.data.body.attributes.validation.verified;
            const dataLocal = JSON.parse(localStorage.getItem("auth"));
            const email = dataLocal.glovalEmail;
            const dataIdentificador = dataLocal.identificador;
            const type = dataLocal.type;
            const dataUser = {
              globalID: dataId,
              globalName: dataName,
              globalLastName: dataLastName,
              globalImage: dataImage,
              globalValidate: dni,
              glovalEmail: email,
              identificador: dataIdentificador,
              type: type,
            };
            const dataCompress = JSON.stringify(dataUser);
            localStorage.setItem("auth", dataCompress);
            location.reload();
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al intentar cambiar la foto de Perfil";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    //obteniendo los datos del localstorage para la imagen y datos del usuario
    asingData() {
      if (this.img === null) {
        this.data.img = imgDefault;
      } else {
        this.data.img = this.img;
      }
    },
    //Cerrar sesión
    closeSesion() {
      window.localStorage.removeItem("datalleProduct");
      window.localStorage.removeItem("shooperProducts");
      window.localStorage.removeItem("direction");
      window.localStorage.removeItem("auth");
      window.localStorage.removeItem("completecellphone");
      this.$router.push({ name: "Home" });
    },
  },
};
</script>
