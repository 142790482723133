<template>
  <div
    v-if="saldo !== 0 && saldo !== null"
    class="py-3 px-3 xl:px-8 border shadow-sm rounded-2xl flex items-center justify-between mb-8 xl:mb-5 bg-white"
  >
    <div
      class="text-xs xl:text-sm font-bold flex items-center justify-between gap-2 xl:gap-6"
    >
      <p class="flex items-center gap-2 xl:gap-4 text-xs xl:text-base">
        <img
          src="../../../../../assets/icons/saldo-icon.svg"
          class="w-8 xl:w-14"
        />SALDO DISPONIBLE
      </p>
      <p
        class="text-mainGreenLight py-2 px-4 border shadow-sm rounded-2xl text-xs xl:text-base"
      >
        $ {{ saldo }}
      </p>
    </div>

    <!-- Al hacer click remover bg-gray-300 y añadir bg-mainBlue -->
    <button
      v-if="saldo !== 0 && saldo !== null"
      @click.prevent="useSaldo"
      :class="statusSaldo ? 'bg-mainGreen' : 'bg-gray-300'"
      class="py-4 px-3 rounded-xl"
      type="button"
    >
      <img src="../../../../../assets/icons/check-white.svg" class="w-5" />
    </button>
  </div>
</template>
<script>
import axios from "axios";
import { server } from "../../../../../config/global";
const BASE_URL = server.API_URL;
export default {
  data() {
    return {
      userId: null,
      saldo: "",
    };
  },
  mounted() {
    this.getBalanceUser();
  },
  created() {
    this.getUser();
  },
  methods: {
    useSaldo() {
      this.$store.state.saldoStatu = !this.$store.state.saldoStatu;
    },
    getBalanceUser() {
      axios
        .get(
          `${BASE_URL}` + "balance/retriveBalanceSumByClientId/" + this.userId
        )
        .then((response) => {
          this.saldo = response.data.body.balance;
          this.$store.state.saldoMonto = response.data.body.balance;
          this.$store.state.saldoMontoSoles = response.data.body.local_balance;
        })
        .catch(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Error";
          this.$store.state.alert.description = "Error al mostrar Su balance";
          this.$store.state.alert.estado = true;
          //--------------------------//
        });
    },
    getUser() {
      const data = JSON.parse(localStorage.getItem("auth"));
      if (data !== null) {
        this.userId = data.globalID;
      }
    },
  },
  computed: {
    statusSaldo() {
      return this.$store.state.saldoStatu;
    },
  },
};
</script>
