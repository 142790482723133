<template>
  <div>
    <article class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
      <h3 class="text-xl xl:text-3xl font-bold mb-10">Eliminar cuenta</h3>
      <a
        @click.prevent="$emit('cambio', 'perfil')"
        class="flex items-center gap-3 mb-6 text-sm xl:text-base font-semibold cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style="fill: rgb(11, 19, 42)"
        >
          <path
            d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"
          ></path>
        </svg>
        Volver
      </a>

      <section class="xl:w-8/12">
        <p class="text-sm xl:text-base mb-6">
          Si eliminas tu perfil, no podrás recuperar el contenido ni la
          información de tus cuentas bancarias y operaciones realizadas en Sendi
        </p>
        <p class="text-sm xl:text-base mb-8">
          No continues con el proceso sino estas 100% seguro de tu desición esta
          acción no es reversible y las cuentas eliminadas no podran ser
          restauradas luego
        </p>
        <label for="motivo" class="block mb-2 font-semibold text-xs xl:text-sm"
          >Motivo de eliminación
          <span class="text-mainGreenLight">(Opcional)</span></label
        >
        <div class="relative mb-6">
          <textarea
            v-model="info"
            class="pb-14 py-5 px-6 border text-sm xl:text-base w-full rounded-2xl focus:outline-none p-6 text-gray-400 resize-none focus:ring focus:ring-blue-400"
            id="motivo"
            placeholder="Lorem ipsum dolor sit amet."
            rows="4"
          ></textarea>
          <p
            class="absolute bottom-2 inset-x-0 bg-white font-bold text-xs xl:text-sm mx-6 pt-2 pb-4 border-t pt-3"
          >
            Cuentanos ¿por qué deseas eliminar tu cuenta?
          </p>
        </div>
        <button
          @click.prevent="changeStatusModal"
          class="py-4 px-6 bg-mainRed text-white font-semibold rounded-xl text-sm flex items-center gap-3"
          type="button"
        >
          <img src="../../assets/icons/eliminar.png" alt="Editar..." />
          <span>Eliminar cuenta</span>
        </button>
      </section>
    </article>
  </div>
  <div
    :class="modalStatus ? '' : 'oculto'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div
      class="mx-4 px-8 xl:px-24 py-16 xl:py-24 bg-white rounded-2xl border shadow-sm"
    >
      <p
        class="text-gray-400 font-semibold xl:text-lg text-center mb-8 xl:mb-12 px-12 xl:px-28"
      >
        ¿Está seguro de eliminar su cuenta?
      </p>
      <button
        @click.prevent="deleteCount"
        class="block mb-6 text-center w-full text-white bg-mainRed font-bold text-xs xl:text-base py-6 rounded-2xl"
      >
        Si
      </button>
      <button
        @click.prevent="changeStatusModal"
        class="block text-center w-full text-mainRed border-2 border-mainRed font-bold text-xs xl:text-base py-6 rounded-2xl"
      >
        No
      </button>
    </div>
  </div>
</template>
<script>
import { server } from "../../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  props: ["id"],
  data() {
    return {
      modalStatus: false,
      info: null,
    };
  },
  methods: {
    deleteCount() {
      axios
        .delete(`${BASE_URL}` + "client/" + this.id + "/delete", {
          data: { info: this.info },
        })
        .then(() => {
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description = "Cuenta Eliminada";
          this.$store.state.alert.estado = true;
          setTimeout(() => {
            window.localStorage.removeItem("datalleProduct");
            window.localStorage.removeItem("shooperProducts");
            window.localStorage.removeItem("direction");
            window.localStorage.removeItem("auth");
            window.localStorage.removeItem("completecellphone");
            this.$router.push({ name: "Home" });
          }, 4000);
        })
        .catch(() => {
          this.$store.state.alert.titulo = "Error";
          this.$store.state.alert.description =
            "Error al intentar eliminar la cuenta";
          this.$store.state.alert.estado = true;
        });
    },
    changeStatusModal() {
      this.modalStatus = !this.modalStatus;
    },
  },
};
</script>
