<template>
  <div
    :class="!show && 'oculto'"
    class="fixed h-screen inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center transition-opacity delay-400"
  >
    <div class="relative mx-4">
      <img src="../../assets/images/pop-up-ejemplo.png" alt="" />
      <button
        @click="ocultPopUp"
        class="absolute top-6 xl:top-8 right-6 xl:right-10"
      >
        <img src="../../assets/icons/exit.svg" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.showPopUp();
  },
  mounted() {},
  methods: {
    ocultPopUp() {
      this.show = false;
    },
    showPopUp() {
      const dayStorage = Number(localStorage.getItem("pop-up"));
      const date = new Date();
      const day = date.getDay();

      if (!dayStorage) {
        this.show = true;
        localStorage.setItem("pop-up", day);
      }

      if (dayStorage && dayStorage != day) {
        localStorage.removeItem("pop-up");
      }
    },
  },
};
</script>
