<template>
  <div
    v-if="estado === false"
    id="menu2"
    class="oculto fixed inset-0 z-50 bg-black bg-opacity-50 transition-all"
  >
    <div
      class="oculto-izquierda overflow-y-auto xl:overflow-hidden px-6 py-2 w-[300px] xl:w-[320px] h-screen bg-[#FAFDFF]"
    >
      <!-- Bloque 1 -->
      <div class="rounded-2xl overflow-hidden border shadow-sm mb-4">
        <div
          class="rounded-2xl px-3 py-2 pr-4 bg-gray-400 flex items-center justify-between cursor-pointer"
          @click.prevent="changeSelectEstado(1)"
        >
          <img
            src="../../../assets/images/user-example.svg"
            class="mr-3 rounded-full h-[60px] w-[60px] object-cover"
            alt="Perfil por default de comprardor en Sendi"
          />
          <div class="w-full flex items-center justify-between">
            <span class="font-semibold text-white">Invitado</span>
            <img
              :class="
                select.user ? 'transform rotate-180 transition-transform' : ''
              "
              class="cursor-pointer"
              width="11"
              height="7"
              src="../../../assets/icons/arrow-down-white.svg"
              alt="Icono de Sendi"
            />
          </div>
        </div>
        <div
          :class="select.user ? '' : 'hidden'"
          class="bg-white text-gray-400 text-sm py-4 px-8 font-semibold"
        >
          <span @click.prevent="autentification(1)" class="block cursor-pointer"
            >Iniciar sesión</span
          >
          <hr class="bg-gray-200 mt-3 mb-5" />
          <span @click.prevent="autentification(2)" class="block cursor-pointer"
            >Registrarse</span
          >
        </div>
      </div>

      <!-- Bloque 2 -->

      <!-- Bloque 3 -->
      <div class="rounded-2xl overflow-hidden border shadow-sm mb-4">
        <div
          class="border-b-2 shadow-sm rounded-2xl py-6 px-8 text-sm bg-white flex items-center justify-between cursor-pointer"
          @click.prevent="changeSelectEstado(2)"
        >
          <span class="font-semibold mr-4">Nuestros Servicios</span>
          <img
            class="cursor-pointer"
            :class="
              select.servicio ? 'transform rotate-180 transition-transform' : ''
            "
            width="11"
            height="7"
            src="../../../assets/icons/arrow-down.svg"
            alt="Icono de Sendi"
          />
        </div>
        <div
          :class="select.servicio ? '' : 'hidden'"
          class="bg-white text-gray-400 text-sm py-4 px-8 font-semibold"
        >
          <router-link
            :to="{ name: 'Home' }"
            @click.prevent="changeService(1)"
            @contextmenu="menuService(1)"
            class="block cursor-pointer"
            >Servicio Courier</router-link
          >
          <hr class="bg-gray-200 mt-3 mb-5" />
          <router-link
            :to="{ name: 'Home' }"
            @click.prevent="changeService(2)"
            @contextmenu="menuService(2)"
            class="block cursor-pointer"
            >Servicio Shopper</router-link
          >
        </div>
      </div>
      <div class="rounded-2xl overflow-hidden border shadow-sm mb-4">
        <div
          class="border-b-2 shadow-sm rounded-2xl py-6 px-8 text-sm bg-white flex items-center justify-between cursor-pointer"
          @click.prevent="changeSelectEstado(3)"
        >
          <span class="font-semibold mr-4">Soporte</span>
          <img
            class="cursor-pointer"
            :class="
              select.soporte ? 'transform rotate-180 transition-transform' : ''
            "
            width="11"
            height="7"
            src="../../../assets/icons/arrow-down.svg"
            alt="Icono de Sendi"
          />
        </div>
        <div
          :class="select.soporte ? '' : 'hidden'"
          class="bg-white text-gray-400 text-sm py-4 px-8 font-semibold"
        >
          <a
            href="https://sendi.freshdesk.com/support/solutions/folders/73000349133"
            target="_blank"
            class="block cursor-pointer"
            >Preguntas frecuentes</a
          >
          <hr class="bg-gray-200 mt-3 mb-5" />
          <a
            href="https://sendi.freshdesk.com/support/home"
            target="_blank"
            class="block cursor-pointer"
            >Base de conocimiento</a
          >
          <hr class="bg-gray-200 mt-3 mb-5" />
          <a
            href="https://wa.link/kd6xi9"
            target="_blank"
            class="block cursor-pointer"
            >Contáctanos</a
          >
        </div>
      </div>
      <!-- Bloque 4 -->
      <div
        @click.prevent="openTracking"
        class="block rounded-2xl overflow-hidden border shadow-sm mb-4 cursor-pointer"
      >
        <div
          class="py-6 px-8 text-sm bg-white flex items-center justify-between"
        >
          <span class="font-semibold mr-4">Tracking</span>
          <img
            class="cursor-pointer"
            width="7"
            height="11"
            src="../../../assets/icons/arrow-right-gray.svg"
            alt="Icono de Sendi"
          />
        </div>
      </div>

      <!-- <a
        href="https://sendi.freshdesk.com/support/solutions/folders/73000349133"
        target="_blank"
        class="block rounded-2xl overflow-hidden border shadow-sm mb-10"
      >
        <div class="py-6 px-8 text-sm bg-white flex items-center justify-between">
          <span class="font-semibold mr-4">Preguntas frecuente</span>
          <img
            class="cursor-pointer"
            width="7"
            height="11"
            src="../../../assets/icons/arrow-right-gray.svg"
            alt="Icono de Sendi"
          />
        </div>
      </a> -->

      <div class="flex gap-5 mb-4">
        <a href="https://www.instagram.com/sendi.envios/" target="Blank">
          <img
            width="50"
            height="50"
            src="../../../assets/icons/icon-instagram.svg"
            class="cursor-pointer w-8 xl:w-10"
            alt="Icono de Sendi"
          />
        </a>
        <a href="https://www.facebook.com/sendi.envios" target="Blank">
          <img
            width="50"
            height="50"
            src="../../../assets/icons/icon-facebook.svg"
            class="cursor-pointer w-8 xl:w-10"
            alt="Icono de Sendi"
          />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["estado"],
  data() {
    return {
      select: {
        user: true,
        servicio: false,
        soporte: false,
      },
    };
  },
  methods: {
    openTracking() {
      this.$emit("openTracking", true);
    },
    menuService(data) {
      window.localStorage.setItem("servicio", data);
    },
    changeService(data) {
      if (this.currentRouteName === "Home") {
        this.$emit("changeService", data);
      } else {
        window.localStorage.setItem("servicio", data);
      }
    },
    autentification(data) {
      if (data === 1) {
        this.$emit("openLogin", true);
      }
      if (data === 2) {
        this.$emit("openRegister", true);
      }
    },
    changeSelectEstado(data) {
      if (data === 1) {
        this.select.user = !this.select.user;
        this.select.servicio = false;
        this.select.soporte = false;
      }
      if (data === 2) {
        this.select.servicio = !this.select.servicio;
        this.select.user = false;
        this.select.soporte = false;
      }
      if (data === 3) {
        this.select.soporte = !this.select.soporte;
        this.select.user = false;
        this.select.servicio = false;
      }
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
