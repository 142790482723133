import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import GAuth from "vue3-google-oauth2";
import VueSplide from "@splidejs/vue-splide";

import "./style/tailwind.css";

setTimeout(function () {
  localStorage.clear();
  location.reload();
}, 60 * 60 * 1000);

const app = createApp(App);
app.use(store).use(router).use(VueSplide);

const gAuthOptions = {
  clientId:
    "82368375958-f5dj613brbovf6a1mj1sop2o9lbsgsos.apps.googleusercontent.com",
  scope: "profile",
  prompt: "consent",
  fetch_basic_profile: true,
};
app.use(GAuth, gAuthOptions);
app
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyAog3Tamm1l2ni9uEr_mrjOzcIOX0763kU",
      libraries: "places",
    },
  })
  .mount("#app");
