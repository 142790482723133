<template>
  <div
    class="bg-mainBlue text-white p-5 rounded-tl-2xl rounded-tr-2xl xl:mb-9"
    v-if="flujo === 4"
  >
    <p
      class="text-xs xl:text-base font-semibold flex items-center justify-center gap-2"
    >
      Tu compra se ha realizado con éxito
      <img src="../../../assets/icons/check-green.png" alt="" />
    </p>
  </div>

  <div
    class="px-4 xl:px-0 text-white flex items-center w-full xl:rounded-tl-2xl xl:rounded-tr-2xl xl:overflow-hidden font-semibold mb-16 xl:mb-9 xl:grid xl:items-stretch grid-cols-3"
    v-else
  >
    <div
      :class="[
        flujo === 1
          ? 'relative xl:pl-4 xl:py-3 cursor-pointer xl:bg-mainBlue xl:hover:bg-mainBlue transition-colors'
          : '',
        flujo === 3 || flujo === 2
          ? 'relative xl:px-4 xl:py-3 cursor-pointer text-white xl:bg-bg-black xl:hover:bg-mainBlue transition-colors'
          : '',
      ]"
      class=""
      @click.prevent="
        flujo === 1 || flujo === 2 || flujo === 3 ? changeFLujo(1) : ''
      "
    >
      <span
        :class="[
          flujo === 1
            ? 'bg-mainBlue xl:bg-transparent w-8 xl:w-auto h-8 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl'
            : '',
          flujo === 3 || flujo === 2
            ? 'bg-bg-black ring ring-bg-black xl:ring-transparent xl:bg-transparent w-7 xl:w-auto h-7 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl'
            : '',
        ]"
        class=""
      >
        <span v-if="flujo === 1">1</span>
        <span v-else> <img :src="img" class="my-2" /></span>
      </span>
      <span class="font-bold text-xs hidden xl:block"
        >Detalles del producto</span
      >
      <span
        :class="[
          flujo === 1 ? 'text-mainBlue' : '',
          flujo === 2 ? 'text-bg-black' : '',
          flujo === 3 ? 'text-bg-black' : '',
        ]"
        class="text-xs absolute -bottom-6 -left-4"
        >Producto</span
      >
    </div>

    <div
      :class="flujo === 2 || flujo === 3 ? 'bg-bg-black' : 'bg-gray-400'"
      class="w-full h-2 xl:hidden"
    ></div>
    <div
      :class="[
        flujo === 1
          ? 'relative xl:px-4 xl:py-3  text-gray-400 xl:bg-gray-light  transition-colors cursor-not-allowed'
          : '',
        flujo === 2
          ? 'relative xl:pl-4 xl:py-3 cursor-pointer xl:bg-mainBlue xl:hover:bg-mainBlue transition-colors'
          : '',
        flujo === 3
          ? 'relative xl:px-4 xl:py-3 cursor-pointer text-white xl:bg-bg-black xl:hover:bg-mainBlue transition-colors'
          : '',
      ]"
      class=""
      @click.prevent="flujo === 2 || flujo === 3 ? changeFLujo(2) : ''"
    >
      <span
        :class="[
          flujo === 1
            ? 'bg-gray-400 xl:bg-transparent w-7 xl:w-auto h-7 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl text-white xl:text-gray-400'
            : '',
          flujo === 2
            ? 'bg-mainBlue xl:bg-transparent w-8 xl:w-auto h-8 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl'
            : '',
          flujo === 3
            ? 'bg-bg-black ring ring-bg-black xl:ring-transparent xl:bg-transparent w-7 xl:w-auto h-7 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl'
            : '',
        ]"
        class=""
      >
        <span v-if="flujo === 2 || flujo === 1">2</span>
        <span v-else> <img :src="img" class="my-2" /></span
      ></span>
      <span class="font-bold text-xs hidden xl:block"
        >Dirección de entrega</span
      >
      <span
        :class="[
          flujo === 1 ? 'text-gray-400' : '',
          flujo === 2 ? 'text-mainBlue' : '',
          flujo === 3 ? 'text-bg-black' : '',
        ]"
        class="text-xs absolute -bottom-6 -left-4"
        >Dirección</span
      >
    </div>
    <div
      :class="flujo === 3 ? 'bg-bg-black' : 'bg-gray-400'"
      class="w-full h-2 xl:hidden"
    ></div>
    <div
      :class="[
        flujo === 1 || flujo === 2
          ? 'relative xl:px-4 xl:py-3  text-gray-400 xl:bg-gray-light  transition-colors cursor-not-allowed'
          : '',
        flujo === 3
          ? 'relative xl:px-4 xl:py-3 cursor-pointer text-white xl:bg-mainBlue xl:hover:bg-mainBlue transition-colors'
          : '',
      ]"
      class=""
      @click.prevent="flujo === 3 ? changeFLujo(3) : ''"
    >
      <span
        :class="[
          flujo === 1 || flujo === 2
            ? 'bg-gray-400 xl:bg-transparent w-7 xl:w-auto h-7 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl text-white xl:text-gray-400'
            : '',
          flujo === 3
            ? 'bg-mainBlue xl:bg-transparent w-8 xl:w-auto h-8 xl:h-auto rounded-full flex xl:block items-center justify-center font-bold xl:text-3xl'
            : '',
        ]"
        class=""
        >3</span
      >
      <span class="font-bold text-xs hidden xl:block">Métodos de pago</span>
      <span
        :class="flujo === 3 ? 'text-mainBlue' : 'text-gray-400'"
        class="text-xs absolute -bottom-6 -left-0"
        >Pago</span
      >
    </div>
  </div>
</template>
<script>
import imgCheck from "../../../assets/icons/check-green.png";
export default {
  props: ["flujo"],
  data() {
    return {
      img: imgCheck,
    };
  },
  methods: {
    changeFLujo(data) {
      this.$emit("changeFlujo", data);
    },
  },
};
</script>
