<template>
  <div
    :class="sendCodigo ? '' : 'oculto'"
    class="fixed h-screen inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center transition-opacity delay-400"
  >
    <div
      class="mx-4 bg-white px-8 py-24 xl:py-52 xl:px-36 rounded-2xl relative"
    >
      <div>
        <h2 class="text-2xl xl:text-4xl text-center font-semibold mb-6">
          Confirma tu celular
        </h2>
        <p class="mb-12 text-center text-sm xl:text-base">
          Enviaremos un código de verificación a tu celular
          <br class="hidden xl:block" />
          por favor confirma tu número o corrigelo de ser necesario
        </p>
        <label for="cel" class="block mb-2 text-xs xl:text-sm font-semibold"
          >Número de celular</label
        >
        <div class="flex gap-4">
          <input
            type="text"
            id="cel"
            class="block w-16 p-4 rounded-md border shadow-md mb-8"
            placeholder="+51"
            disabled
          />
          <input
            type="tel"
            v-model="telefonoVerified"
            id="cel"
            class="block w-full p-4 rounded-md border shadow-md mb-8"
            placeholder="999 999 999"
          />
        </div>

        <button
          @click.prevent="sendCodigoVerifyTelefono"
          class="w-full block bg-mainBlue py-6 text-white font-bold rounded-2xl"
        >
          Enviar SMS
        </button>
      </div>
      <img
        @click.prevent="sendCodigo = false"
        src="../../assets/icons/icon-exit-login.svg"
        alt="exit"
        class="absolute top-8 right-2 sm:top-8 sm:right-8 cursor-pointer"
      />
    </div>
  </div>

  <div
    :class="modalVerifyTelefono ? '' : 'oculto'"
    class="fixed h-screen inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center transition-opacity delay-400"
  >
    <div
      class="mx-4 bg-white px-8 py-24 xl:py-52 xl:px-36 rounded-2xl relative"
    >
      <div>
        <h2 class="text-2xl xl:text-4xl text-center font-semibold mb-6">
          Verifica tu celular
        </h2>
        <p class="mb-6 text-center text-sm xl:text-base">
          Introduce el código de verificación de 4
          <br class="hidden xl:block" />
          digitos que hemos enviado a tu celular
        </p>
        <p class="mb-6 text-center font-bold">
          +51
          {{
            parseInt(telefonoVerified)
              .toLocaleString("es-ES", {
                style: "decimal",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                minimumIntegerDigits: 9,
              })
              .replace(/\./g, " ")
          }}
        </p>

        <div class="flex justify-between mb-12">
          <input
            type="text"
            maxlength="1"
            v-model="codigo.primero"
            class="inputOtp block w-14 h-16 border shadow-md rounded-md text-3xl text-center font-bold"
          />
          <input
            type="text"
            v-model="codigo.segundo"
            maxlength="1"
            class="inputOtp block w-14 h-16 border shadow-md rounded-md text-3xl text-center font-bold"
          />
          <input
            type="text"
            maxlength="1"
            v-model="codigo.tercero"
            class="inputOtp block w-14 h-16 border shadow-md rounded-md text-3xl text-center font-bold"
          />
          <input
            type="text"
            maxlength="1"
            v-model="codigo.cuarto"
            class="inputOtp block w-14 h-16 border shadow-md rounded-md text-3xl text-center font-bold"
          />
        </div>

        <button
          @click.prevent="sendCodigoVerify"
          class="w-full block bg-mainBlue py-6 text-white font-bold rounded-2xl mb-4"
        >
          Verificar
        </button>
        <button
          v-if="!cronometro.status"
          class="w-full block border border-mainBlue py-6 font-medium rounded-2xl mb-4"
        >
          Reenviar código en {{ cronometro.time }} segundos
        </button>
        <button
          v-if="cronometro.status"
          @click.prevent="sendCodigoVerifyTelefono"
          class="w-full block border border-mainGreenLight text-mainGreenLight py-6 font-bold rounded-2xl"
        >
          Reenviar código
        </button>
      </div>
      <img
        @click.prevent="modalVerifyTelefono = false"
        src="../../assets/icons/icon-exit-login.svg"
        alt="exit"
        class="absolute top-8 right-2 sm:top-8 sm:right-8 cursor-pointer"
      />
    </div>
  </div>

  <article class="px-5 py-6 xl:p-10 bg-white rounded-2xl shadow-sm">
    <h3 class="text-xl xl:text-3xl font-bold text-bg-bg-black-blue mb-10">
      Credenciales
    </h3>

    <div class="xl:grid grid-cols-2 gap-8 items-center">
      <div>
        <!-- Correo electrónico -->
        <div class="mb-8">
          <label
            for="idCLiente"
            class="flex items-center gap-2 mb-2 font-semibold text-xs xl:text-sm text-text-blue"
          >
            Código del cliente:
            <span class="tooltip">
              <img src="../../assets/icons/icon-info.svg" alt="" />
              <span class="tooltip-box -left-8">
                Código único de tu cuenta, no lo compartas.<br />
                Podriamos usar este código para verificar tu identidad.
              </span>
            </span>
          </label>
          <input
            id="cliente"
            class="cursor-not-allowed p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="text"
            :value="identificador"
            disabled
          />
        </div>
        <div class="mb-8 relative">
          <div
            class="h-12 absolute bottom-3 left-4 flex items-center pr-4 border-r border-gray-300"
          >
            <img src="../../assets/icons/telefono.svg" class="mb-1" />
          </div>
          <label
            for="telefono"
            class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
            >Celular:</label
          >
          <input
            id="telefono"
            class="p-6 pl-16 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="text"
            :value="'+51 ' + telefono"
            disabled
          />
          <img
            src="../../assets/icons/check-green.png"
            class="absolute right-6 bottom-6"
            v-if="verifytelefono !== 0"
          />
          <img
            src="../../assets/icons/error-orange.png"
            class="absolute right-6 bottom-6"
            v-else
          />
        </div>

        <div class="mb-8 relative">
          <div
            class="h-12 absolute bottom-3 left-4 flex items-center pr-3 border-r border-gray-300"
          >
            <img src="../../assets/icons/dni-black.svg" class="mb-1" />
          </div>
          <label
            for="documento"
            class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
            >Documento:</label
          >
          <input
            id="cliente"
            class="p-6 pl-16 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="text"
            :value="numberDni"
            disabled
          />
          <img
            src="../../assets/icons/check-green.png"
            class="absolute right-6 bottom-6"
            v-if="verify.dni"
          />
          <img
            src="../../assets/icons/error-orange.png"
            class="absolute right-6 bottom-6"
            v-else
          />
        </div>

        <div class="mb-2 relative">
          <div
            class="h-12 absolute bottom-3 left-4 flex items-center pr-4 border-r border-gray-300"
          >
            <img src="../../assets/icons/email.svg" class="" />
          </div>
          <label
            for="email"
            class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
            >E-mail de cuenta:</label
          >
          <input
            id="email"
            class="p-6 pl-16 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
            type="text"
            :value="correo"
            disabled
          />
          <img
            src="../../assets/icons/check-green.png"
            class="absolute right-6 bottom-6"
            v-if="verify.email === 1"
          />
          <img
            src="../../assets/icons/error-orange.png"
            class="absolute right-6 bottom-6"
            v-else
          />
        </div>

        <!-- CASO 1 -- Cuenta creada con google o facebook -->

        <div v-if="type === 2 || type === 3">
          <!-- Google -->
          <p
            v-if="type === 2"
            class="px-6 mb-7 text-mainBlue text-sm font-medium flex items-center gap-3"
          >
            <img src="../../assets/icons/credenciales-google.svg" class="w-8" />
            Cuenta vinculada
          </p>
          <!-- Facebook -->
          <p
            v-if="type === 3"
            class="px-6 mb-7 text-mainBlue text-sm font-medium flex items-center gap-3"
          >
            <img
              src="../../assets/icons/credenciales-facebook.svg"
              class="w-8"
            />
            Cuenta vinculada
          </p>
        </div>

        <!-- CASO 2 -- Cuenta creada con correo electronico -->
        <div class="">
          <button
            @click.prevent="openChangePassword"
            v-if="type === 1"
            class="px-6 block mb-7 mt-2 text-mainBlue text-sm font-medium"
          >
            Quiero cambiar mi contraseña
          </button>
          <span v-if="style.openPassword">
            <div class="mb-8 relative">
              <label
                class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                >Contraseña actual</label
              >
              <input
                class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                :class="error.actualPassword ? 'ring ring-mainRed' : ''"
                :type="style.actualPassword ? 'text' : 'password'"
                v-model="data.actualPassword"
                placeholder="Ingrese su contraseña actual"
                autocomplete="new-password"
              />
              <div class="absolute right-6 bottom-6">
                <img
                  @click.prevent="changePassword('actual')"
                  class="inline cursor-pointer"
                  src="../../assets/icons/icon-watch.svg"
                  alt="Watch"
                />
              </div>
            </div>
            <div class="mb-8 relative">
              <label
                for="new_password"
                class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                >Nueva contraseña</label
              >
              <input
                class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                :class="error.nuevaPassword ? 'ring ring-mainRed' : ''"
                :type="style.nuevaPassword ? 'text' : 'password'"
                v-model="data.nuevaPassword"
                placeholder="Ingrese su nueva contraseña"
              />
              <div class="absolute right-6 bottom-6">
                <img
                  @click.prevent="changePassword('nueva')"
                  class="inline cursor-pointer"
                  src="../../assets/icons/icon-watch.svg"
                  alt="Watch"
                />
              </div>
            </div>
            <div class="mb-8 relative">
              <label
                for="confirm_password"
                class="block mb-2 font-semibold text-xs xl:text-sm text-text-blue"
                >Confirmar contraseña</label
              >
              <input
                class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
                :class="error.confirmPassword ? 'ring ring-mainRed' : ''"
                :type="style.confirmPassword ? 'text' : 'password'"
                v-model="data.confirmPassword"
                placeholder="Confirmar contraseña"
              />
              <div class="absolute right-6 bottom-6">
                <img
                  @click.prevent="changePassword('confirm')"
                  class="inline cursor-pointer"
                  src="../../assets/icons/icon-watch.svg"
                  alt="Watch"
                />
              </div>
            </div>
            <button
              @click.prevent="petitionChangePassword"
              class="pt-5 pb-4 px-6 mb-6 bg-mainGreenLight text-white font-bold rounded-2xl"
              type="submit"
              v-if="type === 1"
            >
              Cambiar contraseña
            </button>
          </span>
        </div>
      </div>

      <div
        v-if="verifytelefono === 0"
        class="py-6 px-10 bg-white border shadow-sm rounded-2xl xl:w-max"
      >
        <p
          class="text-mainOrange font-semibold mb-6 flex items-center gap-5 text-sm xl:text-base"
        >
          <span
            class="w-7 h-7 rounded-full flex items-center justify-center text-white font-bold leading-8"
          >
            <img src="../../assets/icons/error-orange.png"
          /></span>

          <span>Verifica tu celular</span>
        </p>
        <p class="font-medium mb-10 text-sm xl:leading-8">
          <span class="block mb-4"
            >Hola, <b>{{ name }}</b></span
          >

          Para garantizar la seguridad de nuestros usuarios
          <br class="hidden xl:block" />
          <b>debemos verificar tu número celular</b>, por favor
          <br class="hidden xl:block" />
          completa los siguientes pasos.
        </p>
        <div
          @click.prevent="sendCodigo = true"
          class="w-max text-white font-bold py-4 px-6 mb-4 rounded-2xl bg-mainGreenLight cursor-pointer"
        >
          Verificar celular
        </div>
      </div>
      <div
        v-else-if="dni === false"
        class="py-6 px-10 bg-white border shadow-sm rounded-2xl xl:w-max"
      >
        <p
          class="text-mainOrange font-semibold mb-6 flex items-center gap-5 text-sm xl:text-base"
        >
          <span
            class="w-7 h-7 rounded-full flex items-center justify-center text-white font-bold leading-8"
          >
            <img src="../../assets/icons/error-orange.png"
          /></span>

          <span>Verifica tu cuenta</span>
        </p>
        <p class="font-medium mb-10 text-sm xl:leading-8">
          <span class="block mb-4"
            >Hola, <b>{{ name }}</b></span
          >

          Para seguir usando todos nuestros servicios
          <br class="hidden xl:block" />
          <b>tu cuenta debe ser verificado</b>, por favor
          <br class="hidden xl:block" />
          verifica tu identidad.
        </p>
        <div
          @click.prevent="viewVerification('verification')"
          class="w-max text-white font-bold py-4 px-6 mb-4 rounded-2xl bg-mainGreenLight cursor-pointer"
        >
          Verificar identidad
        </div>
      </div>
      <img
        v-else
        src="../../assets/images/banner2.jpg"
        class="w-full object-cover rounded-xl"
      />
    </div>
  </article>
</template>
<script>
import { server } from "../../config/global";
const BASE_URL = server.API_URL;
import axios from "axios";
export default {
  props: [
    "id",
    "dni",
    "name",
    "correo",
    "type",
    "identificador",
    "numberDni",
    "verify",
    "telefono",
    "verifytelefono",
  ],
  data() {
    return {
      cronometro: {
        status: false,
        time: 60,
      },
      codigo: {
        primero: null,
        segundo: null,
        tercero: null,
        cuarto: null,
      },
      telefonoVerified: null,
      sendCodigo: false,
      modalVerifyTelefono: false,
      popUp: false,
      style: {
        openPassword: false,
        actualPassword: false,
        nuevaPassword: false,
        confirmPassword: false,
      },
      data: {
        actualPassword: "",
        nuevaPassword: "",
        confirmPassword: "",
      },
      error: {
        actualPassword: false,
        nuevaPassword: false,
        confirmPassword: false,
      },
    };
  },
  mounted() {
    //Initial references
    const input = document.querySelectorAll(".inputOtp");

    let inputCount = 0;

    //Update input
    const updateInputConfig = (element, disabledStatus) => {
      element.disabled = disabledStatus;
      if (!disabledStatus) {
        element.focus();
      } else {
        element.blur();
      }
    };

    input.forEach((element) => {
      element.addEventListener("keyup", (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
        let { value } = e.target;

        if (value.length == 1) {
          updateInputConfig(e.target, false);
          if (inputCount <= 3 && e.key != "Backspace") {
            if (inputCount < 3) {
              updateInputConfig(e.target.nextElementSibling, false);
            }
          }
          inputCount += 1;
        }
      });
    });
  },
  methods: {
    logCronometro() {
      if (this.modalVerifyTelefono) {
        let num = 60; // número a restar
        let delay = 1000; // tiempo de retraso en milisegundos
        let intervalId = setInterval(() => {
          this.cronometro.time = num;
          num--;
          if (num === 0) {
            this.cronometro.status = true;
            this.cronometro.time = 60;
            clearInterval(intervalId); // detener el intervalo cuando el número llegue a cero
          }
        }, delay);
      }
    },

    sendCodigoVerify() {
      if (
        this.codigo.primero !== null &&
        this.codigo.segundo !== null &&
        this.codigo.tercero !== null &&
        this.codigo.cuarto !== null
      ) {
        axios
          .post(`${BASE_URL}` + "client/verifity-code-cellphone", {
            idClient: this.id,
            codeCellphone:
              this.codigo.primero +
              this.codigo.segundo +
              this.codigo.tercero +
              this.codigo.cuarto,
          })
          .then(() => {
            //----------component--------//
            this.$store.state.alert.titulo = "Éxito";
            this.$store.state.alert.description = "Se verifico su teléfono";
            this.$store.state.alert.estado = true;
            location.reload();
            //--------------------------//
          })
          .catch(() => {
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al verificar su teléfono";
            this.$store.state.alert.estado = true;
          });
      }
    },
    sendCodigoVerifyTelefono() {
      this.sendCodigo = false;
      this.modalVerifyTelefono = true;
      this.cronometro.status = false;
      if (this.telefonoVerified !== null) {
        axios
          .post(`${BASE_URL}` + "client/send-code-cellphone", {
            idClient: this.id,
            store: "SENDI",
            cellphone: "+51" + this.telefonoVerified,
          })
          .then(() => {
            //----------component--------//
            this.$store.state.alert.titulo = "Éxito";
            this.$store.state.alert.description = "Código enviado";
            this.$store.state.alert.estado = true;
            this.logCronometro();
            //--------------------------//
          })
          .catch(() => {
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "No se pudo verificar el código";
            this.$store.state.alert.estado = true;
          });
      }
    },
    viewVerification(data) {
      this.$emit("cambio", data);
    },
    petitionChangePassword() {
      const resulPassword = this.vericationPassword();
      const newPassword = this.verificationNewPassword();
      if (resulPassword && newPassword) {
        axios
          .get(`${BASE_URL}` + "client/resetOldPassword/" + this.id, {
            params: {
              password: this.data.actualPassword,
              new_password: this.data.confirmPassword,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              //----------component--------//
              this.$store.state.alert.titulo = "Éxito";
              this.$store.state.alert.description =
                "Se actualizo su contraseña";
              this.$store.state.alert.estado = true;
              //--------------------------//

              this.data.actualPassword = "";
              this.data.nuevaPassword = "";
              this.data.confirmPassword = "";
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Antigua contraseña incorrecta";
              this.$store.state.alert.estado = true;
              //--------------------------//

              this.data.actualPassword = "";
              this.data.nuevaPassword = "";
              this.data.confirmPassword = "";
            } else if (error.response.status === 422) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "La nueva contraseña no debe ser igual a la antigua";
              this.$store.state.alert.estado = true;
              //--------------------------//

              this.data.actualPassword = "";
              this.data.nuevaPassword = "";
              this.data.confirmPassword = "";
            } else {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al intentar cambiar su contraseña";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
    },
    //Verifica la nueva contraseña
    verificationNewPassword() {
      const newPassword = this.data.nuevaPassword;
      const confirmPassword = this.data.confirmPassword;
      if (newPassword.length >= 8) {
        this.error.nuevaPassword = false;
        if (newPassword.length !== 0 && confirmPassword.length !== 0) {
          this.error.nuevaPassword = false;
          this.error.confirmPassword = false;
          if (newPassword === confirmPassword) {
            this.error.nuevaPassword = false;
            this.error.confirmPassword = false;
            return true;
          } else {
            this.error.nuevaPassword = true;
            this.error.confirmPassword = true;
            //----------component--------//
            this.$store.state.alert.titulo = "Validación";
            this.$store.state.alert.description =
              "Las contraseñas no son iguales";
            this.$store.state.alert.estado = true;
            //--------------------------//

            return false;
          }
        } else {
          this.error.nuevaPassword = true;
          this.error.confirmPassword = true;
          //----------component--------//
          this.$store.state.alert.titulo = "Validación";
          this.$store.state.alert.description =
            "Ingrese su nueva contraseña y confirme";
          this.$store.state.alert.estado = true;
          //--------------------------//

          return false;
        }
      } else {
        this.error.nuevaPassword = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description =
          "La contraseña debe ser mayor o igual a 8 dígitos";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    //Verifica la actual contraseña
    vericationPassword() {
      const actualPassword = this.data.actualPassword;
      if (actualPassword !== 0) {
        this.error.actualPassword = false;
        return true;
      } else {
        this.error.actualPassword = true;
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Ingrese su antigua contraseña";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    changePassword(data) {
      if (data === "actual") {
        this.style.actualPassword = !this.style.actualPassword;
      }
      if (data === "nueva") {
        this.style.nuevaPassword = !this.style.nuevaPassword;
      }
      if (data === "confirm") {
        this.style.confirmPassword = !this.style.confirmPassword;
      }
    },
    openChangePassword() {
      this.style.openPassword = !this.style.openPassword;
    },
  },
  watch: {
    telefono: function (newval) {
      if (newval !== null) {
        this.telefonoVerified = this.telefono.replace(/\s+/g, "");
      } else {
        this.telefonoVerified = null;
      }
    },
  },
};
</script>
