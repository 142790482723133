<template>
  <div
    :class="getStatus ? '' : 'oculto'"
    class="fixed top-20 xl:top-28 right-0 z-50 w-11/12 xl:w-1/3 grid gap-3"
  >
    <div
      class="bg-mainBlue p-4 xl:p-5 rounded-tl-2xl rounded-bl-2xl text-white"
    >
      <h3 class="text-sm xl:text-base font-bold mb-2">{{ getTitulo }}</h3>
      <p class="text-xs xl:text-sm">
        {{ getDescription }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    changeStatusAlert() {
      setTimeout(() => {
        this.$store.state.alert.estado = false;
      }, 4000);
    },
  },
  computed: {
    getTitulo() {
      return this.$store.state.alert.titulo;
    },
    getDescription() {
      return this.$store.state.alert.description;
    },
    getStatus() {
      if (this.$store.state.alert.estado) {
        this.changeStatusAlert();
      }
      return this.$store.state.alert.estado;
    },
  },
};
</script>
