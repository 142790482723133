<template>
  <!-- Compra en las mejores tiendas del mundo -->
  <section class="bg-white z-10 relative">
    <div class="u-container py-5 xl:py-10">
      <h3
        class="text-sm xl:text-2xl text-center font-normal text-bg-black mb-10"
      >
        Compra en las <span class="font-semibold">mejores tiendas</span> del
        mundo
      </h3>
      <Splide :options="optionTienda" :extensions="extensions">
        <SplideSlide
          v-for="tienda in tiendas"
          :key="tienda"
          class="items-center"
        >
          <a :href="tienda.link" target="_blank">
            <img :src="tienda.img" data-slide="1" class="mx-auto" />
          </a>
        </SplideSlide>
      </Splide>
    </div>
  </section>

  <!-- Compra protegida -->

  <section class="bg-[#F6F5FA] relative">
    <div class="u-container pb-8 pt-20 xl:py-80 xl:w-3/5 xl:relative">
      <h3 class="text-2xl xl:text-4xl font-normal text-bg-black mb-1 xl:mb-2">
        ¡Empiece a comprar hoy!
      </h3>
      <h3 class="text-2xl xl:text-4xl text-bg-black mb-3">
        ¡Gratis hasta $10 en su primera compra!
      </h3>
      <p class="text-xs xl:text-base leading-6 xl:leading-8 xl:w-1/2">
        Bienvenido a Sendi. Comience a comprar en las mejores tiendas en línea
        del mundo con nuestro servicio de importación y obtenga un descuento
        especial con nuestro cupón "PRIMERACOMPRA".
      </p>
      <a
        href="https://sendi.freshdesk.com/a/solutions/articles/73000533273"
        target="Blank"
        class="text-[10px] xl:text-sm text-bg-black flex items-center gap-3 underline mt-2"
      >
        Aplican términos y condiciones
        <img
          src="../../assets/icons/question-black.svg"
          class="cursor-pointer"
        />
      </a>
    </div>
    <img
      src="../../assets/images/chica-aside.png"
      class="xl:absolute xl:bottom-0 xl:right-0"
    />
  </section>
</template>
<script>
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
//Tiendas
import img1 from "@/assets/images/landing-ebay.svg";
import img2 from "@/assets/images/landing-amazon.png";
import img3 from "@/assets/images/landing-walmart.png";
import img4 from "@/assets/images/landing-ebay.svg";
import img5 from "@/assets/images/landing-the-home.png";
import img6 from "@/assets/images/landing-bh.png";

export default {
  data() {
    return {
      optionTienda: {
        classes: {
          arrows: "splide__arrows hidden",
          pagination: "splide__pagination opacity-0",
        },
        type: "loop",
        drag: "free",
        focus: "center",
        perPage: 4,
        breakpoints: {
          640: {
            perPage: 1,
          },
        },
        autoWidth: false,
        autoHeight: false,
        autoplay: true,
        interval: 7000,
        resetProgress: true,
        rewind: true,
        speed: 1500,
      },
      extensions: {
        AutoScroll,
      },
      tiendas: [
        { link: "https://www.ebay.com", img: img1 },
        { link: "https://www.amazon.com", img: img2 },
        { link: "https://www.walmart.com", img: img3 },
        { link: "https://www.ebay.com", img: img4 },
        { link: "https://www.homedepot.com.mx", img: img5 },
        { link: "https://www.bhphotovideo.com", img: img6 },
      ],
    };
  },
};
</script>
