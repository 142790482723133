<template>
  <header-component />
  <main class="mt-14 xl:mt-24">
    <section class="section-flujo-compra">
      <article class="u-container py-14 grid xl:grid-cols-2 gap-8 items-start">
        <!-- Bloque de la izquierda -->
        <section>
          <article class="bg-white border shadow-lg rounded-2xl">
            <!-- Timeline -->
            <div
              class="bg-mainBlue text-white p-5 rounded-tl-2xl rounded-tr-2xl xl:mb-9"
            >
              <p
                class="text-xs xl:text-base font-semibold flex items-center justify-center gap-2"
              >
                Detalles de compra
              </p>
            </div>

            <div
              class="px-4 py-6 xl:px-9 rounded-2xl bg-white xl:border xl:shadow-lg xl:border-none xl:shadow-none"
            >
              <h4
                class="text-xs xl:text-base text-text-blue font-semibold mb-8 flex items-center justify-between"
              >
                Detalles del producto
                <span class="text-xs xl:text-sm text-textColor">
                  <span>N° orden:</span>
                  <span class="ml-2 text-mainBlue">SDX{{ product.id }}</span>
                </span>
              </h4>
              <!-- Producto -->
              <div class="p-6 rounded-2xl border shadow-sm mb-8">
                <div class="flex items-center gap-4 mb-6">
                  <img
                    class="w-16 h-16 xl:w-28 xl:h-28 object-contain"
                    :src="product.img"
                    v-if="product.img !== null"
                  />
                  <img
                    class="w-16 h-16 xl:w-28 xl:h-28 object-contain"
                    src="../assets/images/caja.svg"
                    v-else
                  />
                  <div class="text-xs xl:text-base font-bold">
                    <!-- Estatus / Tipo de servicio -->
                    <div class="flex items-center gap-4 mb-4">
                      <span
                        class="hidden px-3 py-1 rounded-full text-xs bg-gray-400 w-max text-white font-semibold"
                        >Solicitud recibida</span
                      >

                      <img
                        src="../assets/icons/icon-tracking-statu.png"
                        alt=""
                      />
                      <span
                        :class="[
                          statusTracking === 1
                            ? 'text-gray-500 bg-gray-500'
                            : '',
                          statusTracking === 2
                            ? 'text-mainOrange bg-mainOrange'
                            : '',
                          statusTracking === 3
                            ? 'text-mainOrange bg-mainOrange'
                            : '',
                          statusTracking === 4
                            ? 'text-mainOrange bg-mainOrange'
                            : '',
                          statusTracking === 5
                            ? 'text-mainOrange bg-mainOrange'
                            : '',
                          statusTracking === 6
                            ? 'text-mainOrange bg-mainOrange'
                            : '',
                          statusTracking === 7
                            ? 'text-mainGreenLight bg-mainGreenLight'
                            : '',
                          statusTracking === 8 ? 'text-red-500 bg-red-500' : '',
                          statusTracking === 9 ? 'text-red-500 bg-red-500' : '',
                          statusTracking === 10
                            ? 'text-gray-500 bg-gray-500'
                            : '',
                        ]"
                        class="px-3 py-1 rounded-full text-xs bg-opacity-20 w-max font-semibold"
                      >
                        <span v-if="statusTracking === 1"> EN REVISIÓN</span
                        ><span v-if="statusTracking === 2"> COMPRADO</span
                        ><span v-if="statusTracking === 3">
                          PREPARANDO PARA ENVIAR</span
                        ><span v-if="statusTracking === 4">
                          LISTO PARA ENVIAR</span
                        ><span v-if="statusTracking === 5"> ENVIADO</span
                        ><span v-if="statusTracking === 6">
                          LISTO PARA ENTREGAR</span
                        ><span v-if="statusTracking === 7"> ENTREGADO</span
                        ><span v-if="statusTracking === 8"> SINIESTRADO</span
                        ><span v-if="statusTracking === 9">
                          EN PROCESO DE DEVOLUCIÓN</span
                        ><span v-if="statusTracking === 10">
                          RETORNADO AL PROVEEDOR</span
                        ></span
                      >

                      <span
                        class="px-3 py-1 rounded-full text-xs border border-mainOrange w-max text-mainOrange font-semibold"
                      >
                        {{
                          product.typeService === 1
                            ? "Servicio Courier"
                            : "Servicio Shopper"
                        }}
                      </span>
                    </div>
                    <p>
                      <span class="line-clamp">
                        {{ product.name }}
                      </span>

                      <a
                        :href="product.link"
                        target="_Blank"
                        v-if="product.typeService === 2"
                      >
                        <img
                          src="../assets/icons/icon-url.svg"
                          class="ml-2 w-4 inline"
                        />
                      </a>
                    </p>
                  </div>
                </div>
                <div
                  class="flex flex-col xl:flex-row gap-5 text-xs xl:text-base mb-6"
                >
                  <p class="flex items-center gap-3">
                    <span class="font-medium text-sm">Precio</span>
                    <span class="font-bold"
                      ><span class="text-mainOrange">USD</span>
                      {{ product.precio }}</span
                    >
                  </p>
                  <p class="flex items-center gap-3">
                    <span class="font-medium text-sm">Cantidad</span>
                    <span class="font-bold">{{ product.cantidad }}</span>
                  </p>
                  <p class="flex items-center gap-3">
                    <span class="font-medium text-sm">Peso</span>
                    <span class="font-bold">{{ product.peso }} kg</span>
                  </p>
                </div>

                <div
                  class="flex flex-row gap-4 items-center text-xs xl:text-sm"
                >
                  <p>Canal de SUNAT:</p>

                  <span
                    v-if="product.canal === 'A'"
                    class="px-5 py-1 bg-mainGreenLight text-white text-xs xl:text-sm rounded-2xl"
                    >Verde</span
                  >
                  <span
                    v-if="product.canal === 'B'"
                    class="px-5 py-1 bg-mainRed text-white text-xs xl:text-sm rounded-2xl"
                    >Rojo</span
                  >

                  <span
                    v-if="product.canal === 'C'"
                    class="px-5 py-1 bg-gray-400 text-white text-xs xl:text-sm rounded-2xl"
                    >Por asignar</span
                  >
                  <img
                    v-if="product.canal === 'A'"
                    src="../assets/images/aduana-1.svg"
                    class="w-7"
                    alt=""
                  />
                  <img
                    v-if="product.canal === 'B'"
                    src="../assets/images/aduana-2.svg"
                    class="w-7"
                    alt=""
                  />
                  <img
                    v-if="product.canal === 'C'"
                    src="../assets/images/aduana-3.svg"
                    class="w-7"
                    alt=""
                  />

                  <div class="relative">
                    <img
                      @mouseover="popUp = true"
                      @mouseleave="popUp = false"
                      src="../assets/icons/icon-info.svg"
                      class="cursor-pointer"
                    />
                    <div
                      v-if="popUp"
                      :class="[
                        product.canal === 'A' ? 'bg-mainGreenLight' : '',
                        product.canal === 'B' ? 'bg-mainRed' : '',
                        product.canal === 'C' ? 'bg-gray-400' : '',
                      ]"
                      class="z-10 absolute -top-48 xl:-top-44 right-[-2.5rem] xl:-right-32 text-white text-xs xl:text-sm py-4 px-6 rounded-2xl w-max transition-all delay-400"
                      style="max-width: 95vw"
                    >
                      <span class="block mb-3 font-semibold underline"
                        >CANALES DE CONTROL</span
                      >
                      <span class="block mb-3">
                        <span class="font-semibold">Canal VERDE:</span> No se
                        requiere la revisión documentaria de <br />
                        la declaración ni el reconocimiento físico de la
                        mercancia.
                      </span>
                      <span class="block">
                        <span class="font-semibold">Canal ROJO:</span> La
                        mercancía se encuentra sujeta <br />
                        a reconocimiento físico
                      </span>
                      <span
                        :class="[
                          product.canal === 'A' ? 'bg-mainGreenLight' : '',
                          product.canal === 'B' ? 'bg-mainRed' : '',
                          product.canal === 'C' ? 'bg-gray-400' : '',
                        ]"
                        class="absolute -bottom-7 right-6 xl:right-28 w-14 h-8 triangle"
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="my-4" />
              <!-- Dirección de entrega -->
              <h4
                class="text-xs xl:text-base text-text-blue font-semibold mb-8"
              >
                Dirección de entrega
              </h4>

              <div
                v-if="product.directionDefault === 0"
                class="relative ring ring-gray-100 hover:ring-mainGreenLight shadow-sm rounded-2xl mb-8"
              >
                <div class="flex items-center gap-4 xl:gap-6 py-4 px-5 xl:p-6">
                  <img src="../assets/icons/oficina2.svg" alt="" />
                  <div
                    class="flex items-center justify-between font-semibold cursor-pointer"
                  >
                    <div class="text-text-blue">
                      <p class="text-sm xl:text-base mb-2">
                        {{ product.directionName }}
                        {{ product.directionApellido }}
                      </p>
                      <p class="text-xs xl:text-sm mb-2">
                        {{ product.directionAddres }}
                      </p>
                      <p class="text-xs xl:text-sm mb-2">
                        {{ product.directionReferencia }}
                      </p>
                      <p class="text-xs xl:text-sm mb-2">
                        {{ product.directionThelephone }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Dirección de entrega CASO 2 -->
              <div
                v-if="product.directionDefault === 1"
                class="relative ring ring-gray-100 hover:ring-mainGreenLight shadow-sm rounded-2xl mb-8"
              >
                <div
                  class="border-b rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold cursor-pointer"
                >
                  <p class="text-text-blue text-sm xl:text-base">
                    Recojo en Oficina
                  </p>
                </div>
                <div
                  class="rounded-2xl p-6 cursor-pointer flex items-center gap-4 xl:gap-8"
                >
                  <img src="../assets/icons/oficina-2.png" alt="" />
                  <div>
                    <p class="text-sm font-bold">Lima</p>
                    <p class="text-xs text-gray-400">
                      Jr. Emilio Althaus Nro. 121 Oficina 403
                    </p>
                  </div>
                  <a
                    href="https://goo.gl/maps/e2vcgRdQ93BdyYrC7"
                    target="Blank"
                    class="ml-auto"
                  >
                    <img
                      src="../assets/icons/ver-direccion.png"
                      class="ml-auto"
                  /></a>
                </div>
              </div>

              <hr class="my-4" />
              <!-- Métodos de pago -->
              <h4
                class="text-xs xl:text-base text-text-blue font-semibold mb-8 flex items-center gap-3"
              >
                Método de pago
                <img
                  src="../assets/icons/icon-pago-status.png"
                  alt=""
                  class="ml-4"
                />
                <span
                  :class="[
                    statusPayed === 1 ? 'text-gray-500 bg-gray-500' : '',
                    statusPayed === 2 ? 'text-mainOrange bg-mainOrange' : '',
                    statusPayed === 3
                      ? 'text-mainGreenLight bg-mainGreenLight'
                      : '',
                    statusPayed === 10 ? 'text-red-500 bg-red-500' : '',
                    statusPayed === 11 ? 'text-mainOrange bg-mainOrange' : '',
                    statusPayed === 12 ? 'text-gray-500 bg-gray-500' : '',
                  ]"
                  class="px-3 py-1 rounded-full text-xs bg-opacity-20 w-max font-semibold"
                >
                  <span v-if="statusPayed === 1">PENDIENTE</span>
                  <span v-else-if="statusPayed === 2">EN PROCESO</span>
                  <span v-else-if="statusPayed === 3">CONFIRMADO</span>
                  <span v-else-if="statusPayed === 10">RECHAZADO"</span>
                  <span v-else-if="statusPayed === 11">RECLAMADO</span>
                  <span v-else-if="statusPayed === 12">RETORNADO</span></span
                >
              </h4>
              <!-- Transferencia bancaria -->

              <div
                class="text-text-blue relative ring ring-gray-100 hover:ring-mainGreenLight shadow-sm rounded-2xl mb-8"
                v-if="product.metodoDepago === 5"
              >
                <div
                  class="border-b rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold cursor-pointer"
                >
                  <p
                    class="text-xs xl:text-base flex items-center gap-2 xl:gap-4"
                  >
                    <img src="../assets/icons/transferencia_bancaria.svg" />
                    Transferencia bancaria
                  </p>
                </div>
                <div class="py-5 px-4 xl:px-6 xl:py-6">
                  <div
                    class="flex items-center justify-between xl:justify-start gap-2 xl:gap-8 mb-8"
                  >
                    <img
                      src="../assets/icons/bcp.svg"
                      class="mt-2 w-12 xl:w-auto"
                    />
                    <p class="text-xs xl:text-base">
                      <span id="transferencia-nombre" class="block font-bold"
                        >GRUPO CASZA SAC</span
                      >
                      <span id="transferencia-ruc" class="block text-gray-400"
                        >20609271044</span
                      >
                      <span
                        id="transferencia-cuenta"
                        class="block text-gray-400"
                        >{{ product.typeMoney === "USD" ? "Cuenta corriente: 200-3005545622" : "Cuenta Corriente: 200-3005545615" }}</span
                      >
                      <span
                        id="transferencia-cci"
                        class="block text-gray-400"
                        >{{ product.typeMoney === "USD" ? "CCI: 003-200-003005545622-39" : "CCI: 003-200-003005545615-33" }}</span
                      >
                    </p>
                    <div
                      class="xl:mr-6 xl:ml-auto"
                      id="btn-copy"
                      @click.prevent="copyPayment"
                    >
                      <img
                        src="../assets/icons/icon-copiar-2.png"
                        class="cursor-pointer mx-auto"
                      />
                      <span class="text-mainOrange text-center text-xs"
                        >Copiar</span
                      >
                    </div>
                  </div>
                  <div class="">
                    <div v-if="product.metodoDepago === 5">
                      <div class="grid grid-cols-12 xl:gap-4 items-center">
                        <label
                          for="num_operacion"
                          class="col-span-12 xl:col-span-3 text-xs sm:text-sm xl:text-base xl:mb-4 font-semibold cursor-pointer"
                          >N° Operación:</label
                        >

                        <div class="col-span-12 xl:col-span-9 xl:mb-4 relative">
                          <input
                            v-if="styleTrans === false"
                            type="text"
                            class="w-full rounded-2xl border border-gray-600 shadow-sm py-4 placeholder-gray-400 text-center transition-colors focus:outline-none"
                            placeholder="Escribe aquí ..."
                            v-model="trans.number"
                            :disabled="styleTrans"
                          />
                          <input
                            v-else-if="
                              styleTrans && statusPayed !== 3 && trans.number
                            "
                            type="text"
                            class="w-full rounded-2xl border border-mainOrange shadow-sm py-4 placeholder-gray-400 text-center transition-colors focus:outline-none"
                            placeholder="Escribe aquí ..."
                            v-model="trans.number"
                            :disabled="styleTrans"
                          />
                          <input
                            v-else-if="
                              styleTrans && statusPayed === 3 && trans.number
                            "
                            type="text"
                            class="w-full rounded-2xl border border-mainGreenLight shadow-sm py-4 placeholder-gray-400 text-center transition-colors focus:outline-none"
                            placeholder="Escribe aquí ..."
                            v-model="trans.number"
                            :disabled="styleTrans"
                          />

                          <img
                            v-if="
                              styleTrans && statusPayed === 3 && trans.number
                            "
                            src="../assets/icons/check-green.png"
                            class="absolute right-4 bottom-5"
                          />
                        </div>

                        <span
                          class="font-semibold xl:mb-6 col-span-12 xl:col-span-3 text-xs sm:text-sm xl:text-base"
                          >Voucher:</span
                        >

                        <label
                          v-if="
                            styleTrans === false ||
                            trans.nombreImg === 'Sin Voucher'
                          "
                          class="underline col-span-12 xl:col-span-9 xl:mb-6 rounded-2xl border border-gray-600 shadow-sm py-4 placeholder-gray-400 text-center text-gray-400 cursor-pointer flex items-center justify-center gap-2"
                        >
                          <img
                            src="../assets/icons/agregar__boleta.png"
                            alt=""
                          />
                          <input
                            type="file"
                            @change="factura2($event)"
                            accept="image/*,.pdf"
                            class="overflow-hidden h-0 w-0"
                            :disabled="styleTrans"
                          />
                          {{ trans.nombreImg }}
                        </label>

                        <label
                          v-else-if="
                            styleTrans &&
                            statusPayed !== 3 &&
                            trans.nombreImg === 'Voucher cargado'
                          "
                          class="underline col-span-12 xl:col-span-9 xl:mb-6 rounded-2xl border border-mainOrange shadow-sm py-4 placeholder-gray-400 text-center text-gray-400 cursor-pointer flex items-center justify-center gap-2"
                        >
                          <img
                            src="../assets/icons/agregar__boleta.png"
                            alt=""
                          />
                          <input
                            type="file"
                            @change="factura2($event)"
                            accept="image/*,.pdf"
                            class="overflow-hidden h-0 w-0"
                            :disabled="styleTrans"
                          />

                          <span v-if="trans.nombreImg === 'Voucher cargado'">
                            <a
                              :href="trans.url.url"
                              target="_Blank"
                              v-if="trans.url.from_admin === 0"
                              >{{ trans.nombreImg }}</a
                            >
                            <a v-else>{{ trans.nombreImg }}</a>
                          </span>
                          <span v-else> {{ trans.nombreImg }} </span>
                        </label>

                        <label
                          v-else-if="
                            styleTrans &&
                            statusPayed === 3 &&
                            trans.nombreImg === 'Voucher cargado'
                          "
                          class="relative underline col-span-12 xl:col-span-9 xl:mb-6 rounded-2xl border border-mainGreenLight shadow-sm py-4 placeholder-gray-400 text-center text-gray-400 cursor-pointer flex items-center justify-center gap-2"
                        >
                          <img
                            src="../assets/icons/agregar__boleta.png"
                            alt=""
                          />
                          <input
                            type="file"
                            @change="factura2($event)"
                            accept="image/*,.pdf"
                            class="overflow-hidden h-0 w-0"
                            :disabled="styleTrans"
                          />
                          <span v-if="trans.nombreImg === 'Voucher cargado'">
                            <a
                              :href="trans.url.url"
                              target="_Blank"
                              v-if="trans.url.from_admin === 0"
                              >{{ trans.nombreImg }}</a
                            >
                            <a v-else>{{ trans.nombreImg }}</a>
                          </span>
                          <span v-else> {{ trans.nombreImg }} </span>
                          <img
                            src="../assets/icons/check-green.png"
                            class="absolute right-4 bottom-5"
                          />
                        </label>
                      </div>

                      <button
                        :class="
                          trans.number.length !== 0
                            ? 'bg-bg-black-blue'
                            : ' bg-gray-500'
                        "
                        v-if="styleTrans === false"
                        @click.prevent="sendVaucher2"
                        class="text-white py-5 font-bold rounded-2xl block w-10/12 mx-auto mb-2"
                      >
                        Enviar
                      </button>
                      <p
                        v-if="styleTrans && statusPayed !== 3"
                        class="text-center text-xs mt-2 xl:text-sm"
                      >
                        Acreditaremos tu pago en las proximas 48 horas
                      </p>
                      <p
                        v-if="styleTrans && statusPayed === 3"
                        class="text-center text-xs mt-2 xl:text-sm"
                      >
                        Acreditamos tu pago correctamente
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="product.metodoDepago === 6"
                class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl mb-6"
              >
                <div
                  class="relative rounded-2xl p-6 font-bold cursor-pointer text-mainGreenLight"
                >
                  <span>Mi balance</span>
                </div>
              </div>

              <div
                v-if="product.metodoDepago === 3"
                class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl mt-1 mb-6"
              >
                <label class="relative block">
                  <span
                    class="bg-white absolute left-4 -top-2 text-xs font-medium"
                    >Tarjeta de débito</span
                  >
                  <div
                    class="absolute inset-y-0 left-3 xl:left-5 flex items-center"
                  >
                    <img
                      src="../assets/icons/icon-tarjetas-outline.svg"
                      alt="Icono de Sendi"
                    />
                  </div>
                  <input
                    class="pl-12 xl:pl-16 p-5 input w-full font-semibold bg-white rounded-2xl"
                    type="text"
                    value="0000-0000-0000-0000"
                    readonly
                  />
                  <div
                    class="absolute inset-y-0 right-3 xl:right-5 flex items-center"
                  >
                    <img
                      class="w-6"
                      src="../assets/icons/icon-visa-small.svg"
                      alt="Icono de Sendi"
                    />
                  </div>
                </label>
              </div>

              <div
                v-if="product.metodoDepago === 2"
                class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl mb-6"
              >
                <div
                  class="relative rounded-2xl pl-16 xl:pl-20 p-6 font-bold cursor-pointer"
                >
                  <div
                    class="absolute inset-y-0 left-4 xl:left-6 flex items-center"
                  >
                    <img
                      src="../assets/icons/paypal-1.svg"
                      alt="Icono de Sendi"
                    />
                  </div>
                  <span>Paypal</span>
                </div>
              </div>
              <!-- Agentes y agencias -->

              <div
                class="text-xs xl:text-base bg-white border shadow-sm rounded-2xl mb-6"
                v-if="product.metodoDepago === 4"
              >
                <div
                  class="relative rounded-2xl border-b pl-16 xl:pl-20 p-6 font-bold cursor-pointer"
                >
                  <div
                    class="absolute inset-y-0 left-4 xl:left-6 flex items-center"
                  >
                    <img
                      class="w-10"
                      src="../assets/icons/pago-efectivo.png"
                      alt="Icono de Sendi"
                    />
                  </div>
                  <span class="text-text-blue">
                    PagoEfectivo
                    <span class="block text-xs text-gray-400 font-normal"
                      >Agentes y agencias</span
                    >
                  </span>
                </div>

                <div class="py-4">
                  <p
                    class="text-gray-400 text-sm xl:text-base flex items-center justify-center mb-2 gap-6"
                  >
                    <span>Este es tu código de pago CIP:</span>
                    <span
                      class="text-right text-textColor font-bold xl:text-xl flex items-center gap-2"
                      >{{ product.atmCodigo }}</span
                    >
                    <span class="tooltip text-left">
                      <img src="../assets/icons/icon-clock.png" alt="" />
                      <span class="tooltip-box -left-8"
                        >Tienes tiempo hasta el {{ product.experienceAtm }}
                      </span>
                    </span>
                  </p>
                  <a
                    :href="product.atmLink"
                    target="_blank"
                    class="block text-center text-mainBlue font-semibold text-sm xl:text-base"
                    >Ver instrucciones de pago</a
                  >
                </div>
              </div>

              <hr class="my-4" />
              <!-- Detalles de pago -->
              <h4
                class="text-xs xl:text-base text-text-blue font-semibold mb-8"
              >
                Detalles de pago
              </h4>
              <div class="py-4 px-5 rounded-2xl border mb-8">
                <p
                  class="text-xs text-bg-black-blue xl:text-sm font-bold flex items-center justify-between"
                >
                  <span>{{
                    product.typeService === 2 ? "Producto" : "Gastos en USA"
                  }}</span>
                  <span>{{ product.typeMoney }} {{ detalle.cif }}</span>
                </p>
                <hr class="my-4" />
                <div class="text-sm text-gray-400 grid gap-3">
                  <p
                    v-if="product.typeService === 2"
                    class="flex items-center justify-between"
                  >
                    <span class="flex gap-3">
                      <img
                        src="../assets/icons/flecha-enter.svg"
                        class="w-2"
                        alt=""
                      />
                      Precio del producto
                    </span>
                    <span
                      >{{ product.typeMoney }}
                      {{ detalle.precioProducto }}</span
                    >
                  </p>
                  <p
                    v-if="product.typeService === 2"
                    class="flex items-center justify-between"
                  >
                    <span class="flex gap-3 underline">
                      <img
                        src="../assets/icons/flecha-enter.svg"
                        class="w-2"
                        alt=""
                      />
                      Shipping
                    </span>
                    <span>{{ product.typeMoney }} {{ detalle.shipping }}</span>
                  </p>
                  <p
                    v-if="product.typeService === 2"
                    class="flex items-center justify-between"
                  >
                    <span class="flex gap-3 underline">
                      <img
                        src="../assets/icons/flecha-enter.svg"
                        class="w-2"
                        alt=""
                      />
                      Tax
                    </span>
                    <span>{{ product.typeMoney }} {{ detalle.tax }}</span>
                  </p>
                  <p class="flex items-center justify-between">
                    <span class="flex gap-3 underline">
                      <img
                        src="../assets/icons/flecha-enter.svg"
                        class="w-2"
                        alt=""
                      />
                      Warehouse
                    </span>
                    <span>{{ product.typeMoney }} {{ detalle.warehouse }}</span>
                  </p>
                  <p class="flex items-center justify-between">
                    <span class="flex gap-3 underline">
                      <img
                        src="../assets/icons/flecha-enter.svg"
                        class="w-2"
                        alt=""
                      />
                      Flete
                    </span>
                    <span>{{ product.typeMoney }} {{ detalle.flete }}</span>
                  </p>
                  <hr class="my-3" />
                </div>
                <div v-if="detalle.impuestos !== '0.00'">
                  <p
                    class="text-xs text-bg-black-blue xl:text-sm font-bold flex items-center justify-between mt-1"
                  >
                    <span>Impuestos</span>
                    <span>{{ product.typeMoney }} {{ detalle.impuestos }}</span>
                  </p>

                  <hr class="my-4" />
                  <div class="text-sm text-gray-400 grid gap-3">
                    <p class="flex items-center justify-between">
                      <span class="flex gap-3">
                        <img
                          src="../assets/icons/flecha-enter.svg"
                          class="w-2"
                          alt=""
                        />
                        Ad/Valorem
                      </span>
                      <span>{{ product.typeMoney }} {{ detalle.valorem }}</span>
                    </p>
                    <p class="flex items-center justify-between">
                      <span class="flex gap-3 underline">
                        <img
                          src="../assets/icons/flecha-enter.svg"
                          class="w-2"
                          alt=""
                        />
                        I.G.V
                      </span>
                      <span>{{ product.typeMoney }} {{ detalle.igv }}</span>
                    </p>
                    <p class="flex items-center justify-between">
                      <span class="flex gap-3 underline">
                        <img
                          src="../assets/icons/flecha-enter.svg"
                          class="w-2"
                          alt=""
                        />
                        I.P.M
                      </span>
                      <span>{{ product.typeMoney }} {{ detalle.ipm }}</span>
                    </p>

                    <hr class="my-3" />
                  </div>
                </div>

                <div v-else>
                  <p
                    class="text-xs xl:text-sm text-bg-black-blue font-bold flex items-center justify-between mt-1"
                  >
                    <span>Impuestos</span>
                    <span class="text-mainGreenLight">Gratis</span>
                  </p>
                  <hr class="my-4" />
                </div>
                <p
                  class="text-xs text-bg-black-blue xl:text-sm font-bold flex items-center justify-between"
                >
                  <span>Tarifa de servicio</span>
                  <span>{{ product.typeMoney }} {{ detalle.tarifa }}</span>
                </p>
                <hr class="my-4" />

                <div class="text-sm text-gray-400 grid gap-3">
                  <p class="flex items-center justify-between">
                    <span class="flex gap-3">
                      <img
                        src="../assets/icons/flecha-enter.svg"
                        class="w-2"
                        alt=""
                      />
                      Trámite aduanero
                    </span>
                    <span
                      :class="
                        detalle.tramiteAduanero === '0.00'
                          ? 'text-mainGreenLight  font-bold'
                          : ''
                      "
                      >{{ product.typeMoney }}
                      {{
                        detalle.tramiteAduanero === "0.00"
                          ? "Gratis"
                          : detalle.tramiteAduanero
                      }}</span
                    >
                  </p>
                  <p class="flex items-center justify-between">
                    <span class="flex gap-3 underline">
                      <img
                        src="../assets/icons/flecha-enter.svg"
                        class="w-2"
                        alt=""
                      />
                      Envío nacional
                    </span>
                    <span
                      :class="free === 1 ? 'text-mainGreenLight font-bold' : ''"
                      >{{ product.typeMoney }}
                      {{ free === 1 ? "Gratis" : detalle.envioNacional }}</span
                    >
                  </p>
                  <hr class="my-3" />
                </div>
                <span v-if="servicios.length !== 0">
                  <p
                    class="text-xs xl:text-sm font-bold flex items-center justify-between"
                  >
                    <span>Servicios Adicionales</span>
                    <span>
                      {{ product.typeMoney }}
                      {{
                        product.typeMoney === "USD"
                          ? servicioDolares
                          : servicioSoles.toFixed(2)
                      }}</span
                    >
                  </p>
                  <hr class="my-4" />
                  <div class="text-sm text-gray-400 grid gap-3">
                    <p
                      class="flex items-center justify-between"
                      v-for="data in servicios"
                      :key="data"
                    >
                      <span class="flex gap-3">
                        <img
                          src="../assets/icons/flecha-enter.svg"
                          class="w-2"
                          alt=""
                        />
                        {{ data.name }}
                      </span>
                      <span
                        >{{ product.typeMoney }}
                        {{
                          product.typeMoney === "USD"
                            ? data.pivot.amount
                            : (data.pivot.amount * data.exchange_rate).toFixed(
                                2
                              )
                        }}</span
                      >
                    </p>

                    <hr class="my-3" />
                  </div>
                </span>

                <h4
                  v-if="cuponUtilizado !== null"
                  class="text-xs xl:text-sm font-bold flex items-center justify-between"
                >
                  <span>Cupón</span>
                  <span>
                    {{ product.typeMoney === "USD" ? "- USD" : "- PEN" }}
                    {{
                      product.typeMoney === "USD"
                        ? Number(cuponUtilizado).toFixed(2)
                        : Number(cuponUtilizadoSoles).toFixed(2)
                    }}
                  </span>
                </h4>
                <hr class="my-3" v-if="cuponUtilizado !== null" />

                <h4
                  v-if="saldoUtilizado !== null"
                  class="text-xs xl:text-sm font-bold flex items-center justify-between"
                >
                  <span> Saldo utilizado</span>
                  <span> - USD {{ saldoUtilizado }} </span>
                </h4>

                <hr class="my-3" v-if="saldoUtilizado !== null" />
                <h4
                  v-if="newTotal === null"
                  class="xl:text-lg font-bold text-text-blue flex items-center justify-between"
                >
                  <span class="text-secondary-blue">Pago total</span>
                  <span class="block">
                    <span class="text-mainOrange">{{ product.typeMoney }}</span>
                    <span class="text-secondary-blue ml-1">{{
                      detalle.totalPagar
                    }}</span>
                  </span>
                </h4>

                <h4
                  v-else
                  class="xl:text-lg font-bold text-text-blue flex items-center justify-between"
                >
                  <span>Pago total</span>
                  <span class="block">
                    <span class="text-mainOrange">{{ product.typeMoney }}</span>
                    {{ newTotal }}
                  </span>
                </h4>
              </div>
              <!-- Tiempo de entrega -->
              <div
                class="text-xs xl:text-base flex items-center justify-center gap-4 mb-6"
              >
                <img src="../assets/icons/entrega.svg" />
                <p>
                  Tiempo estimado de entrega:
                  <span class="font-semibold text-mainBlue">{{
                    product.fechaEntrega
                  }}</span>
                </p>
              </div>
            </div>
          </article>
        </section>

        <!-- Bloque de la derecha -->
        <section class="grid">
          <article
            class="relative bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
          >
            <button
              @click.prevent="imprimir"
              class="tooltip fixed xl:absolute right-4 top-20 xl:right-16 xl:top-12 block ml-auto bg-bg-black-blue text-white rounded-2xl p-3 xl:py-4 xl:px-6 font-bold flex items-center gap-4"
            >
              <img
                src="../assets/icons/icon-impresora.png"
                class="w-6 xl:w-8"
              />
              <span
                class="tooltip-box bottom-16 xl:bottom-20 text-xs xl:text-sm -left-6 xl:left-0"
                >Imprimir</span
              >
            </button>
            <div class="xl:p-8 text-center">
              <h4
                class="text-sm xl:text-base font-semibold mb-10 text-text-blue"
              >
                Solicitud recibida
              </h4>
              <img
                src="../assets/icons/solicitud-recibida.svg"
                class="mx-auto mb-14 xl:w-48"
              />
              <p class="mb-4 text-xs xl:text-sm">
                Tu número de tracking ha sido enviado a
                <a href="#" class="text-mainBlue font-bold block">{{
                  product.email
                }}</a>
              </p>

              <div
                class="font-medium text-sm xl:text-base text-black text-center flex items-center justify-center gap-3 border-b border-l border-r shadow-xl rounded-xl px-6 pb-6"
              >
                <p id="tracking-code">{{ product.numberTracking }}</p>
                <button
                  type="button"
                  @click.prevent="copyCode"
                  @keydown="copyCode"
                  class="cursor-pointer"
                  alt="Icono de Envios USA"
                >
                  <img src="../assets/icons/icon-copiar-3.png" alt="" />
                </button>
              </div>
            </div>
          </article>

          <!-- Invoice de compra -->
          <article
            v-if="
              product.typeService === 1 && product.vaucherStatus.length === 0
            "
            class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
          >
            <h3
              class="text-text-blue font-semibold xl:text-lg mb-6 text-center"
            >
              No olvides agregar tu factura comercial
            </h3>
            <label
              class="xl:mx-16 cursor-pointer font-semibold text-white rounded-2xl bg-mainOrange py-5 text-sm xl:text-base flex items-center justify-center gap-4"
            >
              <img src="../assets/icons/subir-archivo.png" />
              <input
                class="hidden"
                type="file"
                ref="fileupload"
                @change="factura"
                accept="image/*,.pdf"
              />
              Agregar factura comercial
            </label>
          </article>

          <article
            v-if="product.vaucherStatus.length !== 0"
            class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
          >
            <div
              class="mb-6 xl:mx-16 font-semibold rounded-2xl bg-mainGreenLight text-main-green ring ring-mainGreenLight bg-opacity-20 py-5 text-sm xl:text-base flex items-center justify-center gap-4 cursor-pointer"
            >
              Factura comercial cargado
              <img src="../assets/icons/check-green.png" class="w-6" />
            </div>

            <div
              class="xl:mx-16 font-semibold rounded-2xl p-5 text-sm xl:text-base"
            >
              <span
                v-for="(invoice, index) in product.vaucherStatus"
                :key="invoice"
                class="cursor-pointer"
              >
                <a
                  :href="invoice.url"
                  target="_Blank"
                  class="flex items-center gap-4"
                >
                  <img src="../assets/icons/file.svg" alt="" />
                  Invoice-{{ index + 1 }}
                  <span class="tooltip ml-auto">
                    <img
                      v-if="invoice.status === 1"
                      src="../assets/icons/check-green.png"
                      class=""
                    />
                    <img
                      v-if="invoice.status === 0"
                      src="../assets/icons/error-orange.png"
                      class=""
                    />
                    <span class="tooltip-box">{{
                      invoice.status === 1
                        ? "Factura aprobada"
                        : "Factura en revisión"
                    }}</span>
                  </span>
                </a>
                <hr class="my-3" />
              </span>

              <label
                v-if="
                  product.typeService === 1 &&
                  product.vaucherStatus.length !== 0
                "
                class="cursor-pointer flex items-center justify-center gap-4 text-blue-500"
              >
                <input
                  type="file"
                  class="hidden"
                  @change="factura"
                  ref="fileupload"
                  accept="image/*,.pdf"
                />
                <img src="../assets/icons/plus-blue.svg" />
                Agregar otra factura comercial
              </label>
            </div>
          </article>

          <!-- Manifiesto de carga -->
          <article
            class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
          >
            <!-- CASO 1 -->
            <span v-if="statusReturn === false">
              <h3
                class="text-text-blue font-semibold xl:text-lg mb-6 text-center"
              >
                Manifiesto de carga
              </h3>
              <label
                class="xl:mx-16 cursor-pointer font-semibold text-white rounded-2xl bg-mainGreenLight py-5 text-sm xl:text-base flex items-center justify-center gap-4"
              >
                <img src="../assets/icons/subir-archivo.png" />
                <input
                  type="file"
                  class="hidden"
                  @change="returnFile"
                  ref="fileuploadReturn"
                  accept="image/*,.pdf"
                />
                Adjuntar
              </label>
            </span>
            <!-- CASO 2 -->
            <div
              v-if="statusReturn"
              class="mb-6 xl:mx-16 font-semibold rounded-2xl bg-mainGreenLight text-mainGreenLight ring ring-mainGreenLight bg-opacity-20 py-5 text-sm xl:text-base flex items-center justify-center gap-4"
            >
              Manifiesto de carga
              <img src="../assets/icons/check-green.png" class="w-6" />
            </div>

            <div
              v-if="statusReturn"
              class="xl:mx-16 font-semibold rounded-2xl p-5 text-sm xl:text-base"
            >
              <span v-for="(data, index) in returnList" :key="data">
                <a
                  :href="data.url"
                  target="_Blank"
                  class="flex items-center gap-4"
                >
                  <img src="../assets/icons/file.svg" alt="" />
                  {{ data.type === 1 ? "Manifiesto" : "Evidencia " }} -
                  {{ index + 1 }}

                  <img
                    v-if="data.status === 1"
                    src="../assets/icons/check-green.png"
                    class="ml-auto"
                  />
                  <img
                    v-if="data.status === 0"
                    src="../assets/icons/error-orange.png"
                    class="ml-auto"
                  />
                </a>
                <hr class="my-3" />
              </span>

              <label
                class="cursor-pointer flex items-center justify-center gap-4 text-blue-500"
              >
                <input
                  type="file"
                  class="hidden"
                  @change="returnFile"
                  ref="fileuploadReturn"
                  accept="image/*,.pdf"
                />
                <img src="../assets/icons/plus-blue.svg" />
                Agregar otro manifiesto de compra
              </label>
            </div>
          </article>
          <!-- 
Servicio return -->
          <article
            v-if="
              sreturn.status && (statusTracking === 9 || statusTracking === 10)
            "
            class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
          >
            <div
              class="mb-6 xl:mx-16 font-semibold rounded-2xl bg-mainGreenLight text-mainGreenLight ring ring-mainGreenLight bg-opacity-20 py-5 text-sm xl:text-base flex items-center justify-center gap-4 cursor-pointer"
            >
              Paquete/Producto devuelto al proveedor
              <img src="../assets/icons/check-green.png" class="w-6" />
            </div>

            <div
              class="xl:mx-16 font-semibold rounded-2xl p-5 text-sm xl:text-base"
            >
              <span
                v-for="(fileReturn, index) in sreturn.files"
                :key="fileReturn"
                class="cursor-pointer"
              >
                <a
                  :href="fileReturn.url_return"
                  target="_Blank"
                  class="flex items-center gap-4"
                >
                  <img src="../assets/icons/file.svg" alt="" />
                  {{ index === 0 ? "Etiqueta de devolución" : "Evidencia" }}
                  <span v-if="index !== 0">
                    -
                    {{ index }}
                  </span>
                  <span class="tooltip ml-auto">
                    <img src="../assets/icons/check-green.png" class="" />
                    <!-- <img
                      v-if="invoice.status === 0"
                      src="../assets/icons/error-orange.png"
                      class=""
                    /> -->
                    <!-- <span class="tooltip-box">{{
                      invoice.status === 1 ? "Factura aprobada" : "Factura en revisión"
                    }}</span> -->
                  </span>
                </a>
                <hr class="my-3" />
              </span>
            </div>
          </article>

          <article
            v-if="product.typeService === 2 && codeAmazon"
            class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
          >
            <h3 class="text-text-blue font-semibold xl:text-lg mb-5">
              Te podría interesar
            </h3>
            <span v-if="productsRecommend && product.typeService === 2">
              <div class="grid grid-cols-3 gap-8">
                <router-link
                  :to="{ name: 'Shooper', params: { url: data.url } }"
                  v-for="data in productsRecommend"
                  :key="data"
                >
                  <img
                    :src="data.image"
                    class="w-full h-40 object-contain mb-3"
                  />
                  <img src="../assets/images/amazon.svg" class="w-16" />
                  <p class="text-xs xl:text-sm font-medium line-clamp mb-3">
                    {{ data.title }}
                  </p>
                  <p class="text-xs xl:text-sm font-bold">
                    <span class="text-mainOrange">USD</span>
                    {{ data.price }}
                  </p>
                </router-link>
              </div>
            </span>
            <span v-else-if="product.typeService === 2">
              <div class="grid grid-cols-3 gap-8">
                <a href="#">
                  <img
                    src="../assets/icons/loader.svg"
                    class="mx-auto animate-spin w-1/2 h-40 object-contain mb-3"
                    style="animation-duration: 1.5s"
                  />
                  <p
                    class="text-center text-xs xl:text-sm font-medium line-clamp mb-3"
                  >
                    Cargando
                  </p>
                </a>
                <a href="#">
                  <img
                    src="../assets/icons/loader.svg"
                    class="mx-auto animate-spin w-1/2 h-40 object-contain mb-3"
                    style="animation-duration: 1.5s"
                  />
                  <p
                    class="text-center text-xs xl:text-sm font-medium line-clamp mb-3"
                  >
                    Cargando
                  </p>
                </a>
                <a href="#">
                  <img
                    src="../assets/icons/loader.svg"
                    class="mx-auto animate-spin w-1/2 h-40 object-contain mb-3"
                    style="animation-duration: 1.5s"
                  />
                  <p
                    class="text-center text-xs xl:text-sm font-medium line-clamp mb-3"
                  >
                    Cargando
                  </p>
                </a>
              </div>
            </span>
          </article>
        </section>
      </article>
    </section>
  </main>
  <footer-component />
</template>

<script>
import headerComponent from "../components/global/headerComponent.vue";
import footerComponent from "../components/global/footerComponent.vue";
import { server } from "../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  components: { headerComponent, footerComponent },
  data() {
    return {
      sreturn: {
        status: false,
        files: [],
      },
      // Serivicio return
      statusReturn: false,
      imageReturn: "",
      returnList: [],
      statusPayed: null,
      statusTracking: null,
      servicios: [],
      servicioSoles: 0.0,
      servicioDolares: 0.0,

      saldoUtilizado: null,
      cuponUtilizado: null,
      cuponUtilizadoSoles: null,

      newTotal: null,
      imageVaucher: "",
      styleTrans: false,
      trans: {
        number: "",
        img: "",
        nombreImg: "Adjuntar archivo",
        url: null,
      },

      //Estilo para el semaforo
      popUp: false,
      free: 0,
      //-------------------------------//
      //Id del producto para traer los detalles que contiene
      idProduct: this.$route.params.idPedido,
      //----------------------------------------------------//
      //Datos del producto
      product: {
        experienceAtm: "",
        numberTracking: "",
        id: 0,
        canal: "",
        img: "",
        name: "",
        link: "",
        typeService: 0,
        precio: "",
        cantidad: "",
        peso: "",
        directionDefault: 0,
        directionName: "",
        directionApellido: "",
        directionAddres: "",
        directionReferencia: "",
        directionThelephone: "",
        metodoDepago: 0,
        atmCodigo: "",
        atmLink: "",
        email: "",
        typeMoney: "",
        vaucherStatus: "",
        fechaEntrega: "",
      },
      //-------------------------------------------------------//
      detalle: {
        cif: "",
        precioProducto: "",
        shipping: "",
        tax: "",
        warehouse: "",
        flete: "",
        impuestos: "",
        valorem: "",
        igv: "",
        ipm: "",
        tarifa: "",
        tramiteAduanero: "",
        envioNacional: "",
        totalPagar: "",
      },
      codeAmazon: null,
      productsRecommend: null,
    };
  },
  created() {
    this.getDataProduct();
  },
  mounted() {
    window.scrollTo(0, 0);
    window.localStorage.removeItem("datalleProduct");
    window.localStorage.removeItem("shooperProducts");
    window.localStorage.removeItem("producDetail");
  },
  methods: {
    returnFile(e) {
      const file = e.target.files[0];
      this.cargarArchivoReturn(file);
    },
    cargarArchivoReturn(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imageReturn = e.target.result;
        this.sendImageReturn();
      };
      reader.readAsDataURL(file);
    },
    sendImageReturn() {
      axios
        .post(
          `${BASE_URL}` + "purchaseOrder/createmanifest/" + this.product.id,
          {
            from_admin: 0,
            manifest_archivo: this.imageReturn,
          }
        )
        .then(() => {
          location.reload();
        })
        .catch((error) => {
          if (error) {
            this.$refs.fileuploadReturn.value = null;
            this.imageReturn = "";
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description = "Error al enviar archivo";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    //metodo para traer productos recomendados segun el codigo de la orden
    getRecommendProducts(codeAmazon) {
      axios
        .get(`${BASE_URL}` + "product/getMalltinaProductRelation/" + codeAmazon)
        .then((response) => {
          this.productsRecommend = response.data.body ?? null;
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al traer productos relacionados";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    factura(e) {
      const file = e.target.files[0];
      this.cargarImagen(file);
    },
    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.imageVaucher = e.target.result;
        this.sendVaucher();
      };
      reader.readAsDataURL(file);
    },
    sendVaucher() {
      axios
        .put(
          `${BASE_URL}` +
            "purchaseOrder/update-order-no-grabr/" +
            this.product.id,
          {
            voucher_usa: this.imageVaucher,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            this.$refs.fileupload.value = null;
            this.imageVaucher = "";
            //----------component--------//
            this.$store.state.alert.titulo = "Éxito";
            this.$store.state.alert.description = "Factura comercial enviado";
            this.$store.state.alert.estado = true;
            //--------------------------//

            location.reload();
            // this.closeModal();
            // this.exito = true;
          }
        })
        .catch((error) => {
          if (error) {
            this.imageVaucher = "";
            this.$refs.fileupload.value = null;
            // this.closeModal();
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al subir su factura comercial";
            this.$store.state.alert.estado = true;
            //--------------------------//
          }
        });
    },
    copyCode() {
      const trackingCode = document.getElementById("tracking-code").textContent;
      navigator.clipboard
        .writeText(trackingCode)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description =
            "El número de tracking se copio con éxito";
          this.$store.state.alert.estado = true;
          //--------------------------//
        })
        .catch((error) => console.log(error));
    },
    factura2(e) {
      const file = e.target.files[0];
      this.trans.nombreImg = e.target.files[0].name;
      this.cargarImagen2(file);
    },
    cargarImagen2(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.trans.img = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    validateFormTrans() {
      const imagen = this.trans.img;
      const codigo = this.trans.number;

      if (codigo.length !== 0) {
        if (imagen.length === 0) {
          this.trans.img = null;
        }
        return true;
      } else {
        //----------component--------//
        this.$store.state.alert.titulo = "Validación";
        this.$store.state.alert.description = "Debe subir el N° de Operación";
        this.$store.state.alert.estado = true;
        //--------------------------//

        return false;
      }
    },
    sendVaucher2() {
      const result = this.validateFormTrans();
      if (result) {
        axios
          .put(
            `${BASE_URL}` +
              "purchaseOrder/update-order-no-grabr/" +
              this.product.id,
            {
              payment_code: this.trans.number,
              voucher: { voucher: this.trans.img, isFromAdmin: false },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              this.trans.number = response.data.body.attributes.payment_code;
              this.trans.url =
                response.data.body.attributes.vouchers_usa[
                  response.data.body.attributes.vouchers_usa - 1
                ] ?? null;

              if (response.data.body.attributes.voucher_link !== null) {
                this.trans.nombreImg = "Voucher cargado";
              } else {
                this.trans.nombreImg = "Sin Voucher";
              }
              this.styleTrans = true;
              //----------component--------//
              this.$store.state.alert.titulo = "Éxito";
              this.$store.state.alert.description = "Orden Actualizada";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          })
          .catch((error) => {
            if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al subir el Voucher de la transferencia de pago";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      }
    },
    copyPayment() {
      const nombre = document.getElementById(
        "transferencia-nombre"
      ).textContent;
      const ruc = document.getElementById("transferencia-ruc").textContent;
      const cuenta = document.getElementById(
        "transferencia-cuenta"
      ).textContent;
      const datos = `${nombre}\n${ruc}\n${cuenta} `;
      navigator.clipboard
        .writeText(datos)
        .then(() => {
          //----------component--------//
          this.$store.state.alert.titulo = "Éxito";
          this.$store.state.alert.description =
            "Se copio los datos de tranferencia con éxito";
          this.$store.state.alert.estado = true;
          //--------------------------//
        })
        .catch((error) => console.log(error));
    },
    imprimir() {
      window.print();
    },
    //   metodo para traer la data del producto segun el id
    getDataProduct() {
      axios
        .get(`${BASE_URL}` + "purchaseOrder/get/" + this.idProduct)
        .then((response) => {
          if (response.status === 200) {
            const dataProduct = response.data.body;
            this.statusPayed = dataProduct.attributes.payed;
            this.statusReturn =
              dataProduct.attributes.manifest.length === 0 ? false : true;
            this.returnList = dataProduct.attributes.manifest;
            this.statusTracking =
              dataProduct.attributes.order_tracking_details[
                dataProduct.attributes.order_tracking_details.length - 1
              ].attributes.status;

            this.saldoUtilizado = dataProduct.attributes.balance_discount;
            this.cuponUtilizado = dataProduct.attributes.coupon_discount;
            this.cuponUtilizadoSoles =
              dataProduct.attributes.coupon_discount *
              dataProduct.attributes.exchange;

            this.newTotal = dataProduct.attributes.new_total_cost;
            this.product.fechaEntrega = dataProduct.attributes.delivery_date;

            this.servicios = dataProduct.attributes.services;
            var totalServicioDolar = 0.0;
            var totalServicioSoles = 0.0;
            if (this.servicios.length !== 0) {
              this.servicios.forEach((value) => {
                totalServicioDolar = totalServicioDolar + value.pivot.amount;
                totalServicioSoles =
                  totalServicioSoles + value.pivot.amount * value.exchange_rate;
              });
              this.servicioDolares = totalServicioDolar;
              this.servicioSoles = totalServicioSoles;

              const foundReturn = this.servicios.find(
                (element) => element.name === "Return"
              );

              if (foundReturn) {
                this.sreturn.status = true;
                this.sreturn.files = dataProduct.attributes.serviceReturn;
              }
            }

            this.free =
              dataProduct.attributes.shipping_address === null
                ? 1
                : dataProduct.attributes.shipping_address.attributes.free;

            this.product.numberTracking =
              dataProduct.attributes.system_tracking_number;
            this.product.id = dataProduct.id;
            this.product.canal = dataProduct.attributes.channel;
            this.product.img = dataProduct.attributes.product_image_url;
            this.product.name = dataProduct.attributes.product_info;
            this.product.link = dataProduct.attributes.reference_link;
            this.product.typeService = dataProduct.attributes.direct_type;
            this.product.precio = dataProduct.attributes.real_price;
            this.product.cantidad = dataProduct.attributes.product_specimens;
            this.product.peso = dataProduct.attributes.weight_kg;
            this.product.directionDefault =
              dataProduct.attributes.at_office_flag;
            this.product.metodoDepago =
              dataProduct.attributes.payment_method_flag;
            this.product.experienceAtm =
              dataProduct.attributes.atm_payment === undefined
                ? ""
                : dataProduct.attributes.atm_payment.expiration;
            this.product.atmCodigo =
              dataProduct.attributes.atm_payment === undefined
                ? ""
                : dataProduct.attributes.atm_payment.payment_code;
            //Detesta si tiene invoice ya registrado
            this.product.vaucherStatus =
              dataProduct.attributes.vouchers_usa === null
                ? ""
                : dataProduct.attributes.vouchers_usa;
            this.product.typeMoney = dataProduct.attributes.currency;

            this.product.email =
              dataProduct.attributes.client.attributes.profile.attributes.userable.attributes.email;

            //ALmacenamos el link de atm
            this.product.atmLink =
              dataProduct.attributes.atm_payment === undefined
                ? ""
                : dataProduct.attributes.atm_payment.external_resource_url;

            //Datos de la dirección y valida si el campo de addres es cero se les coloca string vacio
            if (dataProduct.attributes.shipping_address !== null) {
              //Nombre
              this.product.directionName =
                dataProduct.attributes.shipping_address.attributes
                  .owner_name === null
                  ? ""
                  : dataProduct.attributes.shipping_address.attributes
                      .owner_name;
              this.product.directionApellido =
                dataProduct.attributes.shipping_address.attributes
                  .owner_lastname === null
                  ? ""
                  : dataProduct.attributes.shipping_address.attributes
                      .owner_lastname;
              this.product.directionAddres =
                dataProduct.attributes.shipping_address.attributes.address ===
                null
                  ? ""
                  : dataProduct.attributes.shipping_address.attributes.address;

              this.product.directionReferencia =
                dataProduct.attributes.shipping_address.attributes.reference ===
                null
                  ? ""
                  : dataProduct.attributes.shipping_address.attributes
                      .reference;
              this.product.directionThelephone =
                dataProduct.attributes.shipping_address.attributes
                  .telephone_number === null
                  ? ""
                  : dataProduct.attributes.shipping_address.attributes
                      .telephone_number;
            } else {
              this.product.directionReferencia = "";
              this.product.directionThelephone = "";
              this.product.directionAddres = "";
              this.product.directionName = "";
            }

            //Esto es para saber si envio vaucher o no
            if (
              dataProduct.attributes.payment_code !== null ||
              dataProduct.attributes.voucher_link !== null
            ) {
              this.styleTrans = true;
              this.trans.number = response.data.body.attributes.payment_code;
              if (dataProduct.attributes.voucher_link !== null) {
                this.trans.nombreImg = "Voucher cargado";
                this.trans.url =
                  response.data.body.attributes.vouchers_usa[
                    response.data.body.attributes.vouchers_usa.length - 1
                  ] ?? null;
              } else {
                this.trans.nombreImg = "Sin Voucher";
              }
            } else {
              this.styleTrans = false;
            }

            // Datos del detalle del pago -CIF
            this.detalle.cif = (
              dataProduct.attributes.cif_cost * dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.precioProducto = (
              dataProduct.attributes.real_price *
              dataProduct.attributes.product_specimens *
              dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.shipping = (
              dataProduct.attributes.real_shipping *
              dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.tax = (
              dataProduct.attributes.real_tax * dataProduct.attributes.exchange
            ).toFixed(2);

            this.detalle.warehouse = (
              dataProduct.attributes.warehouse * dataProduct.attributes.exchange
            ).toFixed(2);

            this.detalle.flete = (
              dataProduct.attributes.flete_cost *
              dataProduct.attributes.exchange
            ).toFixed(2);
            //Datos del detalle del pago - Impuestos
            this.detalle.impuestos = (
              dataProduct.attributes.arancel_cost *
              dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.valorem = (
              dataProduct.attributes.ad_valorem *
              dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.igv = (
              dataProduct.attributes.igv * dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.ipm = (
              dataProduct.attributes.ipm * dataProduct.attributes.exchange
            ).toFixed(2);
            //Datos del detalle del pago - Tarifa
            this.detalle.tarifa = (
              dataProduct.attributes.distribution_cost *
              dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.tramiteAduanero = (
              dataProduct.attributes.tramite_aduanero *
              dataProduct.attributes.exchange
            ).toFixed(2);
            this.detalle.envioNacional = (
              dataProduct.attributes.national_shipping *
              dataProduct.attributes.exchange
            ).toFixed(2);
            //Total a pagar
            this.detalle.totalPagar = (
              dataProduct.attributes.total_cost *
              dataProduct.attributes.exchange
            ).toFixed(2);
            if (dataProduct.attributes.code_amazon !== null) {
              this.codeAmazon = dataProduct.attributes.code_amazon;
              this.getRecommendProducts(dataProduct.attributes.code_amazon);
            }
          }
        })
        .catch((error) => {
          if (error) {
            //----------component--------//
            this.$store.state.alert.titulo = "Error";
            this.$store.state.alert.description =
              "Error al mostrar detalle del pedido";
            this.$store.state.alert.estado = true;
            //--------------------------//

            this.$router.push({ name: "Perfil", params: { type: "pedidos" } });
          }
        });
    },
  },
};
</script>
