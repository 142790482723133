<template>
  <div
    :class="style.information ? '' : 'hidden'"
    class="py-8 px-4 flex justify-center fixed inset-0 z-50 bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div
      class="relative bg-sky border shadow-lg rounded-2xl py-8 px-5 xl:px-20 xl:py-16 overflow-y-auto xl:w-6/12"
    >
      <img
        @click.prevent="seeInformation"
        src="../../../assets/icons/icon-exit-login.svg"
        class="absolute w-4 xl:w-auto top-4 xl:top-8 right-4 xl:right-8 cursor-pointer"
        alt="Icono de Sendi"
      />
      <div class="">
        <p class="font-semibold mb-2">Tarjetas de Crédito</p>
        <p class="text-sm xl:text-base mb-6">Acreditación instantanea.</p>
        <h3 class="xl:hidden text-xs mb-4 font-bold">
          También puedes pagar con tu tarjeta de crédito
        </h3>
        <p class="text-xs xl:text-base mb-6">
          <span class="text-mainGreenLight font-semibold"
            >Hasta 12 cuotas sin intereses</span
          >
          con estos bancos
        </p>
        <div class="xl:flex items-center justify-between mb-8">
          <div class="flex gap-4 xl:gap-10 mb-4">
            <div>
              <img
                class="block mb-4 mx-auto h-8 w-16"
                src="../../../assets/icons/bbva.svg"
                alt="Icono de Sendi"
              />
              <p class="text-center text-xs">12 cuotas</p>
            </div>
            <div>
              <img
                class="block mb-4 mx-auto h-8 w-16"
                src="../../../assets/icons/cencosud.svg"
                alt="Icono de Sendi"
              />
              <p class="text-center text-xs">12 cuotas</p>
            </div>
            <div>
              <img
                class="block mb-4 mx-auto h-8 w-16"
                src="../../../assets/icons/bcp.svg"
                alt="Icono de Sendi"
              />
              <p class="text-center text-xs">12 cuotas</p>
            </div>
          </div>
          <a class="text-mainBlue block mb-6 text-xs" href="#"
            >Ver condiciones</a
          >
        </div>
        <p class="text-xs xl:text-base">
          <span class="text-mainGreenLight font-semibold"
            >Hasta 6 cuotas sin intereses</span
          >
          con estas tarjetas
        </p>
        <p class="mb-6">Con todos los bancos</p>
        <div class="xl:flex items-center justify-between mb-8">
          <div class="flex gap-4 xl:gap-10 mb-4">
            <div>
              <img
                class="block mb-4 mx-auto h-8 w-16"
                src="../../../assets/icons/diners-club.svg"
                alt="Icono de Sendi"
              />
              <p class="text-center text-xs">06 cuotas</p>
            </div>
          </div>
          <a class="text-mainBlue block mb-6 text-xs" href="#"
            >Ver condiciones</a
          >
        </div>
        <p class="text-xs xl:text-base font-medium mb-6">
          O hasta 12 cuotas con interés
        </p>
        <div class="flex gap-4 xl:gap-10">
          <div>
            <img
              class="block mb-4 mx-auto"
              src="../../../assets/icons/icon-visa.svg"
              alt="Icono de Sendi"
            />
            <p class="text-center text-xs">12 cuotas</p>
          </div>
          <div>
            <img
              class="block mb-4 mx-auto"
              src="../../../assets/icons/icon-master-card.svg"
              alt="Icono de Sendi"
            />
            <p class="text-center text-xs">12 cuotas</p>
          </div>
          <div class="">
            <img
              class="block mb-4 mx-auto"
              src="../../../assets/icons/icon-american-express.svg"
              alt="Icono de Sendi"
            />
            <p class="text-center text-xs">12 cuotas</p>
          </div>
          <div>
            <img
              class="block mb-4 mx-auto"
              src="../../../assets/icons/icon-dinners-club.svg"
              alt="Icono de Sendi"
            />
            <p class="text-center text-xs">12 cuotas</p>
          </div>
        </div>
        <hr class="my-6" />
        <p class="font-semibold mb-4 text-sm xl:text-base">Paypal</p>
        <p class="mb-8 text-sm xl:text-base">Acreditación instantanea.</p>
        <div class="grid grid-cols-5 xl:grid-cols-9 gap-y-4">
          <img
            src="../../../assets/icons/info-1.svg"
            class="h-8 object-cover"
            alt="Icono de Sendi"
          />
          <img
            src="../../../assets/icons/info-2.svg"
            class="h-8 object-cover"
            alt="Icono de Sendi"
          />
          <img
            src="../../../assets/icons/info-3.svg"
            class="h-8 object-cover"
            alt="Icono de Sendi"
          />
          <img
            src="../../../assets/icons/info-4.svg"
            class="h-8 object-cover"
            alt="Icono de Sendi"
          />
          <img
            src="../../../assets/icons/info-5.svg"
            class="h-8 object-cover"
            alt="Icono de Sendi"
          />
          <img
            src="../../../assets/icons/info-6.svg"
            class="h-8 object-cover"
            alt="Icono de Sendi"
          />
          <img
            src="../../../assets/icons/info-7.svg"
            class="h-8 object-cover"
            alt="Icono de Sendi"
          />
          <img
            src="../../../assets/icons/info-8.svg"
            class="h-8 object-cover"
            alt="Icono de Sendi"
          />
          <img
            src="../../../assets/icons/info-9.svg"
            class="h-8 object-cover"
            alt="Icono de Sendi"
          />
        </div>
        <hr class="my-6" />
        <p class="font-semibold mb-4 text-sm xl:text-base">
          Tarjetas de débito
        </p>
        <p class="text-gray-400 mb-4 text-sm xl:text-base">
          Acreditación instantánea.
        </p>
        <div class="flex gap-4 xl:gap-10">
          <!-- <img src="../assets/icons/mercado-pago.svg" class="w-24 mb-2 border border-red-500"> -->
          <div>
            <img
              class="block mb-4 mx-auto"
              src="../../../assets/icons/icon-visa.svg"
              alt="Icono de Sendi"
            />
            <p class="text-center text-xs">12 cuotas</p>
          </div>
          <div>
            <img
              class="block mb-4 mx-auto"
              src="../../../assets/icons/icon-master-card.svg"
              alt="Icono de Sendi"
            />
            <p class="text-center text-xs">12 cuotas</p>
          </div>
        </div>
        <hr class="my-8" />
        <p class="font-semibold mb-4 text-sm xl:text-base">
          Otros medios de pago
        </p>
        <p class="mb-4 text-sm xl:text-base">
          Paga desde tu banca por internet, banca móvil o en agencias y agentes
          (BCP, BBVA Continental, Scotibank, Interbank, Banbif, Wester Union,
          Kasnet o FullCarga).
          <span class="block text-gray-400 mt-2"
            >Acreditación instantánea.</span
          >
        </p>
        <img
          src="../../../assets/icons/pago-efectivo.svg"
          class="w-20"
          alt="Icono de Sendi"
        />
      </div>
    </div>
  </div>
  <article
    class="bg-white border shadow-lg rounded-2xl px-4 py-6 xl:py-8 xl:px-10 mb-8"
  >
    <h3 class="text-text-blue font-semibold text-lg mb-5">
      Información de envío
      <span
        @click.prevent="changeStatusInformation"
        class="font-normal text-sm text-mainOrange cursor-pointer"
        >{{ information ? "(Ver más)" : "(Ver menos)" }}</span
      >
    </h3>

    <div class="flex items-center justify-start gap-4 mb-6">
      <img src="../../../assets/icons/entrega.svg" />
      <p class="text-xs xl:text-base">
        Tiempo estimado de entrega:

        <span class="font-semibold text-mainBlue" v-if="fecha.tipo === 1"
          >Entre el {{ fecha.diaInicio }} - {{ fecha.diaFinal }}
          {{ fecha.mesInicio }}</span
        >
        <span class="font-semibold text-mainBlue" v-if="fecha.tipo === 2"
          >Entre el {{ fecha.diaInicio }} {{ fecha.mesInicio }} -
          {{ fecha.diaFinal }} {{ fecha.mesFinal }}</span
        >
      </p>
    </div>
    <p
      class="text-xs xl:text-base mb-2"
      v-if="data.servicio === 2 && this.$store.state.errorShooper === false"
    >
      Vendido por:
      <b>{{ data.store.charAt(0).toUpperCase() + data.store.slice(1) }}</b>
    </p>

    <p
      class="text-xs xl:text-base"
      v-if="data.servicio === 2 && this.$store.state.errorShooper === false"
    >
      Enviado por: <b>Sendi</b>
    </p>
    <span v-if="!information">
      <hr class="my-6" v-if="data.servicio === 2" />
      <a
        href="#"
        class="cursor-pointer xl:text-base text-sm flex items-center gap-6 hover:bg-mainRed hover:bg-opacity-10 py-3 px-2 transition-colors"
      >
        <img src="../../../assets/icons/compr-protegida.png" alt="" />
        <p>
          <router-link :to="{ name: 'CompraSegura' }" target="_blank">
            <span class="text-text-blue underline font-semibold block"
              >Compra protegida</span
            >
            <span>Reembolsamos el 100% de la compra</span>
          </router-link>
        </p>
      </a>
      <a
        href="#"
        class="cursor-pointer xl:text-base text-sm flex items-center gap-6 hover:bg-mainRed hover:bg-opacity-10 py-3 px-2 transition-colors"
      >
        <img src="../../../assets/icons/caja-de-devolucion_1.png" alt="" />
        <p>
          <span class="text-gray-500 font-semibold block"
            >Devolución gratis</span
          >
          <span>Garantía, Cambios y Devoluciones</span>
        </p>
      </a>
      <a
        href="#"
        class="cursor-pointer xl:text-base text-sm flex items-center gap-6 hover:bg-mainRed hover:bg-opacity-10 py-3 px-2 transition-colors"
      >
        <img src="../../../assets/icons/entregas-peru.png" alt="" />
        <p>
          <span class="text-gray-500 font-semibold block"
            >Entrega a todo el pais</span
          >
          <span>Gratis para todo Lima</span>
        </p>
      </a>
      <a
        href="#"
        class="cursor-pointer xl:text-base text-sm flex items-center gap-6 hover:bg-mainRed hover:bg-opacity-10 py-3 px-2 transition-colors"
      >
        <img src="../../../assets/icons/como-pagar.png" alt="" />
        <p>
          <span
            @click.prevent="seeInformation"
            class="text-text-blue underline font-semibold block"
            >¿Cómo pagar con tarjeta de crédito?</span
          >
          <span>Distintos métodos de pago hasta 12 cuotas sin intereses</span>
        </p>
      </a>
    </span>
  </article>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      information: true,
      style: { information: false },
      //Datos de la fecha
      fecha: {
        tipo: 0,
        diaInicio: "",
        diaFinal: "",
        mesInicio: "",
        mesFinal: "",
      },
    };
  },
  mounted() {
    const DICCIONARIO_MESES = {
      1: "Enero",
      2: "Febrero",
      3: "Marzo",
      4: "Abril",
      5: "Mayo",
      6: "Junio",
      7: "Julio",
      8: "Agosto",
      9: "Septiembre",
      10: "Octubre",
      11: "Noviembre",
      12: "Diciembre",
    };

    // Cuantos dias hay que sumar de mas, segun el dia en que se realiza la compra
    // const DICCIONARIO_FINDES = {
    //   Domingo: 4,
    //   Lunes: 4,
    //   Martes: 6,
    //   Miércoles: 6,
    //   Jueves: 6,
    //   Viernes: 6,
    //   Sábado: 4,
    // };

    // Helper para obtener el dia en que se esta realizando la compra
    // const obtenerDia = (fecha) => {
    //   const day = fecha.getDay();

    //   if (day == 0) return "Domingo";
    //   if (day == 1) return "Lunes";
    //   if (day == 2) return "Martes";
    //   if (day == 3) return "Miércoles";
    //   if (day == 4) return "Jueves";
    //   if (day == 5) return "Viernes";
    //   if (day == 6) return "Sábado";
    // };

    // Helper para sumarle dias a una fecha
    const sumarDias = (fecha, dias) => {
      fecha.setDate(fecha.getDate() + dias);
      return fecha.toLocaleDateString();
    };

    const parsearFecha = () => {
      // const dias_no_habil = DICCIONARIO_FINDES[obtenerDia(new Date())];

      // const fecha_inicio = sumarDias(new Date, 5 + dias_no_habil)
      const fecha_inicio = sumarDias(new Date(), 5);
      const fecha_inicio_array = fecha_inicio.split("/");
      const dia_inicio = fecha_inicio_array[0];
      const mes_inicio = DICCIONARIO_MESES[fecha_inicio_array[1]];

      // const fecha_final = sumarDias(new Date, 15 + dias_no_habil)
      const fecha_final = sumarDias(new Date(), 15);
      const fecha_final_array = fecha_final.split("/");
      const dia_final = fecha_final_array[0];
      const mes_final = DICCIONARIO_MESES[fecha_final_array[1]];

      if (mes_inicio === mes_final) {
        this.fecha.tipo = 1;
        this.fecha.diaInicio = `${dia_inicio}`;
        this.fecha.diaFinal = `${dia_final}`;
        this.fecha.mesInicio = `${mes_inicio}`;
      } else {
        this.fecha.tipo = 2;
        this.fecha.diaInicio = `${dia_inicio}`;
        this.fecha.diaFinal = `${dia_final}`;
        this.fecha.mesFinal = `${mes_final}`;
        this.fecha.mesInicio = `${mes_inicio}`;
      }
    };

    parsearFecha();
  },
  methods: {
    changeStatusInformation() {
      this.information = !this.information;
    },
    seeInformation() {
      this.style.information = !this.style.information;
    },
  },
};
</script>
