<template>
  <div
    @click.prevent="selectDirection"
    :class="select.type === 3 ? 'ring-mainGreenLight' : 'ring-gray-100'"
    class="relative ring hover:ring-mainGreenLight shadow-sm rounded-2xl"
  >
    <div
      class="border-b rounded-2xl flex items-center justify-between py-4 px-5 xl:p-6 font-semibold cursor-pointer"
    >
      <p class="text-text-blue text-sm xl:text-base">Recojo en Oficina</p>

      <span
        :class="
          select.type === 3
            ? 'bg-mainGreenLight text-white'
            : 'bg-gray-light text-gray-400'
        "
        class="font-semibold text-xs py-2 px-2 xl:px-4 rounded-xl"
      >
        {{ select.type === 3 ? "Seleccionado" : "Seleccionar" }}</span
      >
    </div>
    <div
      v-if="select.type === 3"
      class="rounded-2xl p-6 cursor-pointer flex items-center gap-4 xl:gap-8"
    >
      <img src="../../../../../assets/icons/oficina.svg" alt="" />
      <div>
        <p class="text-sm font-bold">Lima</p>
        <p class="text-xs text-gray-400">
          Jr. Emilio Althaus Nro. 121 Oficina 403
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["select", "existDirection"],
  data() {
    return {};
  },
  mounted() {
    this.detectUserDirection();
  },
  methods: {
    sendDirection() {
      window.open(
        "https://www.google.com/maps/place/Jr.+Emilio+Althaus+403,+Lima+15046/data=!4m2!3m1!1[…]5e0ee89?sa=X&ved=2ahUKEwi6kdq72uz6AhXJAbkGHZWqDswQ8gF6BAgIEAE"
      );
    },
    detectUserDirection() {
      const direction = localStorage.getItem("direction");
      if (this.existDirection === false && direction === null) {
        this.selectDirection();
      }
    },
    selectDirection() {
      this.$emit("default", { type: 3 });
      this.$emit("changeDirection");
    },
  },
};
</script>
