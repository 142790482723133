<template>
  <!-- Modal de cual es el peso -->
  <div
    :class="modalPeso ? '' : 'oculto'"
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity delay-400"
  >
    <div
      class="relative mx-4 px-8 xl:px-20 py-24 bg-white rounded-2xl border shadow-sm"
    >
      <p class="font-semibold text-2xl xl:text-4xl text-center mb-8 xl:mb-16">
        ¿Cuál es el peso?
      </p>
      <!-- Imagenes -->
      <div class="flex items-center xl:gap-28 mb-6 w-full justify-between">
        <img
          src="../../assets/images/producto-camara.png"
          class="w-12 xl:w-28 h-auto object-contain"
          alt="Icono de Sendi"
        />
        <img
          src="../../assets/images/la-caja.png"
          class="w-12 xl:w-28 h-auto object-contain"
          alt="Icono de Sendi"
        />
        <img
          src="../../assets/images/peso-completo.png"
          class="w-12 xl:w-28 h-auto object-contain"
          alt="Icono de Sendi"
        />
      </div>
      <!-- Textos -->
      <div class="flex items-center justify-between mb-8">
        <h3 class="font-bold text-center text-xs xl:text-base">Tu producto</h3>
        <h3 class="font-bold text-center text-xs xl:text-4xl">+</h3>
        <h3 class="font-bold text-center text-xs xl:text-base">La caja</h3>
        <h3 class="font-bold text-center text-xs xl:text-4xl">=</h3>
        <h3 class="font-bold text-center text-xs xl:text-base text-mainBlue">
          El peso <br />
          completo
        </h3>
      </div>
      <button
        @click.prevent="changeModalPeso"
        class="mb-6 text-white bg-mainBlue font-bold text-sm xl:text-base py-6 w-full xl:w-3/5 block mx-auto rounded-2xl"
      >
        Aceptar
      </button>
      <p class="text-center text-sm xl:text-base">
        Considerar peso completo.
        <a
          href="https://sendi.freshdesk.com/a/solutions/articles/73000554397"
          target="_blank"
          class="underline font-semibold"
          >Más información</a
        >
      </p>
      <img
        @click.prevent="changeModalPeso"
        src="../../assets/icons/icon-exit-login.svg"
        class="cursor-pointer absolute top-8 right-4 xl:right-8"
        alt="Icono de Sendi"
      />
    </div>
  </div>
  <!-- fin Modal de cual es el peso -->
  <div
    class="px-4 xl:px-9 py-4 rounded-2xl bg-white border shadow-lg xl:border-none xl:shadow-none xl:py-0"
  >
    <!-- Direccion en USA -->
    <p class="mb-2 font-semibold text-xs xl:text-sm">
      La dirección de envío que debes usar al comprar en tiendas extranjeras:
    </p>
    <div
      @click.prevent="openDirection"
      class="p-5 text-sm xl:text-base border bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl mb-10 flex items-center justify-between cursor-pointer"
    >
      <div class="flex gap-6 items-center">
        <img src="../../assets/images/mapita.png" class="w-10" />
        <span class="font-normal">Estados Unidos </span>
      </div>

      <img src="../../assets/icons/chevron-right-orange.png" alt="" />
    </div>
    <!-- Nombre del producto -->
    <div class="mb-8">
      <label
        for="nombre_producto"
        class="block mb-2 font-semibold text-xs xl:text-sm"
        >Nombre del producto</label
      >
      <input
        id="nombre_producto"
        :class="typeCotizacion === 'true' ? 'cursor-not-allowed' : ''"
        class="p-6 input w-full text-sm xl:text-base bg-gray-100 bg-opacity-50 focus:border-blue-400 shadow-sm rounded-2xl"
        v-model="product.name"
        placeholder="Ingresa el nombre del producto"
        :disabled="typeCotizacion === 'true'"
      />
    </div>
    <!-- Foto referencial -->
    <div class="mb-8">
      <div
        class="relative flex items-center justify-center rounded-xl bg-white border p-4 py-12 mb-24"
      >
        <span class="bg-white absolute px-2 -top-2 left-3 text-gray-400 text-xs"
          >Foto referencial (Opcional)</span
        >
        <label class="cursor-pointer">
          <img
            class="w-40 h-60 object-contain mx-auto"
            :class="typeCotizacion === 'true' && 'cursor-not-allowed'"
            src="../../assets/images/caja.svg"
            id="image"
            v-if="typeCotizacion === 'true'"
          />
          <img
            class="w-40 h-60 object-contain mx-auto"
            src="../../assets/icons/camara.svg"
            id="image"
            v-else-if="product.foto.length === 0"
          />

          <img
            class="w-40 h-60 object-contain mx-auto"
            :src="product.foto"
            id="image"
            v-else
          />
          <input
            v-if="typeCotizacion !== 'true'"
            type="file"
            @change="previewImage"
            class="hidden"
            accept="image/png,image/jpeg,image/jpg"
          />
        </label>
        <img
          @click.prevent="cancelImage"
          class="absolute top-5 right-5 cursor-pointer"
          src="../../assets/icons/icon-exit-login.svg"
        />
        <div class="absolute inset-x-0 -bottom-12 flex justify-center">
          <div
            class="py-4 px-6 xl:py-5 xl:px-10 flex gap-6 border-2 shadow-sm rounded-2xl bg-white"
          >
            <button class="px-2 cursor-pointer">
              <img
                @click.prevent="
                  typeCotizacion !== 'true' ? cantidadStock(0) : ''
                "
                class="cursor-pointer transform hover:scale-90 transition-all"
                src="../../assets/icons/icon-restar.svg"
              />
            </button>
            <input
              class="w-16 text-center font-semibold text-2xl xl:text-3xl"
              type="number"
              v-model="product.cantidad"
              disabled
            />
            <button class="px-2 cursor-pointer">
              <img
                @click.prevent="
                  typeCotizacion !== 'true' ? cantidadStock(1) : ''
                "
                class="cursor-pointer transform hover:scale-90 transition-all"
                src="../../assets/icons/icon-sumar.svg"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Precio referencial y Peso -->
    <div class="grid xl:grid-cols-2 gap-8 xl:gap-6 mb-8 xl:mb-8">
      <div>
        <label
          for="precio_referencial"
          class="block mb-2 font-semibold text-xs xl:text-sm"
          >Precio referencial</label
        >
        <div class="relative">
          <div
            class="absolute left-6 inset-y-0 flex items-center font-bold text-mainOrange"
          >
            <span>USD</span>
          </div>
          <input
            id="precio_referencial"
            class="pl-16 p-6 input w-full font-semibold focus:border-blue-400 bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl"
            type="number"
            v-model="product.precio"
            @keydown="typeNumber"
          />
          <span class="absolute bottom-2 left-6 text-xs text-gray-400"
            >Precio Max. USD 3000.00</span
          >
          <div
            class="cursor-pointer absolute right-6 inset-y-0 flex items-center text-mainRed"
          >
            <img
              @mouseover="popUp = true"
              @mouseleave="popUp = false"
              id="popup-btn"
              src="../../assets/icons/question-black.svg"
            />
            <!-- POP UP -->
            <div
              v-if="popUp"
              class="z-10 absolute -top-16 xl:-top-20 -right-14 xl:-right-32 bg-mainRed text-white text-xs xl:text-sm text-center p-4 rounded-2xl w-max transition-all delay-400"
            >
              Si el precio de tu producto excede el valor máximo,
              <span class="block">
                comunícate con atención al cliente para poder ayudarte
              </span>
              <span
                class="absolute -bottom-7 right-10 xl:right-28 w-14 h-8 triangle bg-mainRed"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <label for="peso" class="block mb-2 font-semibold text-xs xl:text-sm"
          >Peso</label
        >
        <div class="relative">
          <input
            id="peso"
            class="p-6 input w-full font-bold focus:border-blue-400 bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl"
            type="number"
            v-model="product.peso"
            @keydown="typeNumber"
          />
          <div class="absolute right-6 inset-y-0 flex items-center font-bold">
            <span
              @click.prevent="changeUnit('kg')"
              :class="product.unidad === 'kg' ? 'text-mainOrange' : ''"
              class="cursor-pointer px-3 hover:text-mainOrange transition-colors"
              >kg</span
            >
            <span
              @click.prevent="changeUnit('lb')"
              :class="product.unidad === 'lb' ? 'text-mainOrange' : ''"
              class="cursor-pointer px-3 hover:text-mainOrange transition-colors border-l border-r"
              >lb</span
            >
            <span
              @click.prevent="changeUnit('oz')"
              :class="product.unidad === 'oz' ? 'text-mainOrange' : ''"
              class="cursor-pointer px-3 hover:text-mainOrange transition-colors border-r"
              >oz</span
            >
            <img
              @click.prevent="changeModalPeso"
              src="../../assets/icons/icon-info.svg"
              class="pl-3 cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Agregar boleta -->

    <div class="grid xl:grid-cols-2 gap-8 xl:gap-6 mb-8 xl:mb-8">
      <div>
        <label for="boleta" class="block mb-2 font-semibold text-xs xl:text-sm"
          >Adjuntar Invoice/Factura del Proveedor</label
        >
        <div class="relative">
          <label
            for="boleta"
            :class="
              nameFactura !== '(Invoice/Factura)'
                ? 'ring ring-mainGreenLight'
                : ''
            "
            class="block cursor-pointer p-6 pl-16 input w-full focus:border-blue-400 bg-gray-100 bg-opacity-50 shadow-sm rounded-2xl underline"
          >
            {{ nameFactura }}
            <span class="absolute left-6 inset-y-0 flex items-center font-bold">
              <img
                v-if="nameFactura === '(Invoice/Factura)'"
                id="check_factura"
                src="../../assets/icons/agregar__boleta.png"
              />
              <img
                v-else
                id="check_factura"
                src="../../assets/icons/check-green.png"
              />
            </span>
            <input
              type="file"
              name=""
              id="boleta"
              class="hidden"
              @change="factura"
              accept="image/*,.pdf"
            />
          </label>
        </div>
      </div>
    </div>

    <div class="mb-8">
      <label
        for="descripcion_producto"
        class="block mb-2 font-semibold text-xs xl:text-sm"
        >Describe tu producto
        <span class="text-mainOrange">(Opcional)</span></label
      >
      <div class="relative">
        <textarea
          v-model="product.description"
          id="descripcion_producto"
          class="pb-14 py-5 px-6 border text-sm xl:text-base w-full rounded-2xl focus:outline-none p-6 text-gray-400 resize-none focus:ring focus:ring-blue-400"
          placeholder="Talla, tamaño, capacidad, color, etc"
          rows="3"
        ></textarea>
        <p
          class="absolute bottom-2 inset-x-0 bg-white font-bold text-xs xl:text-sm mx-6 pt-2 pb-4 border-t pt-3"
        >
          Ayúdanos a elegir correctamente tu producto
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { server } from "../../config/global";
const BASE_URL = server.API_URL;

import axios from "axios";
export default {
  props: ["changeMoney"],
  data() {
    return {
      idCotizacion: null,
      idUsuario: null,
      typeCotizacion: this.$route.params.typeCotizacion,
      nameFactura: "(Invoice/Factura)",
      modalPeso: false,
      popUp: false,
      product: {
        name: "",
        precio: parseFloat(this.$route.params.precio),
        peso: parseFloat(this.$route.params.peso),
        unidad: this.$route.params.unidad,
        cantidad: 1,
        foto: "",
        vaucher: "",
        description: "",
      },
    };
  },
  created() {
    const data1 = parseFloat(this.$route.params.precio);
    const data2 = parseFloat(this.$route.params.peso);
    const data3 = this.$route.params.unidad;
    if (isNaN(data1) || isNaN(data2)) {
      //----------component--------//
      this.$store.state.alert.titulo = "Validación";
      this.$store.state.alert.description = "Ingrese datos válidos";
      this.$store.state.alert.estado = true;
      //--------------------------//

      this.$router.push({ name: "Home" });
    }
    if (data3 !== "kg" && data3 !== "lb" && data3 !== "oz") {
      //----------component--------//
      this.$store.state.alert.titulo = "Validación";
      this.$store.state.alert.description = "Unidad de medida no permitida";
      this.$store.state.alert.estado = true;
      //--------------------------//

      this.$router.push({ name: "Home" });
    }
  },
  mounted() {
    if (this.$route.params.typeCotizacion === "true") {
      this.product.name = "PAQUETE";
    }
    const producto = localStorage.getItem("datalleProduct");
    if (producto !== null) {
      const detalle = JSON.parse(producto);
      this.product.name = detalle.nombre;
      this.product.precio = detalle.precio;
      this.product.peso = detalle.peso;
      this.product.unidad = detalle.unidad;
      this.product.cantidad = detalle.cantidad;
      this.product.foto = detalle.foto;
      this.product.vaucher = detalle.filevoucher;
      this.nameFactura = detalle.voucher;
    }
    this.calculo();
    this.getDataLocalStorage();
    this.createCotizacion();
  },
  methods: {
    getDataLocalStorage() {
      const data = localStorage.getItem("auth");
      const dataUser = JSON.parse(data);
      if (dataUser !== null) {
        this.idUsuario = dataUser.globalID;
      } else {
        this.idUsuario = null;
      }
    },
    createCotizacion() {
      axios
        .post(`${BASE_URL}` + "quotation/save", {
          item_url: "vacío",
          product_image_url: "vacío",
          product_name: this.product.name,
          price: this.product.precio,
          weight: this.product.peso,
          idUsuario: this.idUsuario,
          idStore: 1,
        })
        .then((response) => {
          this.idCotizacion = response.data.body.id;
        })
        .catch(() => {});
    },
    cantidadStock(data) {
      const datamin = 1;
      const datmax = 25;
      if (data === 1) {
        if (this.product.cantidad < datmax) {
          this.product.cantidad++;
        }
      }
      if (data === 0) {
        if (this.product.cantidad > datamin) {
          this.product.cantidad--;
        }
      }
    },
    openDirection() {
      this.$store.commit("statusModalDirection");
    },
    changeModalPeso() {
      this.modalPeso = !this.modalPeso;
    },
    typeNumber($event) {
      const val = $event.target.value;
      if (val.includes(".")) {
        const dotIndex = val.indexOf(".");
        const valLength = val.length;
        const diferencia = valLength - dotIndex;
        if (
          diferencia > 2 &&
          $event.key !== "Backspace" &&
          $event.key !== "Tab"
        ) {
          $event.preventDefault();
        }
      }
    },
    calculo() {
      //Operación par amandar al detalle del pedido
      //variables declaradas
      var price = 0.0;
      if (isNaN(this.product.precio)) {
        price = 0.0;
      } else {
        price = this.product.precio;
      }

      var cantidad = 0;
      if (isNaN(this.product.cantidad)) {
        cantidad = 0;
      } else {
        cantidad = this.product.cantidad;
      }

      var pesoProduct = 0.0;
      if (isNaN(this.product.peso)) {
        pesoProduct = 0.0;
      } else {
        pesoProduct = this.product.peso;
      }

      var peso = pesoProduct * cantidad;

      var unidad = this.product.unidad;
      //Conversión para onvertir a kg si el peso es diferente
      if (unidad === "lb") {
        peso = peso * (1 / 2.2046);
      } else if (unidad === "oz") {
        peso = peso * (1 / 35.274);
      }
      if (peso < 1) {
        peso = 1;
      }

      //-------Precio-----------//
      var precio = price * cantidad;
      precio = Math.ceil(100 * precio) / 100;
      this.$store.state.servicePrecioProduct = precio;

      var precioSoles = 0.0;
      precioSoles = precio * this.changeMoney;
      precioSoles = Math.ceil(100 * precioSoles) / 100;
      //-------shipping-----------//
      var shipping = 0.0;

      //-------tax-----------//
      var tax = (7 / 100) * precio;

      var taxSoles = 0.0;
      taxSoles = tax * this.changeMoney;
      taxSoles = Math.ceil(100 * taxSoles) / 100;

      //-------front - tax-----------//
      // var frontTax =
      //   (7 / 100) * precio +
      //   (0.75 / 100) * (precio + shipping + (7 / 100) * precio) -
      //   precio * 0.07;
      // frontTax = Math.ceil(100 * frontTax) / 100;

      // var frontTaxSoles = 0.0;
      // frontTaxSoles = frontTax * this.changeMoney;
      // frontTaxSoles = Math.ceil(100 * frontTaxSoles) / 100;
      //----------Flete------------//
      var flete = 10 * peso;
      flete = Math.ceil(flete);

      var fleteSoles = 0.0;
      fleteSoles = flete * this.changeMoney;
      fleteSoles = Math.ceil(100 * fleteSoles) / 100;

      //----------warehouse------------//

      var warehouse =
        (7 / 100) * precio +
        (0.75 / 100) * (precio + shipping + (7 / 100) * precio) -
        precio * 0.07;

      warehouse = Math.ceil(100 * warehouse) / 100;

      var warehouseSoles = 0.0;
      warehouseSoles = warehouse * this.changeMoney;
      warehouseSoles = Math.ceil(100 * warehouseSoles) / 100;

      //-------total-Cif -----------//
      var totalCif = precio + shipping + tax + flete + warehouse;
      totalCif = Math.ceil(100 * totalCif) / 100;

      var totalCifSoles = 0.0;
      totalCifSoles =
        precioSoles + shipping + taxSoles + fleteSoles + warehouseSoles;
      totalCifSoles = Math.ceil(100 * totalCifSoles) / 100;

      //-------front total-Cif-----------//
      var gastosUsa = warehouse + flete;
      gastosUsa = Math.ceil(100 * gastosUsa) / 100;

      var gastosUsaSoles = 0.0;
      gastosUsaSoles = warehouseSoles + fleteSoles;
      gastosUsaSoles = Math.ceil(100 * gastosUsaSoles) / 100;

      //------------------------------------------//
      //-----------------IMPUESTOS - "mayor a 200 se cobra impuestos"----------------------//
      //------------------------------------------//
      var valorem = 0.0;
      var igv = 0.0;
      var ipm = 0.0;

      var valoremSoles = 0.0;
      var igvSoles = 0.0;
      var ipmSoles = 0.0;
      // 1 -> si se cobrra impuesto y 0  no se cobra impueto
      var estadoImpuesto = 0;
      if (precio > 200) {
        //estado del impuesto
        estadoImpuesto = 1;
        //Ad/Valorem
        valorem = (4 / 100) * totalCif;
        valorem = Math.ceil(valorem);
        valoremSoles = valorem * this.changeMoney;
        valoremSoles = Math.ceil(valoremSoles);
        //Igv
        igv = (16 / 100) * (valorem + totalCif);
        igv = Math.ceil(igv);
        igvSoles = igv * this.changeMoney;
        igvSoles = Math.ceil(igvSoles);
        //IPM
        ipm = (2 / 100) * (valorem + totalCif);
        ipm = Math.ceil(ipm);
        ipmSoles = ipm * this.changeMoney;
        ipmSoles = Math.ceil(ipmSoles);
      }
      // Total de impuestos -> valorem + igv + ipm
      var totalImpuesto = valorem + igv + ipm;
      totalImpuesto = Math.ceil(100 * totalImpuesto) / 100;

      var totalImpuestoSoles = valoremSoles + igvSoles + ipmSoles;
      totalImpuestoSoles = Math.ceil(100 * totalImpuestoSoles) / 100;
      //------------------------------------------//

      this.$emit("productoCalculo", {
        servicio: 1,
        producto: {
          nombre: this.product.name,
          precio: this.product.precio,
          peso: this.product.peso * cantidad,
          unidad: this.product.unidad,
          cantidad: this.product.cantidad,
          foto: this.product.foto,
          vaucher: this.product.vaucher,
          description: this.product.description,
          idCotizacion: this.idCotizacion,
        },
        dolares: {
          primera: {
            precio: precio.toFixed(2),
            shipping: shipping.toFixed(2),
            tax: tax.toFixed(2),
            frontTax: (0.0).toFixed(2),
            warehouse: warehouse.toFixed(2),
            flete: flete.toFixed(2),
            total: totalCif.toFixed(2),
            frontTotal: gastosUsa.toFixed(2),
          },
          segundo: {
            estado: estadoImpuesto,
            valorem: valorem.toFixed(2),
            igv: igv.toFixed(2),
            ipm: ipm.toFixed(2),
            total: totalImpuesto.toFixed(2),
          },
        },
        soles: {
          primera: {
            precio: precioSoles.toFixed(2),
            shipping: shipping.toFixed(2),
            tax: taxSoles.toFixed(2),
            frontTax: (0.0).toFixed(2),
            warehouse: warehouse.toFixed(2),
            flete: fleteSoles.toFixed(2),
            total: totalCifSoles.toFixed(2),
            frontTotal: gastosUsaSoles.toFixed(2),
          },
          segundo: {
            estado: estadoImpuesto,
            valorem: valoremSoles.toFixed(2),
            igv: igvSoles.toFixed(2),
            ipm: ipmSoles.toFixed(2),
            total: totalImpuestoSoles.toFixed(2),
          },
        },
      });
    },
    changeUnit(data) {
      this.product.unidad = data;
    },
    cancelImage() {
      this.product.foto = "";
    },
    previewImage(e) {
      const file = e.target.files[0];
      this.cargarImagen(file);
    },
    cargarImagen(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.product.foto = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    factura(e) {
      // const checkFactura = document.getElementById("check_factura"),
      //   containerFactura = document.getElementById("container_factura"),

      // checkFactura.classList.remove("hidden");
      // containerFactura.classList.add("ring");

      this.nameFactura = e.srcElement.files[0].name;
      const file = e.target.files[0];
      this.image = file;
      this.cargarFactura(file);
    },
    cargarFactura(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.product.vaucher = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    saveLocalStorage() {
      const detalleProduct = {};
      detalleProduct.nombre = this.product.name;
      detalleProduct.precio = this.product.precio;
      detalleProduct.peso = this.product.peso;
      detalleProduct.unidad = this.product.unidad;
      detalleProduct.cantidad = this.product.cantidad;
      detalleProduct.foto = this.product.foto;
      detalleProduct.filevoucher = this.product.vaucher;
      detalleProduct.voucher = this.nameFactura;
      const dataCompressProduct = JSON.stringify(detalleProduct);
      window.localStorage.setItem("datalleProduct", dataCompressProduct);
    },
  },
  watch: {
    idCotizacion: function () {
      this.calculo();
    },
    nameFactura: function () {
      this.saveLocalStorage();
    },

    "product.name": function () {
      this.calculo();
      this.saveLocalStorage();
    },
    "product.precio": function () {
      this.calculo();
      this.saveLocalStorage();
    },
    "product.peso": function () {
      this.calculo();
      this.saveLocalStorage();
    },
    "product.unidad": function () {
      this.calculo();
      this.saveLocalStorage();
    },
    "product.cantidad": function () {
      this.calculo();
      this.saveLocalStorage();
    },
    "product.foto": function () {
      this.saveLocalStorage();
      this.calculo();
    },
    "product.vaucher": function () {
      this.calculo();
      this.saveLocalStorage();
    },
    "product.description": function () {
      this.calculo();
    },
    changeMoney: function () {
      this.calculo();
    },
  },
};
</script>
