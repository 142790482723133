<template>
  <a
    @click.prevent="handleClickSignIn"
    class="col-span-2 cursor-pointer text-[10px] sm:text-base flex items-center justify-center gap-4 py-4 border-2 border-gray-300 bg-white rounded-2xl"
  >
    <img
      width="24"
      height="24"
      src="../../../../assets/icons/google.svg"
      alt="Sendi Google"
    />
    <span class="text[10px] font-semibold">GOOGLE</span>
  </a>
</template>
<script>
import { inject, toRefs } from "vue";
import { server } from "../../../../config/global";
import axios from "axios";
const BASE_URL = server.API_URL;

export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },

  data() {
    return {
      codeInfluencer: this.$route.query.invitation,
    };
  },

  methods: {
    async handleClickSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        axios
          .post(`${BASE_URL}` + "client/login-gmail", {
            email: googleUser.getBasicProfile().getEmail(),
            gmail_id: googleUser.getBasicProfile().getId(),
            name: googleUser.getBasicProfile().getName(),
            profile_image_url: googleUser.getBasicProfile().getImageUrl(),
            store_id: 1,
            influencer_code: this.codeInfluencer,
          })
          .then((response) => {
            if (response.status === 200) {
              //Verificamos si relleno su nmero celuarl ,para mostrar el modal

              const dataId = response.data.body.id;
              const dataName =
                response.data.body.attributes.profile.attributes.name;
              const dataLastName =
                response.data.body.attributes.profile.attributes.surnames;
              const dataImage =
                response.data.body.attributes.profile.attributes.userable
                  .attributes.profile_image_url;
              const dataValidateDNI =
                response.data.body.attributes.validation.verified;
              const dataEmail =
                response.data.body.attributes.profile.attributes.userable
                  .attributes.email;

              const dataIdentificador = response.data.body.client_code;
              const type = 2;
              if (response.data.body.attributes.addresses.length !== 0) {
                const data = {};
                data.nombre =
                  response.data.body.attributes.addresses[0].attributes.address;
                data.lat =
                  response.data.body.attributes.addresses[0].attributes.lat;
                data.lng =
                  response.data.body.attributes.addresses[0].attributes.lng;
                data.departamento =
                  response.data.body.attributes.addresses[0].attributes.department;
                data.provincia =
                  response.data.body.attributes.addresses[0].attributes.city;
                data.distrito =
                  response.data.body.attributes.addresses[0].attributes.district;
                data.location =
                  response.data.body.attributes.addresses[0].attributes.free;
                const dataCompress = JSON.stringify(data);
                window.localStorage.setItem("direction", dataCompress);
              }
              const dataUser = {
                globalID: dataId,
                globalName: dataName,
                globalLastName: dataLastName,
                globalImage: dataImage,
                globalValidate: dataValidateDNI,
                glovalEmail: dataEmail,
                identificador: dataIdentificador,
                type: type,
              };
              const dataCompress = JSON.stringify(dataUser);
              window.localStorage.setItem("auth", dataCompress);
              if (this.$route.query.redirect) {
                this.$router.push(String(this.$route.query.redirect));
                window.localStorage.removeItem("open");
              } else {
                location.reload();
                window.localStorage.removeItem("open");
              }
              if (!response.data.body.attributes.profile.attributes.cellphone) {
                window.localStorage.setItem("completecellphone", "true");
              }
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description = error.response.data.message;
              this.$store.state.alert.estado = true;
              //--------------------------//
            } else if (error) {
              //----------component--------//
              this.$store.state.alert.titulo = "Error";
              this.$store.state.alert.description =
                "Error al iniciar sesión con Google";
              this.$store.state.alert.estado = true;
              //--------------------------//
            }
          });
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },

    async handleClickGetAuthCode() {
      try {
        const authCode = await this.$gAuth.getAuthCode();
        console.log("authCode", authCode);
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },

    async handleClickSignOut() {
      try {
        await this.$gAuth.signOut();
        console.log("isAuthorized", this.Vue3GoogleOauth.isAuthorized);
        this.user = "";
      } catch (error) {
        console.error(error);
      }
    },

    handleClickDisconnect() {
      window.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.href}`;
    },
  },
  setup(props) {
    const { isSignIn } = toRefs(props);
    const Vue3GoogleOauth = inject("Vue3GoogleOauth");

    const handleClickLogin = () => {};
    return {
      Vue3GoogleOauth,
      handleClickLogin,
      isSignIn,
    };
  },
};
</script>
